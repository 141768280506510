import { format } from 'date-fns';
import React from 'react';
import { MdInfo } from 'react-icons/md';
import { IOpinion } from '../../../../../Opinions';

import { Container } from './styles';

interface ICardOpinionRequestedProps {
  lastOpinion: IOpinion;
}

const CardOpinionRequested: React.FC<ICardOpinionRequestedProps> = ({
  lastOpinion,
}) => {
  return (
    <Container>
      <MdInfo size={28} color="#FACA00" />
      <h1>Aguardando retorno do parecer</h1>
      <h2>
        {lastOpinion.owner_customer_user?.user.name} -{' '}
        {lastOpinion.created_at &&
          format(new Date(lastOpinion.created_at), 'dd/MM/yyyy')}
      </h2>
    </Container>
  );
};

export default CardOpinionRequested;
