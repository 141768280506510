import React, { useRef, useCallback, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { mutate as mutateGlobal } from 'swr';

import { IoMdAdd } from 'react-icons/io';
import { v4 } from 'uuid';
import {
  Container,
  HeaderDirectorate,
  DepartmentList,
  WrapperName,
  NewDepartment,
} from './styles';
import Modal, { IModalHandles } from '../../../../components/Modal';
import FormDirectorate from '../../forms/FormDirectorate';
import { useFetch } from '../../../../hooks/fetch';
import LoadingMask from '../../../../components/LoadingMask';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import { ICustomerUser } from '../../../CustomerUsers';
import FormDepartment from '../../forms/FormDepartment';
import { ICustomerDepartment } from '../Department';
import DepartmentItem from '../DepartmentItem';
import { useAuth } from '../../../../hooks/auth';

export interface ICustomerDirectorate {
  id: string;
  name: string;
  responsable_customer_user_id: string;
  responsable_customer_user: ICustomerUser | undefined;
  dpl_customer_user_id?: string;
  dpl_customer_user?: ICustomerUser | undefined;
  scoped_customer_departments: ICustomerDepartment[];
}

interface ICustomerDirectorateProps {
  customerDirectorate: ICustomerDirectorate;
}

const Directorate: React.FC<ICustomerDirectorateProps> = ({
  customerDirectorate,
}) => {
  const modalDirectorate = useRef<IModalHandles>();
  const { data: customerDirectorates } = useFetch<ICustomerDirectorate[]>(
    '/customerDirectorates',
  );
  const { data: customerUsers } = useFetch<ICustomerUser[]>('/customerUsers');
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const modalDepartment = useRef<IModalHandles>();
  const {
    isCustomerDPO,
    isCustomerDPL,
    isCustomerResponsableDirectorate,
  } = useAuth();

  const handleSubmitFormEditDirectorate = useCallback(
    async (newCustomerDirectorate: ICustomerDirectorate) => {
      try {
        modalDirectorate.current?.close();
        await api.patch(
          `/customerDirectorates/${customerDirectorate.id}`,
          newCustomerDirectorate,
        );
        mutateGlobal(
          '/customerDirectorates',
          async (oldCustomerDirectorates: ICustomerDirectorate[]) => {
            return oldCustomerDirectorates.map(cd =>
              cd.id === customerDirectorate.id ? newCustomerDirectorate : cd,
            );
          },
          false,
        );
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar salvar',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, customerDirectorate.id],
  );

  const handleEditDirectorate = useCallback(() => {
    modalDirectorate.current?.open();
  }, []);

  const handleDeleteDirectorate = useCallback(async () => {
    try {
      setLoading(true);
      modalDirectorate.current?.close();
      await api.delete(`/customerDirectorates/${customerDirectorate?.id}`);
      mutateGlobal(
        '/customerDirectorates',
        async (oldCustomerDirectorates: ICustomerDirectorate[]) => {
          return oldCustomerDirectorates.filter(
            cd => cd.id !== customerDirectorate.id,
          );
        },
        false,
      );
    } catch (error) {
      addToast({
        title: 'Erro',
        type: 'error',
        description: 'Falha ao tentar remover',
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, customerDirectorate]);

  const handleSubmitFormNewDepartment = useCallback(
    async (newCustomerDepartment: ICustomerDepartment) => {
      setLoading(true);
      try {
        modalDepartment.current?.close();
        await api.post('/customerDepartments', newCustomerDepartment);

        mutateGlobal(
          '/customerDirectorates',
          async (oldCustomerDirectorates: ICustomerDirectorate[]) => {
            return oldCustomerDirectorates.map(cd => {
              if (cd.id === customerDirectorate.id) {
                return {
                  ...cd,
                  scoped_customer_departments: [
                    ...cd.scoped_customer_departments,
                    newCustomerDepartment,
                  ],
                };
              }
              return cd;
            });
          },
          false,
        );
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar salvar',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, customerDirectorate.id],
  );

  const handleNewDepartment = useCallback(() => {
    modalDepartment.current?.open();
  }, []);

  if (!customerDirectorates) {
    return <LoadingMask text="Carregando..." />;
  }

  return (
    <>
      {loading ? <LoadingMask text="Salvando" /> : null}
      <Container>
        <HeaderDirectorate>
          <WrapperName>
            <h1>
              Diretoria: <span>{customerDirectorate.name}</span>
            </h1>
            {(isCustomerDPO || isCustomerDPL) && (
              <button type="button" onClick={handleEditDirectorate}>
                <MdEdit />
              </button>
            )}
          </WrapperName>
          <h3>
            {customerDirectorate.responsable_customer_user?.user.name ||
              'Responsável indefinido'}
          </h3>
        </HeaderDirectorate>
        <DepartmentList>
          {(isCustomerDPO ||
            isCustomerDPL ||
            isCustomerResponsableDirectorate) && (
            <NewDepartment onClick={handleNewDepartment}>
              <IoMdAdd size={24} />
              <h1>Criar departamento</h1>
            </NewDepartment>
          )}
          {customerDirectorate.scoped_customer_departments.map(
            customerDepartment => (
              <DepartmentItem
                key={customerDepartment.id}
                customer_department={customerDepartment}
              />
            ),
          )}
        </DepartmentList>
      </Container>
      <Modal ref={modalDirectorate} size="sm" removeCloseButton>
        <FormDirectorate
          initialData={customerDirectorate}
          customerUsers={customerUsers || []}
          onSubmit={handleSubmitFormEditDirectorate}
          onCancel={() => modalDirectorate.current?.close()}
          onDelete={handleDeleteDirectorate}
        />
      </Modal>
      <Modal ref={modalDepartment} size="sm" removeCloseButton>
        <FormDepartment
          initialData={{
            id: v4(),
            name: '',
            scoped_customer_processes: [],
            responsable_customer_user_id: '',
            customer_directorate_id: customerDirectorate.id,
            customer_directorate: undefined,
            responsable_customer_user: undefined,
            percent_filled: 0,
          }}
          customerUsers={customerUsers || []}
          customerDirectorates={customerDirectorates}
          onCancel={() => modalDepartment.current?.close()}
          onSubmit={handleSubmitFormNewDepartment}
        />
      </Modal>
    </>
  );
};

export default Directorate;
