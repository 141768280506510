import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 110px 49px 42px;
  overflow-y: auto;
  width: 100%;
`;

export const ProcessDiagramHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 43px 56px;

  > div {
    > h1 {
      text-align: left;
      font-size: 20px;
      letter-spacing: 0px;
      color: #3f536e;
    }
    > h2 {
      text-align: left;
      font-size: 15px;
      letter-spacing: 0px;
      color: #8dabc4;
      font-weight: 400;
    }
  }
`;

export const ProcessDiagramMain = styled.div`
  margin: 0px 56px 56px 56px;
  border-top: 1px solid #c5d9e8;
  padding-top: 50px;

  display: grid;
  grid-template-columns: 5fr 1fr 5fr 1fr 5fr 1fr 5fr;
  grid-gap: 10px;
`;

export const Wrapper = styled.div`
  background: #f6f8fa 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 13px 31px;

  > div.Line1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > div.Line2 {
    margin-top: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
