import React from 'react';

import { Container } from './styles';

interface ICardOpinionFieldProps {
  name: string;
  text?: string;
}

const CardOpinionField: React.FC<ICardOpinionFieldProps> = ({ name, text }) => {
  return (
    <Container className="CardOpinionField">
      <h1>{name}</h1>
      <div>
        <h2>{text}</h2>
      </div>
    </Container>
  );
};

export default CardOpinionField;
