import React from 'react';
import { FcOk } from 'react-icons/fc';
import { Container } from './styles';

interface PasswordRequirementsProps {
  requirements: {
    minLength: boolean;
    number: boolean;
    uppercase: boolean;
    lowercase: boolean;
    specialChar: boolean;
    noSequential: boolean;
    noRepetition: boolean;
  };
}

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  requirements,
}) => {
  return (
    <Container className="PasswordRequirements">
      Sua senha deve conter no mínimo: <br />
      <div>{requirements.minLength && <FcOk />} 10 caracteres;</div>
      <div>{requirements.number && <FcOk />} 1 número;</div>
      <div>{requirements.uppercase && <FcOk />} 1 letra maiúscula;</div>
      <div>{requirements.lowercase && <FcOk />} 1 letra minúscula;</div>
      <div>
        {requirements.specialChar && <FcOk />} 1 caractere especial (!, @, #, $,
        &, etc.);
      </div>
      <div>
        {requirements.noSequential && requirements.noRepetition && <FcOk />} Não
        deve possuir sequências simples ou repetições de caracteres
        consecutivos.
      </div>
    </Container>
  );
};

export default PasswordRequirements;
