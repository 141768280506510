import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { FiSearch, FiMoreVertical } from 'react-icons/fi';
import { MdAdd, MdEdit, MdDelete } from 'react-icons/md';
import { format } from 'date-fns';
import {
  useHistory,
  Link,
  useRouteMatch,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import arraySort from 'array-sort';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { cache } from 'swr';
import {
  Container,
  Header,
  Pagination,
  ItemPage,
  OptionsContainer,
} from './styles';
import InputOnly from '../../components/InputOnly';
import Button from '../../components/Button';
import Table from '../../components/Table';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import SuspensePainel from '../../components/SuspensePainel';
import IconOrderBy from '../../components/IconOrderBy';
import { useFetch } from '../../hooks/fetch';
import PartnerNew from './PartnerNew';
import PartnerDetails from './PartnerDetails';
import { IUser } from '../SignIn';

export interface IPartnerUser {
  id: string;
  partner_id?: string;
  user_id?: string;
  telephone: string;
  resume: string;
  specialty: string;
  user: IUser;
  can_receive_opinion_busines: boolean;
  can_receive_opinion_juridical: boolean;
  can_receive_opinion_compliance: boolean;
  can_receive_opinion_security: boolean;
}

export interface IPartner {
  id: string | undefined;
  name: string;
  register_number: string;
  address: string;
  number: number | undefined;
  complement: string;
  district: string;
  zipcode: string;
  city: string;
  state: string;
  technical_hourly_value: number | undefined;
  partnerUsers: IPartnerUser[];
  created_at: Date;
}

interface IOrderBy {
  fieldName: string;
  ascendant: boolean;
}

const Partners: React.FC = () => {
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [partnersToDisplay, setPartnersToDisplay] = useState<{
    filtered: number;
    partners: IPartner[];
  }>({ filtered: 0, partners: [] });
  const { addToast } = useToast();
  const history = useHistory();
  const [inputFilter, setInputFilter] = useState('');
  const [orderBy, setOrderBy] = useState<IOrderBy>({
    fieldName: 'name',
    ascendant: false,
  });
  const match = useRouteMatch();
  const location = useLocation();

  const { data: partners, mutate } = useFetch<IPartner[]>('/partners');

  const handleRemovePartner = useCallback(
    async (id: string | undefined) => {
      const oldPartners = cache.get('/partners');
      api.delete<IPartner[]>(`/partners/${id}`).catch(() => {
        mutate(oldPartners);
        addToast({
          type: 'error',
          title: 'Erro de remoção',
          description: 'Falha para remover parceiro.',
        });
      });
      const updatedPartners = partners?.filter(t => t.id !== id);
      mutate(updatedPartners, false);
    },
    [addToast, mutate, partners],
  );

  const handleEditPartner = useCallback(
    async (id: string | undefined) => {
      history.push(`/home/partners/${id}/edit`);
    },
    [history],
  );

  const handleOrderBy = useCallback(
    ({ fieldName, ascendant }: IOrderBy) => {
      setOrderBy({
        fieldName,
        ascendant:
          fieldName === orderBy.fieldName ? !orderBy.ascendant : ascendant,
      });
    },
    [orderBy.ascendant, orderBy.fieldName],
  );

  useEffect(() => {
    if (partners) {
      if (!inputFilter.length) {
        setPartnersToDisplay({ filtered: partners?.length, partners });
      }
      const filtered = partners?.filter(
        partner =>
          partner.name?.toLowerCase().includes(inputFilter.toLowerCase()) ||
          partner.register_number
            ?.toLowerCase()
            .includes(inputFilter.toLowerCase()) ||
          partner.city?.toLowerCase().includes(inputFilter.toLowerCase()) ||
          partner.state?.toLowerCase().includes(inputFilter.toLowerCase()) ||
          partner.partnerUsers?.some(pu =>
            pu.user.name.toLowerCase().includes(inputFilter.toLowerCase()),
          ),
      );

      if (filtered) {
        const ordered = arraySort(filtered, orderBy.fieldName, {
          reverse: orderBy.ascendant,
        });

        const paged = ordered.slice(
          (page - 1) * perPage,
          (page - 1) * perPage + perPage,
        );

        if (partners)
          setPartnersToDisplay({ filtered: filtered.length, partners: paged });
      }
    }
  }, [
    inputFilter,
    orderBy.ascendant,
    orderBy.fieldName,
    page,
    partners,
    perPage,
  ]);

  useEffect(() => {
    setPage(1);
  }, [inputFilter]);

  const pages = useMemo(() => {
    if (!partnersToDisplay.filtered) return 1;
    return Math.ceil(partnersToDisplay.filtered / perPage);
  }, [partnersToDisplay.filtered, perPage]);

  const pageNumbers = useMemo(() => {
    const maxDisplayPages = 15;
    const diff = Math.ceil(maxDisplayPages / 2);
    let start = 0;
    if (pages > maxDisplayPages && page > pages - diff) {
      start = pages - maxDisplayPages;
    } else if (pages > maxDisplayPages && page > diff) {
      start = page - diff;
    } else {
      start = 0;
    }
    const limiters = { start, end: start + maxDisplayPages };

    const pns: number[] = [];
    for (let i = 1; i <= pages; i += 1) {
      pns.push(i);
    }
    return pns.slice(limiters.start, limiters.end);
  }, [page, pages]);

  const handleSetPageUp = useCallback(() => {
    if (page < pages) setPage(page + 1);
  }, [page, pages]);

  const handleSetPageDown = useCallback(() => {
    if (page > 1) setPage(page - 1);
  }, [page]);

  if (location.pathname !== '/home/partners') {
    return (
      <Switch>
        <Route path={`${match.path}/new`} component={PartnerNew} />
        <Route path={`${match.path}/:id`} component={PartnerDetails} />
      </Switch>
    );
  }

  return (
    <Container>
      <h1>Parceiros</h1>
      <Header>
        <InputOnly
          name="search"
          icon={FiSearch}
          onChange={e => setInputFilter(e.target.value)}
        />
        <Link to="/home/partners/new">
          <Button icon={MdAdd}>Novo parceiro</Button>
        </Link>
      </Header>
      <Table>
        <thead>
          <tr className="Header">
            <th style={{ width: '35%' }}>
              <div>
                <span>EMPRESA</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'name'}
                  onClick={() =>
                    handleOrderBy({ fieldName: 'name', ascendant: false })
                  }
                />
              </div>
            </th>
            <th style={{ width: '20%' }}>
              {' '}
              <div>
                <span>CNPJ</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'register_number'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'register_number',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>
            <th style={{ width: '12%' }}>
              {' '}
              <div>
                <span>LOCAL</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'city'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'city',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>
            <th style={{ width: '18%' }}>
              {' '}
              <div>
                <span>CADASTRO</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'created_at'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'created_at',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>
            <th style={{ width: '10%' }}>
              {' '}
              <div>
                <span>USUÁRIOS</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'partnerUsers'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'partnerUsers',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>
            <th style={{ width: '5%' }} />
          </tr>
        </thead>
        {!partners && (
          <tbody>
            <tr>
              <td colSpan={6}>Carregando ...</td>
            </tr>
          </tbody>
        )}
        {partners && !partners.length && (
          <tbody>
            <tr>
              <td colSpan={6}>Nenhum parceiro encontrado</td>
            </tr>
          </tbody>
        )}
        <tbody>
          {partnersToDisplay.partners.map(partner => (
            <tr
              key={partner.id}
              onClick={() => history.push(`/home/partners/${partner.id}`)}
            >
              <td>{partner.name}</td>
              <td>{partner.register_number}</td>
              <td>
                {partner.city}{' '}
                {partner.city && partner.state && ` - ${partner.state}`}
              </td>
              <td>{format(new Date(partner.created_at), 'dd/MMM/yyyy')}</td>
              <td style={{ textAlign: 'right', paddingRight: '5px' }}>
                {partner.partnerUsers.length}
              </td>
              <td
                className="Icons"
                style={{
                  paddingRight: '10px',
                }}
              >
                <SuspensePainel icon={FiMoreVertical}>
                  <OptionsContainer>
                    <button
                      type="button"
                      onClick={event => {
                        event.stopPropagation();
                        handleEditPartner(partner.id);
                      }}
                    >
                      <MdEdit size={20} />
                      <h1>Editar Parceiro</h1>
                    </button>
                    <button
                      type="button"
                      onClick={event => {
                        event.stopPropagation();
                        handleRemovePartner(partner.id);
                      }}
                    >
                      <MdDelete size={20} />
                      <h1>Remover Parceiro</h1>
                    </button>
                  </OptionsContainer>
                </SuspensePainel>
              </td>
            </tr>
          ))}
          <tr className="Pagination">
            <td colSpan={6}>
              <Pagination>
                <li>
                  <IoMdArrowDropleft
                    size={18}
                    onClick={() => handleSetPageDown()}
                  />
                </li>
                {pageNumbers.map(pg => (
                  <ItemPage key={pg} active={pg === page}>
                    <button type="button" onClick={() => setPage(pg)}>
                      {pg}
                    </button>
                  </ItemPage>
                ))}
                <li>
                  <IoMdArrowDropright
                    size={18}
                    onClick={() => handleSetPageUp()}
                  />
                </li>
              </Pagination>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default Partners;
