/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { IProject } from '..';

export interface IProjectPercent {
  percentAssessment: number;
  percentRecommendation: number;
}

export const getPercent = (project: IProject): IProjectPercent => {
  const totalNumberOfQuestionsAnswered = project.scopedModules.reduce(
    (accM, m) =>
      (accM += m.categories.reduce(
        (accC, c) => (accC += c.questions.length),
        0,
      )),
    0,
  );

  const totalNumberOfQuestionsToRecommend = project.scopedModules.reduce(
    (accM, m) =>
      (accM += m.categories.reduce(
        (accC, c) =>
          (accC += c.questions.filter(
            q =>
              (q.score || q.score === 0) &&
              (q.score_required || q.score_required === 0) &&
              q.score < q.score_required,
          ).length),
        0,
      )),
    0,
  );

  const numberOfQuestionsAnswered = project.scopedModules.reduce(
    (accM, m) =>
      (accM += m.categories.reduce(
        (accC, c) => (accC += c.questions.filter(q => q.answer).length),
        0,
      )),
    0,
  );

  const numberOfQuestionsRecommended = project.scopedModules.reduce(
    (accM, m) =>
      (accM += m.categories.reduce(
        (accC, c) =>
          (accC += c.questions.filter(
            q =>
              (q.score || q.score === 0) &&
              (q.score_required || q.score_required === 0) &&
              q.score < q.score_required &&
              q.recommendation,
          ).length),
        0,
      )),
    0,
  );

  return {
    percentAssessment: Number(
      (
        (numberOfQuestionsAnswered * 100) / totalNumberOfQuestionsAnswered || 0
      ).toFixed(0),
    ),
    percentRecommendation: Number(
      (
        (numberOfQuestionsRecommended * 100) /
          totalNumberOfQuestionsToRecommend || 0
      ).toFixed(0),
    ),
  };
};
