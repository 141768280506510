/* eslint-disable no-param-reassign */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import uniqueObjects from 'unique-objects';
import { Container, Header, Main, Footer } from './styles';
import Button from '../../../../components/Button';
import { IRiskType } from '../../../Opinions';
import Select3 from '../../../../components/Select3';
import Option3 from '../../../../components/Select3/Option';

import { IAssessmentFilter } from '../..';
import {
  IProjectQuestion,
  IProjectModule,
  IProject,
  IProjectCategory,
} from '../../../Projects';

interface IFormProps {
  projectQuestions: IProjectQuestion[];
  initialData?: IAssessmentFilter | undefined;
  onSubmit(newAssessmentFilter: IAssessmentFilter): void;
  onClear(): void;
}

interface IAssessmentFilterFormData {
  risks: IRiskType[];
  projectId: string[];
  moduleId: string[];
  category: string[];
  status: string[];
}

const FormAssessmentFilter: React.FC<IFormProps> = ({
  projectQuestions,
  initialData,
  onSubmit,
  onClear,
}) => {
  const form = useRef<FormHandles>(null);

  const [projectSelectedFilter, setProjectSelectedFilter] = useState<string[]>(
    [],
  );

  const projects: IProject[] = uniqueObjects(
    projectQuestions.map(p => {
      return { id: p.project?.id, name: p.project?.name };
    }),
    ['id', 'name'],
  ) as IProject[];

  useEffect(() => {
    if (initialData) {
      setProjectSelectedFilter(initialData.projectId);
    }
  }, [initialData]);

  const modules: IProjectModule[] = uniqueObjects(
    projectQuestions.map(p => {
      return {
        id: p.project_module_id,
        name: p.project_module?.name,
        project_id: p.project_id,
        project: { name: p.project?.name },
      };
    }),
    ['id', 'name'],
  ) as IProjectModule[];

  const categories: IProjectCategory[] = uniqueObjects(
    projectQuestions.map(p => {
      return {
        id: p.project_category_id,
        name: p.project_category?.name,
        project_id: p.project_id,
        project: { name: p.project?.name },
      };
    }),
    ['id', 'name'],
  ) as IProjectCategory[];

  const handleSubmit = useCallback(
    async ({
      risks,
      projectId,
      moduleId,
      category,
      status,
    }: IAssessmentFilterFormData) => {
      const newAssessmentFilter: IAssessmentFilter = {
        risks,
        projectId,
        moduleId,
        category,
        status,
      };
      onSubmit(newAssessmentFilter);
    },
    [onSubmit],
  );

  return (
    <>
      <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
        <Header>
          <h1>FILTROS</h1>
        </Header>
        <Main>
          <div className="Line1">
            <Select3 name="risks" label="Risco" multiSelection>
              <Option3 key={5} value={5} label="Muito Baixo" />
              <Option3 key={4} value={4} label="Baixo" />
              <Option3 key={3} value={3} label="Médio" />
              <Option3 key={2} value={2} label="Alto" />
              <Option3 key={1} value={1} label="Muito Alto" />
            </Select3>
          </div>
          <div className="Line3">
            <Select3
              name="projectId"
              label="Projeto"
              multiSelection
              onSelection={sel => {
                setProjectSelectedFilter(sel.map(s => s.value));
              }}
            >
              {projects.map(project => {
                return (
                  <Option3
                    key={project.id}
                    label={project.name}
                    value={project.id}
                  />
                );
              })}
            </Select3>
          </div>

          <div className="Line3">
            <Select3
              name="moduleId"
              label="Módulo"
              disabled={!projectSelectedFilter.length}
              multiSelection
            >
              {modules
                .filter(module =>
                  projectSelectedFilter.includes(module.project_id),
                )
                .map(module => {
                  return (
                    <Option3
                      key={`${module.id}-${module.project_id}`}
                      label={module.name}
                      subLabel={module.project?.name}
                      value={`${module.id}-${module.project_id}`}
                    />
                  );
                })}
            </Select3>
          </div>
          <div className="Line4">
            <Select3
              name="category"
              label="Categoria"
              disabled={!projectSelectedFilter.length}
              multiSelection
            >
              {categories
                .filter(category =>
                  projectSelectedFilter.includes(category.project_id),
                )
                .map(category => {
                  return (
                    <Option3
                      key={`${category.id}-${category.project_id}`}
                      label={category.name}
                      subLabel={category.project?.name}
                      value={`${category.id}-${category.project_id}`}
                    />
                  );
                })}
            </Select3>
          </div>
          <div className="Line5">
            <Select3 label="Status" name="status" multiSelection>
              <Option3 key="Pendente" value="Pendente" label="Pendente">
                Pendente
              </Option3>
              <Option3
                key="Em_Andamento"
                value="Em Andamento"
                label="Em Andamento"
              >
                Em Andamento
              </Option3>
              <Option3 key="Finalizado" value="Finalizado" label="Finalizado">
                Finalizado
              </Option3>
            </Select3>
          </div>
        </Main>
        <Footer>
          <Button background="transparent" color="#A8C6DF" onClick={onClear}>
            Limpar
          </Button>
          <Button type="submit" background="#0079C4">
            Aplicar
          </Button>
        </Footer>
      </Container>
    </>
  );
};

export default FormAssessmentFilter;
