import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.label`
  display: flex;
  align-items: center !important;
  justify-content: flex-start !important;
  padding: 0px !important;

  > input {
    margin-left: 16px;
    margin-right: 10px;
    height: 40px;
  }

  > button {
    flex: 1;
    margin: 0px;
    padding-left: 16px;
    background-color: transparent;
    height: 40px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #3f536e;
  }

  border: 0px;
  color: #3f536e;
  background: #ecf5fd;
  padding: 8px 15px;
  z-index: 51;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;

  &:hover {
    background: ${shade(0.05, '#ecf5fd')};
  }
`;
