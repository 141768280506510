import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  /* background-color: black; */
`;

export const Content = styled.div`
  position: absolute;
  top: calc(100%);
  right: calc(100% - 10px);
  background-color: #ffffff;
  box-shadow: 0px 1px 4px #2c405a35;
  border: 1px solid #b7d2e5cc;
  border-radius: 4px;
  opacity: 1;
  z-index: 51;
  padding: 2px;
`;
