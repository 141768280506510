import React from 'react';
import { IProcessStatus } from '../..';
import Badge from '../../../../../../components/Badge';

import { Container } from './styles';

interface IProcessStatusProps {
  value: IProcessStatus;
}

const processStatus = [
  { value: 'Preenchimento', color: '#FACA00' },
  { value: 'Revalidação', color: '#FD7B1F' },
  { value: 'Acompanhamento', color: '#0079C4' },
  { value: 'Encerrado', color: '#FFAFB2' },
  { value: 'Certificado', color: '#4ECE3D' },
];

const ProcessStatus: React.FC<IProcessStatusProps> = ({ value }) => {
  return (
    <Container>
      <Badge
        value={value}
        color={processStatus.find(d => d.value === value)?.color}
        background="#FFF"
        bordered
      />
    </Container>
  );
};

export default ProcessStatus;
