import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useContext,
} from 'react';
import decode from 'jwt-decode';
import { cache } from 'swr';
import api from '../services/api';
import { ICustomerUser } from '../pages/CustomerUsers';

interface IAuthState {
  token: string;
  user: IUser;
  customerUser: ICustomerUser | undefined;
  roles: string[];
  customer_roles: string[];
}

interface ISignInCredentials {
  email: string;
  password: string;
  ip?: string;
}

interface IToken {
  roles: string[];
  customer_roles: string[];
}

interface IUser {
  avatar: string;
  avatar_url: string;
  email: string;
  id: string;
  name: string;
  created_at: Date;
  updated_at: Date;
}

interface IAuthContextData {
  user: IUser;
  customerUser?: ICustomerUser;
  roles: string[];
  isAdmin: boolean;
  isPartnerConsultant: boolean;
  isCustomerDPO: boolean;
  isCustomerDPL: boolean;
  isCustomerResponsableDirectorate: boolean;
  isCustomerResponsableDepartment: boolean;
  isCustomerResponsableProcess: boolean;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem('@DNASec:token');
    const user = localStorage.getItem('@DNASec:user');
    const customerUser = localStorage.getItem('@DNASec:customerUser');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      const { roles, customer_roles } = decode<IToken>(token);
      return {
        token,
        user: JSON.parse(user),
        customerUser: customerUser ? JSON.parse(customerUser) : undefined,
        roles,
        customer_roles,
      };
    }

    return {} as IAuthState;
  });

  const signIn = useCallback(async ({ email, password, ip }) => {
    const responseAuth = await api.post('sessions', { email, password, ip });

    const { token, user, customerUser } = responseAuth.data;
    api.defaults.headers.authorization = `Bearer ${token}`;
    const { roles, customer_roles } = decode<IToken>(token);

    localStorage.setItem('@DNASec:token', token);
    localStorage.setItem('@DNASec:user', JSON.stringify(user));
    if (customerUser)
      localStorage.setItem(
        '@DNASec:customerUser',
        JSON.stringify(customerUser),
      );

    setData({ token, user, customerUser, roles, customer_roles });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@DNASec:token');
    localStorage.removeItem('@DNASec:user');
    localStorage.removeItem('@DNASec:customerUser');
    cache.clear();
    setData({} as IAuthState);
  }, []);

  const isAdmin = useMemo(() => {
    return data.roles?.includes('Administrator');
  }, [data]);

  const isPartnerConsultant = useMemo(() => {
    return data.roles?.includes('Partner');
  }, [data]);

  const isCustomerDPO = useMemo(() => {
    return data.customer_roles?.includes('DPO');
  }, [data]);

  const isCustomerDPL = useMemo(() => {
    return data.customer_roles?.includes('DPL');
  }, [data]);

  const isCustomerResponsableDirectorate = useMemo(() => {
    return data.customer_roles?.includes('Responsable Directorate');
  }, [data]);

  const isCustomerResponsableDepartment = useMemo(() => {
    return data.customer_roles?.includes('Responsable Department');
  }, [data]);

  const isCustomerResponsableProcess = useMemo(() => {
    return data.customer_roles?.includes('Responsable Process');
  }, [data]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        customerUser: data.customerUser,
        roles: data.roles,
        signIn,
        signOut,
        isAdmin,
        isPartnerConsultant,
        isCustomerDPO,
        isCustomerDPL,
        isCustomerResponsableDirectorate,
        isCustomerResponsableDepartment,
        isCustomerResponsableProcess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
