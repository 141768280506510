import styled from 'styled-components';
import ExpansionPainel from '../../../../../components/ExpansionPainel';

export const Container = styled(ExpansionPainel)``;

export const OptionsContainerTemplateModules = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 16px;
    background-color: #ffffff;
    border: 0px;
    cursor: pointer;

    h1 {
      text-align: left;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #3f536e;
      width: 120px;
    }

    svg {
      color: #8dabc4;
      margin-right: 16px;
    }

    &:hover {
      background-color: #fafbfc;
    }
  }
`;

export const Categories = styled.div`
  padding: 0px 16px;
  > div + div {
    margin-top: 10px;
  }
  > h3 {
    font-size: 14px;
    padding: 16px 24px;
    font-weight: 400;
  }
`;
