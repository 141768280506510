/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useState, useCallback, useRef } from 'react';

import { IoIosArrowBack } from 'react-icons/io';
import { BiCaretUpCircle } from 'react-icons/bi';
import { MdCheck } from 'react-icons/md';
import {
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
  Route,
  Link,
} from 'react-router-dom';
import { mutate as mutateGlobal, cache } from 'swr';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import ExpansionPainel from '../../../../components/ExpansionPainel';
import LoadingMask from '../../../../components/LoadingMask';
import Modal, { IModalHandles } from '../../../../components/Modal';
import Spinner from '../../../../components/Spinner';
import { useAuth } from '../../../../hooks/auth';
import { useFetch } from '../../../../hooks/fetch';
import { useToast } from '../../../../hooks/toast';
import { ExpansionContent } from '../../../Customers/CustomerNew/styles';
import { ICustomerUser } from '../../../CustomerUsers';
import { IOpinions } from '../../../Opinions';

import { ICustomerDepartment } from '../Department';
import { ICustomerDirectorate } from '../Directorate';
import ProcessDiagram from '../ProcessDiagram';
import ProcessOpinions from '../ProcessOpinions';
import ProcessStatus from './components/ProcessStatus';
import QuisProgressBar from './components/QuizProgressBar';
import ModalConfirm from './components/FinalizeProcess';
import FormProcessCollect from './forms/FormCollect';
import FormProcessConsent from './forms/FormConsent';
import FormProcessDiscard from './forms/FormDiscard';
import FormProcessShare from './forms/FormShare';
import FormProcessStore from './forms/FormStore';
import FormProcessTreatment from './forms/FormTreatment';

import {
  Container,
  Header,
  ProcessHeader,
  WrapperButtons,
  ProcessMain,
  Motivation,
  Quiz,
  ProcessFooter,
  WrapperHeader,
} from './styles';

export type IProcessStatus =
  | 'Preenchimento'
  | 'Revalidação'
  | 'Acompanhamento'
  | 'Encerrado'
  | 'Certificado';

export type IProcessOpinionType =
  | 'busines'
  | 'compliance'
  | 'juridical'
  | 'security';

interface IDataCollect {
  percent_filled: number;
  fields: {
    objective?: string;
    data_options_way?: string[];
    way?: string;
    data_what?: string[];
    data_what_hypothesis_types?: string[];
    data_by_who?: string[];
    legislation?: string;
    origin_collect?: string[];
    data_sensitive?: string[];
    data_children?: string[];
    firstrecording_collect?: boolean;
  };
}

interface IDataConsent {
  percent_filled: number;
  fields: {
    consent?: string;
    how?: string;
    who?: string;
    storage?: string;
    has_traceability?: string;
    firstrecording_consent?: boolean;
  };
}

interface IDataTreatment {
  percent_filled: number;
  fields: {
    purpose?: string;
    who?: string;
    who_access?: string;
    firstrecording_treatment?: boolean;
  };
}

interface IDataShare {
  percent_filled: number;
  fields: {
    use_sharing?: string;
    what?: (string | undefined)[];
    with_whom?: string[];
    by_whom?: string[];
    objective?: string;
    data_share_sending_personal_data?: string[];
    how?: string;
    shared?: string;
    data_share_international_companies?: string[];
    firstrecording_share?: boolean;
  };
}

interface IDataStore {
  percent_filled: number;
  fields: {
    where?: string;
    has_legislation?: string;
    has_anonymization?: string;
    who_access?: string;
    firstrecording_store?: boolean;
  };
}

interface IDataDiscard {
  percent_filled: number;
  fields: {
    discard_how?: string;
    use_discard?: string;
    reasons_save?: string;
    firstrecording_discard?: boolean;
  };
}

export interface IDataReasonSaveData {
  id: IDataCollect;
  name: IDataConsent;
}

export interface IDataProcess {
  collect: IDataCollect;
  consent: IDataConsent;
  treatment: IDataTreatment;
  share: IDataShare;
  store: IDataStore;
  discard: IDataDiscard;
}

export interface ICustomerProcess {
  id: string;
  cod: string;
  name: string;
  description: string;
  reopened_process: boolean;
  responsable_customer_user_id: string;
  responsable_customer_user?: ICustomerUser | undefined;
  customer_directorate_id: string;
  customer_directorate?: ICustomerDirectorate | undefined;
  customer_department_id: string;
  customer_department?: ICustomerDepartment | undefined;
  status: IProcessStatus;
  formated_opinions: IOpinions;
  data: IDataProcess;
  percent_filled: number;
}

export interface ICustomerProcessData {
  id: string;
  name: string;
  customer_process_id: string;
}

export interface ICustomerOriginCollectTypes {
  id: string;
  name: string;
}

export interface ICustomerTreatmentHypothesisTypes {
  id: string;
  type: string;
  name: string;
}

export interface ICustomerCollectPersonalDataTypes {
  id: string;
  name: string;
}

export interface ICustomerProcessWho {
  id: string;
  name: string;
  customer_process_id: string;
}

export interface ICustomerProcessShareWithWhom {
  id: string;
  name: string;
  customer_process_id: string;
}

export interface ICustomerProcessShareByWhom {
  id: string;
  name: string;
  customer_process_id: string;
}

export interface ICustomerSendingPersonalData {
  id: string;
  name: string;
}

export interface ICountry {
  id: string;
  name: string;
}

export const getPercentFilled = (dataObj: any, module: string): number => {
  if (!dataObj) {
    return 0;
  }

  const arrData = Object.keys(dataObj);

  let valor = 0;

  arrData.forEach(value => {
    if (
      value != 'data_sensitive' &&
      value != 'data_children' &&
      value != 'way' &&
      value != 'legislation' &&
      value != 'data_share_international_companies' &&
      value != 'shared' &&
      value != 'how' &&
      value != 'firstrecording_collect' &&
      value != 'firstrecording_consent' &&
      value != 'firstrecording_treatment' &&
      value != 'firstrecording_share' &&
      value != 'firstrecording_store' &&
      value != 'firstrecording_discard'
    ) {
      valor += 1;
    }
  });

  let filled = 0;
  let useConsent = true;
  let useShared = true;
  let contDiscard = 0;

  Object.keys(dataObj).forEach(key => {
    if (
      key != 'data_sensitive' &&
      key != 'data_children' &&
      key != 'way' &&
      key != 'legislation' &&
      key != 'data_share_international_companies' &&
      key != 'shared' &&
      key != 'how' &&
      key != 'firstrecording_collect' &&
      key != 'firstrecording_consent' &&
      key != 'firstrecording_treatment' &&
      key != 'firstrecording_share' &&
      key != 'firstrecording_store' &&
      key != 'firstrecording_discard'
    ) {
      if (key === 'consent') {
        if (dataObj[key] === 'false') {
          useConsent = false;
        }
      }

      if (key === 'use_sharing') {
        if (dataObj[key] === 'false') {
          useShared = false;
        }
      }

      if (useConsent === false) {
        switch (key) {
          case 'has_traceability':
            filled += 1;
            useConsent = true;
            break;
          default:
            filled += 1;
        }
      } else if (useShared === false) {
        switch (key) {
          case 'data_share_sending_personal_data':
            filled += 1;
            useShared = true;
            break;
          default:
            filled += 1;
        }
      }

      // DISCARD
      else if (
        key === 'discard_how' ||
        key === 'reasons_save' ||
        key === 'use_discard'
      ) {
        contDiscard += 1;
        if (dataObj[key] && key === 'use_discard') {
          filled += 1;
        }

        if (dataObj[key] && key === 'discard_how') {
          filled += 1;
        }

        if (dataObj[key] && key === 'reasons_save') {
          filled += 1;
        }

        if (contDiscard === 3) {
          valor -= 1;
        }
      } else if (Array.isArray(dataObj[key]) && dataObj[key].length) {
        filled += 1;
      } else if (
        !Array.isArray(dataObj[key]) &&
        (dataObj[key] || dataObj[key] === '')
      ) {
        filled += 1;
      }

      //Verifica se nunca foi respondida a entrevista.
      if (key === 'firstrecording_collect') {
        if (dataObj[key] === false) {
          filled = 0;
        }
      }
      if (key === 'firstrecording_consent') {
        if (dataObj[key] === false) {
          filled = 0;
        }
      }
      if (key === 'firstrecording_treatment') {
        if (dataObj[key] === false) {
          filled = 0;
        }
      }
      if (key === 'firstrecording_share') {
        if (dataObj[key] === false) {
          filled = 0;
        }
      }
      if (key === 'firstrecording_store') {
        if (dataObj[key] === false) {
          filled = 0;
        }
      }
      if (key === 'firstrecording_discard') {
        if (dataObj[key] === false) {
          filled = 0;
        }
      }
    }
  });

  let result = Number(((filled * 100) / valor).toFixed(0));
  if (result > 100) {
    result = 100;
  }

  return result;
};

export const getAllPercentFilled = (
  customer_process: ICustomerProcess,
): ICustomerProcess => {
  const new_customer_process = {
    ...customer_process,
    data: {
      ...customer_process?.data,
      collect: {
        ...customer_process?.data.collect,
        percent_filled: getPercentFilled(
          customer_process.data.collect.fields,
          'collect',
        ),
      },
      consent: {
        ...customer_process?.data.consent,
        percent_filled: getPercentFilled(
          customer_process.data.consent.fields,
          'consent',
        ),
      },
      treatment: {
        ...customer_process?.data.treatment,
        percent_filled: getPercentFilled(
          customer_process.data.treatment.fields,
          'treatment',
        ),
      },
      share: {
        ...customer_process?.data.share,
        percent_filled: getPercentFilled(
          customer_process.data.share.fields,
          'share',
        ),
      },
      store: {
        ...customer_process?.data.store,
        percent_filled: getPercentFilled(
          customer_process.data.store.fields,
          'store',
        ),
      },
      discard: {
        ...customer_process?.data.discard,
        percent_filled: getPercentFilled(
          customer_process.data.discard.fields,
          'discard',
        ),
      },
    },
  };

  let percentTotal =
    Object.keys(new_customer_process.data).reduce((acc, key) => {
      let calc = false;
      if (
        (new_customer_process.data as any)[key].fields
          .firstrecording_collect === true
      ) {
        calc = true;
      }
      if (
        (new_customer_process.data as any)[key].fields
          .firstrecording_consent === true
      ) {
        calc = true;
      }
      if (
        (new_customer_process.data as any)[key].fields
          .firstrecording_treatment === true
      ) {
        calc = true;
      }
      if (
        (new_customer_process.data as any)[key].fields.firstrecording_share ===
        true
      ) {
        calc = true;
      }
      if (
        (new_customer_process.data as any)[key].fields.firstrecording_store ===
        true
      ) {
        calc = true;
      }
      if (
        (new_customer_process.data as any)[key].fields
          .firstrecording_discard === true
      ) {
        calc = true;
      }

      return (acc +=
        calc === true
          ? (new_customer_process.data as any)[key].percent_filled
          : 0);
      // BACKUP COMENTADO CONFORME REUNIÃO COM EQUIPE DO DNASec
      // }, 0) / 7;
    }, 0) / 6;

  if (percentTotal > 100) {
    percentTotal = 100;
  }

  new_customer_process.percent_filled = percentTotal;

  return new_customer_process;
};

export const mutateProcess = async (
  customer_process: ICustomerProcess,
  action: 'add' | 'edit' | 'delete',
): Promise<void> => {
  const newFilledCustomerProcess = getAllPercentFilled(customer_process);
  let newFilledCustomerDepartment: ICustomerDepartment;

  // PROCESS
  await mutateGlobal(
    `/customerProcesses/${customer_process.id}`,
    newFilledCustomerProcess,
    false,
  );

  // DEPARTMENT
  const customerDepartment: ICustomerDepartment = cache.get(
    `/customerDepartments/${customer_process.customer_department_id}`,
  );
  if (customerDepartment) {
    if (action === 'add') {
      newFilledCustomerDepartment = {
        ...customerDepartment,
        scoped_customer_processes: [
          ...customerDepartment.scoped_customer_processes,
          customer_process,
        ],
      };
    } else if (action === 'edit') {
      newFilledCustomerDepartment = {
        ...customerDepartment,
        scoped_customer_processes:
          customerDepartment.scoped_customer_processes.map(cp => {
            if (cp.id === customer_process.id) {
              return newFilledCustomerProcess;
            }
            return cp;
          }),
      };
    } else {
      newFilledCustomerDepartment = {
        ...customerDepartment,
        scoped_customer_processes:
          customerDepartment.scoped_customer_processes.filter(
            cp => cp.id !== customer_process.id,
          ),
      };
    }

    // SET DEPARTMENT PERCENT FILLED
    newFilledCustomerDepartment.percent_filled =
      newFilledCustomerDepartment.scoped_customer_processes.reduce(
        (acc, cp) => {
          return (acc += cp.percent_filled);
        },
        0,
      ) / newFilledCustomerDepartment.scoped_customer_processes.length;

    await mutateGlobal(
      `/customerDepartments/${customer_process.customer_department_id}`,
      newFilledCustomerDepartment,
      false,
    );
  }

  // DIRECTORATE
  const customerDirectorates: ICustomerDirectorate[] = cache.get(
    '/customerDirectorates',
  );
  if (customerDirectorates) {
    await mutateGlobal(
      '/customerDirectorates',
      customerDirectorates.map(cd => {
        if (cd.id === customer_process.customer_directorate_id) {
          const newDirectorate: ICustomerDirectorate = {
            ...cd,
            scoped_customer_departments: cd.scoped_customer_departments.map(
              cdep => {
                if (cdep.id === customer_process.customer_department_id) {
                  return newFilledCustomerDepartment;
                }
                return cdep;
              },
            ),
          };
          return newDirectorate;
        }
        return cd;
      }),
      false,
    );
  }
};

const Process: React.FC = () => {
  const modalConfirm = useRef<IModalHandles>();
  const cardElementHeight = useRef<HTMLDivElement>(null);
  const [confirmSaving, setConfirmSaving] = useState<ICustomerProcess>();
  const [isElementHeight, setElementHeight] = useState(false);
  const closedModal = useCallback(() => {
    modalConfirm.current?.close();
  }, []);

  const { id: idProcess } = useParams<{ id: string }>();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { isCustomerDPO, isCustomerDPL } = useAuth();
  const [usesConsent, setUsesConsent] = useState<boolean | undefined>();

  const elementHeight = useCallback(() => {}, []);

  const { data: customer_process } = useFetch<ICustomerProcess>(
    `/customerProcesses/${idProcess}`,
  );

  const { data: customer_data } =
    useFetch<ICustomerProcessData[]>(`/customerProcessData`);

  const { data: customerOriginCollectTypes } = useFetch<
    ICustomerOriginCollectTypes[]
  >(`/customerOriginCollectTypes`);

  const { data: customer_treatment_hypothesis_types } = useFetch<
    ICustomerTreatmentHypothesisTypes[]
  >(`/customerTreatmentHypothesisTypes`);

  const { data: customer_collect_personal_data_types } = useFetch<
    ICustomerCollectPersonalDataTypes[]
  >(`/customerCollectPersonalDataTypes`);

  const { data: customer_who } =
    useFetch<ICustomerProcessWho[]>(`/customerProcessWho`);

  const { data: customer_process_share_with_whom } = useFetch<
    ICustomerProcessShareWithWhom[]
  >(`/customerProcessShareWithWhom`);

  const { data: customer_process_share_by_whom } = useFetch<
    ICustomerProcessShareByWhom[]
  >(`/customerProcessShareByWhom`);

  const { data: customerSendingPersonalData } =
    useFetch<ICustomerSendingPersonalData[]>(`/sendingPersonalData`);

  const { data: customerReasonSaveData } =
    useFetch<IDataReasonSaveData[]>(`/reasonsSaveData`);

  const { data: countries } = useFetch<ICountry[]>(`/country`);

  const handleFinalizeFilled = useCallback(async () => {
    if (customer_process) {
      if (customer_process.percent_filled === 100) {
        setLoading(true);
        try {
          const new_customer_process: ICustomerProcess = {
            ...customer_process,
            status: 'Acompanhamento',
            reopened_process: false,
            formated_opinions: {
              busines: {
                ...customer_process.formated_opinions.busines,
                status: 'Recomendado',
              },
              compliance: {
                ...customer_process.formated_opinions.compliance,
                status: 'Recomendado',
              },
              juridical: {
                ...customer_process.formated_opinions.juridical,
                status: 'Recomendado',
              },
              security: {
                ...customer_process.formated_opinions.security,
                status: 'Recomendado',
              },
            },
          };

          setConfirmSaving(new_customer_process);
          modalConfirm.current?.open();
        } catch (error) {
          addToast({
            title: 'Erro',
            type: 'error',
            description: 'Falha ao tentar atualizar processo',
          });
        } finally {
          setLoading(false);
        }
      } else {
        addToast({
          title: 'Aviso',
          type: 'info',
          description:
            'Todos os campos devem ser preenchidos antes da finalização desta etapa.',
        });
      }
    }
  }, [addToast, customer_process]);

  const onUsesConsent = useCallback(async (val: boolean | undefined) => {
    setUsesConsent(val);
  }, []);

  window.addEventListener(
    'scroll',
    function () {
      if (
        cardElementHeight.current != null &&
        cardElementHeight.current.clientHeight > 758
      ) {
        setElementHeight(true);
      } else {
        setElementHeight(false);
      }
    },
    true,
  );

  if (
    !customer_process ||
    !customer_data ||
    !customer_who ||
    !customer_process_share_with_whom ||
    !customer_process_share_by_whom ||
    !customerOriginCollectTypes ||
    !customer_treatment_hypothesis_types ||
    !customer_collect_personal_data_types
  ) {
    return <LoadingMask text="Carregando..." />;
  }

  if (
    location.pathname !==
    `/home/data-mapping/department/${customer_process.customer_department_id}/process/${idProcess}`
  ) {
    return (
      <Switch>
        <Route path={`${match.path}/opinions`} component={ProcessOpinions} />
        <Route path={`${match.path}/diagram`} component={ProcessDiagram} />
      </Switch>
    );
  }

  return (
    <>
      {loading ? <LoadingMask text="Atualizando" /> : null}
      <Container isElementHeight={isElementHeight}>
        <Header id="boxChild">
          <IoIosArrowBack size={24} onClick={() => history.goBack()} />
          <div>
            <h1>{customer_process.customer_directorate?.name} /</h1>
            <h2>{customer_process.customer_department?.name} /</h2>
            <h3>{customer_process.name}</h3>
          </div>
        </Header>
        <a className="ButtonTop" href="#boxChild">
          <BiCaretUpCircle size={60} />
        </a>
        {customer_process ? (
          <>
            <div ref={cardElementHeight} onClick={() => elementHeight()}>
              <Card>
                <ProcessHeader>
                  <WrapperHeader>
                    <div>
                      <h1>{customer_process.name}</h1>
                      <h3>
                        {customer_process.responsable_customer_user?.user
                          .name || 'Responsável indefinido'}
                      </h3>
                    </div>

                    <ProcessStatus value={customer_process.status} />
                  </WrapperHeader>

                  <WrapperButtons>
                    {(isCustomerDPO || isCustomerDPL) && (
                      <Link
                        to={`/home/data-mapping/department/${customer_process.customer_department_id}/process/${customer_process.id}/opinions`}
                      >
                        <Button background="#0079C4">Pareceres</Button>
                      </Link>
                    )}

                    <Link
                      to={`/home/data-mapping/department/${customer_process.customer_department_id}/process/${customer_process.id}/diagram`}
                    >
                      <Button background="#0079C4">Diagrama</Button>
                    </Link>
                  </WrapperButtons>
                </ProcessHeader>
                <ProcessMain>
                  <Motivation>
                    <h1>Motivação</h1>
                    <h2>{customer_process.description}</h2>
                  </Motivation>
                  <Quiz>
                    <ExpansionPainel
                      title="COLETA"
                      barComponent={() => (
                        <QuisProgressBar
                          percent={
                            customer_process.data.collect.fields
                              .firstrecording_collect === true
                              ? customer_process.data.collect.percent_filled
                              : 0
                          }
                        />
                      )}
                    >
                      <ExpansionContent>
                        <FormProcessCollect
                          initialData={customer_process}
                          customer_data={customer_data}
                          customer_who={customer_who}
                          customer_process_share_by_whom={
                            customer_process_share_by_whom
                          }
                          customer_origin_collect_types={
                            customerOriginCollectTypes
                          }
                          customer_treatment_hypothesis_types={
                            customer_treatment_hypothesis_types
                          }
                          customer_collect_personal_data_types={
                            customer_collect_personal_data_types
                          }
                        />
                      </ExpansionContent>
                    </ExpansionPainel>
                    <ExpansionPainel
                      title="CONSENTIMENTO"
                      barComponent={() => (
                        <QuisProgressBar
                          percent={
                            customer_process.data.consent.fields
                              .firstrecording_consent === true
                              ? customer_process.data.consent.percent_filled
                              : 0
                          }
                        />
                      )}
                    >
                      <ExpansionContent>
                        <FormProcessConsent
                          initialData={customer_process}
                          callbackUsesConsent={(val: boolean | undefined) =>
                            onUsesConsent(val)
                          }
                        />
                      </ExpansionContent>
                    </ExpansionPainel>
                    <ExpansionPainel
                      title="TRATAMENTO"
                      barComponent={() => (
                        <QuisProgressBar
                          percent={
                            customer_process.data.treatment.fields
                              .firstrecording_treatment === true
                              ? customer_process.data.treatment.percent_filled
                              : 0
                          }
                        />
                      )}
                    >
                      <ExpansionContent>
                        <FormProcessTreatment initialData={customer_process} />
                      </ExpansionContent>
                    </ExpansionPainel>
                    <ExpansionPainel
                      title="COMPARTILHAMENTO"
                      barComponent={() => (
                        <QuisProgressBar
                          percent={
                            customer_process.data.share.fields
                              .firstrecording_share === true
                              ? customer_process.data.share.percent_filled
                              : 0
                          }
                        />
                      )}
                    >
                      <ExpansionContent>
                        <FormProcessShare
                          initialData={customer_process}
                          customer_who={customer_who}
                          customer_process_share_by_whom={
                            customer_process_share_by_whom
                          }
                          customer_process_share_with_whom={
                            customer_process_share_with_whom
                          }
                          customerSendingPersonalData={
                            customerSendingPersonalData
                          }
                          uses_Consent={usesConsent}
                          countries={countries}
                        />
                      </ExpansionContent>
                    </ExpansionPainel>
                    <ExpansionPainel
                      title="ARMAZENAMENTO"
                      barComponent={() => (
                        <QuisProgressBar
                          percent={
                            customer_process.data.store.fields
                              .firstrecording_store === true
                              ? customer_process.data.store.percent_filled
                              : 0
                          }
                        />
                      )}
                    >
                      <ExpansionContent>
                        <FormProcessStore initialData={customer_process} />
                      </ExpansionContent>
                    </ExpansionPainel>
                    {
                      // BACKUP COMENTADO CONFORME REUNIÃO COM EQUIPE DO DNASec
                      // Não excluir,deixar comentado pois pode voltar a ser utilizado.
                      /* {<ExpansionPainel
                    title="BACKUP"
                    barComponent={() => (
                      <QuisProgressBar
                        percent={customer_process.data.backup.percent_filled}
                      />
                    )}
                  >
                    <ExpansionContent>
                      <FormProcessBackup initialData={customer_process} />
                    </ExpansionContent>
                  </ExpansionPainel>} */
                    }
                    <ExpansionPainel
                      title="DESCARTE"
                      barComponent={() => (
                        <QuisProgressBar
                          percent={
                            customer_process.data.discard.fields
                              .firstrecording_discard === true
                              ? customer_process.data.discard.percent_filled
                              : 0
                          }
                        />
                      )}
                    >
                      <ExpansionContent>
                        <FormProcessDiscard
                          initialData={customer_process}
                          listReasonsSaveData={customerReasonSaveData}
                        />
                      </ExpansionContent>
                    </ExpansionPainel>{' '}
                  </Quiz>
                </ProcessMain>
                <ProcessFooter>
                  {(customer_process.status === 'Preenchimento' ||
                    customer_process.reopened_process === true) && (
                    <Button onClick={handleFinalizeFilled} icon={MdCheck}>
                      Finalizar Preenchimento
                    </Button>
                  )}

                  {customer_process.status === 'Revalidação' && (
                    <Button
                      onClick={handleFinalizeFilled}
                      icon={MdCheck}
                      background="#FD7B1F"
                    >
                      Finalizar Revalidação
                    </Button>
                  )}
                </ProcessFooter>
              </Card>
            </div>
          </>
        ) : (
          <Card>
            <Spinner />
          </Card>
        )}
        <Modal ref={modalConfirm} removeCloseButton size="sm">
          <ModalConfirm
            new_customer_process={confirmSaving as ICustomerProcess}
            onCancel={() => modalConfirm.current?.close()}
            onSubmit={closedModal}
          />
        </Modal>
      </Container>
    </>
  );
};

export default Process;
