import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';

interface IItemMenuProps {
  selected?: boolean;
}

export const Container = styled(Link)<IItemMenuProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  color: grey;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  &:hover {
    background: ${shade(0.2, '#2c405a')};
  }

  ${props =>
    props.selected &&
    css`
      color: #ffffff;
      background: ${shade(0.2, '#2c405a')};

      &::before {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #ffffff;
      }
    `}
`;
