import React, { useContext, useMemo } from 'react';

import { toRoman } from 'roman-numerals';
import currency from 'currency-formatter';

import {
  Container,
  Modules,
  ExpansionContentModule,
  Categories,
  ExpansionContentCategory,
  Questions,
} from './styles';
import { ProjectContext } from '..';
import ExpansionPainel from '../../../components/ExpansionPainel';
import { useFetch } from '../../../hooks/fetch';
import { IPartner } from '../../Partners';
import ComercialQuestion from './components/ComercialQuestion';
import Spinner from '../../../components/Spinner';

const CommercialTable: React.FC = () => {
  const { project } = useContext(ProjectContext);

  const { data: partners } = useFetch<IPartner[]>('/partners', {
    revalidateOnFocus: false,
  });

  const modulesFiltered = useMemo(() => {
    return project.scopedModules.filter(
      module =>
        module.status === 'Finalizado' &&
        module.categories.every(c =>
          c.questions
            .filter(
              q =>
                (q.score || q.score === 0) &&
                (q.score_required || q.score_required === 0) &&
                q.score < q.score_required,
            )
            .every(q => q.recommendation),
        ),
    );
  }, [project.scopedModules]);

  if (!partners) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <>
      <Container>
        {modulesFiltered.length ? (
          <Modules>
            <div className="HeaderCost">
              <h1>Total do projeto:</h1>
              <h2>{currency.format(project.cost || 0, { code: 'BRL' })}</h2>
            </div>
            {modulesFiltered.map((module, i) => (
              <ExpansionPainel
                key={module.id}
                preTitle={`MODULO ${toRoman(i + 1)}`}
                preTitleWidth="110px"
                title={module.name}
                titleColor="#0079c4"
                clean
                barComponent={() => (
                  <>
                    <h1
                      style={{
                        color: '#8DABC4',
                        fontSize: '15px',
                        fontWeight: 300,
                      }}
                    >
                      Total do módulo:
                    </h1>
                    <h2
                      style={{
                        color: '#0079C4',
                        fontWeight: 500,
                        marginLeft: '25px',
                        fontSize: '15px',
                        width: '120px',
                        textAlign: 'right',
                      }}
                    >
                      {currency.format(module.cost || 0, { code: 'BRL' })}
                    </h2>
                  </>
                )}
              >
                <ExpansionContentModule>
                  <Categories>
                    {!module?.categories.length && (
                      <h3>Nenhum categoria criada</h3>
                    )}
                    {module?.categories.map((category, y) => (
                      <ExpansionPainel
                        key={category.id}
                        preTitle={(y + 1).toString()}
                        preTitleWidth="10px"
                        preTitleColor="#3F536E"
                        title={category.name}
                        titleFontSize="12px"
                      >
                        <ExpansionContentCategory>
                          <Questions>
                            {!category?.questions.length && (
                              <h3>Nenhum pergunta criada</h3>
                            )}
                            {category.questions
                              .filter(
                                q =>
                                  (q.score || q.score === 0) &&
                                  (q.score_required ||
                                    q.score_required === 0) &&
                                  q.score < q.score_required,
                              )
                              .map((question, z) => (
                                <ComercialQuestion
                                  key={question.id}
                                  question={question}
                                  partners={partners}
                                />
                              ))}
                          </Questions>
                        </ExpansionContentCategory>
                      </ExpansionPainel>
                    ))}
                  </Categories>
                </ExpansionContentModule>
              </ExpansionPainel>
            ))}
          </Modules>
        ) : (
          <h1>Nenhum plano de recomendação finalizado</h1>
        )}
      </Container>
    </>
  );
};

export default CommercialTable;
