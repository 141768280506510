import React, { useContext } from 'react';

import { IoIosArrowBack } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import { toRoman } from 'roman-numerals';
import { format } from 'date-fns';
import { NumberToAlphabet } from 'number-to-alphabet';

import {
  Container,
  QuizHeader,
  QuizCategories,
  Question,
  ModuleConclusion,
} from './styles';
import Card from '../../../components/Card';
import { ProjectContext, IProjectModule } from '..';
import DisplayField from '../../../components/DisplayField';

import ExpansionPainel from '../../../components/ExpansionPainel';

const defaultAlphabet = new NumberToAlphabet();

const QuizShow: React.FC = () => {
  const history = useHistory();
  const { project } = useContext(ProjectContext);
  const { id: idModule } = useParams<{ id: string }>();
  const module = project.scopedModules.find(
    m => m.id === idModule,
  ) as IProjectModule;
  const index = project.scopedModules.indexOf(module);

  return (
    <Container>
      <header>
        <IoIosArrowBack size={24} onClick={() => history.goBack()} />
        <h1>{`Módulo ${toRoman(index + 1)} - ${module?.name}`}</h1>
      </header>
      <Card>
        <QuizHeader>
          <DisplayField label="Empresa">{project.customer.name}</DisplayField>
          <DisplayField label="Data Prevista">
            {module?.interview?.date
              ? format(new Date(module.interview?.date), 'dd/MMM/yyyy HH:mm')
              : null}
          </DisplayField>
          <DisplayField label="Entrevistado">
            {module?.interview?.customerUser?.user.name}
          </DisplayField>
        </QuizHeader>
        <QuizCategories>
          {module?.categories.map((category, categoryIndex) => (
            <ExpansionPainel
              key={category.id}
              preTitle={(categoryIndex + 1).toString()}
              preTitleWidth="10px"
              preTitleColor="#3F536E"
              title={category.name}
              titleFontSize="12px"
              clean
            >
              {category.questions.map((question, questionIndex) => (
                <Question key={question.id}>
                  <h1>
                    <span>
                      {defaultAlphabet.numberToString(questionIndex + 1)}.
                    </span>
                    {question.question}
                  </h1>
                  <div className="Wrapper">
                    <div className="Line1">
                      <DisplayField label="Resposta">
                        {question.answer}
                      </DisplayField>
                    </div>
                    <div className="Line2">
                      <DisplayField label="Nota">
                        {question.score || question.score === 0
                          ? question.score
                          : 'N/A'}
                      </DisplayField>
                      <DisplayField label="Requerida">
                        {question.score_required ||
                        question.score_required === 0
                          ? question.score_required
                          : 'N/A'}
                      </DisplayField>
                    </div>
                  </div>
                </Question>
              ))}
            </ExpansionPainel>
          ))}
        </QuizCategories>
        <ModuleConclusion>
          <DisplayField label="Conclusão do módulo">
            {module.conclusion}
          </DisplayField>
        </ModuleConclusion>
      </Card>
    </Container>
  );
};

export default QuizShow;
