import React, { useContext, useCallback } from 'react';

import { toRoman } from 'roman-numerals';
import { useHistory } from 'react-router-dom';
import { Container, ModuleDisplay, Wrapper } from './styles';

import { ProjectContext } from '..';
import { useAuth } from '../../../hooks/auth';

const Assessment: React.FC = () => {
  const { project } = useContext(ProjectContext);
  const history = useHistory();
  const { isPartnerConsultant } = useAuth();

  const handleOpenQuiz = useCallback(
    (moduleId: string) => {
      history.push(`/home/projects/${project.id}/quiz/${moduleId}`);
    },
    [history, project.id],
  );

  const handleOpenQuizShow = useCallback(
    (moduleId: string) => {
      history.push(`/home/projects/${project.id}/quizShow/${moduleId}`);
    },
    [history, project.id],
  );

  return (
    <Container>
      <ModuleDisplay>
        {project.scopedModules.map((module, i) => (
          <li key={module.id}>
            <div>
              <h1>{`MODULO ${toRoman(i + 1)}`}</h1>
              <h2>{module.name}</h2>
            </div>

            <Wrapper>
              <h3>
                {module.percentAssessment !== undefined
                  ? `${module.percentAssessment || 0}%`
                  : 'N/A'}
              </h3>

              {isPartnerConsultant &&
              module.status === 'Em Andamento' &&
              project.status !== 'Finalizado' ? (
                <button
                  className="ExecuteButton"
                  type="button"
                  onClick={() => handleOpenQuiz(module.id)}
                >
                  Avaliar
                </button>
              ) : (
                <button
                  className="ShowButton"
                  type="button"
                  onClick={() => handleOpenQuizShow(module.id)}
                >
                  Visualizar
                </button>
              )}
            </Wrapper>
          </li>
        ))}
      </ModuleDisplay>
    </Container>
  );
};

export default Assessment;
