import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  padding: 2rem;
  overflow-y: auto;

  > header {
    align-items: center;
    display: grid;
    grid-template-columns: 0fr 5fr 1.1fr;
    margin-bottom: 17px;
    > svg {
      cursor: pointer;
      color: #8dabc4;
      &:hover {
        color: #0093ee;
      }
    }
    > h1 {
      color: var(--unnamed-color-2c405a);
      text-align: left;
      font-weight: 400;
      font-size: 22px;
      letter-spacing: 0px;
    }
  }

  > h1 {
    text-align: left;
    font-size: 22px;
    letter-spacing: 0px;
    color: #2c405a;
    margin-top: 35px;
    margin-bottom: 20px;
  }
`;

export const Projects = styled.div`
  > ul {
    > li {
      margin-bottom: 12px;
    }

    > h1 {
      color: var(--unnamed-color-3f536e);
      text-align: left;
      font-size: 15px;
      letter-spacing: 0px;
      font-weight: 400;
    }
  }

  > h4 {
    color: var(--unnamed-color-2c405a);
    text-align: left;
    font-weight: 400;
    font-size: 22px;
    letter-spacing: 0px;
    margin-top: 21px;
    margin-bottom: 19px;
  }
`;

export const ExpansionContent = styled.div`
  padding: 24px 38px 27px 54px;
  > div.Line1 {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 32px;
  }
  > div.Line2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 21px;
  }
  > div.Line3 {
    display: flex;
    margin-top: 36px;
    flex-wrap: wrap;

    span.Badge {
      padding: 2px 8px;
      margin-right: 8px;
      margin-top: 8px;
    }
  }
`;

export const CustomerContent = styled.div`
  padding: 59px 103px 55px 103px;

  > h1 {
    text-align: left;
    font-size: 20px;
    letter-spacing: 0px;
    color: #3f536e;
    font-weight: 500;
  }

  > span {
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
    color: #8dabc4;
    font-weight: 400;
    margin-top: 3px;
  }

  div.Location {
    margin-top: 24px;
    h3 {
      text-align: left;
      font-size: 15px;
      letter-spacing: 0px;
      color: #3f536e;
      font-weight: 400;
    }
  }

  h4 {
    margin-top: 24px;
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
    color: #3f536e;
    font-weight: 400;
    span {
      text-align: left;
      letter-spacing: 0px;
      color: #3f536e;
      font-weight: 600;
    }
  }
`;

export const ProjectContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px 34px 21px 106px;

  > & + li {
    margin-top: 12px;
  }

  > h1 {
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #3f536e;
  }

  > div.Right {
    display: flex;
    > h1 {
      text-align: left;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #3f536e;
      width: 80px;
    }

    > span.Badge {
      height: 25px;
      margin: 0px 96px 0px 16px;
      text-align: left;
      font-size: 13px;
      letter-spacing: 0px;

      font-weight: 400;
      background: transparent;

      width: 150px;
    }

    > svg {
      color: #8dabc4;
      cursor: pointer;

      &:hover {
        ${shade(0.2, '#8dabc4')}
      }
    }
  }
`;
