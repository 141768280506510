import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f6f8fa 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 190px;
  width: 100%;

  > h1 {
    margin-top: 10px;
    text-align: center;
    font: normal normal normal 15px/22px Heebo;
    letter-spacing: 0px;
    color: #8dabc4;
  }

  > button.Button {
    margin-top: 24px;
    width: 170px;
  }
`;
