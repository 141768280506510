import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface IContainerProps {
  isElementHeight?: boolean;
}

export const Container = styled.div<IContainerProps>`
  padding: 10px 110px 49px 42px;
  overflow-y: auto;

  > a.ButtonTop {
    float: right;
    //width: calc(100% - 80px);  ->
    margin-top: 40%;


    @media only screen and (max-width: 1590px) {
      margin-top: 45%;
    }


    @media only screen and (max-width: 1490px) {
      margin-top: 55%;
    }

    @media only screen and (max-width: 1280px) {
      margin-top: 65%;
    }

    @media only screen and (max-width: 1150px) {
      margin-top: 75%;
    }

    @media only screen and (max-width: 1000px) {
      margin-top: 85%;
    }



    visibility: hidden;

    ${props =>
      props.isElementHeight &&
      css`
        border-color: #c53030;
        visibility: visible;
      `}

    svg{
      position: fixed;
      color: #0079C4;
      z-index: 9999;

      &:hover {
        color: ${shade('0.4', '#0079C4')};
      }
    }
  }

  > box {
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 0px;
  padding-left: 10px;

  > svg {
    color: #8dabc4;
    cursor: pointer;

    &:hover {
      color: ${shade('0.4', '#8dabc4')};
    }
  }

  > div {
    display: flex;
    margin-left: 36px;
    > h1 {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8dabc4;
    }

    > h2 {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8dabc4;
      padding-left: 3px;
    }

    > h3 {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #2c405a;
      padding-left: 3px;
    }
  }
`;

export const ProcessHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 43px 56px;

  > button.Button {
    width: 200px;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > div {
    margin-right: 30px;
    > h1 {
      text-align: left;
      font-size: 22px;
      letter-spacing: 0px;
      color: #2c405a;
    }
    > h3 {
      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;
      color: #8dabc4;
    }
  }
`;

export const WrapperButtons = styled.div`
  display: flex;

  > a {
    margin-left: 20px;
    width: 150px;
    text-decoration: none;
  }
`;

export const ProcessMain = styled.div`
  padding: 0px 56px 56px 56px;
`;

export const ProcessFooter = styled.div`
  padding: 0px 56px 56px 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button.Button {
    width: 250px;
  }
`;

export const Motivation = styled.div`
  background: #f6f8fa 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 18px 33px;

  > h1 {
    text-align: left;
    font: normal normal normal 15px/22px Heebo;
    letter-spacing: 0px;
    color: #8dabc4;
  }
  > h2 {
    margin-top: 10px;
    text-align: left;
    font: normal normal normal 15px/22px Heebo;
    letter-spacing: 0px;
    color: #3f536e;
  }
`;

export const Quiz = styled.div`
  margin-top: 29px;

  > div.ExpansionPainel + div.ExpansionPainel {
    margin-top: 16px;
  }
`;

