import styled from 'styled-components';

export const Container = styled.div`
  padding: 29px 42px;
  overflow: auto;

  > h1 {
    text-align: left;
    font-size: 22px;
    letter-spacing: 0px;
    color: #2c405a;
    opacity: 1;
    font-weight: 400;
    margin-bottom: 21px;
  }

  > form {
    > div.Line1 {
      display: grid;
      grid-template-columns: 4fr 2fr;
      grid-gap: 32px;
    }
    > div.Line2 {
      display: grid;
      grid-template-columns: 3fr 1fr 2fr;
      grid-gap: 32px;
      margin-top: 38px;
    }
    > div.Line3 {
      display: grid;
      grid-template-columns: 3fr 2fr 3fr 1fr;
      grid-gap: 32px;
      margin-top: 38px;
    }

    > div.Line4 {
      display: grid;
      grid-template-columns: 1fr 5fr;
      grid-gap: 32px;
      margin-top: 38px;
    }

    > div.Content {
      background-color: #ffff;
      border: 1px solid #b7d2e5cc;
      border-radius: 4px 4px 0px 0px;
      padding: 46px 86px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 56px;
  background-color: #ffff;
  border-right: 1px solid #b7d2e5cc;
  border-left: 1px solid #b7d2e5cc;
  border-bottom: 1px solid #b7d2e5cc;
  border-radius: 0px 0px 4px 4px;

  > button {
    width: 160px;

    & + button {
      margin-left: 16px;
    }
  }
`;

export const ModalPartnerUser = styled.div`
  > form {
    > div.Line1 {
      display: grid;
      grid-template-columns: 4fr 2fr;
      grid-gap: 32px;
    }
    > div.Line2 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;

      div.TextArea {
        div {
          height: 90px;
        }
      }
    }
    > div.Line3 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line4 {
      display: grid;
      grid-template-columns: 3fr 2fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > header {
      background-color: #fafbfc;
      padding-top: 40px;
      padding-left: 30px;
      padding-bottom: 12px;
      border-radius: 8px 8px 0px 0px;
      h1 {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: #2c405a;
      }
    }
    > main {
      padding: 30px;
      border-top: 1px solid #c5dbea;
      border-bottom: 1px solid #c5dbea;
    }
    > footer {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      padding: 16px 30px;
      button.Button {
        width: 160px;

        & + button {
          margin-left: 16px;
        }
      }
    }
  }
`;

export const ModalPassword = styled.div`
  > form {
    > header {
      background-color: #fafbfc;
      padding-top: 40px;
      padding-left: 30px;
      padding-bottom: 12px;
      border-radius: 8px 8px 0px 0px;
      h1 {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        color: #2c405a;
      }
    }
    > main {
      padding: 30px;
      border-top: 1px solid #c5dbea;
      border-bottom: 1px solid #c5dbea;
      > h1 {
        text-align: left;
        font-size: 18px;
        letter-spacing: 0px;
        color: #3f536e;
        margin-bottom: 17px;
      }
    }
    > footer {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      padding: 16px 30px;
      button.Button {
        width: 160px;

        & + button {
          margin-left: 16px;
        }
      }
    }
  }
`;

export const ExpansionContent = styled.div`
  > div.Line11 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px;
    button {
      margin-left: 16px;
      text-align: left;
      font-size: 13px;
      letter-spacing: 0px;
      color: #0093ee;
      border: 0px;
      background-color: transparent;
    }
  }
  > div.Line22 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 21px;
  }
  > div.Line33 {
    display: flex;
    margin-top: 36px;

    div.Badge {
      padding: 2px 8px;
    }
  }
`;
