import React, { useState, forwardRef, useImperativeHandle } from 'react';
import ReactDOM from 'react-dom';

import { FiX } from 'react-icons/fi';
import { Container, Backdrop, Content } from './styles';

interface ISideModalProps {
  // ref: MutableRefObject<HTMLDivElement | undefined>;
  ref: any;
  title?: string;
}

export interface ISideModalHandles {
  open(): void;
  close(): void;
}

const SideModal: React.FC<ISideModalProps> = forwardRef<
  ISideModalHandles,
  ISideModalProps
>(({ title, children }, ref) => {
  const [show, setShow] = useState(false);

  const open = (): void => {
    setShow(true);
  };

  const close = (): void => {
    setShow(false);
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        open: () => open(),
        close: () => close(),
      };
    },
    [],
  );

  if (show) {
    const el = document.getElementById('modal-root');

    if (!el) {
      throw new Error('Element #modal-root not found in public/index.html');
    }

    return ReactDOM.createPortal(
      <Container>
        <Backdrop onClick={close} />
        <Content>
          <button type="button" onClick={close}>
            <FiX size={18} />
          </button>
          {children}
        </Content>
      </Container>,
      el,
    );
  }

  return null;
});

export default SideModal;
