import styled, { css } from 'styled-components';

interface IProgressBarProps {
  percent: number;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  width: 90%;

  > div {
    margin-left: 14px;
    width: 100%;
    height: 5px;
    background-color: #fff;
    border-radius: 18px;
  }
`;

export const ProgressBar = styled.div<IProgressBarProps>`
  ${props =>
    css`
      width: ${props.percent || 0}%;
    `}
  height: 5px;
  background: #0079c4 0% 0% no-repeat padding-box;
  border-radius: 18px;
`;
