import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Modules = styled.div`
  > div + div {
    margin-top: 20px;
  }

  > h1 {
    color: var(--unnamed-color-3f536e);
    text-align: left;
    font-size: 13px;
    letter-spacing: 0px;
    font-weight: 400;
  }
`;

export const Categories = styled.div`
  > div + div {
    margin-top: 10px;
  }
  > h3 {
    font-size: 14px;
    padding: 16px 24px;
    font-weight: 400;
  }
`;

export const Questions = styled.ul`
  list-style-type: none;
  > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;

    div.Title {
      display: flex;
      h1 {
        font-size: 14px;
        font-weight: 500;
        width: 40px;
      }

      h2 {
        font-size: 14px;
        font-weight: 400;
        flex: 1;
      }
    }
    div.Buttons {
      > button.ExecuteButton {
        width: 180px;
        font-weight: 400;
      }

      > button.ShowButton {
        width: 180px;
        font-weight: 400;
      }
    }

    & + li {
      border-top: 1px solid #d8e5ee;
    }
  }

  > h3 {
    font-size: 14px;
    padding: 16px 24px;
    font-weight: 400;
  }
`;

export const ExpansionContentModule = styled.div`
  padding: 16px;
`;

export const ExpansionContentCategory = styled.div``;

export const ShowQuestion = styled.div`
  header {
    background-color: #fafbfc;
    padding-top: 40px;
    padding-left: 60px;
    padding-bottom: 12px;
    border-radius: 8px 8px 0px 0px;
    h1 {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #2c405a;
    }
  }

  main {
    padding: 30px 60px;
    border-top: 1px solid #c5dbea;
    border-bottom: 1px solid #c5dbea;
    height: 60vh;
    overflow-y: auto;
    > div.Line1 {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr;
      grid-gap: 32px;
    }
    > div.Line2 {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line3 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line4 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line5 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line6 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line7 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line8 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line9 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
  }

  footer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    padding: 24px 56px;
    background-color: #ffff;
    border-right: 1px solid #b7d2e5cc;
    border-left: 1px solid #b7d2e5cc;
    border-bottom: 1px solid #b7d2e5cc;
    border-radius: 0px 0px 4px 4px;

    > button {
      width: 160px;

      & + button {
        margin-left: 16px;
      }
    }
  }
`;

export const Header = styled.header`
  background-color: #fafbfc;
  padding-top: 40px;
  padding-left: 60px;
  padding-bottom: 12px;
  border-radius: 8px 8px 0px 0px;
  h1 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #2c405a;
  }
`;
