import React, { useState, useRef, useCallback } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import LoadingMask from '../../../../components/LoadingMask';
import Modal, { IModalHandles } from '../../../../components/Modal';
import { useAuth } from '../../../../hooks/auth';

import { useFetch } from '../../../../hooks/fetch';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import { IOpinion } from '../../../Opinions';

import FormRequestOpinions, {
  IRequestOpinions,
} from '../../forms/FormRequestOpinions';

import { ICustomerProcess, mutateProcess } from '../Process';

import OpinionGroup from './OpinionGroup';

import { AiOutlineQuestionCircle } from 'react-icons/ai';

import {
  Container,
  Header,
  HeaderTabs,
  ProcessOpinionsHeader,
  ProcessOpinionsMain,
  Tab,
  TabHeader,
  TabsProject,
  ContentProcessOpinions,
} from './styles';

const ProcessOpinions: React.FC = () => {
  const { id: idProcess } = useParams<{ id: string }>();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('Negócios');
  const modalRequestOpinions = useRef<IModalHandles>();
  const { addToast } = useToast();
  const { customerUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const { data: customer_process } = useFetch<ICustomerProcess>(
    `/customerProcesses/${idProcess}`,
  );

  const handleRequestOpinions = useCallback(() => {
    modalRequestOpinions.current?.open();
  }, []);

  const handleRequestOpinion = useCallback(
    async (data: IRequestOpinions) => {
      if (customer_process) {
        let newCustomerProcess: ICustomerProcess = customer_process;
        let newOpinionBusines: IOpinion | undefined;

        // BUSINES
        if (data.busines) {
          const currentDate = new Date();
          newOpinionBusines = {
            id: v4(),
            customer_directorate_id: newCustomerProcess.customer_directorate_id,
            customer_department_id: newCustomerProcess.customer_department_id,
            customer_process_id: newCustomerProcess.id,
            owner_customer_user_id: customerUser?.id,
            owner_customer_user: customerUser,
            created_at: currentDate,
            updated_at: currentDate,
            type: 'busines',
          };
          newCustomerProcess = {
            ...newCustomerProcess,
            formated_opinions: {
              ...newCustomerProcess.formated_opinions,
              busines: {
                ...newCustomerProcess.formated_opinions.busines,
                status: 'Solicitado',
                opinions: [
                  ...newCustomerProcess.formated_opinions.busines.opinions,
                  newOpinionBusines,
                ],
              },
            },
          };
          try {
            await api.post('/opinions', newOpinionBusines);
          } catch (error) {
            addToast({
              title: 'Erro',
              type: 'error',
              description: `Falha ao solicitar parecer de Negócios`,
            });
          }
        }

        // COMPLIANCE
        if (data.compliance) {
          const currentDate = new Date();
          newOpinionBusines = {
            id: v4(),
            customer_directorate_id: newCustomerProcess.customer_directorate_id,
            customer_department_id: newCustomerProcess.customer_department_id,
            customer_process_id: newCustomerProcess.id,
            owner_customer_user_id: customerUser?.id,
            owner_customer_user: customerUser,
            created_at: currentDate,
            updated_at: currentDate,
            type: 'compliance',
          };
          newCustomerProcess = {
            ...newCustomerProcess,
            formated_opinions: {
              ...newCustomerProcess.formated_opinions,
              compliance: {
                ...newCustomerProcess.formated_opinions.compliance,
                status: 'Solicitado',
                opinions: [
                  ...newCustomerProcess.formated_opinions.compliance.opinions,
                  newOpinionBusines,
                ],
              },
            },
          };
          try {
            await api.post('/opinions', newOpinionBusines);
          } catch (error) {
            addToast({
              title: 'Erro',
              type: 'error',
              description: `Falha ao solicitar parecer de Negócios`,
            });
          }
        }

        // JURIDICAL
        if (data.juridical) {
          const currentDate = new Date();
          newOpinionBusines = {
            id: v4(),
            customer_directorate_id: newCustomerProcess.customer_directorate_id,
            customer_department_id: newCustomerProcess.customer_department_id,
            customer_process_id: newCustomerProcess.id,
            owner_customer_user_id: customerUser?.id,
            owner_customer_user: customerUser,
            created_at: currentDate,
            updated_at: currentDate,
            type: 'juridical',
          };
          newCustomerProcess = {
            ...newCustomerProcess,
            formated_opinions: {
              ...newCustomerProcess.formated_opinions,
              juridical: {
                ...newCustomerProcess.formated_opinions.juridical,
                status: 'Solicitado',
                opinions: [
                  ...newCustomerProcess.formated_opinions.juridical.opinions,
                  newOpinionBusines,
                ],
              },
            },
          };
          try {
            await api.post('/opinions', newOpinionBusines);
          } catch (error) {
            addToast({
              title: 'Erro',
              type: 'error',
              description: `Falha ao solicitar parecer de Negócios`,
            });
          }
        }

        // SECURITY
        if (data.security) {
          const currentDate = new Date();
          newOpinionBusines = {
            id: v4(),
            customer_directorate_id: newCustomerProcess.customer_directorate_id,
            customer_department_id: newCustomerProcess.customer_department_id,
            customer_process_id: newCustomerProcess.id,
            owner_customer_user_id: customerUser?.id,
            owner_customer_user: customerUser,
            created_at: currentDate,
            updated_at: currentDate,
            type: 'security',
          };
          newCustomerProcess = {
            ...newCustomerProcess,
            formated_opinions: {
              ...newCustomerProcess.formated_opinions,
              security: {
                ...newCustomerProcess.formated_opinions.security,
                status: 'Solicitado',
                opinions: [
                  ...newCustomerProcess.formated_opinions.security.opinions,
                  newOpinionBusines,
                ],
              },
            },
          };
          try {
            await api.post('/opinions', newOpinionBusines);
          } catch (error) {
            addToast({
              title: 'Erro',
              type: 'error',
              description: `Falha ao solicitar parecer de Negócios`,
            });
          }
        }

        if (newCustomerProcess) {
          await mutateProcess(newCustomerProcess, 'edit');
        }
      }
    },
    [addToast, customerUser, customer_process],
  );

  const handleSubmitRequestOpinions = useCallback(
    async (data: IRequestOpinions) => {
      modalRequestOpinions.current?.close();
      setLoading(true);
      await handleRequestOpinion(data);
      setLoading(false);
    },
    [handleRequestOpinion],
  );

  if (!customer_process) {
    return <LoadingMask text="Carregando..." />;
  }

  return (
    <>
      {loading ? <LoadingMask text="Solicitando ..." /> : null}
      <Container>
        <Header>
          <IoIosArrowBack size={24} onClick={() => history.goBack()} />
          <div>
            <h1>{customer_process.customer_directorate?.name} /</h1>
            <h2>{customer_process.customer_department?.name} /</h2>
            <h3>{customer_process?.name} /</h3>
            <h4>Pareceres</h4>
          </div>
        </Header>
        {customer_process ? (
          <Card>
            <ProcessOpinionsHeader>
              <h1>Pareceres</h1>
              <Button background="#0079C4" onClick={handleRequestOpinions}>
                Solicitar parecer
              </Button>
            </ProcessOpinionsHeader>
            <ProcessOpinionsMain>
              <TabsProject>
                <HeaderTabs>
                  <TabHeader
                    className={activeTab === 'Negócios' ? 'active' : undefined}
                    onClick={() => setActiveTab('Negócios')}
                  >
                    <h1>Negócios</h1>
                    <AiOutlineQuestionCircle
                      size={20}
                      title={`Os pareceres de negócios avaliam a utilização
                              dos dados pessoais em todas as etapas do
                              processo.`}
                      color="rgb(0,121,196)"
                      className="Icon"
                    />
                  </TabHeader>

                  <TabHeader
                    className={activeTab === 'Jurídico' ? 'active' : undefined}
                    onClick={() => setActiveTab('Jurídico')}
                  >
                    <h1>Jurídico</h1>
                    <AiOutlineQuestionCircle
                      size={20}
                      title="Os pareceres jurídicos avaliam a aderência do
                      processo às bases legais."
                      color="rgb(0,121,196)"
                      className="Icon"
                    />
                  </TabHeader>

                  <TabHeader
                    className={
                      activeTab === 'Tecnologia' ? 'active' : undefined
                    }
                    onClick={() => setActiveTab('Tecnologia')}
                  >
                    <h1>Tecnologia</h1>
                    <AiOutlineQuestionCircle
                      size={20}
                      title="Os pareceres de tecnologia abrangem temas relacionados
                      á segurança de informação, infraestrutura,
                      monitoramento, softwares ou banco de dados."
                      color="rgb(0,121,196)"
                      className="Icon"
                    />
                  </TabHeader>

                  <TabHeader
                    className={
                      activeTab === 'Compliance' ? 'active' : undefined
                    }
                    onClick={() => setActiveTab('Compliance')}
                  >
                    <h1>Compliance</h1>
                    <AiOutlineQuestionCircle
                      size={20}
                      title="Os pareceres relacionados ao compliance
                      estão relacionados à gestão de contratos."
                      color="rgb(0,121,196)"
                      className="Icon"
                    />
                  </TabHeader>
                </HeaderTabs>
              </TabsProject>
              <TabsProject className="TabsProjectContent">
                <ContentProcessOpinions>
                  {activeTab === 'Negócios' && (
                    <Tab>
                      <OpinionGroup
                        label="de negócios"
                        customer_group_opinion={
                          customer_process.formated_opinions.busines
                        }
                        modal_request_opinion={modalRequestOpinions}
                      />
                    </Tab>
                  )}
                  {activeTab === 'Compliance' && (
                    <Tab>
                      <OpinionGroup
                        label="de compliance"
                        customer_group_opinion={
                          customer_process.formated_opinions.compliance
                        }
                        modal_request_opinion={modalRequestOpinions}
                      />
                    </Tab>
                  )}
                  {activeTab === 'Jurídico' && (
                    <Tab>
                      <OpinionGroup
                        label="jurídico"
                        customer_group_opinion={
                          customer_process.formated_opinions.juridical
                        }
                        modal_request_opinion={modalRequestOpinions}
                      />
                    </Tab>
                  )}
                  {activeTab === 'Tecnologia' && (
                    <Tab>
                      <OpinionGroup
                        label="de Tecnologia"
                        customer_group_opinion={
                          customer_process.formated_opinions.security
                        }
                        modal_request_opinion={modalRequestOpinions}
                      />
                    </Tab>
                  )}
                </ContentProcessOpinions>
              </TabsProject>
            </ProcessOpinionsMain>
          </Card>
        ) : (
          <LoadingMask text="Carregando..." />
        )}
      </Container>
      <Modal ref={modalRequestOpinions} size="smm" removeCloseButton>
        <FormRequestOpinions
          onSubmit={handleSubmitRequestOpinions}
          onCancel={() => modalRequestOpinions.current?.close()}
          customer_process={customer_process}
        />
      </Modal>
    </>
  );
};

export default ProcessOpinions;
