import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Quiz = styled.div`
  width: 100%;
  > div + div.ExpansionPainel {
    margin-top: 16px;
  }
`;
