import React from 'react';
import { MdEdit } from 'react-icons/md';

import { Container } from './styles';

const CardOpinionFill: React.FC = () => {
  return (
    <Container>
      <MdEdit size={28} color="#FACA00" />
      <h1>Aguardando preenchimento</h1>
    </Container>
  );
};

export default CardOpinionFill;
