/* eslint-disable react/jsx-indent */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useMemo, useContext } from 'react';

import { toRoman } from 'roman-numerals';
import { format } from 'date-fns';
import {
  Container,
  HeaderModule,
  Categories,
  ChartContent,
  Conclusion,
} from './styles';
import ExpansionPainel from '../../../components/ExpansionPainel';
import DisplayField from '../../../components/DisplayField';
import ChartRadar from '../../../components/ChartRadar';
import { ProjectContext, IProjectModule } from '..';
import TableResult from './components/TableResult';

const Results: React.FC = () => {
  const { project } = useContext(ProjectContext);
  const modulesWithSum: IProjectModule[] = useMemo(() => {
    return project.scopedModules
      .filter(m => m.status === 'Finalizado')
      .map(m => ({
        ...m,
        categories: m.categories.map(c =>
          Object.assign(c, {
            avg_score:
              c.questions
                .filter(q => q.score || q.score === 0)
                .reduce((acc, cur) => (acc += cur.score || 0), 0) /
              c.questions.filter(q => q.score || q.score === 0).length,
            avg_score_required:
              c.questions
                .filter(q => q.score_required || q.score_required === 0)
                .reduce((acc, cur) => (acc += cur.score_required || 0), 0) /
              c.questions.filter(
                q => q.score_required || q.score_required === 0,
              ).length,
          }),
        ),
      }));
  }, [project.scopedModules]);

  return (
    <Container>
      {modulesWithSum.length ? (
        modulesWithSum.map((module, i) => (
          <ExpansionPainel
            key={module.id}
            preTitle={`MODULO ${toRoman(i + 1)}`}
            preTitleWidth="110px"
            title={module.name}
            titleColor="#0079c4"
            clean
          >
            <HeaderModule>
              <DisplayField label="Data">
                {module.interview?.done_date &&
                  format(
                    new Date(module.interview?.done_date),
                    'dd/MMM/yyyy HH:mm',
                  )}
              </DisplayField>
              <DisplayField label="Entrevistado">
                {module.interview?.customerUser?.user?.name}
              </DisplayField>
              <DisplayField label="Consultor">
                {module.interview?.partnerUser?.user.name}
              </DisplayField>
            </HeaderModule>
            <Categories>
              {module.categories.map(category => {
                return category.questions.length ? (
                  <TableResult key={category.id} category={category} />
                ) : null;
              })}
            </Categories>
            <ChartContent>
              <h1>Gráfico do módulo</h1>
              <div className="Content">
                <ChartRadar
                  labels={module.categories.map(c => c.name)}
                  scores={{
                    score: module.categories.map(c => c.avg_score),
                    perfect: module.categories.map(c => 5),
                    required: module.categories.map(c => c.avg_score_required),
                  }}
                />
              </div>
            </ChartContent>
            <Conclusion>
              <h1>Conclusão do módulo</h1>
              <div className="Content">
                <p>{module.conclusion}</p>
              </div>
            </Conclusion>
          </ExpansionPainel>
        ))
      ) : (
        <h1>Nenhuma avaliação finalizada</h1>
      )}
    </Container>
  );
};

export default Results;
