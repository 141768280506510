/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useContext, useMemo } from 'react';

import { toRoman } from 'roman-numerals';
import { NumberToAlphabet } from 'number-to-alphabet';
import currency from 'currency-formatter';

import { Container } from './styles';
import {
  ProjectContext,
  IProjectQuestion,
  IProjectModule,
  IProjectCategory,
} from '..';
import { useFetch } from '../../../hooks/fetch';
import { IPartner } from '../../Partners';
import Spinner from '../../../components/Spinner';

const defaultAlphabet = new NumberToAlphabet();

type QuestionExport = IProjectQuestion & {
  module: IProjectModule;
  category: IProjectCategory;
  partner: IPartner | undefined;
};

const SummaryTable: React.FC = () => {
  const { project } = useContext(ProjectContext);

  const { data: partners } = useFetch<IPartner[]>('/partners', {
    revalidateOnFocus: false,
  });

  const questionsFiltered = useMemo(() => {
    return project.scopedModules
      .reduce(
        (accM, m) =>
          (accM = accM.concat(
            m.categories.reduce(
              (accC, c) =>
                (accC = accC.concat(
                  c.questions.map(q => ({
                    ...q,
                    module: m,
                    category: c,
                    partner: partners?.find(
                      p => p.id === q.partner_executor_id,
                    ),
                  })),
                )),
              [] as QuestionExport[],
            ),
          )),
        [] as QuestionExport[],
      )
      .filter(
        q =>
          (q.score || q.score === 0) &&
          (q.score_required || q.score_required === 0) &&
          q.score < q.score_required &&
          q.recommendation &&
          q.partner_executor_id,
      );
  }, [partners, project.scopedModules]);

  if (!partners) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th style={{ width: '5%' }}>Módulo</th>
            <th style={{ width: '5%' }}>Item</th>
            <th style={{ width: '35%' }}>Plano de Ação</th>
            <th style={{ width: '20%' }}>Tipo de Recomendação</th>
            <th style={{ width: '5%' }}>Impacto</th>
            <th style={{ width: '5%' }}>Prior.</th>
            <th style={{ width: '5%' }}>Horas</th>
            <th style={{ width: '10%' }}>Executor</th>
            <th style={{ width: '10%' }}>Valor Final</th>
          </tr>
        </thead>
        <tbody>
          {!questionsFiltered.length ? (
            <tr>
              <td colSpan={5}>Nenhum plano de recomendação finalizado</td>
            </tr>
          ) : null}

          {questionsFiltered.map(question => (
            <tr key={question.id}>
              <td>{`Módulo ${toRoman(question.module.position + 1)}`}</td>
              <td>
                {`${Number(
                  question.category.position + 1,
                )}.${defaultAlphabet.numberToString(question.position + 1)}`}
              </td>

              <td>{question.recommendation}</td>
              <td>{question.recommendation_type?.name}</td>
              <td>{question.impact} </td>
              <td>{question.priority} </td>
              <td>{question.consulting_hours} </td>
              <td>{question.partner?.name} </td>
              <td>
                {question.partner &&
                question.partner?.technical_hourly_value &&
                question.consulting_hours
                  ? currency.format(
                      Number(
                        question.partner?.technical_hourly_value *
                          question.consulting_hours,
                      ) || 0,
                      { code: 'BRL' },
                    )
                  : null}{' '}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default SummaryTable;
