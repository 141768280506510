/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-indent */
import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { MdDelete, MdAdd, MdEdit } from 'react-icons/md';
import { v4 } from 'uuid';
import { cache } from 'swr';
import { Form } from '@unform/web';
import {
  Container,
  Main,
  Footer,
  Projects,
  ExpansionContent,
  NewProject,
  HeaderCustomerUsers,
  CustomerUsers,
  NewPartner,
} from './styles';
import { cnpjMask, zipCodeMask } from '../../../components/Masks';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import ExpansionPainel from '../../../../components/ExpansionPainel';
import FormNewProject from '../FormNewProject';
import { ITemplate } from '../../../Templates';
import { useFetch } from '../../../../hooks/fetch';
import Modal, { IModalHandles } from '../../../../components/Modal';
import { IProject } from '../../../Projects';
import FormCustomerUser, { optionsCustomerGroups } from '../FormCustomerUser';
import InterviewsTable from '../../InterviewsTable';
import DisplayField from '../../../../components/DisplayField';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import FormChangePassword from '../../../Users/FormChangePassword';
import Badge from '../../../../components/Badge';
import { ICustomer } from '../..';
import { ICustomerUser } from '../../../CustomerUsers';
import { IPartner } from '../../../Partners';
import Table from '../../../../components/Table';
import FormNewPartner from '../FormNewPartner';
import findZipCode, { IZipcode } from '../../../../utils/viacep';

interface IFormProps {
  initialData?: ICustomer;
  onSubmit(customer: ICustomer): void;
}

const FormCustomer: React.FC<IFormProps> = ({ initialData, onSubmit }) => {
  const form = useRef<FormHandles>(null);
  const modalCustomerUser = useRef<IModalHandles>();
  const modalPassword = useRef<IModalHandles>();
  const history = useHistory();
  const [customer, setCustomer] = useState<ICustomer>(
    initialData || {
      id: v4(),
      address: '',
      city: '',
      complement: '',
      created_at: new Date(),
      district: '',
      name: '',
      number: undefined,
      register_number: '',
      state: '',
      zipcode: '',
      scopedProjects: [],
      enabledPartners: [],
      customerUsers: [],
      customerGroups: [],
    },
  );
  const [newCustomerUserModalState, setNewCustomerUserModalState] =
    useState(true);
  const [customerUserSelected, setCustomerUserSelected] =
    useState<ICustomerUser>({
      id: v4(),
      customer_id: v4(),
      telephone: '',
      position: '',
      user: {
        id: v4(),
        name: '',
        email: '',
        groups: [],
      },
      customerGroups: [],
      reopen_process: false,
    });
  const { addToast } = useToast();

  const { data: templates } = useFetch<ITemplate[]>('/templates', {
    revalidateOnFocus: true,
  });

  const { data: partners } = useFetch<IPartner[]>('/partners', {
    revalidateOnFocus: true,
  });

  const templatesFiltered = useMemo(() => {
    return templates?.filter(t => t.consolidated);
  }, [templates]);

  const handleSubmit = useCallback(async () => {
    const data = form.current?.getData() as ICustomer;
    const {
      name,
      register_number,
      address,
      number,
      complement,
      district,
      zipcode,
      city,
      state,
    } = data;
    try {
      form.current?.setErrors({});
      const customers: ICustomer[] = cache.get('/customers');
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome da empresa é obrigatório'),
        register_number: Yup.string()
          .required('CNPJ Obrigatório')
          .min(14, 'CNPJ inválido.')
          .test('checkDuplicate', 'CNPJ já existe', () => {
            return new Promise(resolve => {
              const exists = customers.find(
                p =>
                  p.register_number === register_number &&
                  p.id !== initialData?.id,
              );
              resolve(!exists);
            });
          }),
      });
      await schema.validate(
        { name, register_number },
        {
          abortEarly: false,
        },
      );

      const newCustomer: ICustomer = {
        id: customer?.id || undefined,
        name,
        register_number,
        address,
        number: Number(number),
        complement,
        district,
        zipcode,
        city,
        state,
        scopedProjects: customer?.scopedProjects || [],
        enabledPartners: customer?.enabledPartners || [],
        customerUsers: customer?.customerUsers || [],
        customerGroups: customer?.customerGroups || [],
        created_at: customer?.created_at || new Date(),
      };

      onSubmit(newCustomer);
    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        form.current?.setErrors(errors);
      }
    }
  }, [customer, onSubmit, initialData]);

  const [zipCode, setZipCode] = useState('');

  const checkZipCode = async (e: string) => {
    if (!e) return;
    const zipCodes = e.replace(/\D/g, '');
    if (zipCodes.length === 8) {
      setZipCode(zipCodes);
    }
  };

  const [valueCity, setValueCity] = useState('');
  const [valueBairro, setValueBairro] = useState('');
  const [valueUF, setValueUF] = useState('');
  const [valueLogradouro, setValueLogradouro] = useState('');
  const [focusedNumber, setFocusedNumber] = useState(false);

  useEffect(() => {
    async function getCep() {
      if (zipCode) {
        try {
          const data: IZipcode | any = await findZipCode(zipCode);
          setValueCity(data.localidade);
          setValueBairro(data.bairro);
          setValueUF(data.uf);
          setValueLogradouro(data.logradouro);
          setFocusedNumber(true);
        } catch (error) {
          alert('Ocorreu um erro ao buscar o endereço');
        }
      }
    }
    getCep();
  }, [zipCode]);

  const handleSubmitNewProject = useCallback(
    (templateIdSelected: string) => {
      const templateSelected = templates?.find(
        t => t.id === templateIdSelected,
      );
      const newProject: IProject = {
        id: v4(),
        customer_id: customer.id || '',
        template_id: templateSelected?.id || '',
        name: templateSelected?.name || '',
        type: templateSelected?.type || '',
        version: templateSelected?.version || '',
        status: 'Em Andamento',
        scopedModules: [],
        created_at: new Date(),
        customer: {
          id: v4(),
          name: '',
          register_number: '',
          scopedProjects: [],
          enabledPartners: [],
          customerUsers: [],
          customerGroups: [],
          created_at: new Date(),
        },
        interviews: [],
      };
      setCustomer(oldCustomer => ({
        ...oldCustomer,
        scopedProjects: [...oldCustomer.scopedProjects, newProject],
      }));
    },
    [customer.id, templates],
  );

  const handleSubmitNewPartner = useCallback(
    (partnerIdSelected: string) => {
      const partnerSelected = partners?.find(t => t.id === partnerIdSelected);

      if (partnerSelected) {
        setCustomer(oldCustomer => ({
          ...oldCustomer,
          enabledPartners: [...oldCustomer.enabledPartners, partnerSelected],
        }));
      }
    },
    [partners],
  );

  const handleRemoveProject = useCallback((id: string) => {
    setCustomer(oldCustomer => ({
      ...oldCustomer,
      scopedProjects: oldCustomer.scopedProjects.filter(p => p.id !== id),
    }));
  }, []);

  const handleRemoveEnabledPartner = useCallback((id: string | undefined) => {
    setCustomer(oldCustomer => ({
      ...oldCustomer,
      enabledPartners: oldCustomer.enabledPartners.filter(ep => ep.id !== id),
    }));
  }, []);

  const handleNewCustomerUserModal = useCallback(() => {
    setNewCustomerUserModalState(true);
    setCustomerUserSelected({
      id: v4(),
      customer_id: v4(),
      telephone: '',
      position: '',
      user: {
        id: v4(),
        name: '',
        email: '',
        groups: [],
      },
      customerGroups: [],
      reopen_process: false,
    });
    modalCustomerUser.current?.open();
  }, []);

  const handleEditCustomerUserModal = useCallback(
    (id: string | undefined) => {
      setNewCustomerUserModalState(false);
      const customerUserLoaded = customer?.customerUsers.find(
        c => c.id?.toString() === id?.toString(),
      );
      if (customerUserLoaded) {
        setCustomerUserSelected(customerUserLoaded);
      }
      modalCustomerUser.current?.open();
    },
    [customer],
  );

  const handleRemoveCustomerUser = useCallback(
    (id: string | undefined) => {
      if (customer) {
        setCustomer({
          ...customer,
          customerUsers: customer.customerUsers.filter(cu => cu.id !== id),
        });
      }
    },
    [customer],
  );

  const handleSubmitCustomersUser = useCallback(
    customerUserReturned => {
      if (newCustomerUserModalState) {
        setCustomer({
          ...customer,
          customerUsers: [...customer.customerUsers, customerUserReturned],
        });
      } else {
        setCustomer({
          ...customer,
          customerUsers: customer.customerUsers.map(i => {
            if (i.id.toString() === customerUserSelected.id.toString()) {
              return customerUserReturned;
            }
            return i;
          }),
        });
      }
      modalCustomerUser.current?.close();
    },
    [customerUserSelected.id, newCustomerUserModalState, customer],
  );

  const handleChangePasswordCustomerUserModal = useCallback(
    (id: string | undefined) => {
      setNewCustomerUserModalState(false);
      const customerUserLoaded = customer?.customerUsers.find(
        c => c.id?.toString() === id?.toString(),
      );
      if (customerUserLoaded) {
        setCustomerUserSelected(customerUserLoaded);
      }
      modalPassword.current?.open();
    },
    [customer],
  );

  const handleChangePassword = useCallback(
    async ({ id, password }) => {
      api
        .patch(`/users/${id}`, {
          password,
        })
        .catch(error => {
          console.log('ERROR', error.response.data.message);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Ocorreu um erro ao alterar a senha.',
          });
        });
      modalPassword.current?.close();
    },
    [addToast],
  );

  return (
    <>
      <Container>
        <Form ref={form} onSubmit={handleSubmit} initialData={customer}>
          <Main>
            <div className="Line1">
              <Input label="Nome da empresa *" name="name" type="text" />
              <Input
                label="CNPJ *"
                name="register_number"
                type="text"
                onChange={e => cnpjMask(e)}
              />
            </div>
            <div className="Line2">
              <Input
                label="CEP"
                name="zipcode"
                type="text"
                onBlur={e => checkZipCode(e.target.value)}
                onChange={e => zipCodeMask(e)}
              />
              <Input
                label="Logradouro"
                name="address"
                type="text"
                defaultValue={valueLogradouro}
              />
              <Input
                focused={focusedNumber}
                label="Número"
                name="number"
                type="number"
              />
              <Input label="Complemento" name="complement" type="text" />
            </div>
            <div className="Line3">
              <Input
                label="Bairro"
                name="district"
                type="text"
                defaultValue={valueBairro}
              />
              <Input
                label="Cidade"
                name="city"
                type="text"
                defaultValue={valueCity}
              />
              <Input
                label="UF"
                name="state"
                type="text"
                defaultValue={valueUF}
              />
            </div>

            <HeaderCustomerUsers>
              <h1>USUARIOS DO CLIENTE</h1>
              <Button
                icon={MdAdd}
                background="#0079C4"
                onClick={() => handleNewCustomerUserModal()}
              >
                Novo
              </Button>
            </HeaderCustomerUsers>

            <CustomerUsers>
              {customer?.customerUsers.map(customerUser => (
                <ExpansionPainel
                  key={customerUser.id}
                  title={customerUser.user?.name}
                  barComponent={() => (
                    <>
                      <MdEdit
                        size={22}
                        onClick={() =>
                          handleEditCustomerUserModal(customerUser.id)
                        }
                      />
                      <MdDelete
                        size={22}
                        onClick={() =>
                          handleRemoveCustomerUser(customerUser.id)
                        }
                      />
                    </>
                  )}
                >
                  <ExpansionContent>
                    <div className="Line11">
                      <DisplayField label="Telefone">
                        {customerUser.telephone}
                      </DisplayField>
                      <DisplayField label="E-mail">
                        {customerUser.user.email}
                      </DisplayField>
                      <DisplayField label="Senha">
                        *********
                        <button
                          type="button"
                          onClick={() =>
                            handleChangePasswordCustomerUserModal(
                              customerUser.id,
                            )
                          }
                        >
                          Alterar Senha
                        </button>
                      </DisplayField>
                    </div>
                    <div className="Line22">
                      <DisplayField label="Cargo">
                        {customerUser.position}
                      </DisplayField>
                    </div>
                    <div className="Line33">
                      {customerUser.customerGroups.length
                        ? customerUser.customerGroups.map(c => {
                            const matchingOption = optionsCustomerGroups.find(
                              oc => oc.value === c.name,
                            );

                            if (
                              matchingOption &&
                              matchingOption.value === c.name
                            ) {
                              return (
                                <Badge
                                  key={v4()}
                                  value={matchingOption.label || ''}
                                />
                              );
                            }
                            return null;
                          })
                        : null}
                    </div>
                  </ExpansionContent>
                </ExpansionPainel>
              ))}
            </CustomerUsers>
          </Main>
        </Form>

        <NewPartner>
          <FormNewPartner
            onSubmit={handleSubmitNewPartner}
            partners={
              partners?.filter(p => !customer.enabledPartners.includes(p)) || []
            }
          />

          <Table>
            <thead>
              <tr className="Header">
                <th style={{ width: '50%' }}>
                  <div>
                    <span>PARCEIRO</span>
                  </div>
                </th>
                <th style={{ width: '20%' }}>
                  <div>
                    <span>LOCAL</span>
                  </div>
                </th>
                <th style={{ width: '20%' }}>
                  <div>
                    <span>CONSULTORES</span>
                  </div>
                </th>
                <th style={{ width: '10%' }} />
              </tr>
            </thead>

            {!customer?.enabledPartners?.length && (
              <tbody>
                <tr>
                  <td colSpan={6}>Nenhum parceiro vinculado</td>
                </tr>
              </tbody>
            )}
            <tbody>
              {customer?.enabledPartners?.map(partner => (
                <tr key={partner.id}>
                  <td>{partner.name}</td>
                  <td>
                    {partner.city} - {partner.state}
                  </td>
                  <td>{partner.partnerUsers.length}</td>

                  <td
                    className="Icons"
                    style={{
                      paddingRight: '10px',
                    }}
                  >
                    <MdDelete
                      size={20}
                      onClick={() => handleRemoveEnabledPartner(partner.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </NewPartner>

        <NewProject>
          <FormNewProject
            initialData={{ newProject: '' }}
            onSubmit={handleSubmitNewProject}
            templates={templatesFiltered || []}
          />
        </NewProject>

        {customer?.scopedProjects.length ? (
          <Projects>
            {customer?.scopedProjects.map(project => (
              <ExpansionPainel
                key={project.id}
                title={`${project.name} - ${project.version}`}
                clean
                barComponent={() => (
                  <>
                    <MdDelete
                      size={22}
                      onClick={() => handleRemoveProject(project.id)}
                    />
                  </>
                )}
              >
                <ExpansionContent>
                  <InterviewsTable project={project} />
                </ExpansionContent>
              </ExpansionPainel>
            ))}
          </Projects>
        ) : null}

        <Footer>
          <Button
            type="button"
            background="transparent"
            color="grey"
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            loading={false}
            loadingMsg="Salvando ..."
            onClick={() => handleSubmit()}
          >
            Salvar
          </Button>
        </Footer>
      </Container>
      <Modal ref={modalCustomerUser} size="md">
        <FormCustomerUser
          initialData={customerUserSelected}
          customerGroups={initialData?.customerGroups || []}
          onSubmit={handleSubmitCustomersUser}
          onCancel={() => modalCustomerUser.current?.close()}
        />
      </Modal>
      <Modal ref={modalPassword} size="sm">
        <FormChangePassword
          initialData={customerUserSelected.user}
          onSubmit={handleChangePassword}
          onCancel={() => modalPassword.current?.close()}
        />
      </Modal>
    </>
  );
};

export default FormCustomer;
