import React, {
  useRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import arraySort from 'array-sort';
import { Container, Main, Footer } from './styles';
import Button from '../../../../../../components/Button';
import Modal, { IModalHandles } from '../../../../../../components/Modal';
import TextArea from '../../../../../../components/TextArea';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import {
  ICustomerProcess,
  ICustomerProcessShareByWhom,
  ICustomerProcessShareWithWhom,
  ICustomerProcessWho,
  ICustomerSendingPersonalData,
  ICountry,
} from '../..';
import FormSelect from '../../../../../../components/FormSelect';
import FormCreatableSelect from '../../../../../../components/FormCreatableSelect';
import onlyUnique from '../../../../../../utils/unique';
import Input from '../../../../../../components/Input';
import InputBoolean2 from '../../../../../../components/InputBoolean2';
import InputList from '../../../../../../components/InputList';
import ModalConfirm from '../../components/ProcessConfirm';

interface IFormProps {
  initialData: ICustomerProcess;
  customer_who?: ICustomerProcessWho[];
  customer_process_share_by_whom?: ICustomerProcessShareByWhom[];
  customer_process_share_with_whom?: ICustomerProcessShareWithWhom[];
  readOnly?: boolean;
  uses_Consent?: boolean | undefined;
  customerSendingPersonalData?: ICustomerSendingPersonalData[];
  countries?: ICountry[];
}

const FormProcessShare: React.FC<IFormProps> = ({
  initialData,
  customer_process_share_with_whom,
  customer_who,
  customer_process_share_by_whom,
  customerSendingPersonalData,
  uses_Consent,
  countries,
  readOnly,
}) => {
  const form = useRef<FormHandles>(null);
  const modalConfirm = useRef<IModalHandles>();
  const [usesConsent, setusesConsent] = useState(true);

  useEffect(() => {
    if (uses_Consent === true) {
      setusesConsent(uses_Consent);
    } else if (
      uses_Consent === false ||
      initialData.data.consent.fields.consent === 'false'
    ) {
      setusesConsent(false);
    }
  }, [initialData.data.consent.fields.consent, uses_Consent]);

  const [confirmSaving, setConfirmSaving] =
    useState<ICustomerProcess>(initialData);
  const closedModal = useCallback(() => {
    modalConfirm.current?.close();
  }, []);

  const [visibleSendingPersonalData, setVisibleSendingPersonalData] =
    useState(false);

  useEffect(() => {
    initialData.data.share.fields.data_share_sending_personal_data?.map(
      value => {
        customerSendingPersonalData?.map(valueTypes => {
          if (
            value === valueTypes.id &&
            valueTypes.name.toUpperCase() === 'OUTROS'
          ) {
            setVisibleSendingPersonalData(true);
          }
        });
      },
    );
  }, [customerSendingPersonalData, initialData]);

  const [
    share_Internarional_companies_boolean,
    setShare_Internarional_companies_boolean,
  ] = useState<boolean | undefined>(
    !!initialData?.data.share?.fields.data_share_international_companies,
  );

  const [enableInputs, setEnableInputs] = useState(true);

  const handleSubmit = useCallback(
    async ({ data }: ICustomerProcess) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          data: Yup.object().shape({
            share: Yup.object().shape({
              fields: Yup.object().shape({
                data_share_international_companies:
                  share_Internarional_companies_boolean &&
                  data.share.fields.use_sharing === 'true'
                    ? Yup.string().required('Campo obrigatório')
                    : Yup.string().nullable(),
                how:
                  visibleSendingPersonalData &&
                  (data.share.fields.how === '' ||
                    data.share.fields.how === undefined)
                    ? Yup.string().required('Campo obrigatório')
                    : Yup.string().nullable(),
                what:
                  data.share.fields.use_sharing === 'true'
                    ? Yup.string().required('Campo obrigatório')
                    : Yup.string().nullable(),
                by_whom:
                  data.share.fields.use_sharing === 'true'
                    ? Yup.string().required('Campo obrigatório')
                    : Yup.string().nullable(),
                with_whom:
                  data.share.fields.use_sharing === 'true'
                    ? Yup.string().required('Campo obrigatório')
                    : Yup.string().nullable(),
                objective:
                  data.share.fields.use_sharing === 'true'
                    ? Yup.string().required('Campo obrigatório')
                    : Yup.string().nullable(),
                data_share_sending_personal_data:
                  data.share.fields.use_sharing === 'true'
                    ? Yup.string().required('Campo obrigatório')
                    : Yup.string().nullable(),
              }),
            }),
          }),
        });

        await schema.validate(
          { data },
          {
            abortEarly: false,
          },
        );

        const newCustomerProcess: ICustomerProcess = {
          ...initialData,
          data: {
            ...initialData.data,
            share: {
              ...initialData.data.share,
              fields: {
                ...data.share.fields,
                firstrecording_share: true,
                data_share_international_companies:
                  data.share.fields.data_share_international_companies?.filter(
                    w => {
                      if (share_Internarional_companies_boolean) {
                        return data.share.fields.data_share_international_companies?.includes(
                          w,
                        );
                      }
                      return false;
                    },
                  ),
              },
            },
          },
        };

        setConfirmSaving(newCustomerProcess);
        modalConfirm.current?.open();

        // setLoading(true);
        // try {
        //   await api.patch(
        //     `/customerProcesses/${initialData.id}`,
        //     newCustomerProcess,
        //   );
        //   mutateProcess(newCustomerProcess, 'edit');
        // } catch (error) {
        //   addToast({
        //     title: 'Erro',
        //     type: 'error',
        //     description: 'Falha ao tentar salvar',
        //   });
        // } finally {
        //   setLoading(false);
        // }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [
      share_Internarional_companies_boolean,
      visibleSendingPersonalData,
      initialData,
    ],
  );

  const disabled = useMemo(() => {
    if (readOnly) {
      return true;
    }
    if (
      initialData.status !== 'Preenchimento' &&
      initialData.status !== 'Revalidação' &&
      initialData.reopened_process !== true
    ) {
      return true;
    }
    return false;
  }, [initialData.reopened_process, initialData.status, readOnly]);

  const optionsByWhom = useMemo(() => {
    let options;
    if (customer_process_share_by_whom) {
      options = customer_who?.concat(customer_process_share_by_whom);
    }
    if (initialData.data.collect.fields.data_by_who) {
      options = options?.concat(
        initialData.data.collect.fields.data_by_who.map(f => ({
          id: f,
          name: f,
          customer_process_id: f,
        })),
      );
    }
    return options
      ?.map(cd => cd.name)
      .filter(onlyUnique)
      .map(strData => ({
        value: strData,
        label: strData,
      }));
  }, [
    customer_process_share_by_whom,
    customer_who,
    initialData.data.collect.fields.data_by_who,
  ]);

  return (
    <>
      <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
        <Main>
          <InputBoolean2
            label="Há compartilhamento de dados pessoais deste processo com empresas terceiras?"
            explanation={
              'indicar se há envio de dados pessoais para outras organizações.\nExemplo: Governo, plano de saúde, empresas de benefícios,\n intituições financeiras, escritórios de contabilidade, etc.'
            }
            name="data.share.fields.use_sharing"
            disabled={disabled}
            onChangeBoolean={booleanValue => {
              setEnableInputs(false);
              if (!booleanValue) {
                setVisibleSendingPersonalData(false);
              }
              booleanValue && setEnableInputs(booleanValue);
            }}
          />

          {enableInputs && (
            <>
              <FormSelect
                label="Quais os dados pessoais que são compatilhados com terceiros para fora da empresa?"
                explanation="Indicar quais dos dados coletados são compartilhados."
                name="data.share.fields.what"
                placeholder=""
                isMulti
                options={initialData.data.collect.fields.data_what?.map(dw => ({
                  value: dw,
                  label: dw,
                }))}
                disabled={disabled}
              />

              {usesConsent === true && (
                <InputBoolean2
                  label="O compartilhamento é especificado no consentimento?"
                  explanation={
                    'Indicar se mo ato do consentimento esta explicito que ah \n compartilhamento de dados pessoais.'
                  }
                  name="data.share.fields.shared"
                  disabled={disabled}
                />
              )}

              <FormCreatableSelect
                label="Quais os cargos, setores ou funções são responsáveis por compartilhar os dados pessoais neste processo?"
                explanation="Indicar os responsaveis pelo compartilhamento dos dados pessoais."
                name="data.share.fields.by_whom"
                isMulti
                options={optionsByWhom}
                placeholder=""
                isDisabled={disabled}
              />

              <FormCreatableSelect
                label="Quais organizações recebem os dados pessoais compartilhados neste processo?"
                explanation="indicar com quem os dados pessoais são compartilhados."
                name="data.share.fields.with_whom"
                isMulti
                options={customer_process_share_with_whom
                  ?.map(cd => cd.name)
                  .filter(onlyUnique)
                  .map(strData => ({
                    value: strData,
                    label: strData,
                  }))}
                placeholder=""
                isDisabled={disabled}
              />

              <TextArea
                label="Qual o propósito do compartilhamento?"
                explanation="Indicar o objetivo pelo qual os dados pessoais são compartilhados neste processo."
                name="data.share.fields.objective"
                disabled={disabled}
              />

              <FormSelect
                label="Qual a forma de envio dos dados pessoais?"
                explanation={
                  'Descrever os meios utilizados para envio dos dados pessoas.\nExemplo: e-mail, WhatsApp, sistemas, telefone, presencialmente, etc.'
                }
                name="data.share.fields.data_share_sending_personal_data"
                placeholder=""
                isMulti
                options={customerSendingPersonalData?.map(strData => ({
                  value: strData.id,
                  label: strData.name,
                }))}
                disabledSelec={disabled}
                onChange={event => {
                  setVisibleSendingPersonalData(false);
                  let have_others = false;

                  if (event) {
                    // eslint-disable-next-line array-callback-return
                    event.map(value => {
                      if (value.label.toString().toUpperCase() === 'OUTROS') {
                        setVisibleSendingPersonalData(true);
                        have_others = true;
                      }
                    });
                  }
                  if (have_others === false) {
                    // eslint-disable-next-line no-param-reassign
                    initialData.data.share.fields.how = '';
                  }
                }}
              />

              {visibleSendingPersonalData ? (
                <Input
                  className="fieldway"
                  label=""
                  explanation=""
                  placeholder="informe outros meios utilizados para envio dos dados pessoais."
                  name="data.share.fields.how"
                  disabled={disabled}
                />
              ) : null}

              <InputList
                label="Há compartilhamento de dados pessoais com empresas internacionais?"
                explanation="Indicar se existe envio de dados pessoais para o exterior."
                inputLabel="Com quais paises há compartilhamento de dados pessoais?"
                explanationsec={
                  'Indicar os paises para os quais há envio de dados pessoais, uma vez que havendo compartilhamento\ninternacional de dados, seu negóciocio ficara sujeito há outras lesgislações de proteção de dados\npessoais além da brasileira.'
                }
                value="data.share.fields.data_share_international_companies"
                name="data.share.fields.data_share_international_companies"
                onChangeBoolean={booleanValue => {
                  setShare_Internarional_companies_boolean(booleanValue);
                }}
                disabled={disabled}
                initialData={
                  initialData?.data.share.fields
                    .data_share_international_companies
                }
                list={countries && arraySort(countries, 'name')}
              />
            </>
          )}
        </Main>
        <Modal ref={modalConfirm} removeCloseButton size="sm">
          <ModalConfirm
            newData={confirmSaving}
            onCancel={() => modalConfirm.current?.close()}
            onSubmit={closedModal}
          />
        </Modal>
        {!disabled && (
          <Footer>
            <Button type="submit" background="#4ECE3D">
              Salvar
            </Button>
          </Footer>
        )}
      </Container>
    </>
  );
};

export default FormProcessShare;
