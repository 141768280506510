import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { MdAdd } from 'react-icons/md';
import { Container, Main } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Button from '../../../../components/Button';
import Select3 from '../../../../components/Select3';
import Option from '../../../../components/Select3/Option';
import { IPartner } from '../../../Partners';

interface IFormProps {
  onSubmit(partnerId: string): void;
  onCancel?(): void;
  partners: IPartner[];
}

interface INewPartnerFormData {
  partnerId: string;
}

const FormNewPartner: React.FC<IFormProps> = ({ partners, onSubmit }) => {
  const form = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async ({ partnerId }: INewPartnerFormData) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          partnerId: Yup.string().required('Parceiro é obrigatório'),
        });

        await schema.validate(
          { partnerId },
          {
            abortEarly: false,
          },
        );

        onSubmit(partnerId);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [onSubmit],
  );

  return (
    <Container ref={form} onSubmit={handleSubmit}>
      <Main>
        <Select3 name="partnerId" label="Vincular parceiros">
          {partners.map(p => (
            <Option key={p.id} label={p.name} value={p.id}>
              {p.name}
            </Option>
          ))}
        </Select3>
        <Button type="submit" icon={MdAdd} background="#0079C4">
          Adicionar
        </Button>
      </Main>
    </Container>
  );
};

export default FormNewPartner;
