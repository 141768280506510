import React, { useMemo } from 'react';
import { ICustomerDepartment } from '../Department';

import DepartmentIcon from './components/DepartmentIcon';
import DepartmentProgressBar from './components/DepartmentProgressBar';
import DepartmentProcessInfo from './components/DepartmnetProcessInfo';

import { Container } from './styles';

interface IDepartmentProps {
  customer_department: ICustomerDepartment;
}

const DepartmentItem: React.FC<IDepartmentProps> = ({
  customer_department,
}) => {
  const total = useMemo(() => {
    return customer_department.scoped_customer_processes.length;
  }, [customer_department]);

  const opened = useMemo<number>(() => {
    return customer_department.scoped_customer_processes.filter(
      cp => cp.status !== 'Encerrado' && cp.status !== 'Certificado',
    ).length;
  }, [customer_department]);

  const concluded = useMemo<number>(() => {
    return customer_department.scoped_customer_processes.filter(
      cp => cp.status === 'Encerrado' || cp.status === 'Certificado',
    ).length;
  }, [customer_department]);

  return (
    <Container to={`/home/data-mapping/department/${customer_department.id}`}>
      <DepartmentIcon concluded={customer_department.percent_filled === 100} />
      <h1>{customer_department.name}</h1>
      <h3>
        {customer_department.responsable_customer_user?.user.name ||
          'Responsável indefinido'}
      </h3>
      <DepartmentProgressBar percent={customer_department.percent_filled} />
      <DepartmentProcessInfo
        total={total}
        opened={opened}
        concluded={concluded}
      />
    </Container>
  );
};

export default DepartmentItem;
