import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { FiSearch } from 'react-icons/fi';
import arraySort from 'array-sort';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { format } from 'date-fns';
import { Container, Header, Pagination, ItemPage } from './styles';
import InputOnly from '../../components/InputOnly';
import Table from '../../components/Table';
import IconOrderBy from '../../components/IconOrderBy';
import { useFetch } from '../../hooks/fetch';
import { IUser } from '../SignIn';

export interface IAudit {
  id: string;
  action: string;
  user: IUser;
  created_at: Date;
}

interface IOrderBy {
  fieldName: string;
  ascendant: boolean;
}

const Audit: React.FC = () => {
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [auditToDisplay, setAuditToDisplay] = useState<{
    filtered: number;
    audits: IAudit[];
  }>({ filtered: 0, audits: [] });

  const [inputFilter, setInputFilter] = useState('');

  const [orderBy, setOrderBy] = useState<IOrderBy>({
    fieldName: 'created_at',
    ascendant: true,
  });

  const { data: audits } = useFetch<IAudit[]>('/audit');

  const handleOrderBy = useCallback(
    ({ fieldName, ascendant }: IOrderBy) => {
      setOrderBy({
        fieldName,
        ascendant:
          fieldName === orderBy.fieldName ? !orderBy.ascendant : ascendant,
      });
    },
    [orderBy.ascendant, orderBy.fieldName],
  );

  useEffect(() => {
    if (audits) {
      if (!inputFilter.length) {
        setAuditToDisplay({
          filtered: audits?.length,
          audits,
        });
      }
      const filtered = audits?.filter(
        audit =>
          audit?.user?.name.toLowerCase().includes(inputFilter.toLowerCase()) ||
          audit?.action?.toLowerCase().includes(inputFilter.toLowerCase()),
      );

      if (filtered) {
        const ordered = arraySort(filtered, orderBy.fieldName, {
          reverse: orderBy.ascendant,
        });

        const paged = ordered.slice(
          (page - 1) * perPage,
          (page - 1) * perPage + perPage,
        );

        if (audits)
          setAuditToDisplay({
            filtered: filtered.length,
            audits: paged,
          });
      }
    }
  }, [
    inputFilter,
    orderBy.ascendant,
    orderBy.fieldName,
    page,
    perPage,
    audits,
  ]);

  useEffect(() => {
    setPage(1);
  }, [inputFilter]);

  const pages = useMemo(() => {
    if (!auditToDisplay.filtered) return 1;
    return Math.ceil(auditToDisplay.filtered / perPage);
  }, [auditToDisplay.filtered, perPage]);

  const pageNumbers = useMemo(() => {
    const maxDisplayPages = 15;
    const diff = Math.ceil(maxDisplayPages / 2);
    let start = 0;
    if (pages > maxDisplayPages && page > pages - diff) {
      start = pages - maxDisplayPages;
    } else if (pages > maxDisplayPages && page > diff) {
      start = page - diff;
    } else {
      start = 0;
    }
    const limiters = { start, end: start + maxDisplayPages };

    const pns: number[] = [];
    for (let i = 1; i <= pages; i += 1) {
      pns.push(i);
    }
    return pns.slice(limiters.start, limiters.end);
  }, [page, pages]);

  const handleSetPageUp = useCallback(() => {
    if (page < pages) setPage(page + 1);
  }, [page, pages]);

  const handleSetPageDown = useCallback(() => {
    if (page > 1) setPage(page - 1);
  }, [page]);

  return (
    <>
      <Container>
        <h1>Auditoria</h1>
        <Header>
          <InputOnly
            name="search"
            icon={FiSearch}
            onChange={e => setInputFilter(e.target.value)}
          />
        </Header>
        <Table>
          <thead>
            <tr className="Header">
              <th style={{ width: '20%' }}>
                <div>
                  <span>NOME</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'name'}
                    onClick={() =>
                      handleOrderBy({ fieldName: 'name', ascendant: false })
                    }
                  />
                </div>
              </th>
              <th style={{ width: '60%' }}>
                {' '}
                <div>
                  <span>AÇÃO</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'action'}
                    onClick={() =>
                      handleOrderBy({
                        fieldName: 'action',
                        ascendant: false,
                      })
                    }
                  />
                </div>
              </th>
              <th style={{ width: '20%' }}>
                {' '}
                <div>
                  <span>DATA / HORA</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'created_at'}
                    onClick={() =>
                      handleOrderBy({
                        fieldName: 'created_at',
                        ascendant: false,
                      })
                    }
                  />
                </div>
              </th>
            </tr>
          </thead>
          {!audits && (
            <tbody>
              <tr>
                <td colSpan={6}>Carregando ...</td>
              </tr>
            </tbody>
          )}
          {audits && !audits.length && (
            <tbody>
              <tr>
                <td colSpan={6}>Nenhum registro de auditoria encontrado</td>
              </tr>
            </tbody>
          )}
          <tbody>
            {auditToDisplay.audits.map(audit => (
              <tr key={audit.id}>
                <td>{audit?.user?.name}</td>
                <td>{audit?.action}</td>
                <td>
                  {format(new Date(audit?.created_at), 'dd/MMM/yyyy HH:mm')}
                </td>
              </tr>
            ))}
            <tr className="Pagination">
              <td colSpan={6}>
                <Pagination>
                  <li>
                    <IoMdArrowDropleft
                      size={18}
                      onClick={() => handleSetPageDown()}
                    />
                  </li>
                  {pageNumbers.map(pg => (
                    <ItemPage key={pg} active={pg === page}>
                      <button type="button" onClick={() => setPage(pg)}>
                        {pg}
                      </button>
                    </ItemPage>
                  ))}
                  <li>
                    <IoMdArrowDropright
                      size={18}
                      onClick={() => handleSetPageUp()}
                    />
                  </li>
                </Pagination>
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default Audit;
