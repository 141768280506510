import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { format } from 'date-fns';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import { Container, Content, Label, InnerLabel, Error } from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  explanation?: string;
  innerLabel?: string;
  name: string;
  focused?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  disabled?: boolean;
  placeholder?: string;
  type?: string;
}

const Input: React.FC<IInputProps> = ({
  name,
  label,
  explanation,
  innerLabel,
  icon: Icon,
  focused,
  disabled,
  placeholder,
  type,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(focused || false);
  const [isFilled, setisFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setisFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    // if (isFocused) {
    //   console.log('disparou');
    //   inputRef.current?.focus();
    // }
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: HTMLInputElement) => {
        return ref.value || undefined;
      },
    });
  }, [defaultValue, fieldName, isFocused, registerField]);

  return (
    <Container className="Input">
      {label && (
        <Label>
          {label}
          {explanation && (
            <AiOutlineQuestionCircle
              size={20}
              color="rgb(0,121,196)"
              title={explanation}
              className="Icon"
            />
          )}
        </Label>
      )}
      <Content
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        innerLabel={innerLabel}
        disabled={disabled}
      >
        {Icon && <Icon size={20} />}
        <label htmlFor={name}>
          {innerLabel && <InnerLabel>{innerLabel}</InnerLabel>}
          <input
            id={name}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={
              type === 'date' && defaultValue
                ? format(new Date(defaultValue), 'yyyy-MM-dd')
                : defaultValue
            }
            autoComplete="off"
            ref={inputRef}
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            {...rest}
          />
        </label>

        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Content>
    </Container>
  );
};

export default Input;
