import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  overflow-y: auto;

  > h1 {
    text-align: left;
    font: normal normal normal 22px/32px Heebo;
    letter-spacing: 0px;
    color: #2c405a;
    margin-bottom: 27px;
  }
`;

export const Header = styled.div`
  display: flex;
  > h1 {
    text-align: left;
    font: normal normal normal 22px/32px Heebo;
    letter-spacing: 0px;
    color: #2c405a;
    margin-bottom: 27px;
    width: 85%;
  }

  > div.button {
    width: 250px;
    white-space: nowrap;
  }
`;

export const Main = styled.div`
  > div.Card {
    margin-top: 13px;
  }

  > div.cardGraphics {
    margin-bottom: 20px;
    background: #d1d;
    width: 90%;
    height: 564px;
  }
`;

export const CardGraphics = styled.div`
  background-color: #ffffff;
  border: 1px solid #b7d2e5cc;
  border-radius: 4px;
  padding: 29px 42px 29px 42px;

  > div.cardGraphics {
    margin-bottom: 20px;
    width: 60%;
    > h4 {
      margin-bottom: 20px;
    }
  }
`;

export const TolerancyContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 27px 60px;

  > h1 {
    text-align: left;
    font-size: 14px;
    letter-spacing: 2.16px;
    color: #3f536e;
  }

  > table {
    margin-top: 17px;
    border: 1px solid #7c858c;
    width: 819px;
    border-collapse: collapse;
    border-spacing: 0;

    > thead > tr > th {
      border: 1px solid #7c858c;
      text-align: center;
      height: 36px;
      background-color: #f6f8fa;
    }

    > tbody > tr > td {
      border: 1px solid #7c858c;
      text-align: center;
      height: 36px;
    }

    > tbody > tr > td.Yellow {
      background: #fef5d1 0% 0% no-repeat padding-box;
      text-align: center;
      font-size: 15px;
      letter-spacing: 0px;
      color: #ffbc00;
    }

    > tbody > tr > td.Orange {
      background: #fcd8bf 0% 0% no-repeat padding-box;
      text-align: center;
      font-size: 15px;
      letter-spacing: 0px;
      color: #fd7b1f;
    }

    > tbody > tr > td.Red {
      background: #ffafb2 0% 0% no-repeat padding-box;
      text-align: center;
      font-size: 15px;
      letter-spacing: 0px;
      color: #cc0000;
    }
    > tbody > tr > td.RedExtreme {
      background: #fe7075 0% 0% no-repeat padding-box;
      text-align: center;
      font-size: 15px;
      letter-spacing: 0px;
      color: #c10000;
    }

    > tbody > tr > td.Green {
      background: #cefcc8 0% 0% no-repeat padding-box;
      text-align: center;
      font-size: 15px;
      letter-spacing: 0px;
      color: #40b630;
    }

    > tbody > tr > td.GreenMore {
      background: #82ff70 0% 0% no-repeat padding-box;
      text-align: center;
      font-size: 15px;
      letter-spacing: 0px;
      color: #72b36a;
    }
  }
`;

export const CustomerAssessmentContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 27px 60px;

  > h1 {
    text-align: left;
    font-size: 14px;
    letter-spacing: 2.16px;
    color: #3f536e;
  }
`;

export const WrapperFilter = styled.div`
  display: flex;

  > div.InputOnly {
    width: 283px;
  }

  > button.Button {
    width: 42px;
    height: 42px;
    border-radius: 4px;
    padding: 0px;
    margin-left: 21px;
  }
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;

  > button.Button {
    width: 200px;
  }
`;

export const WrapperCustomerAssessment = styled.div`
  margin-top: 37px;

  > div.ExpansionPainel {
    margin-bottom: 16px;
  }
`;

export const CustomerOpinionContent = styled.div`
  display: flex;
`;

export const HeaderCustomerOpinion = styled.div``;
