import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Container } from './styles';
import getValidationErrors from '../../../../../utils/getValidationErrors';

import { IProjectQuestion } from '../../..';
import TextArea from '../../../../../components/TextArea';
import InputScore from '../../../../../components/InputScore';

interface IFormProps {
  initialData: IProjectQuestion;
  onSubmit(newQuestion: IProjectQuestion): void;
  onCancel?(): void;
}

interface IFormData {
  answer: string;
  score: number;
  score_required: number;
}

interface ICheckboxOption {
  id: string;
  value: string;
  label: string;
}

const FormQuestion: React.FC<IFormProps> = ({ initialData, onSubmit }) => {
  const form = useRef<FormHandles>(null);
  const [editMode, setEditMode] = useState(!initialData.answer);

  const handleSave = useCallback(async () => {
    const { answer, score, score_required } =
      form.current?.getData() as IFormData;
    try {
      form.current?.setErrors({});
      const schema = Yup.object().shape({
        answer: Yup.string().required('Resposta é obrigatória'),
      });

      await schema.validate(
        {
          answer,
        },
        {
          abortEarly: false,
        },
      );

      const newQuestion = {
        ...initialData,
        score,
        score_required,
        answer,
      };
      setEditMode(false);
      onSubmit(newQuestion);
    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        form.current?.setErrors(errors);
      }
    }
  }, [initialData, onSubmit]);

  return (
    <Container
      ref={form}
      initialData={initialData}
      onSubmit={() => {
        // console.log('Submit');
      }}
    >
      <TextArea label="" name="answer" disabled={!editMode} />
      <InputScore label="Nota" name="score" disabled={!editMode} />
      <InputScore
        label="Requerida"
        name="score_required"
        disabled={!editMode}
      />
      <div className="Buttons">
        {editMode ? (
          <button className="SaveButton" type="button" onClick={handleSave}>
            Salvar
          </button>
        ) : (
          <button
            className="EditButton"
            type="button"
            onClick={() => setEditMode(true)}
          >
            Editar
          </button>
        )}
      </div>
    </Container>
  );
};

export default FormQuestion;
