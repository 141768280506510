import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { v4 } from 'uuid';
import { mutate as mutateGlobal } from 'swr';
import { Container, Header, Main, Footer } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { ICustomerUser } from '../../../CustomerUsers';
import Modal, { IModalHandles } from '../../../../components/Modal';
import FormCustomerUserFromCustomer from '../../../CustomerUsers/forms/FormCustomerUserFromCustomer';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import LoadingMask from '../../../../components/LoadingMask';
import { ICustomerDepartment } from '../../components/Department';
import { ICustomerDirectorate } from '../../components/Directorate';
import FormSelect from '../../../../components/FormSelect';

interface IFormProps {
  initialData?: ICustomerDepartment | undefined;
  customerUsers: ICustomerUser[];
  customerDirectorates: ICustomerDirectorate[];
  onSubmit(newCustomerDirecotorate: ICustomerDepartment): void;
  onCancel(): void;
  onDelete?(): void;
}

interface IDepartmentFormData {
  name: string;
  responsable_customer_user_id: string;
}

const FormDepartment: React.FC<IFormProps> = ({
  initialData,
  customerUsers,
  customerDirectorates,
  onSubmit,
  onCancel,
  onDelete,
}) => {
  const form = useRef<FormHandles>(null);
  const modalCustomerUser = useRef<IModalHandles>();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async ({ name, responsable_customer_user_id }: IDepartmentFormData) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome do departamento é obrigatório'),
          responsable_customer_user_id: Yup.string().required(
            'Responsável é obrigatório',
          ),
        });

        await schema.validate(
          { name, responsable_customer_user_id },
          {
            abortEarly: false,
          },
        );

        const responsable_customer_user = customerUsers.find(
          cu => cu.id === responsable_customer_user_id,
        );

        const newDepartment: ICustomerDepartment = {
          ...(initialData || {
            id: v4(),
            customer_directorate_id: '',
            customer_directorate: undefined,
            scoped_customer_processes: [],
            percent_filled: 0,
          }),
          name,
          responsable_customer_user_id,
          responsable_customer_user,
        };

        onSubmit(newDepartment);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [customerUsers, initialData, onSubmit],
  );

  const handleSubmitFormNewCustomerUser = useCallback(
    async (newCustomerUser: ICustomerUser) => {
      setLoading(true);
      try {
        modalCustomerUser.current?.close();

        await api.post('/customerUsers', newCustomerUser);
        mutateGlobal(
          '/customerUsers',
          async (oldCustomerUsers: ICustomerUser[]) => {
            return [...oldCustomerUsers, newCustomerUser];
          },
          false,
        );
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar salvar',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <>
      {loading ? <LoadingMask text="Salvando" /> : null}
      <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
        <Header>
          <h1>{initialData ? 'Editar Departamento' : 'Novo Departamento'}</h1>
        </Header>
        <Main>
          <FormSelect
            name="customer_directorate_id"
            label="Diretoria"
            placeholder=""
            options={customerDirectorates.map(customerDirectorate => ({
              label: customerDirectorate.name,
              value: customerDirectorate.id,
            }))}
          />
          <Input name="name" label="Nome do departamento *" />
          <FormSelect
            name="responsable_customer_user_id"
            label="Responsável pelo departamento *"
            placeholder=""
            options={customerUsers
              .filter(cu =>
                cu.customerGroups.find(
                  cg => cg.name === 'Responsable Department',
                ),
              )
              .map(customerUser => ({
                label: `${customerUser.user.name} (${customerUser.position})`,
                value: customerUser.id,
              }))}
          />
          <button
            type="button"
            className="AddNewCustomerUser"
            onClick={() => modalCustomerUser.current?.open()}
          >
            + Novo usuário
          </button>
        </Main>
        <Footer>
          {initialData && (
            <Button background="#FF151F" onClick={onDelete}>
              Remover
            </Button>
          )}
          <Button background="transparent" color="#A8C6DF" onClick={onCancel}>
            Cancelar
          </Button>
          <Button type="submit" background="#4ECE3D">
            Salvar
          </Button>
        </Footer>
      </Container>
      <Modal ref={modalCustomerUser} size="md" removeCloseButton>
        <FormCustomerUserFromCustomer
          onCancel={() => modalCustomerUser.current?.close()}
          onSubmit={handleSubmitFormNewCustomerUser}
        />
      </Modal>
    </>
  );
};

export default FormDepartment;
