/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  InputHTMLAttributes,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import FormSelect from '../FormSelect';

import {
  Wrapper,
  InputContainer,
  CheckboxContainer,
  Label,
  Options,
} from './styles';

interface IInputBooleanProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  explanation?: string;
  explanationsec?: string;
  inputLabel: string;
  innerLabel?: string;
  inputInnerLabel?: string;
  name: string;
  focused?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  disabled?: boolean;
  list?: string[] | any;
  value: string;
  initialData?: string[];
  onChangeBoolean?(booleanValue: boolean | undefined): void;
}

const InputBoolean: React.FC<IInputBooleanProps> = ({
  name,
  label,
  explanation,
  explanationsec,
  inputLabel,
  inputInnerLabel,
  icon: Icon,
  focused,
  onChangeBoolean,
  disabled,
  list,
  value,
  initialData,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(focused || false);
  const isFilled = false;
  const { fieldName, defaultValue, error } = useField(name);

  const [booleanValue, setBooleanValue] = useState<boolean | undefined>(
    defaultValue === undefined || defaultValue === null
      ? undefined
      : !!defaultValue,
  );

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    if (onChangeBoolean) {
      onChangeBoolean(booleanValue);
    }
  }, [booleanValue, onChangeBoolean]);

  useEffect(() => {
    if (initialData && initialData[0]) {
      setBooleanValue(true);
    } else {
      setBooleanValue(false);
    }
  }, []);
  return (
    <Wrapper className="InputBoolean">
      <CheckboxContainer>
        {label && (
          <Label>
            {label}{' '}
            {explanation && (
              <AiOutlineQuestionCircle
                size={20}
                color="rgb(0,121,196)"
                title={explanation}
                className="Icon"
              />
            )}
          </Label>
        )}
        <Options>
          <label htmlFor={`${fieldName}_sim`}>
            <input
              id={`${fieldName}_sim`}
              type="radio"
              checked={booleanValue || false}
              onChange={() => !disabled && setBooleanValue(true)}
            />
            <span style={{ paddingLeft: '12px' }}>Sim</span>
          </label>
          <label htmlFor={`${fieldName}_nao`} style={{ marginLeft: '40px' }}>
            <input
              id={`${fieldName}_nao`}
              type="radio"
              checked={booleanValue === undefined ? false : !booleanValue}
              onChange={() => !disabled && setBooleanValue(false)}
            />
            <span style={{ paddingLeft: '12px' }}>Não</span>
          </label>
        </Options>
      </CheckboxContainer>

      {inputLabel && (
        <InputContainer
          isErrored={!!error}
          isFilled={isFilled}
          isFocused={isFocused}
          innerLabel={inputInnerLabel}
          disabled={!booleanValue || disabled}
        >
          <FormSelect
            label={inputLabel}
            explanation={explanationsec}
            placeholder=""
            name={value}
            onFocus={handleInputFocus}
            isMulti
            options={list?.map((strData: { id: unknown; name: unknown }) => ({
              value: strData.id,
              label: strData.name,
            }))}
            disabledSelec={!booleanValue || disabled}
          />
        </InputContainer>
      )}
    </Wrapper>
  );
};

export default InputBoolean;
