import React, { useContext } from 'react';

import { NumberToAlphabet } from 'number-to-alphabet';
import { MdDragHandle, MdEdit, MdDelete } from 'react-icons/md';
import { Container } from './styles';
import {
  ITemplateQuestion,
  ITemplateModule,
  ITemplateCategory,
} from '../../..';

import { TemplateContext } from '..';

const defaultAlphabet = new NumberToAlphabet();

interface ITemplateQuestionCardProps {
  templateModule: ITemplateModule;
  templateCategory: ITemplateCategory;
  templateQuestion: ITemplateQuestion;
  templateQuestionIndex: number;
}

const TemplateQuestionCard: React.FC<ITemplateQuestionCardProps> = ({
  templateModule,
  templateCategory,
  templateQuestion,
  templateQuestionIndex,
}) => {
  const { handleEditQuestion, handleRemoveQuestion } =
    useContext(TemplateContext);

  return (
    <Container>
      <li key={templateQuestion.id}>
        <div className="Title">
          <h1>{defaultAlphabet.numberToString(templateQuestionIndex + 1)}.</h1>
          <h2>{templateQuestion.description}</h2>
        </div>
        <div className="Icons">
          <MdDragHandle size={24} style={{ cursor: 'grab' }} />
          <MdEdit
            size={22}
            onClick={() => {
              handleEditQuestion(
                templateModule,
                templateCategory,
                templateQuestion,
              );
            }}
          />
          <MdDelete
            size={22}
            onClick={() => {
              handleRemoveQuestion(
                templateModule.id,
                templateCategory.id,
                templateQuestion.id,
              );
            }}
          />
        </div>
      </li>
    </Container>
  );
};

export default TemplateQuestionCard;
