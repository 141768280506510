import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 67px;
  height: 67px;
  border: 3px solid var(--unnamed-color-0079c4);
  border: 3px solid #0079c4;
  opacity: 1;
  border-radius: 50%;
`;
