import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useField } from '@unform/core';

import { Container, Wrapper } from './styles';

interface IInputScoreProps {
  name: string;
  label: string;
  disabled?: boolean;
}

const InputScore: React.FC<IInputScoreProps> = ({ name, label, disabled }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [valueSelected, setValueSelected] = useState<number | null | undefined>(
    null,
  );
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        const rst = (refs.find(ref => ref.checked) as HTMLInputElement).value;
        return rst ? Number(rst) : null;
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue !== null) {
      setValueSelected(defaultValue);
    } else {
      setValueSelected(null);
    }
  }, [defaultValue]);

  const handleSelect = useCallback((number: number | null) => {
    setValueSelected(number);
  }, []);

  return (
    <Container>
      <h1>{label}</h1>
      <Wrapper>
        <label htmlFor="n0">
          <input
            ref={ref => {
              inputRefs.current[0] = ref as HTMLInputElement;
            }}
            type="radio"
            disabled={disabled}
            value={0}
            checked={valueSelected === 0}
            onChange={() => {
              handleSelect(0);
            }}
          />
          0
        </label>
        <label htmlFor="n1">
          <input
            ref={ref => {
              inputRefs.current[1] = ref as HTMLInputElement;
            }}
            type="radio"
            disabled={disabled}
            value={1}
            checked={valueSelected === 1}
            onChange={() => {
              handleSelect(1);
            }}
          />
          1
        </label>
        <label htmlFor="n2">
          <input
            ref={ref => {
              inputRefs.current[2] = ref as HTMLInputElement;
            }}
            type="radio"
            disabled={disabled}
            value={2}
            checked={valueSelected === 2}
            onChange={() => {
              handleSelect(2);
            }}
          />
          2
        </label>
        <label htmlFor="n3">
          <input
            ref={ref => {
              inputRefs.current[3] = ref as HTMLInputElement;
            }}
            type="radio"
            disabled={disabled}
            value={3}
            checked={valueSelected === 3}
            onChange={() => {
              handleSelect(3);
            }}
          />
          3
        </label>
        <label htmlFor="n4">
          <input
            ref={ref => {
              inputRefs.current[4] = ref as HTMLInputElement;
            }}
            type="radio"
            disabled={disabled}
            value={4}
            checked={valueSelected === 4}
            onChange={() => {
              handleSelect(4);
            }}
          />
          4
        </label>
        <label htmlFor="n5">
          <input
            ref={ref => {
              inputRefs.current[5] = ref as HTMLInputElement;
            }}
            type="radio"
            disabled={disabled}
            value={5}
            checked={valueSelected === 5}
            onChange={() => {
              handleSelect(5);
            }}
          />
          5
        </label>
        <label htmlFor="na">
          <input
            ref={ref => {
              inputRefs.current[6] = ref as HTMLInputElement;
            }}
            type="radio"
            disabled={disabled}
            value={undefined}
            checked={valueSelected === null}
            onChange={() => {
              handleSelect(null);
            }}
          />
          N/A
        </label>
      </Wrapper>
    </Container>
  );
};

export default InputScore;
