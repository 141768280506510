import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 17px;
  > h1 {
    font-size: 14px;
    text-align: center;
    letter-spacing: 0px;
    color: #2c405a;
    opacity: 1;
  }

  > h3 {
    font-size: 13px;
    text-align: center;
    letter-spacing: 0px;
    color: #8dabc4;
    opacity: 1;
  }
`;
