import styled from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  innerLabel?: string;
  disabled?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Label = styled.h1`
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #8dabc4;
  margin-bottom: 10px;
  display: flex;

  > .Icon {
    margin-left: 5px;
  }
`;
