import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { format } from 'date-fns';
import { Container } from './styles';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import TextArea from '../../../../../components/TextArea';
import Select from '../../../../../components/Select';
import Option from '../../../../../components/Select/Option';
import { IProjectInterview } from '../../..';

interface IFormProps {
  initialData: { conclusion: string; project_interview_id: string };
  onSubmit(conclusion: string, project_interview_id: string): void;
  onCancel?(): void;
  interviews: IProjectInterview[];
}

interface IFormData {
  conclusion: string;
  project_interview_id: string;
}

const FormModuleConclusion: React.FC<IFormProps> = ({
  initialData,
  onSubmit,
  interviews,
}) => {
  const form = useRef<FormHandles>(null);
  const [editMode, setEditMode] = useState(!initialData.conclusion);

  const handleSave = useCallback(async () => {
    const { conclusion, project_interview_id } =
      form.current?.getData() as IFormData;
    try {
      form.current?.setErrors({});
      const schema = Yup.object().shape({
        conclusion: Yup.string().required('Conclusão é obrigatória'),
        project_interview_id: Yup.string().required('Entrevista é obrigatória'),
      });

      await schema.validate(
        {
          conclusion,
          project_interview_id,
        },
        {
          abortEarly: false,
        },
      );

      setEditMode(false);
      onSubmit(conclusion, project_interview_id);
    } catch (err) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        form.current?.setErrors(errors);
      }
    }
  }, [onSubmit]);

  return (
    <Container
      ref={form}
      initialData={initialData}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
    >
      <TextArea
        label="Conclusão do módulo *"
        name="conclusion"
        disabled={!editMode}
      />
      <Select
        name="project_interview_id"
        label="Entrevista *"
        disabled={!editMode}
      >
        {interviews.map(interview => (
          <Option
            key={interview.id}
            label={`${interview.customerUser?.user.name} - ${
              interview.customerUser?.position
            } - ${
              interview.date &&
              format(new Date(interview.date), 'dd/MMM/yyyy HH:mm')
            }`}
            value={interview.id}
          >
            {`${interview.customerUser?.user.name} (${
              interview.customerUser?.position
            }) - ${
              interview.date &&
              format(new Date(interview.date), 'dd/MMM/yyyy HH:mm')
            }`}
          </Option>
        ))}
      </Select>
      <div className="Buttons">
        {editMode ? (
          <button className="SaveButton" type="button" onClick={handleSave}>
            Salvar
          </button>
        ) : (
          <button
            className="EditButton"
            type="button"
            onClick={() => setEditMode(true)}
          >
            Editar
          </button>
        )}
      </div>
    </Container>
  );
};

export default FormModuleConclusion;
