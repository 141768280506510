/* eslint-disable react/jsx-indent */
import React, { useState, useRef, useCallback } from 'react';
import { FiSearch } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';
import { Switch, useLocation, useRouteMatch, Route } from 'react-router-dom';
import { mutate as mutateGlobal } from 'swr';
import Button from '../../components/Button';
import InputOnly from '../../components/InputOnly';
import Directorate, { ICustomerDirectorate } from './components/Directorate';
import Departmnet from './components/Department';

import { Container, Header, Main } from './styles';
import { useFetch } from '../../hooks/fetch';
import Modal, { IModalHandles } from '../../components/Modal';
import FormDirectorate from './forms/FormDirectorate';
import LoadingMask from '../../components/LoadingMask';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { ICustomerUser } from '../CustomerUsers';
import { useAuth } from '../../hooks/auth';

const DataMapping: React.FC = () => {
  const match = useRouteMatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [inputFilter, setInputFilter] = useState('');
  const { addToast } = useToast();
  const modalDirectorate = useRef<IModalHandles>();
  const { isCustomerDPO } = useAuth();

  const { data: customerDirectorates } = useFetch<ICustomerDirectorate[]>(
    '/customerDirectorates',
  );

  const { data: customerUsers } = useFetch<ICustomerUser[]>('/customerUsers');

  const handleNewDirectorate = useCallback(() => {
    modalDirectorate.current?.open();
  }, []);

  const handleSubmitFormNewDirecorate = useCallback(
    async (newCustomerDirectorate: ICustomerDirectorate) => {
      setLoading(true);
      try {
        modalDirectorate.current?.close();
        await api.post('/customerDirectorates', newCustomerDirectorate);
        mutateGlobal(
          '/customerDirectorates',
          async (oldCustomerDirectorates: ICustomerDirectorate[]) => {
            return [...oldCustomerDirectorates, newCustomerDirectorate];
          },
          false,
        );
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar salvar',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  if (location.pathname !== '/home/data-mapping') {
    return (
      <Switch>
        <Route path={`${match.path}/department/:id`} component={Departmnet} />
      </Switch>
    );
  }

  if (!customerDirectorates || !customerUsers) {
    return <LoadingMask text="Carregando..." />;
  }

  const normalizeString = (str: string): string =>
    str
      .toLowerCase()
      .normalize('NFKD')
      .replace(/[\s\p{M}-]+/gu, '');

  const inputFilterNormalized = normalizeString(inputFilter);

  const filteredDirectorates = customerDirectorates.filter(directorate => {
    const directorateNameMatch = normalizeString(directorate.name).includes(
      inputFilterNormalized,
    );
    const responsableNameMatch = directorate.responsable_customer_user?.user
      ?.name
      ? normalizeString(
          directorate.responsable_customer_user.user.name,
        ).includes(inputFilterNormalized)
      : false;

    const departmentMatch = directorate.scoped_customer_departments.some(
      department => {
        const departmentNameMatch = normalizeString(department.name).includes(
          inputFilterNormalized,
        );
        const responsableDepartmentMatch = department.responsable_customer_user
          ?.user?.name
          ? normalizeString(
              department.responsable_customer_user.user.name,
            ).includes(inputFilterNormalized)
          : false;
        return departmentNameMatch || responsableDepartmentMatch;
      },
    );

    return directorateNameMatch || responsableNameMatch || departmentMatch;
  });

  return (
    <>
      {loading ? <LoadingMask text="Salvando Diretoria" /> : null}
      <Container>
        <h1>Data Mapping</h1>
        <Header>
          <InputOnly
            name="search"
            icon={FiSearch}
            onChange={e => setInputFilter(e.target.value)}
          />
          {isCustomerDPO && (
            <Button icon={MdAdd} onClick={handleNewDirectorate}>
              Nova Diretoria
            </Button>
          )}
        </Header>
        <Main>
          {!filteredDirectorates.length && (
            <h1>Nenhuma diretoria ou departamento encontrado</h1>
          )}
          {filteredDirectorates.length
            ? filteredDirectorates.map(customerDirectorate => (
                <Directorate
                  key={customerDirectorate.id}
                  customerDirectorate={customerDirectorate}
                />
              ))
            : null}
        </Main>
      </Container>
      <Modal ref={modalDirectorate} size="sm" removeCloseButton>
        <FormDirectorate
          customerUsers={customerUsers}
          onCancel={() => modalDirectorate.current?.close()}
          onSubmit={handleSubmitFormNewDirecorate}
        />
      </Modal>
    </>
  );
};

export default DataMapping;
