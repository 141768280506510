/* eslint-disable no-param-reassign */
/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react';

import { Header, Main } from './styles';
import ChartRadar from '../../../../components/ChartRadar';
import { ICustomerGroup, ICustomerUser } from '../../../CustomerUsers';
import { IProject } from '../../../Projects';
import { useFetch } from '../../../../hooks/fetch';

interface IFormProps {
  initialData?: ICustomerUser;
  customerGroups?: ICustomerGroup[];
  onCancel?(): void;
}

const CustomerAssessmentMaturityCharts: React.FC<IFormProps> = () => {
  const { data: projects } = useFetch<IProject[]>(
    `/customerAssessmentProjects`,
    { revalidateOnFocus: false },
  );

  const modulesWithSum = useMemo(() => {
    return projects?.map(module =>
      module.scopedModules.map(m => ({
        ...m,
        projectName: `${module.name}-${module.version}`,
        categories: m.categories.map(c =>
          Object.assign(c, {
            avg_score:
              c.questions
                .filter(q => q.score || q.score === 0)
                // eslint-disable-next-line no-return-assign
                .reduce((acc, cur) => (acc += cur.score || 0), 0) /
              c.questions.filter(q => q.score || q.score === 0).length,
            avg_score_required:
              c.questions
                .filter(q => q.score_required || q.score_required === 0)
                // eslint-disable-next-line no-return-assign
                .reduce((acc, cur) => (acc += cur.score_required || 0), 0) /
              c.questions.filter(
                q => q.score_required || q.score_required === 0,
              ).length,
          }),
        ),
      })),
    );
  }, [projects]);

  return (
    <div>
      <Header>
        <h1>Gráficos de maturidade</h1>
      </Header>
      <Main>
        {modulesWithSum?.length ? (
          modulesWithSum?.map(module =>
            module.map(category => (
              <div className="Line" key={category.id}>
                <h2>
                  {category.projectName} - {category.name}
                </h2>
                <ChartRadar
                  labels={category.categories.map(c => c.name)}
                  scores={{
                    score: category.categories.map(c => c.avg_score),
                    perfect: category.categories.map(c => 5),
                    required: category.categories.map(
                      c => c.avg_score_required,
                    ),
                  }}
                />
              </div>
            )),
          )
        ) : (
          <h1>Nenhum gráfico de maturidade.</h1>
        )}
      </Main>
    </div>
  );
};

export default CustomerAssessmentMaturityCharts;
