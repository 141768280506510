/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useRef, useCallback, useState, useMemo } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Container, Main, Footer } from './styles';
import Button from '../../../../../../components/Button';
import TextArea from '../../../../../../components/TextArea';
import InputBoolean2 from '../../../../../../components/InputBoolean2';
import Modal, { IModalHandles } from '../../../../../../components/Modal';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { ICustomerProcess, IDataReasonSaveData } from '../..';
import FormSelect from '../../../../../../components/FormSelect';
import ModalConfirm from '../../components/ProcessConfirm';

interface IFormProps {
  initialData: ICustomerProcess;
  listReasonsSaveData?: IDataReasonSaveData[];
  readOnly?: boolean;
}

const FormProcessDiscard: React.FC<IFormProps> = ({
  initialData,
  listReasonsSaveData,
  readOnly,
}) => {
  const form = useRef<FormHandles>(null);
  const modalConfirm = useRef<IModalHandles>();

  const [useDiscard, setUseDiscard] = useState(false);

  const [confirmSaving, setConfirmSaving] = useState<ICustomerProcess>(
    initialData,
  );
  const closedModal = useCallback(() => {
    modalConfirm.current?.close();
  }, []);

  const handleSubmit = useCallback(
    async ({ data }: ICustomerProcess) => {
      try {
        form.current?.setErrors({});

        const schema = Yup.object().shape({
          data: Yup.object().shape({
            discard: Yup.object().shape({
              fields: Yup.object().shape({
                discard_how:
                  useDiscard === true
                    ? Yup.string().required('Campo obrigatório')
                    : Yup.string().nullable(),
                reasons_save:
                  useDiscard === false
                    ? Yup.string().required('Campo obrigatório')
                    : Yup.string().nullable(),
              }),
            }),
          }),
        });
        await schema.validate(
          { data },
          {
            abortEarly: false,
          },
        );

        const newCustomerProcess: ICustomerProcess = {
          ...initialData,
          data: {
            ...initialData.data,
            discard: {
              ...initialData.data.discard,
              fields: {
                ...data.discard.fields,
                firstrecording_discard: true,
              },
            },
          },
        };

        setConfirmSaving(newCustomerProcess);
        modalConfirm.current?.open();

        // setLoading(true);
        // try {
        //   await api.patch(
        //     `/customerProcesses/${initialData.id}`,
        //     newCustomerProcess,
        //   );

        //   mutateProcess(newCustomerProcess, 'edit');
        // } catch (error) {
        //   addToast({
        //     title: 'Erro',
        //     type: 'error',
        //     description: 'Falha ao tentar salvar',
        //   });
        // } finally {
        //   setLoading(false);
        // }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [initialData, useDiscard],
  );

  const disabled = useMemo(() => {
    if (readOnly) {
      return true;
    }
    if (
      initialData.status !== 'Preenchimento' &&
      initialData.status !== 'Revalidação' &&
      initialData.reopened_process !== true
    ) {
      return true;
    }
    return false;
  }, [initialData.reopened_process, initialData.status, readOnly]);

  return (
    <>
      <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
        <Main>
          <InputBoolean2
            label="Os dados pessoais utilizados neste processo são descartados após o cumprimento da finalidade?"
            explanation="Informar se há eliminação dos dados pessoais tanto no meio físico quanto no meio digital."
            name="data.discard.fields.use_discard"
            disabled={disabled}
            onChangeBoolean={booleanValue => {
              setUseDiscard(false);

              if (booleanValue) {
                setUseDiscard(false);
                setUseDiscard(booleanValue);
              }
            }}
          />

          {useDiscard ? (
            <TextArea
              label="Qual a forma de descarte?"
              explanation={
                'Descrever como é feita a eliminação destes dados pessoais.\nExemplo: exclusão de e-mails e arquivos, máquina picotadora'
              }
              name="data.discard.fields.discard_how"
              disabled={disabled}
            />
          ) : (
            <div className="InputSelectList">
              <FormSelect
                label="Por que os dados pessoais são mantidos após o cumprimento de sua finalidade?"
                explanation={
                  'Para manter os dados pessoais coletados neste processo após o cumprimento da finalidade,\né necessário que seja utilizada uma das hipóteses abaixo:\n\t - Consentimento do Titular: utilizado quando o Titular concorda, de maneira inequívoca e\nexpressa, que a organização mantenha seus dados armazenados após o cumprimento\nda finalidade original. Deve-se manter o controle do consentimento e observar os\ndireitos do Titular enquanto mantiver os dados armazenados na organização;\n\t - Tratamento de dados anonimizados: utilização dos dados após aplicação de meios\ntécnicos dos quais um dado perde a possibilidade de associação, direta ou indireta, a um\nindivíduo;\n\t - Interesses legítimos: para uso exclusivo do controlador, vedado seu acesso por terceiro,\ne desde que anonimizados os dados.\n\t - Motivo desconhecido ou indeterminado: os dados são mantidos na organização, sem\num processo de exclusão definido e sem um motivo válido;'
                }
                name="data.discard.fields.reasons_save"
                placeholder=""
                options={listReasonsSaveData?.map(strData => ({
                  value: strData.id,
                  label: strData.name,
                }))}
                disabledSelec={disabled}
              />

              {/*
              {whatUse && (
                <Input
                  label="Qual o uso?"
                  name="data.discard.fields.what_use"
                  disabled={disabled}
                />
              )}

              /* {useRegulation && (
                <Input
                  label="Qual a lei ou regulação? "
                  name="data.discard.fields.regulation"
                  disabled={disabled}
                />
              )}  */}
            </div>
          )}
        </Main>

        <Modal ref={modalConfirm} removeCloseButton size="sm">
          <ModalConfirm
            newData={confirmSaving}
            onCancel={() => modalConfirm.current?.close()}
            onSubmit={closedModal}
          />
        </Modal>
        {!disabled && (
          <Footer>
            <Button type="submit" background="#4ECE3D">
              Salvar
            </Button>
          </Footer>
        )}
      </Container>
    </>
  );
};

export default FormProcessDiscard;
