import React from 'react';

import { Container } from './styles';

interface IDisplayOpinionFieldProps {
  name: string;
  value?: string;
}

const DisplayOpinionField: React.FC<IDisplayOpinionFieldProps> = ({
  name,
  value,
}) => {
  return (
    <Container className="DisplayOpinionField">
      <h1>{name}</h1>
      <div>
        <h2>{value}</h2>
      </div>
    </Container>
  );
};

export default DisplayOpinionField;
