import React, { useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import { IOpinion, LookupOpinionsType } from '..';
import Card from '../../../components/Card';
import LoadingMask from '../../../components/LoadingMask';
import { useFetch } from '../../../hooks/fetch';
import Diagram from '../../DataMapping/components/Diagram';
import BadgeLabel from '../../DataMapping/components/Diagram/components/BadgeLabel';
import { IProcessOpinionType } from '../../DataMapping/components/Process';
import Details from './Details';
import Opinion from './Opinion';

import {
  Container,
  Header,
  WrapperTitle,
  WrapperBadgeLabel,
  OpinionHeader,
  OpinionMain,
  Motivation,
  TabsProject,
  HeaderTabs,
  TabHeader,
  ContentOpinion,
  Tab,
} from './styles';

const OpinionView: React.FC = () => {
  const { id: idOpinion } = useParams<{ id: string }>();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('Parecer');

  const { data: opinion } = useFetch<IOpinion>(`/opinions/${idOpinion}`);

  if (!opinion) {
    return <LoadingMask text="Carregando" />;
  }

  return (
    <Container>
      <Header>
        <IoIosArrowBack size={24} onClick={() => history.goBack()} />
        <h1>{opinion.type && LookupOpinionsType[opinion.type]}</h1>
      </Header>

      <Card>
        <OpinionHeader>
          <WrapperTitle>
            <div>
              <h1>{`${opinion.customer_process?.cod} - ${opinion.customer_process?.name}`}</h1>
              <h3>{opinion.customer?.name}</h3>
            </div>
          </WrapperTitle>

          <WrapperBadgeLabel>
            <BadgeLabel
              color="#4ECE3D"
              name={opinion.type as IProcessOpinionType}
              label={opinion.type ? LookupOpinionsType[opinion.type] : ''}
            />
          </WrapperBadgeLabel>
        </OpinionHeader>
        <OpinionMain>
          <Motivation>
            <h1>{opinion.customer_process?.description}</h1>
          </Motivation>

          <TabsProject>
            <HeaderTabs>
              <TabHeader
                className={activeTab === 'Detalhes' ? 'active' : undefined}
                onClick={() => setActiveTab('Detalhes')}
              >
                Detalhes do processo
              </TabHeader>

              <TabHeader
                className={activeTab === 'Diagrama' ? 'active' : undefined}
                onClick={() => setActiveTab('Diagrama')}
              >
                Diagrama
              </TabHeader>

              <TabHeader
                className={activeTab === 'Parecer' ? 'active' : undefined}
                onClick={() => setActiveTab('Parecer')}
              >
                Parecer
              </TabHeader>
            </HeaderTabs>
          </TabsProject>
          <TabsProject className="TabsProjectContent">
            <ContentOpinion>
              {activeTab === 'Detalhes' && (
                <Tab>
                  {opinion.customer_process && (
                    <Details customer_process={opinion.customer_process} />
                  )}
                </Tab>
              )}
              {activeTab === 'Diagrama' && (
                <Tab>
                  {opinion.customer_process && (
                    <Diagram customer_process={opinion.customer_process} />
                  )}
                </Tab>
              )}
              {activeTab === 'Parecer' && (
                <Tab>
                  {opinion.customer_process && <Opinion opinion={opinion} />}
                </Tab>
              )}
            </ContentOpinion>
          </TabsProject>
        </OpinionMain>
      </Card>
    </Container>
  );
};

export default OpinionView;
