import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { v4 } from 'uuid';
import Chip from '../Chip';

import { Container, Content, Label, Error } from './styles';

interface IInputChipsProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: string;
  focused?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
}

const InputChips: React.FC<IInputChipsProps> = ({
  name,
  label,
  icon: Icon,
  focused,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(focused || false);
  const [isFilled, setisFilled] = useState(false);
  const [newValue, setNewValue] = useState<string>('');
  const [dbValue, setDbValue] = useState<string>('');
  const [chips, setChips] = useState<{ id: string; label: string }[]>([]);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setisFilled(!!inputRef.current?.value);
  }, []);

  const handleRemoveBadge = useCallback(
    (id: string) => {
      setChips(chips.filter(b => b.id.toString() !== id.toString()));
    },
    [chips],
  );

  const handleKeyDown = useCallback(
    e => {
      if (e.keyCode === 188) {
        e.preventDefault();
      }
      if (e.keyCode === 13) {
        e.preventDefault();
        if (newValue.trim()) {
          setChips([...chips, { id: v4(), label: newValue.trim() }]);
          setNewValue('');
        }
      }
    },
    [chips, newValue],
  );

  useEffect(() => {
    setDbValue(chips.map(b => b.label).join(','));
  }, [chips]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue) {
      const items = defaultValue.split(',');
      setChips(items.map((i: string) => ({ id: v4(), label: i })));
    }
  }, [defaultValue]);

  return (
    <Container className="Input">
      {label && <Label>{label}</Label>}
      {/* CHIPS: {JSON.stringify(chips)} */}
      <Content isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
        {Icon && <Icon size={20} />}
        <label htmlFor={name}>
          {chips.map(b => (
            <Chip
              key={b.id}
              value={b.label}
              withClose
              onClose={() => handleRemoveBadge(b.id)}
            />
          ))}
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyDown}
            value={newValue}
            onChange={e => setNewValue(e.target.value)}
          />
          <input
            style={{ display: 'none' }}
            id={name}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={e => setDbValue(e.currentTarget.value)}
            value={dbValue}
            ref={inputRef}
          />
        </label>

        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Content>
    </Container>
  );
};

export default InputChips;
