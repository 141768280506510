import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { FiSearch } from 'react-icons/fi';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import arraySort from 'array-sort';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { Container, Header, Pagination, ItemPage } from './styles';
import InputOnly from '../../components/InputOnly';
import Table from '../../components/Table';
import IconOrderBy from '../../components/IconOrderBy';
import { useFetch } from '../../hooks/fetch';
import CustomerDataDetails from './CustomerDataDetails';

export interface ICustomerData {
  name: string;
  numberOfDirectorates: number;
  numberOfDepartments: number;
  numberOfProcesses: number;
}

interface IOrderBy {
  fieldName: string;
  ascendant: boolean;
}

const CustomerData: React.FC = () => {
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [customerDataToDisplay, setCustomerDataToDisplay] = useState<{
    filtered: number;
    customerData: ICustomerData[];
  }>({ filtered: 0, customerData: [] });
  const history = useHistory();
  const [inputFilter, setInputFilter] = useState('');
  const [orderBy, setOrderBy] = useState<IOrderBy>({
    fieldName: 'name',
    ascendant: false,
  });
  const match = useRouteMatch();
  const location = useLocation();

  const { data: customerData } =
    useFetch<ICustomerData[]>('/customerDataGroup');

  const handleOrderBy = useCallback(
    ({ fieldName, ascendant }: IOrderBy) => {
      setOrderBy({
        fieldName,
        ascendant:
          fieldName === orderBy.fieldName ? !orderBy.ascendant : ascendant,
      });
    },
    [orderBy.ascendant, orderBy.fieldName],
  );

  useEffect(() => {
    if (customerData) {
      if (!inputFilter.length) {
        setCustomerDataToDisplay({
          filtered: customerData?.length,
          customerData,
        });
      }
      const filtered = customerData?.filter(customer => {
        const normalizedInputFilter = inputFilter
          .toLowerCase()
          .normalize('NFKD')
          .replace(/[\s\p{M}-]+/gu, '');

        const normalizedCustomerName = customer.name
          .toLowerCase()
          .normalize('NFKD')
          .replace(/[\s\p{M}-]+/gu, '');

        return (
          normalizedCustomerName.includes(normalizedInputFilter) ||
          customer.name.toLowerCase().includes(normalizedInputFilter)
        );
      });

      if (filtered) {
        const ordered = arraySort(filtered, orderBy.fieldName, {
          reverse: orderBy.ascendant,
        });

        const paged = ordered.slice(
          (page - 1) * perPage,
          (page - 1) * perPage + perPage,
        );

        if (customerData)
          setCustomerDataToDisplay({
            filtered: filtered.length,
            customerData: paged,
          });
      }
    }
  }, [
    inputFilter,
    orderBy.ascendant,
    orderBy.fieldName,
    page,
    perPage,
    customerData,
  ]);

  useEffect(() => {
    setPage(1);
  }, [inputFilter]);

  const pages = useMemo(() => {
    if (!customerDataToDisplay.filtered) return 1;
    return Math.ceil(customerDataToDisplay.filtered / perPage);
  }, [customerDataToDisplay.filtered, perPage]);

  const pageNumbers = useMemo(() => {
    const maxDisplayPages = 15;
    const diff = Math.ceil(maxDisplayPages / 2);
    let start = 0;
    if (pages > maxDisplayPages && page > pages - diff) {
      start = pages - maxDisplayPages;
    } else if (pages > maxDisplayPages && page > diff) {
      start = page - diff;
    } else {
      start = 0;
    }
    const limiters = { start, end: start + maxDisplayPages };

    const pns: number[] = [];
    for (let i = 1; i <= pages; i += 1) {
      pns.push(i);
    }
    return pns.slice(limiters.start, limiters.end);
  }, [page, pages]);

  const handleSetPageUp = useCallback(() => {
    if (page < pages) setPage(page + 1);
  }, [page, pages]);

  const handleSetPageDown = useCallback(() => {
    if (page > 1) setPage(page - 1);
  }, [page]);

  if (location.pathname !== '/home/customerData') {
    return (
      <Switch>
        <Route path={`${match.path}/:name`} component={CustomerDataDetails} />
      </Switch>
    );
  }

  return (
    <Container>
      <h1>Mapa de dados</h1>
      <Header>
        <InputOnly
          name="searchProcessStr"
          icon={FiSearch}
          onChange={e => setInputFilter(e.target.value)}
        />
      </Header>
      <Table>
        <thead>
          <tr className="Header">
            <th style={{ width: '55%' }}>
              <div>
                <span>DADO</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'name'}
                  onClick={() =>
                    handleOrderBy({ fieldName: 'name', ascendant: false })
                  }
                />
              </div>
            </th>
            <th style={{ width: '15%' }}>
              {' '}
              <div>
                <span>DIRETORIAS</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'numberOfDirectorates'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'numberOfDirectorates',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>
            <th style={{ width: '15%' }}>
              {' '}
              <div>
                <span>DEPARTAMENTOS</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'numberOfDepartments'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'numberOfDepartments',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>
            <th style={{ width: '15%' }}>
              {' '}
              <div>
                <span>PROCESSOS</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'numberOfProcesses'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'numberOfProcesses',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>
          </tr>
        </thead>
        {!customerData && (
          <tbody>
            <tr>
              <td colSpan={6}>Carregando ...</td>
            </tr>
          </tbody>
        )}
        {customerData && !customerData.length && (
          <tbody>
            <tr>
              <td colSpan={6}>Nenhum dado encontrado</td>
            </tr>
          </tbody>
        )}
        <tbody>
          {customerDataToDisplay.customerData.map(cd => (
            <tr
              key={cd.name}
              onClick={() => history.push(`/home/customerData/${cd.name}`)}
            >
              <td>{cd.name}</td>
              <td>{cd.numberOfDirectorates}</td>
              <td>{cd.numberOfDepartments}</td>
              <td>{cd.numberOfProcesses}</td>
            </tr>
          ))}
          <tr className="Pagination">
            <td colSpan={6}>
              <Pagination>
                <li>
                  <IoMdArrowDropleft
                    size={18}
                    onClick={() => handleSetPageDown()}
                  />
                </li>
                {pageNumbers.map(pg => (
                  <ItemPage key={pg} active={pg === page}>
                    <button type="button" onClick={() => setPage(pg)}>
                      {pg}
                    </button>
                  </ItemPage>
                ))}
                <li>
                  <IoMdArrowDropright
                    size={18}
                    onClick={() => handleSetPageUp()}
                  />
                </li>
              </Pagination>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default CustomerData;
