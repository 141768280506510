import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { mutate as mutateGlobal, cache } from 'swr';
import { Container } from './styles';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import Spinner from '../../../components/Spinner';
import { useFetch } from '../../../hooks/fetch';
import FormPartner from '../forms/FormPartner';
import { IPartner } from '..';

interface INewPasswordFormData {
  new_password: string;
}

const PartnerForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id: idPartner } = useParams<{ id: string }>();
  const { data: partner, error, mutate } = useFetch(`/partners/${idPartner}`, {
    revalidateOnFocus: false,
  });

  const handleSubmit = useCallback(
    (partnerReturned: IPartner) => {
      const save = async (): Promise<void> => {
        const oldPartners = cache.get('/partners');
        const oldPartner = cache.get(`/partners/${idPartner}`);
        api.patch(`/partners/${idPartner}`, partnerReturned).catch(err => {
          const { message } = err.response.data;
          let description = 'Ocorreu um erro ao salvar os dados do parceiro.';
          if (message === 'Partner register number already used.') {
            description = 'CNPJ já utilizado.';
          }
          mutate(oldPartner, false);
          mutateGlobal('/partners', oldPartners, false);
          addToast({
            type: 'error',
            title: 'Erro ao salvar',
            description,
          });
        });
        mutate(partnerReturned, false);
        mutateGlobal(
          '/partners',
          async (partners: IPartner[]) => {
            return partners.map(p =>
              p.id === idPartner ? partnerReturned : p,
            );
          },
          false,
        );
        history.goBack();
      };
      save();
    },
    [addToast, history, idPartner, mutate],
  );

  if (!partner) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (error) {
    addToast({
      type: 'error',
      title: 'Erro de carregamento',
      description: 'Ocorreu um erro ao carregar dados do parceiro.',
    });
  }

  return (
    <Container>
      <h1>Editar Parceiro</h1>
      <FormPartner initialData={partner} onSubmit={handleSubmit} />
    </Container>
  );
};

export default PartnerForm;
