import styled from 'styled-components';
import Tooltip from '../Tooltip';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div.box_input {
    display: flex;

    > div.input_select {
      width: 100%;
      display: block;
    }
  }
`;

export const Label = styled.h1`
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #8dabc4;
  margin-bottom: 10px;
  display: flex;

  > .Icon {
    margin-left: 5px;
  }
`;


export const Error = styled(Tooltip)`
  svg {
    margin: 10px 10px 0 5px;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

