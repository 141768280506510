/* eslint-disable react/require-default-props */
import React, { useRef, useEffect, useMemo } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
  Styles,
  GroupTypeBase,
} from 'react-select';
import { useField } from '@unform/core';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FiAlertCircle } from 'react-icons/fi';
import { Container, Label, Error } from './styles';

interface IProps
  extends SelectProps<OptionTypeBase, true, GroupTypeBase<OptionTypeBase>> {
  name: string;
  label: string;
  explanation?: string;
  disabledSelec?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function FormSelect({
  name,
  label,
  explanation,
  disabledSelec,
  ...rest
}: IProps) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const defaultValueModified = useMemo(() => {
    if (rest.isMulti) {
      if (defaultValue) {
        return (defaultValue as string[]).map(df =>
          rest?.options ? rest?.options.find(o => o.value === df) : [],
        );
      }
    }
    if (rest?.options) {
      return rest?.options.find(o => o.value === defaultValue);
    }
  }, [defaultValue, rest]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }

          if (ref.state.value[0] === undefined) {
            return [];
          }

          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }

        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const customStyles: Styles<
    OptionTypeBase,
    true,
    GroupTypeBase<OptionTypeBase>
  > = {
    control: (provided, state) => ({
      ...provided,
      border: error ? '1px solid #c53030' : '1px solid #a8c6df',
      boxShadow: `0 0 0 0px '#a8c6df'`,
      '&:hover': {
        boxShadow: `0 0 0 0px '#a8c6df'`,
      },
      backgroundColor: state.isFocused
        ? '#ecf5fd'
        : state.isDisabled
        ? '#f7f7f7'
        : '#fff',
    }),
  };

  return (
    <Container className="FormSelect">
      {label && (
        <Label>
          {label}
          {explanation && (
            <AiOutlineQuestionCircle
              size={20}
              color="rgb(0,121,196)"
              title={explanation}
              className="Icon"
            />
          )}{' '}
        </Label>
      )}

      <div className="box_input">
        <div className="input_select">
          <ReactSelect
            styles={customStyles}
            defaultValue={defaultValueModified}
            ref={selectRef}
            className="basic-multi-select"
            classNamePrefix="select"
            noOptionsMessage={() => 'Nenhuma opção'}
            isDisabled={disabledSelec}
            {...rest}
          />
        </div>
        <div className="button_error">
          {error && (
            <Error title={error}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )}
        </div>
      </div>
    </Container>
  );
}
