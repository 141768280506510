import React, { useRef, useCallback, useContext } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { MdAdd } from 'react-icons/md';
import { Container, Main } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import Option from '../../../../components/Select/Option';
import { ITemplateModule } from '../..';
import { TemplateContext } from '../FormTemplate';

interface IFormProps {
  onSubmit(data: IQuizFormData): void;
  onCancel?(): void;
  onClickTemplateModule(): void;
  onClickTemplateCategory(): void;
  modules: ITemplateModule[];
  onChangeModule?(idModule: string): void;
}

export interface IQuizFormData {
  moduleId: string;
  categoryId: string;
  description: string;
}

const FormTemplateQuiz: React.FC<IFormProps> = ({
  onSubmit,
  onClickTemplateModule,
  onClickTemplateCategory,
  onChangeModule,
  modules,
}) => {
  const {
    quizFormModuleId,
    setQuizFormModuleId,
    quizFormCategoryId,
    setQuizFormCategoryId,
  } = useContext(TemplateContext);
  const form = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async ({ moduleId, categoryId, description }: IQuizFormData) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          moduleId: Yup.string().required('Módulo é obrigatório'),
          categoryId: Yup.string().required('Categoria é obrigatório'),
          description: Yup.string().required('Questão é obrigatório'),
        });

        await schema.validate(
          { moduleId, categoryId, description },
          {
            abortEarly: false,
          },
        );

        onSubmit({ moduleId, categoryId, description });
        form.current?.clearField('description');
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [onSubmit],
  );

  return (
    <Container ref={form} onSubmit={handleSubmit}>
      <Main>
        <div className="Line1">
          <Select
            name="moduleId"
            label="Módulo"
            onChange={(value: string) => {
              setQuizFormCategoryId('');
              setQuizFormModuleId(value);
            }}
            value={quizFormModuleId}
          >
            {modules.map(module => (
              <Option key={module.id} label={module.name} value={module.id}>
                {module.name}
              </Option>
            ))}
          </Select>
          <Select
            name="categoryId"
            label="Categoria"
            onChange={value => setQuizFormCategoryId(value)}
            value={quizFormCategoryId}
          >
            {modules
              .find(m => m.id === quizFormModuleId)
              ?.categories.map(category => (
                <Option
                  key={category.id}
                  label={category.name}
                  value={category.id}
                >
                  {category.name}
                </Option>
              ))}
          </Select>
        </div>
        <div className="Line2">
          <div className="New">
            <button type="button" onClick={onClickTemplateModule}>
              + Novo módulo
            </button>
          </div>
          <div className="New">
            <button type="button" onClick={onClickTemplateCategory}>
              + Nova categoria
            </button>
          </div>
        </div>
        <div className="Line3">
          <Input label="Pergunta" name="description" />
          <Button type="submit" icon={MdAdd} background="#0079C4">
            Adicionar
          </Button>
        </div>
      </Main>
    </Container>
  );
};

export default FormTemplateQuiz;
