import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { v4 } from 'uuid';
import { mutate as mutateGlobal } from 'swr';
import { Container, Header, Main, Footer } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { ICustomerUser } from '../../../CustomerUsers';
import Modal, { IModalHandles } from '../../../../components/Modal';
import FormCustomerUserFromCustomer from '../../../CustomerUsers/forms/FormCustomerUserFromCustomer';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import LoadingMask from '../../../../components/LoadingMask';
import { ICustomerProcess } from '../../components/Process';
import TextArea from '../../../../components/TextArea';
import { ICustomerDepartment } from '../../components/Department';
import FormSelect from '../../../../components/FormSelect';

interface IFormProps {
  initialData?: ICustomerProcess | undefined;
  customerUsers: ICustomerUser[];
  customerDepartment: ICustomerDepartment;
  onSubmit(newCustomerDirecotorate: ICustomerProcess): void;
  onCancel(): void;
  onDelete?(): void;
}

interface IProcessFormData {
  cod: string;
  name: string;
  description: string;
  responsable_customer_user_id: string;
}

const FormProcess: React.FC<IFormProps> = ({
  initialData,
  customerUsers,
  customerDepartment,
  onSubmit,
  onCancel,
  onDelete,
}) => {
  const form = useRef<FormHandles>(null);
  const modalCustomerUser = useRef<IModalHandles>();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async ({
      cod,
      name,
      description,
      responsable_customer_user_id,
    }: IProcessFormData) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          cod: Yup.string()
            .required('ID do processo é obrigatório')
            .test('checkDuplicate', 'ID já existe', () => {
              return new Promise(resolve => {
                const exists =
                  customerDepartment.scoped_customer_processes.find(
                    cp => cp.cod === cod && cp.id !== initialData?.id,
                  );
                resolve(!exists);
              });
            }),
          name: Yup.string().required('Nome do processo é obrigatório'),
          description: Yup.string().required(
            'Motivação/Objetivo do processo é obrigatório',
          ),
          responsable_customer_user_id: Yup.string().required(
            'Responsável é obrigatório',
          ),
        });

        await schema.validate(
          { cod, name, description, responsable_customer_user_id },
          {
            abortEarly: false,
          },
        );

        const responsable_customer_user = customerUsers.find(
          cu => cu.id === responsable_customer_user_id,
        );

        const newProcess: ICustomerProcess = {
          ...(initialData || {
            id: v4(),
            customer_directorate_id: customerDepartment.customer_directorate_id,
            customer_department_id: customerDepartment.id,
            cod: '',
            name: '',
            status: 'Preenchimento',
            description: '',
            reopened_process: false,
            formated_opinions: {
              busines: { status: 'Preenchimento', opinions: [] },
              compliance: { status: 'Preenchimento', opinions: [] },
              juridical: { status: 'Preenchimento', opinions: [] },
              security: { status: 'Preenchimento', opinions: [] },
            },
            busines_status: 'Preenchimento',
            compliance_status: 'Preenchimento',
            juridical_status: 'Preenchimento',
            security_status: 'Preenchimento',
            data: {
              collect: {
                percent_filled: 0,
                fields: {},
              },
              consent: {
                percent_filled: 0,
                fields: {},
              },
              treatment: {
                percent_filled: 0,
                fields: {},
              },
              store: {
                percent_filled: 0,
                fields: {},
              },
              share: {
                percent_filled: 0,
                fields: {},
              },
              // backup: {
              //   percent_filled: 0,
              //   fields: {},
              // },
              discard: {
                percent_filled: 0,
                fields: {},
              },
            },
            percent_filled: 0,
          }),
          cod,
          name,
          description,
          responsable_customer_user_id,
          responsable_customer_user,
        };

        onSubmit(newProcess);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [
      customerDepartment.customer_directorate_id,
      customerDepartment.scoped_customer_processes,
      customerDepartment.id,
      customerUsers,
      initialData,
      onSubmit,
    ],
  );

  const handleSubmitFormNewCustomerUser = useCallback(
    async (newCustomerUser: ICustomerUser) => {
      setLoading(true);
      try {
        modalCustomerUser.current?.close();

        await api.post('/customerUsers', newCustomerUser);
        mutateGlobal(
          '/customerUsers',
          async (oldCustomerUsers: ICustomerUser[]) => {
            return [...oldCustomerUsers, newCustomerUser];
          },
          false,
        );
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar salvar',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <>
      {loading ? <LoadingMask text="Salvando" /> : null}
      <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
        <Header>
          <h1>{initialData ? 'Renomear Processo' : 'Novo Processo'}</h1>
        </Header>
        <Main>
          <div className="Line1">
            <Input name="cod" label="ID *" type="text" />
            <Input name="name" label="Nome do processo *" />
          </div>
          <FormSelect
            name="responsable_customer_user_id"
            label="Responsável pelo processo *"
            placeholder=""
            options={customerUsers
              .filter(cu =>
                cu.customerGroups.find(cg => cg.name === 'Responsable Process'),
              )
              .map(customerUser => ({
                label: `${customerUser.user.name} (${customerUser.position})`,
                value: customerUser.id,
              }))}
          />
          <button
            type="button"
            className="AddNewCustomerUser"
            onClick={() => modalCustomerUser.current?.open()}
          >
            + Novo usuário
          </button>
          <TextArea name="description" label="Motivação / Objetivo *" />
        </Main>
        <Footer>
          {initialData && (
            <Button background="#FF151F" onClick={onDelete}>
              Remover
            </Button>
          )}
          <Button background="transparent" color="#A8C6DF" onClick={onCancel}>
            Cancelar
          </Button>
          <Button type="submit" background="#4ECE3D">
            Salvar
          </Button>
        </Footer>
      </Container>
      <Modal ref={modalCustomerUser} size="md" removeCloseButton>
        <FormCustomerUserFromCustomer
          onCancel={() => modalCustomerUser.current?.close()}
          onSubmit={handleSubmitFormNewCustomerUser}
        />
      </Modal>
    </>
  );
};

export default FormProcess;
