import React, {
  useRef,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { toRoman } from 'roman-numerals';
import { v4 } from 'uuid';
import { Container, Header, Main, Footer } from './styles';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import Input from '../../../../../components/Input';
import Button from '../../../../../components/Button';
import Select from '../../../../../components/Select';
import Option from '../../../../../components/Select/Option';
import { IPartner } from '../../../../Partners';
import { IProjectInterview, ProjectContext } from '../../..';
import Spinner from '../../../../../components/Spinner';
import { useFetch } from '../../../../../hooks/fetch';

interface IFormProps {
  initialData: IProjectInterview;
  onSubmit(newProjectInterview: IProjectInterview): void;
  onCancel?(): void;
}

interface IFormData {
  project_module_id: string;
  customer_user_id: string;
  partner_id: string;
  partner: IPartner;
  partner_user_id: string;
  date: Date;
  time: Date;
}

const FormInterview: React.FC<IFormProps> = ({
  initialData,
  onSubmit,
  onCancel,
}) => {
  const form = useRef<FormHandles>(null);
  const { project } = useContext(ProjectContext);
  const [partnerSelected, setPartnerSelected] = useState<
    IPartner | undefined
  >();

  const { data: partners } = useFetch<IPartner[]>('/partners', {
    revalidateOnFocus: false,
  });

  const handleSubmit = useCallback(
    async ({
      project_module_id,
      customer_user_id,
      partner_id,
      partner_user_id,
      date,
      time,
    }: IFormData) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          project_module_id: Yup.string().required('Módulo é obrigatório'),
          customer_user_id: Yup.string().required('Entrevistado é obrigatório'),
          partner_id: Yup.string().required('Parceiro é obrigatório'),
          partner_user_id: Yup.string().required('Consultor é obrigatório'),
          date: Yup.string().required('Data é obrigatório'),
          time: Yup.string().required('Horário é obrigatório'),
        });

        await schema.validate(
          {
            project_module_id,
            customer_user_id,
            partner_id,
            partner_user_id,
            date,
            time,
          },
          {
            abortEarly: false,
          },
        );

        const partnerUser = partners
          ?.find(p => p.id === partner_id)
          ?.partnerUsers.find(c => c.id === partner_user_id);

        onSubmit({
          id: v4(),
          project_id: project.id,
          project_module_id,
          customer_user_id,
          partner_id,
          partner_user_id,
          date: new Date(`${date}T${time}`),
          done: false,
          partnerUser,
        });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [onSubmit, partners, project.id],
  );

  const handleSelectPartner = useCallback(
    (idPartner: string) => {
      setPartnerSelected(partners?.find(p => p.id === idPartner));
    },
    [partners],
  );

  useEffect(() => {
    setPartnerSelected(partners?.find(p => p.id === initialData.partner_id));
    if (initialData.date) {
      form.current?.setFieldValue(
        'time',
        format(new Date(initialData.date), 'HH:mm'),
      );
      form.current?.setFieldValue(
        'date',
        format(new Date(initialData.date), 'yyyy-MM-dd'),
      );
    }
  }, [initialData, partners]);

  if (!partners) {
    return (
      <Container ref={form} onSubmit={handleSubmit}>
        <Header>
          <h1>
            {!initialData.project_module_id
              ? 'Nova Entrevista'
              : 'Editar Entrevista'}
          </h1>
        </Header>
        <Main>
          <Spinner />
        </Main>
        <Footer>
          <Button
            type="button"
            background="transparent"
            color="grey"
            onClick={onCancel}
          >
            Cancelar
          </Button>
          <Button type="submit">Salvar</Button>
        </Footer>
      </Container>
    );
  }

  return (
    <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
      <Header>
        <h1>
          {!initialData.project_module_id
            ? 'Nova Entrevista'
            : 'Editar Entrevista'}
        </h1>
      </Header>
      <Main>
        <div className="Line1">
          <Select
            label="Módulo *"
            name="project_module_id"
            disabled={!!initialData.project_module_id}
          >
            {project.scopedModules
              .filter(
                module =>
                  !module.interview?.done || initialData.project_module_id,
              )
              .map(module => (
                <Option
                  key={module.id}
                  value={module.id}
                  label={`Módulo ${toRoman(module.position + 1)} - ${
                    module.name
                  }`}
                >
                  {`Módulo ${toRoman(module.position + 1)} - ${module.name}`}
                </Option>
              ))}
          </Select>
        </div>
        <div className="Line2">
          <Select
            label="Entrevistado *"
            name="customer_user_id"
            disabled={!!initialData.project_module_id}
          >
            {project.customer.customerUsers
              .filter(customerUser =>
                customerUser.customerGroups.find(
                  cg => cg.name === 'Interviewee',
                ),
              )
              .map(customerUser => (
                <Option
                  key={customerUser.id}
                  value={customerUser.id}
                  label={customerUser.user.name}
                >
                  {customerUser.user.name} ({customerUser.position})
                </Option>
              ))}
          </Select>
        </div>
        <div className="Line3">
          <Select
            label="Parceiro *"
            name="partner_id"
            loading={!partners}
            onChange={handleSelectPartner}
            disabled={!!initialData.project_module_id}
          >
            {partners?.map(partner => (
              <Option key={partner.id} value={partner.id} label={partner.name}>
                {partner.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="Line4">
          <Select
            label="Consultor *"
            name="partner_user_id"
            disabled={!!initialData.project_module_id}
          >
            {partnerSelected?.partnerUsers?.map(partnerUser => (
              <Option
                key={partnerUser.id}
                value={partnerUser.id}
                label={partnerUser.user.name}
              >
                {partnerUser.user.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="Line5">
          <Input label="Data *" name="date" type="date" />
          <Input label="Horário *" name="time" type="time" />
        </div>
      </Main>
      <Footer>
        <Button
          type="button"
          background="transparent"
          color="grey"
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button type="submit">Salvar</Button>
      </Footer>
    </Container>
  );
};

export default FormInterview;
