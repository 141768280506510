import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 34px;
`;

export const HeaderCustomerOpinion = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > h1 {
      text-align: left;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8dabc4;
    }

    > h2 {
      text-align: left;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #3f536e;
      margin-left: 10px;
    }
  }
`;

export const MainCustomerOpinion = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  > div {
    background: #f6f8fa 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 14px 23px;
    margin-bottom: 10px;

    > h1 {
      text-align: left;
      font-size: 15px;
      letter-spacing: 0px;
      color: #2c405a;
      font-weight: 500;
      margin-bottom: 11px;
    }

    > p {
      text-align: left;
      font-size: 15px;
      letter-spacing: 0px;
      color: #3f536e;
    }
  }
`;

export const FooterCustomerOpinion = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > h1 {
      text-align: left;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8dabc4;
    }

    > h2 {
      text-align: left;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #3f536e;
      margin-left: 10px;
    }
  }

  > form {
    display: flex;

    > div.FormCheckbox {
      margin-left: 20px;
      margin-top: 38px;
    }

    > button {
      width: 200px;
      margin-left: 20px;
      height: 42px;
      margin-top: 32px;
      border-radius: 4px;
      background-color: rgb(78, 206, 61);
      padding-right: 20px;
      padding-left: 20px;
      color: white;
      font-size: 14px;
    }
  }
`;
