import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)``;

export const Main = styled.main`
  padding: 30px;
  border-top: 1px solid #c5dbea;
  border-bottom: 1px solid #c5dbea;
  > h1 {
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
    color: #8dabc4;
    font-weight: 400;
    margin-bottom: 10px;
  }
  > div.Select {
    margin-top: 16px;
  }
  > div.Input {
    margin-top: 16px;
  }
  > div.TextArea {
    margin-top: 16px;
  }
`;

export const Header = styled.header`
  background-color: #fafbfc;
  padding-top: 40px;
  padding-left: 30px;
  padding-bottom: 12px;
  border-radius: 8px 8px 0px 0px;
  h1 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #2c405a;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 30px;
  button.Button {
    width: 160px;

    & + button {
      margin-left: 16px;
    }
  }
`;
