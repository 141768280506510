import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > header {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h1 {
      color: var(--unnamed-color-3f536e);
      text-align: left;
      font-size: 12px;
      letter-spacing: 1.85px;
      opacity: 1;
      text-transform: uppercase;
    }

    button {
      border: 1px solid #0000000d;
      border-radius: 4px;
      opacity: 1;
      width: 146px;
    }
  }

  > table {
    background: #ffffff;
    border: 1px solid #b7d2e5cc;
    border-radius: 4px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 24px;

    thead tr:first-child th:first-child {
      border-top-left-radius: 4px;
    }

    thead tr:first-child th:last-child {
      border-top-right-radius: 4px;
    }

    tbody tr:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }

    tbody tr:last-child td:last-child {
      border-bottom-right-radius: 4px;
    }

    thead > tr > th {
      color: var(--unnamed-color-8dabc4);
      text-align: left;
      font-size: 12px;
      letter-spacing: 1.85px;
      opacity: 1;
      text-transform: uppercase;
      font-weight: 400;
      background-color: #fafbfc;
      padding: 10px;
    }

    tbody > tr > td {
      color: var(--unnamed-color-3f536e);
      text-align: left;
      font-size: 13px;
      letter-spacing: 0px;
      opacity: 1;
      padding: 12px;
      border-top: 1px solid #e5eef5;

      &.Icons {
        padding-left: 0px;
        padding-right: 16px;
        text-align: right;
        svg {
          color: #a8c6df;
          cursor: pointer;
          margin-left: 4px;

          &:hover {
            color: #0093ee;
          }
        }
      }
    }
  }
`;

export const ModalConfirmationContent = styled.div`
  padding: 20px;

  > h1 {
    font-size: 16px;
    margin-bottom: 32px;
    font-weight: 400;
  }

  > div.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`;
