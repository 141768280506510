import React from 'react';
import { BsArrowRight } from 'react-icons/bs';

import { Container } from './styles';

const ArrowRight: React.FC = () => {
  return (
    <Container>
      <BsArrowRight size={48} color="#3F536E" />
    </Container>
  );
};

export default ArrowRight;
