import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)``;

export const Main = styled.main`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  div.Select {
    width: 448px;
  }

  button.Button {
    width: 146px;
    margin-left: 32px;
  }
`;
