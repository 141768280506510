import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  > div.LoadingMask {
    border: 1px solid red;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 38px 38px 38px;

  > div.line2 {
    margin-top: 33px;
    display: flex;

  }

  > div.line3 {
    width: 50%;
    margin: 0 50%;
    display: flex;
    justify-content: flex-end;

    > div.Input {
      margin: 5px 0 0 10px;
    }
  }

  > div.FormSelect {
    margin-top: 33px;
  }

  > div.TextArea {
    margin-top: 33px;
  }

  > div.InputBoolean {
    margin-top: 33px;
  }

  > div.InputBoolean2 {
    margin-top: 33px;
  }

  > div.FormCreatableSelect {
    margin-top: 33px;
  }

  > div.Input {
    margin-top: 10px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #f6f8fa;
  padding: 20px 38px;

  > button.Button {
    width: 195px;
  }
`;
