import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/images/logo-gray.png';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AnimationContainer, Background } from './styles';
import { ICustomerUser } from '../CustomerUsers';

interface ISignInForData {
  email: string;
  password: string;
  ip: string;
}

export interface IGroup {
  id: string;
  name: string;
}

export interface IUser {
  id: string;
  name: string;
  email: string;
  password?: string;
  avatar_url?: string;
  groups: IGroup[];
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [UserIp, setUserIp] = useState<string>();

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ISignInForData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail é obrigatório'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
          ip: UserIp,
        });

        const user: IUser = JSON.parse(
          localStorage.getItem('@DNASec:user') || '',
        ) as IUser;

        const customerUserInStorage = localStorage.getItem(
          '@DNASec:customerUser',
        );

        let customerUser: ICustomerUser | undefined;

        if (customerUserInStorage) {
          customerUser = JSON.parse(customerUserInStorage) as ICustomerUser;
        }

        if (customerUser?.customerGroups.find(cg => cg.name === 'DPO')) {
          history.push('/home/data-mapping');
        } else if (customerUser?.customerGroups.find(cg => cg.name === 'DPL')) {
          history.push('/home/data-mapping');
        } else if (
          customerUser?.customerGroups.find(
            cg => cg.name === 'Responsable Directorate',
          )
        ) {
          history.push('/home/data-mapping');
        } else if (
          customerUser?.customerGroups.find(
            cg => cg.name === 'Responsable Department',
          )
        ) {
          history.push('/home/data-mapping');
        } else if (
          customerUser?.customerGroups.find(
            cg => cg.name === 'Responsable Process',
          )
        ) {
          history.push('/home/data-mapping');
        } else if (user.groups.find(g => g.name === 'Partner')) {
          history.push('/home/customers');
        } else if (user.groups.find(g => g.name === 'Administrator')) {
          history.push('/home/customers');
        } else {
          history.push('/home');
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao fazer login, verifique as credenciais.',
        });
      } finally {
        setLoading(false);
      }
    },
    [signIn, history, addToast, UserIp],
  );

  useEffect(() => {
    fetch('https://api.ipify.org?format=json', {
      method: 'GET',
      headers: {},
    })
      .then(res => {
        return res.text();
      })
      .then(ip => {
        setUserIp(ip);
      });
  }, []);

  return (
    <>
      <Container>
        <Content>
          {UserIp && (
            <AnimationContainer>
              <img src={logoImg} alt="DNA Sec" />
              <Form ref={formRef} onSubmit={handleSubmit}>
                <h1>Bem-vindo ao DNASec!</h1>
                <Input name="email" type="email" placeholder="E-mail" />
                <Input name="password" type="password" placeholder="Senha" />
                <Button
                  type="submit"
                  loading={loading}
                  loadingMsg="Autenticando ..."
                >
                  Entrar
                </Button>
                {/* <p>Esqueceu a senha?</p> */}
                <div className="Buttons">
                  <button
                    className="ForgotPassword"
                    type="button"
                    onClick={() => {
                      history.push('/forgot_password');
                    }}
                  >
                    Esqueceu sua senha?
                  </button>
                  <button
                    className="FirstAccess"
                    type="button"
                    onClick={() => {
                      history.push('/first_access');
                    }}
                  >
                    Primeiro acesso?
                  </button>
                </div>
              </Form>
            </AnimationContainer>
          )}
        </Content>

        <Background />
      </Container>
    </>
  );
};

export default SignIn;
