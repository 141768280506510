/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useRef, useCallback, useEffect } from 'react';

import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { MdAdd, MdEdit, MdDelete } from 'react-icons/md';
import { v4 } from 'uuid';
import { cache } from 'swr';
import {
  Container,
  Main,
  Footer,
  HeaderPartnerUsers,
  PartnerUsers,
  ExpansionContent,
} from './styles';
import { cnpjMask, zipCodeMask } from '../../../components/Masks';
import { IPartner, IPartnerUser } from '../..';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Modal, { IModalHandles } from '../../../../components/Modal';
import FormPartnerUser from '../FormPartnerUser';
import Badge from '../../../../components/Badge';
import ExpansionPainel from '../../../../components/ExpansionPainel';
import DisplayField from '../../../../components/DisplayField';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';
import FormChangePassword from '../../../Users/FormChangePassword';
import findZipCode, { IZipcode } from '../../../../utils/viacep';

interface IFormProps {
  initialData?: IPartner;
  onSubmit(partner: IPartner): void;
}

const FormPartner: React.FC<IFormProps> = ({ initialData, onSubmit }) => {
  const form = useRef<FormHandles>(null);
  const history = useHistory();
  const modalPartnerUser = useRef<IModalHandles>();
  const modalPassword = useRef<IModalHandles>();
  const [partner, setPartner] = useState<IPartner>(
    initialData || {
      id: v4(),
      address: '',
      city: '',
      complement: '',
      created_at: new Date(),
      district: '',
      name: '',
      number: undefined,
      register_number: '',
      state: '',
      technical_hourly_value: undefined,
      zipcode: '',
      partnerUsers: [],
    },
  );
  const [newPartnerUserModalState, setNewPartnerUserModalState] =
    useState(true);
  const [partnerUserSelected, setPartnerUserSelected] = useState<IPartnerUser>({
    id: v4(),
    telephone: '',
    resume: '',
    specialty: '',
    user: {
      id: v4(),
      name: '',
      email: '',
      password: '',
      avatar_url: '',
      groups: [],
    },
    can_receive_opinion_busines: false,
    can_receive_opinion_juridical: false,
    can_receive_opinion_compliance: false,
    can_receive_opinion_security: false,
  });
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async ({
      name,
      register_number,
      address,
      number,
      complement,
      district,
      zipcode,
      city,
      state,
      technical_hourly_value,
    }: IPartner) => {
      try {
        form.current?.setErrors({});
        const partners: IPartner[] = cache.get('/partners');
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da empresa é obrigatório'),
          register_number: Yup.string()
            .required('CNPJ Obrigatório')
            .test('checkDuplicate', 'CNPJ já existe', () => {
              return new Promise((resolve, reject) => {
                const exists = partners.find(
                  p =>
                    p.register_number === register_number &&
                    p.id !== initialData?.id,
                );
                resolve(!exists);
              });
            }),
        });
        await schema.validate(
          { name, register_number },
          {
            abortEarly: false,
          },
        );

        const newPartner: IPartner = {
          id: partner?.id || undefined,
          name,
          register_number,
          address,
          number: Number(number),
          complement,
          district,
          zipcode,
          city,
          state,
          technical_hourly_value: Number(technical_hourly_value),
          partnerUsers: partner?.partnerUsers || [],
          created_at: partner?.created_at || new Date(),
        };

        onSubmit(newPartner);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [initialData, onSubmit, partner],
  );

  const [zipCode, setZipCode] = useState('');

  const checkZipCode = async (e: string) => {
    if (!e) return;
    const zipCodes = e.replace(/\D/g, '');
    if (zipCodes.length === 8) {
      setZipCode(zipCodes);
    }
  };

  const [valueCity, setValueCity] = useState('');
  const [valueBairro, setValueBairro] = useState('');
  const [valueUF, setValueUF] = useState('');
  const [valueLogradouro, setValueLogradouro] = useState('');

  useEffect(() => {
    async function getCep() {
      if (zipCode) {
        try {
          const data: IZipcode | any = await findZipCode(zipCode);
          setValueCity(data.localidade);
          setValueBairro(data.bairro);
          setValueUF(data.uf);
          setValueLogradouro(data.logradouro);
        } catch (error) {
          alert('Ocorreu um erro ao buscar o endereço');
        }
      }
    }
    getCep();
  }, [zipCode]);

  const handleNewPartnerUserModal = useCallback(() => {
    setNewPartnerUserModalState(true);
    setPartnerUserSelected({
      id: v4(),
      telephone: '',
      resume: '',
      specialty: '',
      user: {
        id: v4(),
        name: '',
        email: '',
        password: '',
        avatar_url: '',
        groups: [],
      },
      can_receive_opinion_busines: false,
      can_receive_opinion_juridical: false,
      can_receive_opinion_compliance: false,
      can_receive_opinion_security: false,
    });
    modalPartnerUser.current?.open();
  }, []);

  const handleEditPartnerUserModal = useCallback(
    (id: string | undefined) => {
      setNewPartnerUserModalState(false);
      const partnerUserLoaded = partner?.partnerUsers.find(
        c => c.id?.toString() === id?.toString(),
      );
      if (partnerUserLoaded) {
        setPartnerUserSelected(partnerUserLoaded);
      }
      modalPartnerUser.current?.open();
    },
    [partner],
  );

  const handleChangePasswordPartnerUserModal = useCallback(
    (id: string | undefined) => {
      setNewPartnerUserModalState(false);
      const partnerUserLoaded = partner?.partnerUsers.find(
        c => c.id?.toString() === id?.toString(),
      );
      if (partnerUserLoaded) {
        setPartnerUserSelected(partnerUserLoaded);
      }
      modalPassword.current?.open();
    },
    [partner],
  );

  const handleRemovePartnerUser = useCallback(
    (id: string | undefined) => {
      if (partner) {
        setPartner({
          ...partner,
          partnerUsers: partner.partnerUsers.filter(p => p.id !== id),
        });
      }
    },
    [partner],
  );

  const handleSubmitPartnerUser = useCallback(
    partnerUserReturned => {
      if (newPartnerUserModalState) {
        setPartner({
          ...partner,
          partnerUsers: [...partner.partnerUsers, partnerUserReturned],
        });
      } else {
        setPartner({
          ...partner,
          partnerUsers: partner.partnerUsers.map(c => {
            if (c.id.toString() === partnerUserSelected.id.toString()) {
              return partnerUserReturned;
            }
            return c;
          }),
        });
      }
      modalPartnerUser.current?.close();
    },
    [partnerUserSelected.id, newPartnerUserModalState, partner],
  );

  const handleChangePassword = useCallback(
    async ({ id, password }) => {
      api
        .patch(`/users/${id}`, {
          password,
        })
        .catch(error => {
          console.log('ERROR', error.response.data.message);
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Ocorreu um erro ao alterar senha.',
          });
        });
      modalPassword.current?.close();
    },
    [addToast],
  );

  return (
    <>
      <Container ref={form} onSubmit={handleSubmit} initialData={partner}>
        <Main>
          <div className="Line1">
            <Input label="Nome da empresa *" name="name" type="text" />
            <Input
              label="CNPJ *"
              name="register_number"
              type="text"
              onChange={e => cnpjMask(e)}
            />
          </div>
          <div className="Line2">
            <Input
              label="CEP"
              name="zipcode"
              type="text"
              onBlur={e => checkZipCode(e.target.value)}
              onChange={e => zipCodeMask(e)}
            />
            <Input
              label="Logradouro"
              name="address"
              type="text"
              defaultValue={valueLogradouro}
            />
            <Input label="Número" name="number" type="number" />
            <Input label="Complemento" name="complement" type="text" />
          </div>
          <div className="Line3">
            <Input
              label="Bairro"
              name="district"
              type="text"
              defaultValue={valueBairro}
            />
            <Input
              label="Cidade"
              name="city"
              type="text"
              defaultValue={valueCity}
            />
            <Input label="UF" name="state" type="text" defaultValue={valueUF} />
          </div>
          <div className="Line4">
            <Input
              label="Valor Hora Técnica"
              name="technical_hourly_value"
              type="number"
              min="0.00"
              step="0.01"
              innerLabel="R$"
            />
          </div>

          <HeaderPartnerUsers>
            <h1>USUARIOS DO PARCEIRO</h1>
            <Button
              icon={MdAdd}
              background="#0079C4"
              onClick={() => handleNewPartnerUserModal()}
            >
              Novo
            </Button>
          </HeaderPartnerUsers>

          <PartnerUsers>
            {partner?.partnerUsers.map(partnerUser => (
              <ExpansionPainel
                key={partnerUser.id}
                title={partnerUser.user?.name}
                barComponent={() => (
                  <>
                    <MdEdit
                      size={22}
                      onClick={() => handleEditPartnerUserModal(partnerUser.id)}
                    />
                    <MdDelete
                      size={22}
                      onClick={() => handleRemovePartnerUser(partnerUser.id)}
                    />
                  </>
                )}
              >
                <ExpansionContent>
                  <div className="Line11">
                    <DisplayField label="Telefone">
                      {partnerUser.telephone}
                    </DisplayField>
                    <DisplayField label="E-mail">
                      {partnerUser.user.email}
                    </DisplayField>
                    <DisplayField label="Senha">
                      *********
                      <button
                        type="button"
                        onClick={() =>
                          handleChangePasswordPartnerUserModal(partnerUser.id)
                        }
                      >
                        Alterar Senha
                      </button>
                    </DisplayField>
                  </div>
                  <div className="Line22">
                    <DisplayField label="Mini curriculo">
                      {partnerUser.resume}
                    </DisplayField>
                  </div>
                  <div className="Line33">
                    {partnerUser.specialty &&
                      partnerUser.specialty !== '' &&
                      partnerUser.specialty
                        .split(',')
                        .map(c => <Badge key={v4()} value={c} />)}
                  </div>
                </ExpansionContent>
              </ExpansionPainel>
            ))}
          </PartnerUsers>
        </Main>
        <Footer>
          <Button
            type="button"
            background="transparent"
            color="grey"
            onClick={() => history.push('/home/partners')}
          >
            Cancelar
          </Button>
          <Button type="submit" loading={false} loadingMsg="Salvando ...">
            Salvar
          </Button>
        </Footer>
      </Container>
      <Modal ref={modalPartnerUser} size="md">
        <FormPartnerUser
          initialData={partnerUserSelected}
          onSubmit={handleSubmitPartnerUser}
          onCancel={() => modalPartnerUser.current?.close()}
        />
      </Modal>
      <Modal ref={modalPassword} size="sm">
        <FormChangePassword
          initialData={partnerUserSelected.user}
          onSubmit={handleChangePassword}
          onCancel={() => modalPassword.current?.close()}
        />
      </Modal>
    </>
  );
};

export default FormPartner;
