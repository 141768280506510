import styled from 'styled-components';

export const Container = styled.div`
  > h1 {
    text-align: left;
    font: normal normal normal 15px/22px Heebo;
    letter-spacing: 0px;
    color: #2c405a;
  }

  > div {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 18px 22px;
    margin-top: 13px;
    > h2 {
      text-align: left;
      font: normal normal normal 15px/22px Heebo;
      letter-spacing: 0px;
      color: #3f536e;
    }
  }
`;
