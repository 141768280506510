import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useCallback, useState } from 'react';
import * as Yup from 'yup';
import { mutate as mutateGlobal } from 'swr';
import FormCheckbox from '../../../../components/FormCheckbox';
import Input from '../../../../components/Input';
import getValidationErrors from '../../../../utils/getValidationErrors';
import { IOpinion } from '../../../Opinions';
import { getDateWithoutTimezone } from '../../../../utils/date';

import {
  Container,
  HeaderCustomerOpinion,
  MainCustomerOpinion,
  FooterCustomerOpinion,
} from './styles';
import Button from '../../../../components/Button';
import { IResponseCustomerOpinion } from '../..';
import api from '../../../../services/api';
import { useToast } from '../../../../hooks/toast';

interface IOpinionProps {
  customerOpinion: IOpinion;
}

const lookupOpinionsType = {
  busines: 'Negócios',
  compliance: 'Compliance',
  juridical: 'Jurídica',
  security: 'Tecnologia',
};

interface IFormStatus {
  deadline: string;
  done: boolean;
}

const CustomerOpinion: React.FC<IOpinionProps> = ({ customerOpinion }) => {
  const form = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async ({ deadline, done }: IFormStatus) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          deadline: Yup.date().required('Prazo é obrigatório'),
        });

        await schema.validate(
          { deadline },
          {
            abortEarly: false,
          },
        );

        setLoading(true);
        await api
          .patch(`/customerOpinions/${customerOpinion.id}`, {
            deadline,
            done,
          })
          .then(() => {
            mutateGlobal(
              '/customerOpinions',
              async (oldCustomerOpinions: IResponseCustomerOpinion) => {
                return {
                  ...oldCustomerOpinions,
                  data: oldCustomerOpinions.data.map(co => {
                    if (co.id === customerOpinion.id) {
                      return {
                        ...co,
                        deadline: getDateWithoutTimezone(deadline),
                        done,
                      };
                    }
                    return co;
                  }),
                };
              },
              false,
            );
            addToast({
              title: 'Atualizado',
              type: 'success',
              description: 'Plano de ação atualizado com sucesso',
            });
          })
          .catch(() => {
            addToast({
              title: 'Erro',
              type: 'error',
              description: 'Falha ao tentar atualizar plano de acão',
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [addToast, customerOpinion.id],
  );

  return (
    <Container>
      <HeaderCustomerOpinion>
        <div>
          <h1>Processo</h1>
          <h2>
            {`${customerOpinion.customer_process?.cod} - ${customerOpinion.customer_process?.name}`}
          </h2>
        </div>
        <div>
          <h1>Departamento</h1>
          <h2>{customerOpinion.customer_department?.name}</h2>
        </div>
        <div>
          <h1>Diretoria</h1>
          <h2>{customerOpinion.customer_directorate?.name}</h2>
        </div>
      </HeaderCustomerOpinion>
      <MainCustomerOpinion>
        <div>
          <h1>Risco Identificado</h1>
          <p>{customerOpinion.risk_identified}</p>
        </div>
        <div>
          <h1>Plano de ação</h1>
          <p>{customerOpinion.plan_action}</p>
        </div>
      </MainCustomerOpinion>
      <FooterCustomerOpinion>
        <div>
          <h1>Tipo</h1>
          <h2>
            {customerOpinion?.type && lookupOpinionsType[customerOpinion.type]}
          </h2>
        </div>

        <Form
          ref={form}
          onSubmit={handleSubmit}
          initialData={{
            done: customerOpinion.done,
            deadline: customerOpinion.deadline,
          }}
        >
          <Input type="date" name="deadline" label="Prazo" />
          <FormCheckbox name="done" label="Finalizado" />
          <Button type="submit" loading={loading} loadingMsg="Salvando ...">
            Salvar
          </Button>
        </Form>
      </FooterCustomerOpinion>
    </Container>
  );
};

export default CustomerOpinion;
