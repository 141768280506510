import React, {
  useState,
  useRef,
  useCallback,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import { cache } from 'swr';
import {
  Container,
  Main,
  Modules,
  Footer,
  Quiz,
  Form,
  ContainerModal,
  Header,
  AlertModal,
  FooterModal,
} from './styles';
import {
  ITemplate,
  ITemplateModule,
  ITemplateCategory,
  ITemplateQuestion,
} from '../..';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Modal, { IModalHandles } from '../../../../components/Modal';
import { useToast } from '../../../../hooks/toast';
import FormTemplateModule from '../FormTemplateModule';
import FormTemplateCategory from '../FormTemplateCategory';
import Select from '../../../../components/Select';
import Option from '../../../../components/Select/Option';
import FormTemplateQuiz from '../FormTemplateQuiz';
import FormTemplateQuestion from '../FormTemplateQuestion';
import TemplateModuleCard from './TemplateModuleCard';

interface IFormProps {
  initialData?: ITemplate;
  onSubmit(template: ITemplate): void;
  newVersion?: boolean;
}

interface ITemplateContextData {
  quizFormModuleId: string;
  quizFormCategoryId: string;
  setQuizFormModuleId: Dispatch<SetStateAction<string>>;
  setQuizFormCategoryId: Dispatch<SetStateAction<string>>;
  handleEditModule(templateModule: ITemplateModule): void;
  handleRemoveModule(idModule: string): void;
  handleEditCategory(
    templateModule: ITemplateModule,
    templateCategory: ITemplateCategory,
  ): void;
  handleRemoveCategory(idModule: string, idCategory: string): void;
  handleEditQuestion(
    templateModule: ITemplateModule,
    templateCategory: ITemplateCategory,
    templateQuestion: ITemplateQuestion,
  ): void;
  handleRemoveQuestion(
    idModule: string,
    idCategory: string,
    idQuestion: string,
  ): void;
}

export const TemplateContext = createContext<ITemplateContextData>(
  {} as ITemplateContextData,
);

const FormTemplate: React.FC<IFormProps> = ({
  initialData,
  onSubmit,
  newVersion,
}) => {
  const form = useRef<FormHandles>(null);
  const history = useHistory();
  const modalTemplateModule = useRef<IModalHandles>();
  const modalTemplateCategory = useRef<IModalHandles>();
  const modalTemplateQuestion = useRef<IModalHandles>();
  const modalRef = useRef<IModalHandles | null>(null);
  const [quizFormModuleId, setQuizFormModuleId] = useState<string>('');
  const [quizFormCategoryId, setQuizFormCategoryId] = useState<string>('');
  const [template, setTemplate] = useState<ITemplate>(
    initialData || {
      id: v4(),
      name: '',
      type: '',
      created_at: new Date(),
      version: '',
      consolidated: false,
      modules: [],
    },
  );

  const [newTemplateModuleModalState, setNewTempateModuleModalState] =
    useState(true);
  const [templateModuleSelected, setTemplateModuleSelected] =
    useState<ITemplateModule>({
      id: v4(),
      name: '',
      categories: [],
    });

  const [newTemplateCategoryModalState, setNewTempateCategoryModalState] =
    useState(true);
  const [templateCategorySelected, setTemplateCategorySelected] =
    useState<ITemplateCategory>({
      id: v4(),
      name: '',
      questions: [],
    });

  const [newTemplateQuestionModalState, setNewTempateQuestionModalState] =
    useState(true);
  const [templateQuestionSelected, setTemplateQuestionSelected] =
    useState<ITemplateQuestion>({
      id: v4(),
      description: '',
    });

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (consolidated?: boolean) => {
      const data = form.current?.getData() as ITemplate;
      const { name, type, version } = data;
      const templates: ITemplate[] = cache.get('/templates');
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Nome é obrigatório')
            .test(
              'checkDuplicate',
              'Já existe um versão para esse nome de template',
              () => {
                if (newVersion) return true;
                return new Promise(resolve => {
                  const exists = templates.find(
                    t =>
                      t.name === name &&
                      t.version === version &&
                      t.id !== initialData?.id,
                  );
                  resolve(!exists);
                });
              },
            ),
          type: Yup.string().required('Tipo é obrigatório'),
          version: Yup.string()
            .required('Versão é obrigatório')
            .test(
              'checkDuplicate',
              'Já existe um versão para esse nome de template',
              () => {
                return new Promise(resolve => {
                  const exists = templates.find(
                    t =>
                      t.name === name &&
                      t.version === version &&
                      t.id !== initialData?.id,
                  );
                  resolve(!exists);
                });
              },
            ),
        });
        await schema.validate(
          { type, name, version },
          {
            abortEarly: false,
          },
        );

        const newTemplate: ITemplate = {
          id: template?.id || undefined,
          name,
          type,
          version,
          created_at: template?.created_at || new Date(),
          consolidated: consolidated || false,
          modules: template?.modules || [],
        };

        Object.assign(newTemplate, {
          ...newTemplate,
          modules: newTemplate.modules.map((m, i) => ({
            ...m,
            position: i,
            categories: m.categories.map((c, y) => ({
              ...c,
              position: y,
              questions: c.questions.map((q, z) => ({
                ...q,
                position: z,
              })),
            })),
          })),
        });

        onSubmit(newTemplate);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [initialData, newVersion, onSubmit, template],
  );
  const handleConsolidateConfirmation = () => {
    if (modalRef.current) {
      modalRef.current.open();
    }
  };

  const handleConfirm = () => {
    handleSubmit(true);
    // Lógica para consolidar o formulário aqui
    if (modalRef.current) {
      modalRef.current.close();
    }
  };

  const handleCancel = () => {
    if (modalRef.current) {
      modalRef.current.close();
    }
  };

  const handleSubmitTemplateModule = useCallback(
    ({ name }) => {
      if (newTemplateModuleModalState) {
        const id = v4();
        setTemplate({
          ...template,
          modules: [
            ...template.modules,
            {
              id,
              name,
              categories: [],
            },
          ],
        });
        setQuizFormModuleId(id);
      } else {
        setTemplate({
          ...template,
          modules: template.modules.map(m => {
            if (m.id.toString() === templateModuleSelected.id.toString()) {
              return {
                ...m,
                name,
              };
            }
            return m;
          }),
        });
      }
      modalTemplateModule.current?.close();
    },
    [newTemplateModuleModalState, template, templateModuleSelected.id],
  );

  const handleSubmitTemplateCategory = useCallback(
    ({ name, newModuleId }) => {
      if (newTemplateCategoryModalState) {
        const id = v4();
        setTemplate({
          ...template,
          modules: template.modules.map(m => {
            if (m.id.toString() === newModuleId.toString()) {
              return {
                ...m,
                categories: [
                  ...m.categories,
                  {
                    id,
                    name,
                    questions: [],
                  },
                ],
              };
            }
            return m;
          }),
        });
        setQuizFormCategoryId(id);
      } else {
        setTemplate({
          ...template,
          modules: template.modules.map(m => {
            if (m.id.toString() === newModuleId.toString()) {
              return {
                ...m,
                categories: m.categories.map(c => {
                  if (
                    c.id.toString() === templateCategorySelected.id.toString()
                  ) {
                    return {
                      ...c,
                      name,
                    };
                  }
                  return c;
                }),
              };
            }
            return m;
          }),
        });
      }
      modalTemplateCategory.current?.close();
    },
    [newTemplateCategoryModalState, template, templateCategorySelected.id],
  );

  const handleSubmitTemplateQuestion = useCallback(
    ({ description, newModuleId, newCategoryId }) => {
      if (newTemplateQuestionModalState) {
        setTemplate({
          ...template,
          modules: template.modules.map(m => {
            if (m.id.toString() === newModuleId.toString()) {
              return {
                ...m,
                categories: m.categories.map(c => {
                  if (c.id.toString() === newCategoryId.toString()) {
                    return {
                      ...c,
                      questions: [
                        ...c.questions,
                        {
                          id: v4(),
                          description,
                          template_category_id: c.id,
                          template_id: template.id,
                          template_module_id: m.id,
                        },
                      ],
                    };
                  }
                  return c;
                }),
              };
            }
            return m;
          }),
        });
      } else {
        setTemplate({
          ...template,
          modules: template.modules.map(m => {
            if (m.id.toString() === newModuleId.toString()) {
              return {
                ...m,
                categories: m.categories.map(c => {
                  if (c.id.toString() === newCategoryId.toString()) {
                    return {
                      ...c,
                      questions: c.questions.map(q => {
                        if (
                          q.id.toString() ===
                          templateQuestionSelected.id.toString()
                        ) {
                          return {
                            ...q,
                            description,
                          };
                        }
                        return q;
                      }),
                    };
                  }
                  return c;
                }),
              };
            }
            return m;
          }),
        });
      }
      modalTemplateQuestion.current?.close();
    },
    [newTemplateQuestionModalState, template, templateQuestionSelected.id],
  );

  const handleSubmitQuiz = useCallback(
    ({ moduleId, categoryId, description }) => {
      setTemplate({
        ...template,
        modules: template.modules.map(m => {
          if (m.id.toString() === moduleId.toString()) {
            return {
              ...m,
              categories: m.categories.map(c => {
                if (c.id.toString() === categoryId.toString()) {
                  return {
                    ...c,
                    questions: [
                      ...c.questions,
                      {
                        id: v4(),
                        description,
                        template_category_id: c.id,
                        template_id: template.id,
                        template_module_id: m.id,
                      },
                    ],
                  };
                }
                return c;
              }),
            };
          }
          return m;
        }),
      });
      addToast({
        title: 'Nova pergunta adicionada.',
        type: 'success',
      });
    },
    [addToast, template],
  );

  const handleEditModule = useCallback((module: ITemplateModule) => {
    setNewTempateModuleModalState(false);
    setTemplateModuleSelected(module);
    modalTemplateModule.current?.open();
  }, []);

  const handleRemoveModule = useCallback(
    (moduleId: string) => {
      setTemplate({
        ...template,
        modules: template.modules.filter(
          m => m.id.toString() !== moduleId.toString(),
        ),
      });
    },
    [template],
  );

  const handleEditCategory = useCallback(
    (module: ITemplateModule, category: ITemplateCategory) => {
      setNewTempateCategoryModalState(false);
      setTemplateModuleSelected(module);
      setTemplateCategorySelected(category);
      modalTemplateCategory.current?.open();
    },
    [],
  );

  const handleRemoveCategory = useCallback(
    (moduleId: string, categoryId: string) => {
      setTemplate({
        ...template,
        modules: template.modules.map(m => {
          if (m.id.toString() === moduleId) {
            return {
              ...m,
              categories: m.categories.filter(
                c => c.id.toString() !== categoryId,
              ),
            };
          }
          return m;
        }),
      });
    },
    [template],
  );

  const handleEditQuestion = useCallback(
    (
      module: ITemplateModule,
      category: ITemplateCategory,
      question: ITemplateQuestion,
    ) => {
      setNewTempateQuestionModalState(false);
      setTemplateModuleSelected(module);
      setTemplateCategorySelected(category);
      setTemplateQuestionSelected(question);
      modalTemplateQuestion.current?.open();
    },
    [],
  );

  const handleRemoveQuestion = useCallback(
    (moduleId: string, categoryId: string, questionId: string) => {
      setTemplate({
        ...template,
        modules: template.modules.map(m => {
          if (m.id.toString() === moduleId) {
            return {
              ...m,
              categories: m.categories.map(c => {
                if (c.id.toString() === categoryId) {
                  return {
                    ...c,
                    questions: c.questions.filter(
                      q => q.id.toString() !== questionId.toString(),
                    ),
                  };
                }
                return c;
              }),
            };
          }
          return m;
        }),
      });
    },
    [template],
  );

  return (
    <TemplateContext.Provider
      value={{
        quizFormModuleId,
        quizFormCategoryId,
        setQuizFormModuleId,
        setQuizFormCategoryId,
        handleEditModule,
        handleRemoveModule,
        handleEditCategory,
        handleRemoveCategory,
        handleEditQuestion,
        handleRemoveQuestion,
      }}
    >
      <Container>
        <Main>
          <Form
            ref={form}
            onSubmit={() => {
              // console.log('SUBMIT');
            }}
            initialData={template}
          >
            <div className="Line1">
              <Select name="type" label="Tipo de projeto *">
                <Option label="Assessment" value="Assessment">
                  Assessment
                </Option>
              </Select>
              <Input
                label="Nome do projeto *"
                name="name"
                type="text"
                disabled={newVersion}
              />
              <Input
                label="Versão *"
                name="version"
                type="number"
                pattern="[0-9]*\.?[0-9]+"
              />
            </div>
          </Form>

          <Quiz>
            <h1>QUESTIONÁRIO</h1>
            <div className="Quiz">
              <FormTemplateQuiz
                modules={template.modules}
                onSubmit={handleSubmitQuiz}
                onClickTemplateModule={() => {
                  setNewTempateModuleModalState(true);
                  modalTemplateModule.current?.open();
                }}
                onClickTemplateCategory={() => {
                  setNewTempateCategoryModalState(true);
                  modalTemplateCategory.current?.open();
                }}
                onChangeModule={idModule => setQuizFormModuleId(idModule)}
              />
            </div>
          </Quiz>

          <Modules>
            {template?.modules.map((templateModule, templateModuleIndex) => (
              <TemplateModuleCard
                key={templateModule.id}
                templateModule={templateModule}
                templateModuleIndex={templateModuleIndex}
              />
            ))}
          </Modules>
        </Main>
      </Container>
      <Footer>
        <Button
          type="button"
          background="transparent"
          color="grey"
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button>
        <Button
          type="button"
          title="Salvar e continuar editando"
          onClick={() => handleSubmit()}
          background="#0079C4"
        >
          Salvar
        </Button>
        <Button type="button" title="" onClick={handleConsolidateConfirmation}>
          Consolidar
        </Button>
        <Modal ref={modalRef} size="smm">
          <ContainerModal>
            <Header>
              <h1>Tem certeza?</h1>
            </Header>
            <AlertModal>Após consolidar não é mais possível editar!</AlertModal>
            <FooterModal>
              <Button onClick={handleConfirm}>Confirmar</Button>
              <Button background="#a0a0a0" onClick={handleCancel}>
                Cancelar
              </Button>
            </FooterModal>
          </ContainerModal>
        </Modal>
      </Footer>
      <Modal ref={modalTemplateModule} size="md">
        <FormTemplateModule
          onSubmit={handleSubmitTemplateModule}
          onCancel={() => modalTemplateModule.current?.close()}
          initialData={
            newTemplateModuleModalState ? undefined : templateModuleSelected
          }
          edit={!newTemplateModuleModalState}
        />
      </Modal>
      <Modal ref={modalTemplateCategory} size="md">
        <FormTemplateCategory
          onSubmit={handleSubmitTemplateCategory}
          onCancel={() => modalTemplateCategory.current?.close()}
          modules={template.modules}
          initialData={
            newTemplateCategoryModalState
              ? { name: '', newModuleId: quizFormModuleId || '' }
              : {
                  ...templateCategorySelected,
                  newModuleId: templateModuleSelected.id,
                }
          }
          edit={!newTemplateCategoryModalState}
        />
      </Modal>
      <Modal ref={modalTemplateQuestion} size="md">
        <FormTemplateQuestion
          onSubmit={handleSubmitTemplateQuestion}
          onCancel={() => modalTemplateQuestion.current?.close()}
          modules={template.modules}
          initialData={{
            ...templateQuestionSelected,
            newModuleId: templateModuleSelected.id,
            newCategoryId: templateCategorySelected.id,
          }}
          edit={!newTemplateQuestionModalState}
        />
      </Modal>
    </TemplateContext.Provider>
  );
};

export default FormTemplate;
