import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > h1 {
    text-align: left;
    font: normal normal normal 15px/22px Heebo;
    letter-spacing: 0px;
    color: #3f536e;
    width: 150px;
  }

  > div {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ecf5fd;
    border-radius: 4px;
    width: 150px;
    height: 42px;
    padding: 9px 14px;
    margin-top: 10px;

    > h2 {
      text-align: left;
      font: normal normal normal 16px/24px Heebo;
      letter-spacing: 0px;
      color: #2c405a;
    }
  }
`;
