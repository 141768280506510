import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import Home from '../pages/Home';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import FirstAccess from '../pages/FirstAccess';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/forgot_password" exact component={ForgotPassword} />
      <Route path="/first_access" exact component={FirstAccess} />
      <Route path="/reset_password" exact component={ResetPassword} />
      <Route path="/home" component={Home} isPrivate />
    </Switch>
  );
};

export default Routes;
