import React from 'react';

import { Container } from './styles';

interface IDisplayFieldInlineProps {
  label: string;
  bold?: boolean;
}

const DisplayFieldInline: React.FC<IDisplayFieldInlineProps> = ({
  children,
  label,
  bold,
}) => {
  return (
    <Container className="DisplayFieldInline" bold={bold}>
      <h3>{label}</h3>
      <p>{children}</p>
    </Container>
  );
};

export default DisplayFieldInline;
