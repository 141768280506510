import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { MdAdd } from 'react-icons/md';
import { Container, Main } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import Option from '../../../../components/Select/Option';
import { ITemplate } from '../../../Templates';

interface IFormProps {
  initialData: { newProject: string };
  onSubmit(templateId: string): void;
  onCancel?(): void;
  templates: ITemplate[];
}

interface INewProjectFormData {
  templateId: string;
}

const FormNewProject: React.FC<IFormProps> = ({
  initialData,
  templates,
  onSubmit,
}) => {
  const form = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async ({ templateId }: INewProjectFormData) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          templateId: Yup.string().required('Projeto é obrigatório'),
        });

        await schema.validate(
          { templateId },
          {
            abortEarly: false,
          },
        );

        onSubmit(templateId);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [onSubmit],
  );

  return (
    <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
      <Main>
        <Select name="templateId" label="Incluir projetos">
          {templates.map(t => (
            <Option key={t.id} label={`${t.name} - ${t.version}`} value={t.id}>
              {t.name} - {t.version}
            </Option>
          ))}
        </Select>
        <Button type="submit" icon={MdAdd} background="#0079C4">
          Adicionar
        </Button>
      </Main>
    </Container>
  );
};

export default FormNewProject;
