import styled, { css } from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  innerLabel?: string;
  disabled?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0px 20px 0px 0px;
`;

export const Label = styled.h1`
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #8dabc4;
  margin-bottom: 10px;
  display: flex;

  > .Icon {
    margin-left: 5px;
  }
`;

export const InputContainer = styled.div<IContainerProps>`
  flex: 1;
  display: flex;

  > div.FormSelect {
    > h1 {
      text-align: left;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8dabc4;
      margin-bottom: 10px;
      display: flex;
    }
  }

  ${props =>
    props.isErrored &&
    css`
      > div.FormSelect {
        > div.basic-multi-select {
          > div {
            border-style: solid;
            border-radius: 4px;
            border-width: 1px;
            border-color: #c53030;
          }
        }
      }

      padding-right: 10px;
    `}

  ${props =>
    props.disabled &&
    css`
      display: none;
    `}
`;

export const Options = styled.div``;
