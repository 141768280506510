import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import arraySort from 'array-sort';
import { Container, Header, Main, Footer } from './styles';
import Button from '../../../../components/Button';
import { IOpinionStatus, IRiskType } from '../../../Opinions';
import Select3 from '../../../../components/Select3';
import Option3 from '../../../../components/Select3/Option';
import { ICustomerDirectorate } from '../../../DataMapping/components/Directorate';
import { IProcessOpinionType } from '../../../DataMapping/components/Process';
import { IOpinionsFilter } from '../..';
import FormCheckbox from '../../../../components/FormCheckbox';

interface IFormProps {
  initialData?: IOpinionsFilter | undefined;
  onSubmit(newOpinionsFilter: IOpinionsFilter): void;
  onClear(): void;
  customerDirectorates: ICustomerDirectorate[];
}

interface IOpinionsFilterFormData {
  risks: IRiskType[];
  directorates: string[];
  types: IProcessOpinionType[];
  status: IOpinionStatus[];
  done: boolean;
}

const FormOpinionsFilter: React.FC<IFormProps> = ({
  initialData,
  onSubmit,
  onClear,
  customerDirectorates,
}) => {
  const form = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async ({
      risks,
      directorates,
      types,
      status,
      done,
    }: IOpinionsFilterFormData) => {
      const newOpinionsFilter: IOpinionsFilter = {
        risks,
        directorates,
        types,
        status,
        done,
      };

      onSubmit(newOpinionsFilter);
    },
    [onSubmit],
  );

  return (
    <>
      <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
        <Header>
          <h1>FILTROS</h1>
        </Header>
        <Main>
          <div className="Line1">
            <Select3 name="risks" label="Risco" multiSelection>
              <Option3 key={1} value={1} label="Muito Baixo" />
              <Option3 key={2} value={2} label="Baixo" />
              <Option3 key={3} value={3} label="Médio" />
              <Option3 key={4} value={4} label="Alto" />
              <Option3 key={5} value={5} label="Muito Alto" />
            </Select3>
          </div>
          <div className="Line2">
            <Select3
              name="directorates"
              label="Diretoria"
              loading={!customerDirectorates}
              multiSelection
            >
              {customerDirectorates &&
                arraySort(customerDirectorates, 'name').map(
                  customerDirectorate => (
                    <Option3
                      key={customerDirectorate.id}
                      label={customerDirectorate.name}
                      value={customerDirectorate.id}
                    >
                      {customerDirectorate.name}
                    </Option3>
                  ),
                )}
            </Select3>
          </div>
          <div className="Line3">
            <Select3 label="Tipo" name="types" multiSelection>
              <Option3
                key="busines"
                value="busines"
                label="Parecer de Negócios"
              >
                Parecer de Negócios
              </Option3>
              <Option3
                key="compliance"
                value="compliance"
                label="Parecer de Compliance"
              >
                Parecer de Compliance
              </Option3>
              <Option3
                key="juridical"
                value="juridical"
                label="Parecer Jurídico"
              >
                Parecer Jurídico
              </Option3>
              <Option3
                key="security"
                value="security"
                label="Parecer de Cybersecurity"
              >
                Parecer de Cybersecurity
              </Option3>
            </Select3>
          </div>
          <div className="Line4">
            <Select3 label="Status" name="status" multiSelection>
              <Option3 key="Pendente" value="Pendente" label="Pendente">
                Pendente
              </Option3>
              <Option3 key="Respondido" value="Respondido" label="Respondido">
                Respondido
              </Option3>
              <Option3 key="Criado" value="Criado" label="Criado">
                Criado
              </Option3>
            </Select3>
          </div>
          <div className="Line5">
            <FormCheckbox name="done" label="Mostrar finalizados" />
          </div>
        </Main>
        <Footer>
          <Button background="transparent" color="#A8C6DF" onClick={onClear}>
            Limpar
          </Button>
          <Button type="submit" background="#0079C4">
            Aplicar
          </Button>
        </Footer>
      </Container>
    </>
  );
};

export default FormOpinionsFilter;
