import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Badges = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > svg {
    margin-right: 8px;
  }
`;
