/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useMemo } from 'react';

import { Container } from './styles';

export interface IOption {
  value: any;
  label: string;
}

export interface IOptionProps {
  value: any;
  label: string;
  selectOption?(option: IOption): void;
  selection?: IOption | IOption[];
  MultiSelection?: boolean;
}

const Option: React.FC<IOptionProps> = ({
  value,
  label,
  selectOption,
  selection,
  MultiSelection,
}) => {
  const handleClick = useCallback(
    event => {
      event.stopPropagation();
      if (selectOption) selectOption({ value, label });
    },
    [label, selectOption, value],
  );

  const isChecked = useMemo(() => {
    if (MultiSelection) {
      const filteredOptions = (selection as IOption[]).filter(
        sO => sO?.label !== undefined,
      );
      return !!filteredOptions?.find(sO => sO.label === label);
    }
    return (selection as IOption).label === label;
  }, [MultiSelection, label, selection]);

  return (
    <Container className="Option" htmlFor={label}>
      {MultiSelection ? (
        <>
          <input
            type="checkbox"
            id={label}
            checked={isChecked}
            onClick={handleClick}
            onChange={() => {}}
          />
          {label}
        </>
      ) : (
        <button type="button" onClick={handleClick}>
          {label}
        </button>
      )}
    </Container>
  );
};

export default Option;
