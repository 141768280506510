import React from 'react';

import { Container, ProgressBar } from './styles';

interface IDepartmentProgressBarProps {
  percent: number;
}

const DepartmentProgressBar: React.FC<IDepartmentProgressBarProps> = ({
  percent,
}) => {
  return (
    <Container>
      <div>
        <ProgressBar percent={percent ? Number(percent.toFixed(0)) : 0} />
      </div>
      <h1>{percent ? Number(percent.toFixed(0)) : 0}% concluído</h1>
    </Container>
  );
};

export default DepartmentProgressBar;
