import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/images/logo-gray.png';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AnimationContainer, Background } from './styles';
import api from '../../services/api';

import { validatePassword } from '../../utils/validatePassword';
import PasswordRequirements from '../../components/PasswordRequirements';

interface IResetPasswordForData {
  password: string;
  password_confirmation: string;
}

const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [tokenIsValid, setTokenIsValid] = useState(true);
  const query = useQuery();
  const token = query.get('token');

  const { addToast } = useToast();

  const [password, setPassword] = useState('');
  const defaultRequirements = {
    minLength: false,
    number: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    noSequential: false,
    noRepetition: false,
  };

  const [requirements, setRequirements] = useState(defaultRequirements);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    if (value === '') {
      setRequirements(defaultRequirements);
    } else {
      const { requirements } = validatePassword(value);
      setRequirements(requirements);
    }
  };

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        await api.get(`/password/validate-token?token=${token}`);
      } catch (err) {
        setTokenIsValid(false);
      }
    };

    checkTokenValidity();
  }, [token]);

  const handleSubmit = useCallback(
    async ({ password, password_confirmation }: IResetPasswordForData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string()
            .required('A senha é obrigatória')
            .test(
              'is-valid-password',
              'A senha não cumpre os requisitos',
              value => {
                if (!value) return false;
                const { isValid } = validatePassword(value);
                return isValid;
              },
            ),
          password_confirmation: Yup.string()
            .required('Confirmação de senha obrigatória')
            .oneOf(
              [Yup.ref('password'), null],
              'As senhas precisam ser iguais',
            ),
        });

        await schema.validate(
          { password, password_confirmation },
          {
            abortEarly: false,
          },
        );

        await api
          .post<void>(`/password/reset`, {
            token,
            password,
            password_confirmation,
          })
          .then(() => {
            addToast({
              type: 'success',
              title: 'Senha alterada',
              description: 'Sua senha foi alterada com sucesso.',
            });
            history.push('/');
          })
          .catch(() => {
            addToast({
              type: 'error',
              title: 'Erro na troca de senha',
              description:
                'Ocorreu um erro ao tentar trocar sua senha, tente novamente.',
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, history, token],
  );

  return (
    <>
      <Container>
        <Content>
          <AnimationContainer>
            <img src={logoImg} alt="DNA Sec" />
            {tokenIsValid ? (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <h1>Alterar senha</h1>
                <Input
                  name="password"
                  type="password"
                  placeholder="Senha"
                  value={password}
                  onChange={handlePasswordChange}
                />
                <PasswordRequirements requirements={requirements} />
                <Input
                  name="password_confirmation"
                  type="password"
                  placeholder="Confirmar Senha"
                />
                <Button
                  type="submit"
                  loading={loading}
                  loadingMsg="Processando ..."
                >
                  Enviar
                </Button>
              </Form>
            ) : (
              <>
                <h3>Link para recuperação de senha expirado!</h3>
                <p>Voltar ao Início</p>
                <button
                  className="ForgotPassword"
                  type="button"
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  Clique aqui
                </button>
              </>
            )}
          </AnimationContainer>
        </Content>
        <Background />
      </Container>
    </>
  );
};

export default ResetPassword;
