import { format } from 'date-fns';
import React from 'react';
import { IOpinion, riskTypeLookup } from '../../../../../Opinions';
import CardOpinionField from '../CardOpinionField';
import DisplayOpinionField from '../DisplayOpinionField';

import { Container, Header, Content, Wrapper } from './styles';

interface IOpinionItemProps {
  opinion: IOpinion;
}

const OpinionItem: React.FC<IOpinionItemProps> = ({ opinion }) => {
  return (
    <Container className="OpinionItem">
      <Header>
        <h1>
          Recebido em:{' '}
          {opinion.response_date &&
            format(new Date(opinion.response_date), 'dd/MM/yyyy: HH:mm')}
        </h1>
        <h1>
          Solicitado por: {opinion.owner_customer_user?.user.name},{' '}
          {opinion.created_at &&
            format(new Date(opinion.created_at), 'dd/MM/yyyy')}
        </h1>
      </Header>
      <Content>
        <Wrapper>
          <div>
            <DisplayOpinionField
              name="Impacto"
              value={opinion.impact && riskTypeLookup[opinion.impact]}
            />
            <DisplayOpinionField
              name="Probabilidade"
              value={opinion.probability && riskTypeLookup[opinion.probability]}
            />
            <DisplayOpinionField
              name="Risco"
              value={opinion.risk && riskTypeLookup[opinion.risk]}
            />
          </div>
          <DisplayOpinionField
            name="Resposta ao risco"
            value={opinion.risk_answer}
          />
        </Wrapper>
        <CardOpinionField name="Parecer" text={opinion.description} />
        <CardOpinionField name="Plano de ação" text={opinion.plan_action} />
      </Content>
    </Container>
  );
};

export default OpinionItem;
