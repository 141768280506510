import styled from 'styled-components';

export const Container = styled.aside`
  min-width: 60px;
  background-color: #2c405a;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
