import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface IButtonProps {
  background?: string;
  color?: string;
  outline?: boolean;
}

export const Container = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  background: ${props => props.background || '#4ECE3D'};
  color: ${props => props.color || '#FFFFFF'};
  height: 56px;
  border-radius: 4px;
  border: 1px solid ${props => props.background || '#4ECE3D'};
  width: 100%;
  font-weight: 500;
  transition: background-color 0.2s;
  font-size: 15px;
  height: 42px;

  &:hover {
    background: ${props =>
      props.background ? shade(0.2, props.background) : shade(0.2, '#4ECE3D')};
  }

  ${props =>
    props.outline &&
    css`
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    `}

  /* svg {
    margin-right: 8px;
  } */

  div.Label {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;
