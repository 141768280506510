import React from 'react';

import { Container } from './styles';

interface IDepartmentProcessInfoProps {
  total: number;
  opened: number;
  concluded: number;
}

const DepartmentProcessInfo: React.FC<IDepartmentProcessInfoProps> = ({
  total,
  opened,
  concluded,
}) => {
  return (
    <Container>
      <h1>
        <strong>{total}</strong> processos
      </h1>
      <h3>
        <strong>{opened}</strong> abertos | <strong>{concluded}</strong>{' '}
        concluídos
      </h3>
    </Container>
  );
};

export default DepartmentProcessInfo;
