import styled, { css } from 'styled-components';

interface IProgressBarProps {
  percent: number;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90%;

  > div {
    width: 59px;
    height: 5px;
    background-color: #ecf5fd;
    border-radius: 6px;
  }

  > h1 {
    margin-left: 14px;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0px;
    color: #0079c4;
    opacity: 1;
    width: 40px;
  }
`;

export const ProgressBar = styled.div<IProgressBarProps>`
  ${props =>
    css`
      width: ${props.percent}%;
    `}
  height: 5px;
  background: #0079c4 0% 0% no-repeat padding-box;
  border-radius: 6px;
`;
