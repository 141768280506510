import React, { useMemo } from 'react';

import { Container } from './styles';

interface ICustomerAssessmentTitleComponentProps {
  risk?: number;
  project_module?: string;
  project_category?: string;
  question: string;
}

const CustomerAssessmentTitleComponent: React.FC<ICustomerAssessmentTitleComponentProps> =
  ({ risk, project_module, project_category, question }) => {
    let label;
    if (risk && risk <= 3) {
      label = 'Muito Alto';
    }
    if (risk && risk >= 4 && risk <= 7) {
      label = 'Alto';
    }
    if (risk && risk >= 8 && risk <= 14) {
      label = 'Médio';
    }
    if (risk && risk >= 15 && risk <= 20) {
      label = 'Baixo';
    }
    if (risk && risk >= 21 && risk <= 25) {
      label = 'Muito Baixo';
    }

    const [background, color] = useMemo((): [string, string] => {
      if (risk && risk <= 3) {
        return ['#ffafb2', '#cc0000']; // Extremo/Muito Alto
      }
      if (risk && risk >= 4 && risk <= 7) {
        return ['#fcd8bf', '#fd7b1f']; // Alto
      }
      if (risk && risk >= 8 && risk <= 14) {
        return ['#fef5d1', '#ffbc00']; // Médio
      }
      if (risk && risk >= 15 && risk <= 20) {
        return ['#cefcc8', '#40b630']; // Baixo
      }
      if (risk && risk >= 21) {
        return ['#82ff70', '#72b36a']; // Muito Baixo
      }
      return ['#FFFFFF', '#000000'];
    }, [risk]);

    return (
      <Container color={color} backgroundColor={background}>
        <div>
          <h1>{label}</h1>
        </div>
        <section>
          <h1>{project_module} /</h1>
          <h2>{project_category} /</h2>
          <h3>{question}</h3>
        </section>
      </Container>
    );
  };

export default CustomerAssessmentTitleComponent;
