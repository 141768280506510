import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const Content = styled.div`
  position: relative;
  top: 0;
  left: calc(100% - 400px);
  z-index: 101;
  width: 400px;
  min-height: 100vh;
  max-height: 100vh;
  /* transform: translate(-50%, -50%); */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #2c405a35;
  border: 1px solid #b7d2e5cc;
  opacity: 1;

  > button {
    position: absolute;
    top: 20px;
    left: 20px;
    border: 0;
    color: inherit;
    background-color: transparent;
    z-index: 6;
  }
`;
