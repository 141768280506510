import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > div.ExpansionPainel + div.ExpansionPainel {
    margin-top: 20px;
  }

  > h1 {
    color: var(--unnamed-color-3f536e);
    text-align: left;
    font-size: 13px;
    letter-spacing: 0px;
    font-weight: 400;
  }
`;

export const HeaderModule = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 2fr 3fr 3fr;
  padding: 18px 55px 5px 55px;
`;

export const Categories = styled.div`
  padding: 5px 55px 20px 55px;
`;

export const ChartContent = styled.div`
  padding: 28px 55px 20px 55px;
  > h1 {
    color: var(--unnamed-color-8dabc4);
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
    font-weight: 400;
  }

  > div.Content {
    border: 1px solid var(--unnamed-color-c5d9e8);
    border: 1px solid #c5d9e8;
    border-radius: 4px;
    margin-top: 15px;
    padding: 30px;
  }
`;

export const Conclusion = styled.div`
  padding: 28px 55px 40px 55px;
  > h1 {
    color: var(--unnamed-color-8dabc4);
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
    font-weight: 400;
  }
  > div.Content {
    background: var(--unnamed-color-fafbfc) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 14px 15px 14px 26px;
    margin-top: 15px;
  }
  > p {
    color: var(--unnamed-color-3f536e);
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
  }
  border-bottom: 1px solid #c5d9e8;
`;
