import React, { useState, useRef, useCallback, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { FaFilter } from 'react-icons/fa';
import { mutate as mutateGlobal } from 'swr';
import Button from '../../components/Button';
import Card from '../../components/Card';
import ExpansionPainel from '../../components/ExpansionPainel';
import InputOnly from '../../components/InputOnly';
import { ExpansionContent } from '../Customers/CustomerNew/styles';

import {
  Container,
  Main,
  TolerancyContent,
  CustomerOpinionsContent,
  WrapperFilter,
  WrapperHeader,
  WrapperCustomerOpinions,
  ModalConfirmationContent,
} from './styles';

import { useFetch } from '../../hooks/fetch';
import LoadingMask from '../../components/LoadingMask';
import {
  IOpinion,
  IOpinionStatus,
  IRiskType,
  riskTypeLookup,
} from '../Opinions';
import CustomerOpinionTitleComponent from './components/CustomerOpinionTitleComponent';
import CustomerOpinionBarComponent from './components/CustomerOpinionBarComponent';
import CustomerOpinion from './components/CustomerOpinion';
import Modal, { IModalHandles } from '../../components/Modal';
import SideModal, { ISideModalHandles } from '../../components/SideModal';
import FormOpinion from './forms/FormOpinion';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import FormOpinionsFilter from './forms/FormOpinionsFilter';
import { ICustomerDirectorate } from '../DataMapping/components/Directorate';
import { IProcessOpinionType } from '../DataMapping/components/Process';

interface ITolerancyGrade {
  MA_MB: number;
  MA_B: number;
  MA_M: number;
  MA_A: number;
  MA_MA: number;
  A_MB: number;
  A_B: number;
  A_M: number;
  A_A: number;
  A_MA: number;
  M_MB: number;
  M_B: number;
  M_M: number;
  M_A: number;
  M_MA: number;
  B_MB: number;
  B_B: number;
  B_M: number;
  B_A: number;
  B_MA: number;
  MB_MB: number;
  MB_B: number;
  MB_M: number;
  MB_A: number;
  MB_MA: number;
}

export interface IResponseCustomerOpinion {
  data: IOpinion[];
  tolerancy_grade: ITolerancyGrade;
}

export interface IOpinionsFilter {
  risks: IRiskType[];
  directorates: string[];
  types: IProcessOpinionType[];
  status: IOpinionStatus[];
  done: boolean;
}

const DPIA: React.FC = () => {
  const [inputFilter, setInputFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const modalOpinion = useRef<IModalHandles>();
  const modalConfirmation = useRef<IModalHandles>();
  const modalFilter = useRef<ISideModalHandles>();
  const [filter, setFilter] = useState<IOpinionsFilter>();
  const [customerOpinionsToDisplay, setCustomerOpinionsToDisplay] =
    useState<IOpinion[]>();
  const [selectedCustomerOpinion, setSelectedCustomerOpinion] =
    useState<IOpinion | null>(null);
  const { addToast } = useToast();

  const { data: customerDirectorates } = useFetch<ICustomerDirectorate[]>(
    '/customerDirectorates',
    { revalidateOnFocus: false },
  );

  const { data: customerOpinions } = useFetch<IResponseCustomerOpinion>(
    '/customerOpinions',
    { revalidateOnFocus: false },
  );

  const handleNewOpinion = useCallback(() => {
    setSelectedCustomerOpinion(null);
    modalOpinion.current?.open();
  }, []);

  const handleSubmitFormOpinion = useCallback(
    async (newOpinion: IOpinion, edit: boolean) => {
      try {
        if (edit) {
          await api.put(`/customerOpinions/${newOpinion.id}`, newOpinion);

          mutateGlobal(
            '/customerOpinions',
            async (responseCustomerOpinions: IResponseCustomerOpinion) => {
              return {
                ...responseCustomerOpinions,
                data: responseCustomerOpinions.data.map(co => {
                  if (co.id === newOpinion.id) {
                    return newOpinion;
                  }
                  return co;
                }),
              };
            },
            false,
          );
        } else {
          await api.post(`/customerOpinions`, newOpinion);

          const customer_directorate = customerDirectorates?.find(
            cd => cd.id === newOpinion.customer_directorate_id,
          );
          const customer_department =
            customer_directorate?.scoped_customer_departments.find(
              cd => cd.id === newOpinion.customer_department_id,
            );
          const customer_process =
            customer_department?.scoped_customer_processes.find(
              cp => cp.id === newOpinion.customer_process_id,
            );

          const opinion: IOpinion = {
            ...newOpinion,
            customer_directorate,
            customer_department,
            customer_process,
            status: 'Criado',
          };

          mutateGlobal(
            '/customerOpinions',
            async (responseCustomerOpinions: IResponseCustomerOpinion) => {
              return {
                ...responseCustomerOpinions,
                data: [opinion, ...responseCustomerOpinions.data],
              };
            },
            false,
          );
        }

        modalOpinion.current?.close();
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar salvar',
        });
      }
    },
    [addToast, customerDirectorates],
  );

  const handleEditOpinion = useCallback((customerOpinion: IOpinion) => {
    setSelectedCustomerOpinion(customerOpinion);
    modalOpinion.current?.open();
  }, []);

  const handleDeleteOpinion = useCallback(
    (customerOpinion: IOpinion) => {
      api
        .delete(`/customerOpinions/${customerOpinion.id}`)
        .then(() => {
          addToast({
            title: 'Removido',
            type: 'success',
            description: 'Plano de ação removido com sucesso',
          });
        })
        .catch(() => {
          addToast({
            title: 'Erro',
            type: 'error',
            description: 'Falha ao tentar remover',
          });
        });

      mutateGlobal(
        '/customerOpinions',
        async (responseCustomerOpinions: IResponseCustomerOpinion) => {
          return {
            ...responseCustomerOpinions,
            data: responseCustomerOpinions.data.filter(
              co => co.id !== customerOpinion.id,
            ),
          };
        },
        false,
      );
    },
    [addToast],
  );

  const handleOpenConfirmation = useCallback((customerOpinion: IOpinion) => {
    setSelectedCustomerOpinion(customerOpinion);
    modalConfirmation.current?.open();
  }, []);

  const handleSubmitFormOpinionsFilter = useCallback(newOpinionFilter => {
    setFilter(newOpinionFilter);
    modalFilter.current?.close();
  }, []);

  const handleClearFilter = useCallback(() => {
    if (customerDirectorates) {
      setFilter({
        risks: [1, 2, 3, 4, 5],
        directorates: customerDirectorates.map(cd => cd.id),
        types: ['busines', 'compliance', 'juridical', 'security'],
        status: ['Pendente', 'Respondido', 'Criado'],
        done: false,
      });
      modalFilter.current?.close();
    }
  }, [customerDirectorates]);

  useEffect(() => {
    if (customerDirectorates) {
      setFilter({
        risks: [1, 2, 3, 4, 5],
        directorates: customerDirectorates.map(cd => cd.id),
        types: ['busines', 'compliance', 'juridical', 'security'],
        status: ['Pendente', 'Respondido', 'Criado'],
        done: false,
      });
    }
  }, [customerDirectorates]);

  useEffect(() => {
    if (customerOpinions?.data) {
      if (!filter) {
        return;
      }
      setCustomerOpinionsToDisplay(
        customerOpinions.data
          .filter(co => {
            if (
              filter?.risks.some(r => r === co.risk) &&
              filter?.directorates.some(
                d => d === co.customer_directorate_id,
              ) &&
              filter?.types.some(t => t === co.type) &&
              filter?.status.some(s => s === co.status)
            ) {
              return true;
            }
            return false;
          })
          .filter(co => {
            if (!filter.done && co.done) {
              return false;
            }
            return true;
          })
          .filter(co => {
            if (inputFilter) {
              const normalizedInput = inputFilter
                .toLowerCase()
                .normalize('NFKD')
                .replace(/^\s+|\p{M}/gu, '');
              return (
                co.customer_process?.name
                  .toLowerCase()
                  .normalize('NFKD')
                  .replace(/^\s+|\p{M}/gu, '')
                  .includes(normalizedInput) ||
                co.customer_directorate?.name
                  .toLowerCase()
                  .normalize('NFKD')
                  .replace(/^\s+|\p{M}/gu, '')
                  .includes(normalizedInput) ||
                co.customer_department?.name
                  .toLowerCase()
                  .normalize('NFKD')
                  .replace(/^\s+|\p{M}/gu, '')
                  .includes(normalizedInput)
              );
            }
            return true;
          }),
      );
      setLoading(false);
    }
  }, [customerOpinions, filter, inputFilter]);

  const handleClear = (): void => {
    setInputFilter('');
  };

  if (!customerOpinions) {
    return <LoadingMask text="Carregando..." />;
  }

  return (
    <>
      {loading ? <LoadingMask text="Carregando..." /> : null}
      <Container>
        <h1>DPIA - Data Protection Impact Assessment</h1>
        <Main>
          <Card>
            <TolerancyContent>
              <h1>GRADE DE TOLERÂNCIA A RISCOS</h1>
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '224px' }}>Probabilidade / Impacto</th>
                    <th style={{ width: '119px', fontWeight: 400 }}>
                      Muito Baixo
                    </th>
                    <th style={{ width: '119px', fontWeight: 400 }}>Baixo</th>
                    <th style={{ width: '119px', fontWeight: 400 }}>Médio</th>
                    <th style={{ width: '119px', fontWeight: 400 }}>Alto</th>
                    <th style={{ width: '119px', fontWeight: 400 }}>
                      Muito Alto
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'left', paddingLeft: '25px' }}>
                      Muito Alto
                    </td>
                    <td className="Yellow">
                      {customerOpinions.tolerancy_grade?.MA_MB}
                    </td>
                    <td className="Orange">
                      {customerOpinions.tolerancy_grade?.MA_B}
                    </td>
                    <td className="Orange">
                      {customerOpinions.tolerancy_grade?.MA_M}
                    </td>
                    <td className="Red">
                      {customerOpinions.tolerancy_grade?.MA_A}
                    </td>
                    <td className="Red">
                      {customerOpinions.tolerancy_grade?.MA_MA}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left', paddingLeft: '25px' }}>
                      Alto
                    </td>
                    <td className="Yellow">
                      {customerOpinions.tolerancy_grade?.A_MB}
                    </td>
                    <td className="Yellow">
                      {customerOpinions.tolerancy_grade?.A_B}
                    </td>
                    <td className="Orange">
                      {customerOpinions.tolerancy_grade?.A_M}
                    </td>
                    <td className="Red">
                      {customerOpinions.tolerancy_grade?.A_A}
                    </td>
                    <td className="Red">
                      {customerOpinions.tolerancy_grade?.A_MA}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left', paddingLeft: '25px' }}>
                      Médio
                    </td>
                    <td className="Green">
                      {customerOpinions.tolerancy_grade?.M_MB}
                    </td>
                    <td className="Yellow">
                      {customerOpinions.tolerancy_grade?.M_B}
                    </td>
                    <td className="Yellow">
                      {customerOpinions.tolerancy_grade?.M_M}
                    </td>
                    <td className="Orange">
                      {customerOpinions.tolerancy_grade?.M_A}
                    </td>
                    <td className="Red">
                      {customerOpinions.tolerancy_grade?.M_MA}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left', paddingLeft: '25px' }}>
                      Baixo
                    </td>
                    <td className="Green">
                      {customerOpinions.tolerancy_grade?.B_MB}
                    </td>
                    <td className="Yellow">
                      {customerOpinions.tolerancy_grade?.B_B}
                    </td>
                    <td className="Yellow">
                      {customerOpinions.tolerancy_grade?.B_M}
                    </td>
                    <td className="Orange">
                      {customerOpinions.tolerancy_grade?.B_A}
                    </td>
                    <td className="Orange">
                      {customerOpinions.tolerancy_grade?.B_MA}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: 'left', paddingLeft: '25px' }}>
                      Muito Baixo
                    </td>
                    <td className="GreenMore">
                      {customerOpinions.tolerancy_grade?.MB_MB}
                    </td>
                    <td className="Green">
                      {customerOpinions.tolerancy_grade?.MB_B}
                    </td>
                    <td className="Green">
                      {customerOpinions.tolerancy_grade?.MB_M}
                    </td>
                    <td className="Yellow">
                      {customerOpinions.tolerancy_grade?.MB_A}
                    </td>
                    <td className="Orange">
                      {customerOpinions.tolerancy_grade?.MB_MA}
                    </td>
                  </tr>
                </tbody>
              </table>
            </TolerancyContent>
          </Card>
          <Card>
            <CustomerOpinionsContent>
              <h1>PLANOS DE AÇÃO</h1>
              <WrapperHeader>
                <WrapperFilter>
                  <InputOnly
                    name="search"
                    icon={FiSearch}
                    onChange={e => setInputFilter(e.target.value)}
                    onClick={handleClear}
                  />
                  <Button
                    background="#a8c6df"
                    icon={FaFilter}
                    color="#FFF"
                    onClick={() => modalFilter.current?.open()}
                  />
                </WrapperFilter>
                <Button
                  background="#0079C4"
                  color="#FFF"
                  onClick={handleNewOpinion}
                >
                  Novo plano de ação
                </Button>
              </WrapperHeader>

              <WrapperCustomerOpinions>
                {customerOpinionsToDisplay?.map(customerOpinion => (
                  <ExpansionPainel
                    key={customerOpinion.id}
                    titleComponent={() => (
                      <CustomerOpinionTitleComponent
                        label={
                          customerOpinion.risk &&
                          riskTypeLookup[customerOpinion.risk]
                        }
                        directorate={customerOpinion.customer_directorate?.name}
                        department={customerOpinion.customer_department?.name}
                        process={`${customerOpinion.customer_process?.cod} - ${customerOpinion.customer_process?.name}`}
                      />
                    )}
                    barComponent={() => (
                      <CustomerOpinionBarComponent
                        customerOpinion={customerOpinion}
                        onEdit={() => handleEditOpinion(customerOpinion)}
                        onDelete={() => handleOpenConfirmation(customerOpinion)}
                      />
                    )}
                  >
                    <ExpansionContent>
                      <CustomerOpinion customerOpinion={customerOpinion} />
                    </ExpansionContent>
                  </ExpansionPainel>
                ))}
              </WrapperCustomerOpinions>
            </CustomerOpinionsContent>
          </Card>
        </Main>
      </Container>
      <Modal ref={modalOpinion} size="lg" removeCloseButton>
        <FormOpinion
          initialData={selectedCustomerOpinion}
          onSubmit={handleSubmitFormOpinion}
          onCancel={() => modalOpinion.current?.close()}
        />
      </Modal>
      <Modal ref={modalConfirmation} size="sm" removeCloseButton>
        <ModalConfirmationContent>
          <h1>Tem certeza que deseja remover o plano de ação selecionado?</h1>
          <div className="wrapper">
            <Button
              onClick={() => {
                selectedCustomerOpinion &&
                  handleDeleteOpinion(selectedCustomerOpinion);
                modalConfirmation.current?.close();
              }}
              background="#0079C4"
            >
              Sim
            </Button>
            <Button
              onClick={() => modalConfirmation.current?.close()}
              background="#FF151F"
            >
              Não
            </Button>
          </div>
        </ModalConfirmationContent>
      </Modal>
      <SideModal ref={modalFilter} title="FILTROS">
        <FormOpinionsFilter
          initialData={filter}
          onSubmit={handleSubmitFormOpinionsFilter}
          onClear={handleClearFilter}
          customerDirectorates={customerDirectorates || []}
        />
      </SideModal>
    </>
  );
};

export default DPIA;
