import { Form as FormWeb } from '@unform/web';
import styled from 'styled-components';
import { shade } from 'polished';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  > h1 {
    text-align: center;
    font-size: 25px;
    // letter-spacing: 2.16px;
    color: #3f536e;
    opacity: 1;
  }
`;

export const AlertModal = styled.header`
  padding-top: 15px;
  text-align: center;
`;

export const ContainerModal = styled.div`
  background-color: #ffff;
  width: 70%;
  margin: auto;
  padding-top: 30px;
`;

export const Container = styled.div`
  background-color: #ffff;
  border: 1px solid #b7d2e5cc;
  border-radius: 4px 4px 0px 0px;
  padding: 46px 86px;
`;

export const Form = styled(FormWeb)`
  > div.Line1 {
    display: grid;
    grid-template-columns: 2fr 6fr 2fr;
    grid-gap: 32px;
  }

  > div.Content {
    background-color: #ffff;
    border: 1px solid #b7d2e5cc;
    border-radius: 4px 4px 0px 0px;
    padding: 46px 86px;
  }

  > h1 {
    text-align: left;
    font-size: 11px;
    letter-spacing: 1.7px;
    color: #3f536e;
    margin-top: 37px;
  }
`;

export const Main = styled.main``;

export const Modules = styled.div`
  margin-top: 32px;

  > div + div {
    margin-top: 10px;
  }
`;

export const Questions = styled.ul`
  list-style-type: none;
  > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;

    div.Title {
      display: flex;
      h1 {
        font-size: 14px;
        font-weight: 500;
        width: 40px;
      }

      h2 {
        font-size: 14px;
        font-weight: 400;
        flex: 1;
      }
    }
    div.Icons {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: #c4dae9;
        cursor: pointer;

        margin-left: 8px;

        &:hover {
          color: ${shade(0.2, '#C4DAE9')};
        }
      }
    }

    & + li {
      border-top: 1px solid #d8e5ee;
    }
  }

  > h3 {
    font-size: 14px;
    padding: 16px 24px;
    font-weight: 400;
  }
`;

export const Quiz = styled.div`
  > h1 {
    text-align: left;
    font-size: 11px;
    letter-spacing: 1.7px;
    color: #3f536e;
    opacity: 1;
    margin-top: 37px;
  }

  > div.Quiz {
    padding: 19px 39px 25px 32px;
    background: #fafbfc;
    border: 1px solid #d8e5ee;
    border-radius: 4px;
    margin-top: 17px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 56px;
  background-color: #ffff;
  border-right: 1px solid #b7d2e5cc;
  border-left: 1px solid #b7d2e5cc;
  border-bottom: 1px solid #b7d2e5cc;
  border-radius: 0px 0px 4px 4px;

  > button {
    width: 160px;

    & + button {
      margin-left: 16px;
    }
  }
`;

export const FooterModal = styled.footer`
  padding: 20px 30px;
  display: flex;
  // width: 50%;
  margin: auto;

  button.Button {
    flex: 1;
    & + button {
      margin-left: 10px;
    }
  }
`;
