import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;

  > h1 {
    text-align: left;
    font: normal normal normal 15px/22px Heebo;
    letter-spacing: 0px;
    color: #8dabc4;
  }
`;

export const Content = styled.div`
  margin-top: 17px;
  padding: 23px 30px;
  background: #f6f8fa 0% 0% no-repeat padding-box;
  border-radius: 5px;

  div.CardOpinionField {
    margin-top: 28px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div.DisplayOpinionField {
      margin-right: 22px;
    }
  }
`;
