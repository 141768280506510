import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 42px 49px 42px;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 0px;
  padding-left: 10px;
  cursor: pointer;

  > h1 {
    margin-left: 20px;
    text-align: left;
    font: normal normal normal 22px/32px Heebo;
    letter-spacing: 0px;
    color: #2c405a;
    opacity: 1;
  }
`;

export const OpinionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 33px 56px 23px 56px;
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > div {
    margin-right: 30px;
    > h1 {
      text-align: left;
      font-size: 22px;
      letter-spacing: 0px;
      color: #2c405a;
    }
    > h3 {
      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;
      color: #8dabc4;
    }
  }
`;

export const WrapperBadgeLabel = styled.div`
  display: flex;
  background: #f6f8fa 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 13px 16px;

  > div.BadgeLabel {
    width: 180px;
    text-align: right;
    align-items: center;
    justify-content: space-around;
  }
`;

export const OpinionMain = styled.div`
  padding: 0px 56px 56px 56px;
`;

export const OpinionFooter = styled.div`
  padding: 0px 56px 56px 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > button.Button {
    width: 250px;
  }
`;

export const Motivation = styled.div`
  background: #f6f8fa 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 13px 16px;
  margin-bottom: 33px;

  > h1 {
    text-align: left;
    font: normal normal normal 15px/22px Heebo;
    letter-spacing: 0px;
    color: #3f536e;
    opacity: 1;
  }
`;

export const TabsProject = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &.TabsProjectContent {
    border-top: 1px solid #b7d2e5cc;
  }
`;

export const HeaderTabs = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0px 32px 0px 0px;
`;

export const ContentOpinion = styled.div`
  display: flex;
  width: 100%;
  padding: 34px 0px;
`;

export const TabHeader = styled.div`
  display: flex;
  margin-right: 65px;
  color: var(--unnamed-color-8dabc4);
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
  position: relative;
  padding-bottom: 8px;

  &.active {
    color: var(--unnamed-color-2c405a);
    text-align: left;

    &::before {
      content: '';
      width: 100%;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #0093ee;
    }
  }
`;

export const Tab = styled.div`
  display: flex;
  width: 100%;
`;
