import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  width: 100%;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 38px 38px 38px;

  > div.Line0 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-bottom: 30px;
  }

  > div.Line1 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    grid-gap: 32px;
    margin-bottom: 30px;
  }

  > div.Line2 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-bottom: 30px;
  }

  > div.Line3 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-bottom: 30px;
  }

  > div.Line4 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 32px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 38px;

  > button.Button {
    width: 195px;
    margin-left: 30px;

    &:hover {
      color: white;
    }
  }
`;
