import React from 'react';

import { Container, ProgressBar } from './styles';

interface ICardStageProgressBarProps {
  percent: number;
}

const CardStageProgressBar: React.FC<ICardStageProgressBarProps> = ({
  percent,
}) => {
  return (
    <Container>
      <div>
        <ProgressBar percent={percent} />
      </div>
    </Container>
  );
};

export default CardStageProgressBar;
