import React, { useCallback } from 'react';

import { MdEdit } from 'react-icons/md';
import {
  useParams,
  useHistory,
  useLocation,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import { v4 } from 'uuid';
import { IoIosArrowBack } from 'react-icons/io';
import { Container, PartnerUsers, ExpansionContent } from './styles';
import Button from '../../../components/Button';
import { IPartner } from '..';
import PartnerEdit from '../PartnerEdit';

import ExpansionPainel from '../../../components/ExpansionPainel';
import Badge from '../../../components/Badge';
import DisplayField from '../../../components/DisplayField';
import Spinner from '../../../components/Spinner';
import { useFetch } from '../../../hooks/fetch';

const Partner: React.FC = () => {
  const { id: idPartner } = useParams<{ id: string }>();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const { data: partner } = useFetch<IPartner>(`/partners/${idPartner}`, {
    revalidateOnFocus: false,
  });

  const handleEditPartner = useCallback(
    (id: string) => {
      history.push(`/home/partners/${id}/edit`);
    },
    [history],
  );

  if (location.pathname !== `/home/partners/${idPartner}`) {
    return (
      <Switch>
        <Route path={`${match.path}/edit`} component={PartnerEdit} />
      </Switch>
    );
  }

  return (
    <Container>
      <header>
        <IoIosArrowBack size={24} onClick={() => history.goBack()} />
        <h1>Parceiro</h1>
        <Button
          icon={MdEdit}
          background="#FD7B1F"
          onClick={() => handleEditPartner(idPartner)}
        >
          Editar Parceiro
        </Button>
      </header>
      {partner ? (
        <div className="PartnerData">
          <h1>{partner.name}</h1>
          <span>{partner.register_number}</span>
          <div className="Location">
            <h3>
              {partner.address}{' '}
              {partner.address && partner.number && `, ${partner.number}`}{' '}
              {partner.district && `- ${partner.district}`}
            </h3>
            <h3>
              {partner.city}{' '}
              {partner.city && partner.state && `- ${partner.state}`}{' '}
              {partner.city &&
                partner.state &&
                partner.zipcode &&
                `- ${partner.zipcode}`}
            </h3>
          </div>
          {partner.technical_hourly_value ||
          partner.technical_hourly_value === 0 ? (
            <h4>
              Valor da hora técnica:{' '}
              <span>R$ {partner.technical_hourly_value}</span>
            </h4>
          ) : null}
        </div>
      ) : (
        <div>
          <Spinner />
        </div>
      )}
      <h1>Usuários</h1>
      {partner ? (
        <PartnerUsers>
          {!partner?.partnerUsers.length && (
            <h3>Nenhum usuário do parceiro cadastrado</h3>
          )}
          {partner?.partnerUsers.map(partnerUser => (
            <ExpansionPainel
              key={partnerUser.id}
              title={partnerUser.user?.name}
            >
              <ExpansionContent>
                <div className="Line1">
                  <DisplayField label="Telefone">
                    {partnerUser.telephone}
                  </DisplayField>
                  <DisplayField label="E-mail">
                    {partnerUser.user.email}
                  </DisplayField>
                </div>
                <div className="Line2">
                  <DisplayField label="Mini curriculo">
                    {partnerUser.resume}
                  </DisplayField>
                </div>
                <div className="Line3">
                  {partnerUser.specialty &&
                    partnerUser.specialty !== '' &&
                    partnerUser.specialty
                      .split(',')
                      .map(c => <Badge key={v4()} value={c} />)}
                </div>
              </ExpansionContent>
            </ExpansionPainel>
          ))}
        </PartnerUsers>
      ) : (
        <PartnerUsers>
          <Spinner />
        </PartnerUsers>
      )}
    </Container>
  );
};

export default Partner;
