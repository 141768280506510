import React from 'react';

import { format } from 'date-fns';
import { Container } from './styles';

interface IAssessmentBarComponentProps {
  assessmentStatus?: string;
  assessmentDate?: Date;
}

const AssessmentBarComponent: React.FC<IAssessmentBarComponentProps> = ({
  assessmentStatus,
  assessmentDate,
}) => {
  return (
    <Container>
      <div>
        <h1>Prazo</h1>
        <h2>
          {assessmentDate && format(new Date(assessmentDate), 'dd/MM/yyyy')}
        </h2>
        <h1>Status</h1>
        <h2>{assessmentStatus}</h2>
      </div>
    </Container>
  );
};

export default AssessmentBarComponent;
