import React, { useMemo } from 'react';
import { IModalHandles } from '../../../../../components/Modal';
import { IOpinion, IOpinionItem } from '../../../../Opinions';
import CardOpinionFill from '../components/CardOpinionFill';
import CardOpinionNotRecommended from '../components/CardOpinionNotRecommended';
import CardOpinionNotRequested from '../components/CardOpinionNotRequested';
import CardOpinionRequested from '../components/CardOpinionRequested';
import OpinionItem from '../components/OpinionItem';

import { Container } from './styles';

interface IOpinionGroupProps {
  label: string;
  customer_group_opinion: IOpinionItem;
  modal_request_opinion: React.MutableRefObject<IModalHandles | undefined>;
}

const OpinionGroup: React.FC<IOpinionGroupProps> = ({
  label,
  customer_group_opinion,
  modal_request_opinion,
}) => {
  const lastOpinion: IOpinion = useMemo(() => {
    return customer_group_opinion.opinions[
      customer_group_opinion.opinions.length - 1
    ];
  }, [customer_group_opinion.opinions]);

  return (
    <Container>
      {customer_group_opinion.status === 'Não Recomendado' && (
        <CardOpinionNotRecommended
          label={label}
          modal_request_opinion={modal_request_opinion}
        />
      )}
      {customer_group_opinion.status === 'Recomendado' && (
        <CardOpinionNotRequested
          label={label}
          modal_request_opinion={modal_request_opinion}
        />
      )}
      {customer_group_opinion.status === 'Solicitado' && (
        <CardOpinionRequested lastOpinion={lastOpinion} />
      )}
      {customer_group_opinion.status === 'Preenchimento' && <CardOpinionFill />}
      {customer_group_opinion.opinions
        .filter(opinion => opinion.status === 'Respondido')
        .map(opinion => (
          <OpinionItem key={opinion.id} opinion={opinion} />
        ))}
    </Container>
  );
};

export default OpinionGroup;
