import React, { useContext, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { MdDragHandle, MdMoreVert, MdEdit, MdDelete } from 'react-icons/md';
import {
  Container,
  OptionsContainerTemplateCategories,
  Questions,
} from './styles';
import { ITemplateCategory, ITemplateModule } from '../../..';
import SuspensePainel from '../../../../../components/SuspensePainel';
import TemplateQuestionCard from '../TemplateQuestionCard';
import { TemplateContext } from '..';

interface ITemplateCategoryCardProps {
  templateModule: ITemplateModule;
  templateCategory: ITemplateCategory;
  templateCategoryIndex: number;
}

const TemplateCategoryCard: React.FC<ITemplateCategoryCardProps> = ({
  templateModule,
  templateCategory,
  templateCategoryIndex,
}) => {
  const { handleEditCategory, handleRemoveCategory } =
    useContext(TemplateContext);
  const ref = useRef(null);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'CARD_CATEGORY', id: templateCategory.id },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'CARD_CATEGORY',
  });

  dragRef(dropRef(ref));

  return (
    <div ref={ref}>
      <Container
        isDragging={isDragging}
        preTitle={(templateCategoryIndex + 1).toString()}
        preTitleWidth="10px"
        preTitleColor="#3F536E"
        title={templateCategory.name}
        titleFontSize="12px"
        barComponent={() => (
          <>
            <MdDragHandle size={24} style={{ cursor: 'grab' }} />
            <SuspensePainel icon={MdMoreVert}>
              <OptionsContainerTemplateCategories>
                <button
                  type="button"
                  onClick={() => {
                    handleEditCategory(templateModule, templateCategory);
                  }}
                >
                  <MdEdit size={22} />
                  <h1>Editar Categoria</h1>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    handleRemoveCategory(
                      templateModule.id,
                      templateCategory.id,
                    );
                  }}
                >
                  <MdDelete size={22} />
                  <h1>Remover Categoria</h1>
                </button>
              </OptionsContainerTemplateCategories>
            </SuspensePainel>
          </>
        )}
      >
        <Questions>
          {!templateCategory?.questions.length && (
            <h3>Nenhum pergunta criada</h3>
          )}
          {templateCategory.questions.map(
            (templateQuestion, templateQuestionIndex) => (
              <TemplateQuestionCard
                key={templateQuestion.id}
                templateModule={templateModule}
                templateCategory={templateCategory}
                templateQuestion={templateQuestion}
                templateQuestionIndex={templateQuestionIndex}
              />
            ),
          )}
        </Questions>
      </Container>
    </div>
  );
};

export default TemplateCategoryCard;
