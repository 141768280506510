import React, { useRef, useCallback, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Header, Main, Container } from './styles';
import Button from '../../../../../../components/Button';
import LoadingMask from '../../../../../../components/LoadingMask';
import { useToast } from '../../../../../../hooks/toast';
import api from '../../../../../../services/api';
import { ICustomerProcess, mutateProcess } from '../..';

interface IFormProps {
  new_customer_process: ICustomerProcess;
  onSubmit(data: ICustomerProcess): void;
  onCancel(): void;
}

const ModalConfirmSaving: React.FC<IFormProps> = ({
  new_customer_process,
  onSubmit,
  onCancel,
}) => {
  const form = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await api.patch(
        `/customerProcesses/${new_customer_process.id}`,
        new_customer_process,
      );
      mutateProcess(new_customer_process, 'edit');
    } catch (error) {
      addToast({
        title: 'Erro',
        type: 'error',
        description: 'Falha ao tentar salvar',
      });
    } finally {
      setLoading(false);
      onSubmit(new_customer_process);
    }
  }, [addToast, new_customer_process, onSubmit]);

  return (
    <>
      {loading ? (
        <LoadingMask text="Salvando" />
      ) : (
        <Container
          ref={form}
          onSubmit={handleSubmit}
          initialData={new_customer_process}
        >
          <Header>
            <h1>Alerta</h1>
          </Header>
          <Main>
            Deseja finalizar o preenchimento do processo?
            <div className="BtnConfirmCancel">
              <Button type="submit" background="#4ECE3D">
                Confirmar
              </Button>

              <Button background="#FF151F" onClick={onCancel}>
                Cancelar
              </Button>
            </div>
          </Main>
        </Container>
      )}
    </>
  );
};

export default ModalConfirmSaving;
