import React from 'react';

import { Container } from './styles';

interface IBadgeProps {
  value: string | number | undefined;
  color?: string;
  background?: string;
  bordered?: boolean;
}
//

const Badge: React.FC<IBadgeProps> = ({
  value,
  color,
  background,
  bordered,
}) => {
  return (
    <Container
      className="Badge"
      color={color}
      background={background}
      bordered={bordered}
    >
      {value}
    </Container>
  );
};
export default Badge;
