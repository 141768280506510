import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 0px;
  padding-left: 10px;

  > svg {
    color: #8dabc4;
    cursor: pointer;

    &:hover {
      color: ${shade('0.4', '#8dabc4')};
    }
  }

  > h1 {
    text-align: left;
    font: normal normal normal 22px/32px Heebo;
    letter-spacing: 0px;
    color: #2c405a;
    opacity: 1;
    margin-left: 34px;
  }
`;

export const CardContent = styled.div`
  padding: 24px 35px;

  > header {
    > h1 {
      text-align: left;
      font: normal normal normal 14px/21px Heebo;
      letter-spacing: 0px;
      color: #8dabc4;
      opacity: 1;
    }
  }

  > main {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;
