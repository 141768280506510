import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { Container, Header, Main, Footer, Line } from './styles';
import Button from '../../../../components/Button';
import { ICustomerProcess } from '../../components/Process';
import FormCheckbox from '../../../../components/FormCheckbox';
import OpinionStatus from '../../components/ProcessOpinions/components/OpinionStatus';

export interface IRequestOpinions {
  busines: boolean;
  compliance: boolean;
  juridical: boolean;
  security: boolean;
}

interface IFormProps {
  initialData?: IRequestOpinions;
  customer_process: ICustomerProcess;
  onSubmit(requestOpinions: IRequestOpinions): void;
  onCancel(): void;
}

const FormRequestOpinions: React.FC<IFormProps> = ({
  initialData,
  customer_process,
  onSubmit,
  onCancel,
}) => {
  const form = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async ({ busines, compliance, juridical, security }: IRequestOpinions) => {
      form.current?.setErrors({});

      const requestOpinions: IRequestOpinions = {
        busines,
        compliance,
        juridical,
        security,
      };

      onSubmit(requestOpinions);
    },
    [onSubmit],
  );

  return (
    <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
      <Header>
        <h1>Solicitar parecer</h1>
      </Header>
      <Main>
        <Line>
          <FormCheckbox
            name="busines"
            label="Parecer de Negócios"
            disabled={
              customer_process.formated_opinions.busines.status !==
                'Recomendado' &&
              customer_process.formated_opinions.busines.status !==
                'Não Recomendado'
            }
          />
          <OpinionStatus
            opinion_item={customer_process.formated_opinions.busines}
          />
        </Line>
        <Line>
          <FormCheckbox
            name="compliance"
            label="Parecer de Compliance"
            disabled={
              customer_process.formated_opinions.compliance.status !==
                'Recomendado' &&
              customer_process.formated_opinions.compliance.status !==
                'Não Recomendado'
            }
          />
          <OpinionStatus
            opinion_item={customer_process.formated_opinions.compliance}
          />
        </Line>
        <Line>
          <FormCheckbox
            name="juridical"
            label="Parecer Jurídico"
            disabled={
              customer_process.formated_opinions.juridical.status !==
                'Recomendado' &&
              customer_process.formated_opinions.juridical.status !==
                'Não Recomendado'
            }
          />
          <OpinionStatus
            opinion_item={customer_process.formated_opinions.juridical}
          />
        </Line>
        <Line>
          <FormCheckbox
            name="security"
            label="Parecer de Tecnologia"
            disabled={
              customer_process.formated_opinions.security.status !==
                'Recomendado' &&
              customer_process.formated_opinions.security.status !==
                'Não Recomendado'
            }
          />
          <OpinionStatus
            opinion_item={customer_process.formated_opinions.security}
          />
        </Line>
      </Main>
      <Footer>
        <Button background="transparent" color="#A8C6DF" onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit" background="#4ECE3D">
          Solicitar
        </Button>
      </Footer>
    </Container>
  );
};

export default FormRequestOpinions;
