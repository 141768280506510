import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const ModuleDisplay = styled.ul`
  flex: 1;
  border-top: 1px solid #e5eef5;
  > li {
    display: flex;
    padding: 40px 30px;
    border-bottom: 1px solid #e5eef5;
    align-items: center;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      > h1 {
        color: var(--unnamed-color-8dabc4);
        text-align: left;
        font-size: 14px;
        letter-spacing: 2.16px;
        opacity: 1;
        width: 120px;
      }

      > h2 {
        color: var(--unnamed-color-0079c4);
        text-align: left;
        font-size: 14px;
        letter-spacing: 2.16px;
        opacity: 1;
      }
    }
  }
`;

export const Wrapper = styled.div`
  > h3 {
    color: var(--unnamed-color-3f536e);
    text-align: left;
    font-size: 18px;
    letter-spacing: 0px;
    opacity: 1;
  }

  > button.ExecuteButton {
    background: var(--unnamed-color-0079c4) 0% 0% no-repeat padding-box;
    background: #0079c4 0% 0% no-repeat padding-box;
    border: 1px solid #0000000d;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: normal normal medium 15px/22px Heebo;
    letter-spacing: 0px;
    color: #ffffff;
    width: 146px;
    padding: 7px 30px;
    margin-left: 49px;
  }

  > button.ShowButton {
    border: 1px solid var(--unnamed-color-0079c4);
    border: 1px solid #0079c4;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    color: var(--unnamed-color-0079c4);
    text-align: center;
    font: normal normal medium 15px/22px Heebo;
    letter-spacing: 0px;
    color: #0079c4;
    width: 146px;
    padding: 7px 30px;
    margin-left: 49px;
  }
`;
