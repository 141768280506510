import React from 'react';

import { NumberToAlphabet } from 'number-to-alphabet';
import { Container } from './styles';
import { IProjectCategory } from '../../..';

const defaultAlphabet = new NumberToAlphabet();

interface ITableResultProps {
  category: IProjectCategory;
}

const TableResult: React.FC<ITableResultProps> = ({ category }) => {
  return (
    <Container>
      <thead>
        <tr>
          <th style={{ width: '5%' }} />
          <th style={{ width: '65%' }}>{category.name}</th>
          <th style={{ width: '10%', textAlign: 'center' }}>Avaliado</th>
          <th style={{ width: '10%', textAlign: 'center' }}>Ideal</th>
          <th style={{ width: '10%', textAlign: 'center' }}>Requerido</th>
        </tr>
      </thead>
      <tbody>
        {category.questions.map(question => (
          <tr key={question.id}>
            <td style={{ fontWeight: 500, textAlign: 'center' }}>
              {`${defaultAlphabet.numberToString(question.position + 1)}.`}
            </td>
            <td>{question.question}</td>
            <td style={{ textAlign: 'center' }}>
              {question.score || question.score === 0 ? question.score : 'N/A'}
            </td>
            <td style={{ textAlign: 'center' }}>5</td>
            <td style={{ textAlign: 'center' }}>
              {question.score_required || question.score_required === 0
                ? question.score_required
                : 'N/A'}
            </td>
          </tr>
        ))}
      </tbody>
    </Container>
  );
};

export default TableResult;
