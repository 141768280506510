import React, { ButtonHTMLAttributes } from 'react';

import { IconBaseProps } from 'react-icons';
import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  loadingMsg?: string;
  background?: string;
  color?: string;
  icon?: React.ComponentType<IconBaseProps> | string;
  iconSize?: number;
  outline?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  loadingMsg,
  icon: Icon,
  iconSize,
  ...rest
}) => (
  <Container className="Button" type="button" {...rest}>
    {Icon && <Icon size={iconSize || 20} />}
    {children && (
      <div className="Label">
        {loading ? loadingMsg || 'Carregando ...' : children}
      </div>
    )}
  </Container>
);
export default Button;
