import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  overflow-y: auto;

  > header {
    align-items: center;
    display: grid;
    grid-template-columns: 0fr 5fr 1.1fr;
    margin-bottom: 17px;
    > svg {
      cursor: pointer;
      color: #8dabc4;
      &:hover {
        color: #0093ee;
      }
    }
    h1 {
      text-align: left;
      font-size: 22px;
      letter-spacing: 0px;
      color: #2c405a;
    }
    button {
      // width: 180px;
    }
  }

  > div {
    background-color: #ffffff;
    border: 1px solid #b7d2e5cc;
    border-radius: 4px;
    padding: 59px 103px 55px 103px;

    h1 {
      text-align: left;
      font-size: 20px;
      letter-spacing: 0px;
      color: #3f536e;
      font-weight: 500;
    }

    span {
      text-align: left;
      font-size: 15px;
      letter-spacing: 0px;
      color: #8dabc4;
      font-weight: 400;
      margin-top: 3px;
    }

    div.Location {
      margin-top: 24px;
      h3 {
        text-align: left;
        font-size: 15px;
        letter-spacing: 0px;
        color: #3f536e;
        font-weight: 400;
      }
    }

    h4 {
      margin-top: 24px;
      text-align: left;
      font-size: 15px;
      letter-spacing: 0px;
      color: #3f536e;
      font-weight: 400;
      span {
        text-align: left;
        letter-spacing: 0px;
        color: #3f536e;
        font-weight: 600;
      }
    }
  }

  > h1 {
    text-align: left;
    font-size: 22px;
    letter-spacing: 0px;
    color: #2c405a;
    margin-top: 35px;
    margin-bottom: 20px;
  }
`;

export const PartnerUsers = styled.div`
  > div {
    margin-bottom: 10px;
  }

  > h3 {
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #3f536e;
  }
`;

export const ExpansionContent = styled.div`
  padding: 24px 38px 27px 54px;
  > div.Line1 {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 32px;
  }
  > div.Line2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 21px;
  }
  > div.Line3 {
    display: flex;
    margin-top: 36px;
    flex-wrap: wrap;

    span.Badge {
      padding: 2px 8px;
      margin-right: 8px;
      margin-top: 8px;
    }
  }
`;
