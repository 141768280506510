import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)``;

export const Header = styled.header`
  border-radius: 8px 8px 0px 0px;
  padding: 20px 30px 20px 30px;
  background: #f6f8fa 0% 0% no-repeat padding-box;

  > h1 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #2c405a;
  }
`;

export const Main = styled.main`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #c5dbea;
  border-bottom: 1px solid #c5dbea;

  > div.Line1 {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 32px;
  }

  > div.FormSelect {
    margin-top: 20px;
  }

  > div.Input {
    margin-top: 20px;
  }

  > div.TextArea {
    margin-top: 20px;
  }

  > button.Button {
    width: 146px;
    margin-left: 32px;
  }

  > button.AddNewCustomerUser {
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #0093ee;
    background-color: transparent;
    border: 0px;
    margin-top: 8px;
  }
`;

export const Footer = styled.footer`
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button.Button {
    flex: 1;
    & + button {
      margin-left: 10px;
    }
  }
`;
