import React from 'react';
import LoadingMask from '../../../../components/LoadingMask';

import { getAllPercentFilled, ICustomerProcess } from '../Process';

import ArrowDown from './components/ArrowDown';
import ArrowRight from './components/ArrowRight';
import ArrowUp from './components/ArrowUp';
import BadgeLabel from './components/BadgeLabel';
import CardStage from './components/CardStage';

import {
  Container,
  ProcessDiagramHeader,
  ProcessDiagramMain,
  Wrapper,
} from './styles';

interface IDiagramProps {
  customer_process: ICustomerProcess;
}

const Diagram: React.FC<IDiagramProps> = ({ customer_process }) => {
  if (customer_process) {
    getAllPercentFilled(customer_process);
  }

  if (!customer_process) {
    return <LoadingMask text="Carregando..." />;
  }

  return (
    <Container>
      <ProcessDiagramHeader>
        <div>
          <h1>{customer_process.name}</h1>
          <h2>
            {customer_process.responsable_customer_user?.user.name ||
              'Responsável indefinido'}
          </h2>
        </div>
        <Wrapper>
          <div className="Line1">
            <BadgeLabel
              name="busines"
              label="Parecer de Negócios Recomendado"
            />
            <BadgeLabel name="juridical" label="Parecer Jurídico Recomendado" />
          </div>
          <div className="Line2">
            <BadgeLabel
              name="compliance"
              label="Parecer de Compliance Recomendado"
            />
            <BadgeLabel
              name="security"
              label="Parecer de Cybersecurity Recomendada"
            />
          </div>
        </Wrapper>
      </ProcessDiagramHeader>
      <ProcessDiagramMain>
        <div />
        <div />
        <div />
        <div />
        <CardStage
          name="COMPARTILHAMENTO"
          customer_process={customer_process}
          percent_filled={customer_process.data.share.percent_filled}
          badges={['busines']}
          alert={
            customer_process.formated_opinions.busines.status === 'Recomendado'
          }
        />
        <div />
        <div />

        <div />
        <div />
        <div />
        <div />
        <ArrowUp />
        <div />
        <div />

        <CardStage
          name="COLETA"
          customer_process={customer_process}
          percent_filled={customer_process.data.collect.percent_filled}
          badges={['juridical', 'security']}
          alert={
            customer_process.formated_opinions.juridical.status ===
              'Recomendado' ||
            customer_process.formated_opinions.security.status === 'Recomendado'
          }
        />
        <ArrowRight />
        <CardStage
          name="CONSENTIMENTO"
          customer_process={customer_process}
          percent_filled={customer_process.data.consent.percent_filled}
        />
        <ArrowRight />
        <CardStage
          name="TRATAMENTO"
          customer_process={customer_process}
          percent_filled={customer_process.data.treatment.percent_filled}
          badges={['compliance']}
          alert={
            customer_process.formated_opinions.compliance.status ===
            'Recomendado'
          }
        />
        <ArrowRight />
        <CardStage
          name="DESCARTE"
          customer_process={customer_process}
          percent_filled={customer_process.data.discard.percent_filled}
        />

        <div />
        <div />
        <div />
        <div />
        <ArrowDown />
        <div />
        <div />

        <div />
        <div />
        <div />
        <div />
        <CardStage
          name="ARMAZENAMENTO"
          customer_process={customer_process}
          percent_filled={customer_process.data.store.percent_filled}
          badges={['juridical', 'security']}
          alert={
            customer_process.formated_opinions.juridical.status ===
              'Recomendado' ||
            customer_process.formated_opinions.security.status === 'Recomendado'
          }
        />
        <ArrowRight />
        {/* <CardStage
          name="BACKUP"
          customer_process={customer_process}
          percent_filled={customer_process.data.backup.percent_filled}
        /> */}
      </ProcessDiagramMain>
    </Container>
  );
};

export default Diagram;
