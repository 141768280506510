import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { v4 } from 'uuid';
import { Container, Header, Main, Footer } from './styles';
import { FcOk } from 'react-icons/fc';
import { phoneMask } from '../../../components/Masks';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import Select2 from '../../../../components/Select2';
import Option from '../../../../components/Select2/Option';
import { ICustomerGroup, ICustomerUser } from '../../../CustomerUsers';
import { validatePassword } from '../../../../utils/validatePassword';
import PasswordRequirements from '../../../../components/PasswordRequirements';

interface IFormProps {
  initialData: ICustomerUser;
  customerGroups?: ICustomerGroup[];
  onSubmit(customerUser: ICustomerUser): void;
  onCancel?(): void;
}

interface IFormData {
  telephone: string;
  position: string;
  user: {
    name: string;
    email: string;
    password: string;
  };
  customerGroups: string[];
}

export const optionsCustomerGroups = [
  { value: 'DPO', label: 'DPO' },
  { value: 'Interviewee', label: 'Entrevistado' },
];

const FormCustomerUser: React.FC<IFormProps> = ({
  initialData,
  onSubmit,
  onCancel,
}) => {
  const form = useRef<FormHandles>(null);

  const newInitialData = {
    ...initialData,
    customerGroups: initialData.customerGroups
      ? initialData.customerGroups.map(cg =>
          optionsCustomerGroups.find(ocg => ocg.value === cg.name),
        )
      : [],
  };

  const [password, setPassword] = useState('');
  const defaultRequirements = {
    minLength: false,
    number: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    noSequential: false,
    noRepetition: false,
  };

  const [requirements, setRequirements] = useState(defaultRequirements);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    if (value === '') {
      setRequirements(defaultRequirements);
    } else {
      const { requirements } = validatePassword(value);
      setRequirements(requirements);
    }
  };

  const handleSubmit = useCallback(
    async ({
      telephone,
      position,
      user,
      customerGroups: CustomerGroups,
    }: IFormData) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          user: Yup.object().shape({
            name: Yup.string().required('Nome é obrigatório'),
            email: Yup.string()
              .email('Formato de e-mail inválido')
              .required('E-mail é obrigatório'),
            password: Yup.string()
              .required('A senha é obrigatória')
              .test(
                'is-valid-password',
                'A senha não cumpre os requisitos',
                value => {
                  if (!value) return false;
                  const { isValid } = validatePassword(value);
                  return isValid;
                },
              ),
          }),
          position: Yup.string().required('Cargo é obrigatório'),
        });

        if (!initialData.user.name) {
          await schema.validate(
            { user, position },
            {
              abortEarly: false,
            },
          );
        }

        const newCustomerUser: ICustomerUser = {
          ...initialData,
          telephone,
          position,
          user: {
            ...initialData.user,
            name: initialData.user.name ? initialData.user.name : user.name,
            email: initialData.user.email ? initialData.user.email : user.email,
            password: user.password,
          },
          customerGroups: CustomerGroups.map(cg => ({
            id: v4(),
            name: cg,
          })),
        };

        onSubmit(newCustomerUser);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [initialData, onSubmit],
  );

  return (
    <Container
      ref={form}
      onSubmit={handleSubmit}
      initialData={newInitialData}
      autoComplete="off"
    >
      <Header>
        <h1>{!initialData.user.name ? 'Novo Usuário' : 'Editar Usuário'}</h1>
      </Header>
      <Main>
        <div className="Line1">
          <Input
            label="Nome *"
            name="user.name"
            type="text"
            disabled={!!initialData.user.name}
            autoComplete="off"
            defaultValue=""
          />
          <Input
            label="Telefone"
            name="telephone"
            type="text"
            onChange={e => phoneMask(e)}
          />
        </div>

        <div className="Line2">
          <Input label="Cargo *" name="position" type="text" />
          <Select2 label="Grupos" name="customerGroups" multiSelection>
            <Option
              key="Entrevistado"
              value="Interviewee"
              label="Entrevistado"
            />
            <Option key="DPO" value="DPO" label="DPO" />
          </Select2>
        </div>

        {!initialData.user.name && (
          <div className="Line3">
            <Input
              label="E-mail *"
              name="user.email"
              type="email"
              disabled={!!initialData.user.name}
            />

            <div>
              <Input
                label="Senha *"
                name="user.password"
                type="password"
                autoComplete="off"
                value={password}
                onChange={handlePasswordChange}
                disabled={!!initialData.user.password}
              />
              <PasswordRequirements requirements={requirements} />
            </div>
          </div>
        )}
      </Main>
      <Footer>
        <Button
          type="button"
          background="transparent"
          color="grey"
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button type="submit">Salvar</Button>
      </Footer>
    </Container>
  );
};

export default FormCustomerUser;
