import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 18px;
    color: var(--color-primary);
    margin-right: 20px;
    font-weight: 400;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 400px;
  height: 100px;
  background-color: #fff;
  box-shadow: 0px 1px 4px #2c405a35;
  opacity: 1;
  border-radius: 5px;
`;
