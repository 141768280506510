import React, { useRef, useCallback, useState, useMemo } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Container, Main, Footer } from './styles';
import Button from '../../../../../../components/Button';
import Modal, { IModalHandles } from '../../../../../../components/Modal';

import TextArea from '../../../../../../components/TextArea';

import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { ICustomerProcess } from '../..';
import ModalConfirm from '../../components/ProcessConfirm';

interface IFormProps {
  initialData: ICustomerProcess;
  readOnly?: boolean;
}

const FormProcessTreatment: React.FC<IFormProps> = ({
  initialData,
  readOnly,
}) => {
  const form = useRef<FormHandles>(null);
  const modalConfirm = useRef<IModalHandles>();

  const [confirmSaving, setConfirmSaving] = useState<ICustomerProcess>(
    initialData,
  );
  const closedModal = useCallback(() => {
    modalConfirm.current?.close();
  }, []);

  const handleSubmit = useCallback(
    async ({ data }: ICustomerProcess) => {
      try {
        form.current?.setErrors({});

        const schema = Yup.object().shape({
          data: Yup.object().shape({
            treatment: Yup.object().shape({
              fields: Yup.object().shape({
                purpose: Yup.string().required('Campo obrigatório'),
                who: Yup.string().required('Campo obrigatório'),
                who_access: Yup.string().required('Campo obrigatório'),
              }),
            }),
          }),
        });
        await schema.validate(
          { data },
          {
            abortEarly: false,
          },
        );

        const newCustomerProcess: ICustomerProcess = {
          ...initialData,
          data: {
            ...initialData.data,
            treatment: {
              ...initialData.data.treatment,
              fields: {
                ...data.treatment.fields,
                firstrecording_treatment: true,
              },
            },
          },
        };

        setConfirmSaving(newCustomerProcess);
        modalConfirm.current?.open();
        // setLoading(true);
        // try {
        //   await api.patch(
        //     `/customerProcesses/${initialData.id}`,
        //     newCustomerProcess,
        //   );

        //   mutateProcess(newCustomerProcess, 'edit');
        // } catch (error) {
        //   addToast({
        //     title: 'Erro',
        //     type: 'error',
        //     description: 'Falha ao tentar salvar',
        //   });
        // } finally {
        //   setLoading(false);
        // }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [initialData],
  );

  const disabled = useMemo(() => {
    if (readOnly) {
      return true;
    }
    if (
      initialData.status !== 'Preenchimento' &&
      initialData.status !== 'Revalidação' &&
      initialData.reopened_process !== true
    ) {
      return true;
    }
    return false;
  }, [initialData.reopened_process, initialData.status, readOnly]);

  return (
    <>
      <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
        <Main>
          <TextArea
            label="Para que a organização utiliza os dados pessoais que foram coletados neste processo?"
            explanation="Indicar a finalidade do tratamento dos dados e especificar como são utilizados."
            name="data.treatment.fields.purpose"
            disabled={disabled}
          />
          <TextArea
            label="Quais os cargos, setores ou funções são responsáveis por manipular os dados pessoais neste processo?"
            explanation="Indicar as partes envolvidas diretamente no processo."
            name="data.treatment.fields.who"
            disabled={disabled}
          />

          <TextArea
            label="Existem outros setores, colaboradores ou terceiros que possuem acesso indiretamente aos dados pessoais neste processo?"
            explanation="Indicar todos aqueles que possuem acesso aos dados pessoais, mesmo que não façam uso deles para tratamento."
            name="data.treatment.fields.who_access"
            disabled={disabled}
          />
        </Main>
        <Modal ref={modalConfirm} removeCloseButton size="sm">
          <ModalConfirm
            newData={confirmSaving}
            onCancel={() => modalConfirm.current?.close()}
            onSubmit={closedModal}
          />
        </Modal>

        {!disabled && (
          <Footer>
            <Button type="submit" background="#4ECE3D">
              Salvar
            </Button>
          </Footer>
        )}
      </Container>
    </>
  );
};

export default FormProcessTreatment;
