import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import signInBackground from '../../assets/images/sign-in-background.png';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: stretch;

  button.Button {
    margin-top: 10px;
  }

  p {
    color: #8dabc4;
    font-size: 13px;
    margin-top: 30px;
    padding: 0px;
  }

  button.FirstAccess {
    font-size: 13px;
    color: #0093ee;
    margin: 0px;
    border: 0px;
    background-color: transparent;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#0093EE')};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 565px;
  min-width: 565px;
  box-shadow: 0px 1px 4px #2c405a35;
  border: 1px solid #b7d2e5cc;
  opacity: 1;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${appearFromLeft} 1s;
  flex: 1;
  position: relative;

  > img {
    width: 204px;
    position: absolute;
    top: 69px;
    left: 71.5px;
  }

  form {
    margin: 80px 0px 0px 0px;
    width: 320px;
    text-align: center;

    h1 {
      margin-bottom: 30px;
      font-size: 22px;
      font-weight: 400;
    }

    p {
      color: #8dabc4;
      font-size: 13px;
      margin-top: 30px;
      padding: 0px;
    }

    div.Input {
      margin-bottom: 10px;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackground}) no-repeat center;
  background-size: cover;
`;

export const ModalForgotPassword = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;

  img {
    margin-top: 27px;
  }

  h1 {
    color: #8dabc4;
    font-size: 15px;
    letter-spacing: 0px;
    margin-top: 69px;
  }

  p {
    color: #3f536e;
    text-align: center;
    font-size: 18px;
    letter-spacing: 0px;
    margin-top: 20px;
  }

  button.Button {
    margin-top: 30px;
  }
`;

export const Sent = styled.div`
  padding: 20px 60px 100px 60px;

  h1 {
    font-size: 16px;
    font-weight: 400;
    span {
      font-weight: 600;
    }
  }
`;
