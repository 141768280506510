import React from 'react';

import { RiEditBoxFill } from 'react-icons/ri';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { Container } from './styles';

interface IDepartmentIconProps {
  concluded: boolean;
}

const DepartmentIcon: React.FC<IDepartmentIconProps> = ({ concluded }) => {
  return (
    <Container>
      {concluded ? (
        <IoIosCheckmarkCircle size={36} color="#4ECE3D" />
      ) : (
        <RiEditBoxFill size={36} color="#0079C4" />
      )}
    </Container>
  );
};

export default DepartmentIcon;
