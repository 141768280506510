import { shade } from 'polished';
import styled, { css } from 'styled-components';

interface IItemPageProps {
  active?: boolean;
}

export const Container = styled.div`
  padding: 10px 42px 49px 42px;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 0px;
  padding-left: 10px;

  > svg {
    color: #8dabc4;
    cursor: pointer;

    &:hover {
      color: ${shade('0.4', '#8dabc4')};
    }
  }

  > div {
    display: flex;
    margin-left: 36px;
    > h1 {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8dabc4;
    }

    > h2 {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #2c405a;
      padding-left: 3px;
    }
  }
`;

export const DepartmentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 43px 56px;

  > div.InputOnly {
    width: 500px;
  }

  > div {
    > h3 {
      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;
      color: #8dabc4;
    }
  }

  > button.Button {
    width: 200px;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
`;

export const OptionsContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 16px;
    background-color: #ffffff;
    border: 0px;
    cursor: pointer;

    h1 {
      text-align: left;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #3f536e;
      width: 150px;
    }

    svg {
      color: #8dabc4;
      margin-right: 16px;
    }

    &:hover {
      background-color: #fafbfc;
    }
  }
`;

export const DepartmentTable = styled.div`
  padding: 0px 56px 43px 56px;
`;

export const WrapperName = styled.div`
  display: flex;

  > h1 {
    text-align: left;
    font-size: 22px;
    letter-spacing: 0px;
    color: #2c405a;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border-radius: 50%;
    background-color: #fafbfc;

    cursor: pointer;

    &:hover {
      background-color: ${shade('0.1', '#fafbfc')};
    }

    > svg {
      font-size: 18px;
      color: #8dabc4;
      margin: 6px;
    }
  }
`;

export const Pagination = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 31px;
    border-top: 1px solid #c5d9e8;
    border-left: 1px solid #c5d9e8;
    border-bottom: 1px solid #c5d9e8;
    margin: 0px;
    padding: 0px;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */

    cursor: pointer;

    button {
      border: 0;
      background-color: transparent;
      font-size: 13px;
      letter-spacing: 0px;
      color: #a8c6df;
      flex: 1;
    }

    svg {
      color: #a8c6df;
    }

    &:hover {
      background-color: #e1ebf5;
    }
  }

  li:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  li:last-child {
    border-right: 1px solid #c5d9e8;
    border-radius: 0px 4px 4px 0px;
  }
`;

export const ItemPage = styled.li<IItemPageProps>`
  ${props =>
    props.active &&
    css`
      background-color: #e1ebf5;
    `}
`;
