import React, { useState, useCallback } from 'react';
import { useFetch } from '../../../../hooks/fetch';
import ExpansionPainel from '../../../../components/ExpansionPainel';
import { ExpansionContent } from '../../../Customers/CustomerNew/styles';
import {
  ICustomerProcess,
  ICustomerProcessData,
  ICustomerProcessWho,
  ICustomerProcessShareByWhom,
  ICustomerOriginCollectTypes,
  ICustomerTreatmentHypothesisTypes,
  ICustomerCollectPersonalDataTypes,
  ICountry,
  ICustomerProcessShareWithWhom,
  ICustomerSendingPersonalData,
  IDataReasonSaveData,
} from '../../../DataMapping/components/Process';
import QuisProgressBar from '../../../DataMapping/components/Process/components/QuizProgressBar';
import FormCollect from '../../../DataMapping/components/Process/forms/FormCollect';
import FormConsent from '../../../DataMapping/components/Process/forms/FormConsent';
import FormDiscard from '../../../DataMapping/components/Process/forms/FormDiscard';
import FormShare from '../../../DataMapping/components/Process/forms/FormShare';
import FormStore from '../../../DataMapping/components/Process/forms/FormStore';
import FormTreatment from '../../../DataMapping/components/Process/forms/FormTreatment';

import { Container, Quiz } from './styles';

interface IDetailsProps {
  customer_process: ICustomerProcess;
}

const Details: React.FC<IDetailsProps> = ({ customer_process }) => {
  let customer_data: ICustomerProcessData[] | undefined = [];

  if (customer_process.data.collect.fields.data_what) {
    const listCustomerData: ICustomerProcessData[] =
      customer_process.data.collect.fields.data_what?.map(data => ({
        id: '',
        name: data,
        customer_process_id: '',
      }));
    customer_data = listCustomerData;
  }

  let customer_who: ICustomerProcessWho[] | undefined = [];

  if (customer_process.data.collect.fields.data_by_who) {
    const listDataByWho: ICustomerProcessWho[] =
      customer_process.data.collect.fields.data_by_who.map(data => ({
        id: '',
        name: data,
        customer_process_id: '',
      }));

    customer_who = listDataByWho;
  }

  let customer_process_share_by_whom:
    | ICustomerProcessShareByWhom[]
    | undefined = [];

  if (customer_process.data.share.fields.by_whom) {
    const listByWhom: ICustomerProcessShareByWhom[] =
      customer_process.data.share.fields.by_whom.map(data => ({
        id: '',
        name: data,
        customer_process_id: '',
      }));

    customer_process_share_by_whom = listByWhom;
  }

  let customer_process_share_with_whom:
    | ICustomerProcessShareWithWhom[]
    | undefined = [];

  if (customer_process.data.share.fields.with_whom) {
    const listWithWhon: ICustomerProcessShareWithWhom[] =
      customer_process.data.share.fields.with_whom.map(data => ({
        id: '',
        name: data,
        customer_process_id: '',
      }));

    customer_process_share_with_whom = listWithWhon;
  }

  const { data: customerSendingPersonalData } =
    useFetch<ICustomerSendingPersonalData[]>(`/sendingPersonalData`);

  const { data: customerOriginCollectTypes } = useFetch<
    ICustomerOriginCollectTypes[]
  >(`/customerOriginCollectTypes`);

  const { data: customer_treatment_hypothesis_types } = useFetch<
    ICustomerTreatmentHypothesisTypes[]
  >(`/customerTreatmentHypothesisTypes`);

  const { data: customer_collect_personal_data_types } = useFetch<
    ICustomerCollectPersonalDataTypes[]
  >(`/customerCollectPersonalDataTypes`);

  const { data: countries } = useFetch<ICountry[]>(`/country`);

  const { data: customerReasonSaveData } =
    useFetch<IDataReasonSaveData[]>(`/reasonsSaveData`);

  const [usesConsent, setUsesConsent] = useState<boolean | undefined>();

  const onUsesConsent = useCallback(async (val: boolean | undefined) => {
    setUsesConsent(val);
  }, []);

  return (
    <Container>
      <Quiz>
        <ExpansionPainel
          title="COLETA"
          barComponent={() => (
            <QuisProgressBar
              percent={customer_process.data.collect.percent_filled}
            />
          )}
        >
          <ExpansionContent>
            <FormCollect
              initialData={customer_process}
              customer_data={customer_data}
              customer_who={customer_who}
              customer_process_share_by_whom={customer_process_share_by_whom}
              customer_origin_collect_types={customerOriginCollectTypes}
              customer_treatment_hypothesis_types={
                customer_treatment_hypothesis_types
              }
              customer_collect_personal_data_types={
                customer_collect_personal_data_types
              }
              readOnly
            />
          </ExpansionContent>
        </ExpansionPainel>
        <ExpansionPainel
          title="CONSENTIMENTO"
          barComponent={() => (
            <QuisProgressBar
              percent={customer_process.data.consent.percent_filled}
            />
          )}
        >
          <ExpansionContent>
            <FormConsent
              initialData={customer_process}
              callbackUsesConsent={(val: boolean | undefined) =>
                onUsesConsent(val)
              }
              readOnly
            />
          </ExpansionContent>
        </ExpansionPainel>
        <ExpansionPainel
          title="TRATAMENTO"
          barComponent={() => (
            <QuisProgressBar
              percent={customer_process.data.treatment.percent_filled}
            />
          )}
        >
          <ExpansionContent>
            <FormTreatment initialData={customer_process} readOnly />
          </ExpansionContent>
        </ExpansionPainel>
        <ExpansionPainel
          title="COMPARTILHAMENTO"
          barComponent={() => (
            <QuisProgressBar
              percent={customer_process.data.share.percent_filled}
            />
          )}
        >
          <ExpansionContent>
            <FormShare
              initialData={customer_process}
              customer_who={customer_who}
              customer_process_share_by_whom={customer_process_share_by_whom}
              customer_process_share_with_whom={
                customer_process_share_with_whom
              }
              customerSendingPersonalData={customerSendingPersonalData}
              uses_Consent={usesConsent}
              countries={countries}
              readOnly
            />
          </ExpansionContent>
        </ExpansionPainel>
        <ExpansionPainel
          title="ARMAZENAMENTO"
          barComponent={() => (
            <QuisProgressBar
              percent={customer_process.data.store.percent_filled}
            />
          )}
        >
          <ExpansionContent>
            <FormStore initialData={customer_process} readOnly />
          </ExpansionContent>
        </ExpansionPainel>
        {/* <ExpansionPainel
          title="BACKUP"
          barComponent={() => (
            <QuisProgressBar
              percent={customer_process.data.backup.percent_filled}
            />
          )}
        >
          <ExpansionContent>
            <FormBackup initialData={customer_process} readOnly />
          </ExpansionContent>
        </ExpansionPainel> */}
        <ExpansionPainel
          title="DESCARTE"
          barComponent={() => (
            <QuisProgressBar
              percent={customer_process.data.discard.percent_filled}
            />
          )}
        >
          <ExpansionContent>
            <FormDiscard
              initialData={customer_process}
              listReasonsSaveData={customerReasonSaveData}
              readOnly
            />
          </ExpansionContent>
        </ExpansionPainel>
      </Quiz>
    </Container>
  );
};

export default Details;
