const isSequential = (value: string) => {
  const sequences = [
    '0123456789',
    'abcdefghijklmnopqrstuvwxyz',
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  ];
  const reversedSequences = sequences.map(seq =>
    seq.split('').reverse().join(''),
  );

  const checkForSequences = (val: string) => {
    for (let sequence of [...sequences, ...reversedSequences]) {
      for (let i = 0; i < sequence.length - 3; i++) {
        const subSequence = sequence.substring(i, i + 4);
        if (val.includes(subSequence)) {
          return true;
        }
      }
    }
    return false;
  };

  return checkForSequences(value);
};

export const validatePassword = (password: string) => {
  const requirements = {
    minLength: password.length >= 10,
    number: /\d/.test(password),
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    specialChar: /[!@#$%^&*(),.?":{}|<>_+=\[\]\\/'`~-]/.test(password),
    noSequential: !isSequential(password),
    noRepetition: !/(.)\1{3}/.test(password),
  };

  const isValid = Object.values(requirements).every(Boolean);
  return { isValid, requirements };
};
