import React, { useRef, useCallback, useState, useMemo } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Container, Main, Footer } from './styles';
import Button from '../../../../../../components/Button';
import Modal, { IModalHandles } from '../../../../../../components/Modal';

import TextArea from '../../../../../../components/TextArea';
import InputBoolean from '../../../../../../components/InputBoolean';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { ICustomerProcess } from '../..';
import ModalConfirm from '../../components/ProcessConfirm';

interface IFormProps {
  initialData: ICustomerProcess;
  readOnly?: boolean;
}

const FormProcessStore: React.FC<IFormProps> = ({ initialData, readOnly }) => {
  const modalConfirm = useRef<IModalHandles>();
  const form = useRef<FormHandles>(null);
  const [
    store_has_legislation_boolean,
    setStore_has_legislation_boolean,
  ] = useState<boolean | undefined>(
    !!initialData?.data.store?.fields.has_legislation,
  );
  const [confirmSaving, setConfirmSaving] = useState<ICustomerProcess>(
    initialData,
  );
  const closedModal = useCallback(() => {
    modalConfirm.current?.close();
  }, []);

  const handleSubmit = useCallback(
    async ({ data }: ICustomerProcess) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          data: Yup.object().shape({
            store: Yup.object().shape({
              fields: Yup.object().shape({
                has_legislation: store_has_legislation_boolean
                  ? Yup.string().required('Campo obrigatório')
                  : Yup.string().nullable(),
                where: Yup.string().required('Campo obrigatório'),
                who_access: Yup.string().required('Campo obrigatório'),
              }),
            }),
          }),
        });
        await schema.validate(
          { data },
          {
            abortEarly: false,
          },
        );

        const newCustomerProcess: ICustomerProcess = {
          ...initialData,
          data: {
            ...initialData.data,
            store: {
              ...initialData.data.store,
              fields: {
                ...data.store.fields,
                firstrecording_store: true,
              },
            },
          },
        };

        setConfirmSaving(newCustomerProcess);
        modalConfirm.current?.open();
        // setLoading(true);
        // try {
        //   await api.patch(
        //     `/customerProcesses/${initialData.id}`,
        //     newCustomerProcess,
        //   );

        //   mutateProcess(newCustomerProcess, 'edit');
        // } catch (error) {
        //   addToast({
        //     title: 'Erro',
        //     type: 'error',
        //     description: 'Falha ao tentar salvar',
        //   });
        // } finally {
        //   setLoading(false);
        // }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [store_has_legislation_boolean, initialData],
  );

  const disabled = useMemo(() => {
    if (readOnly) {
      return true;
    }
    if (
      initialData.status !== 'Preenchimento' &&
      initialData.status !== 'Revalidação' &&
      initialData.reopened_process !== true
    ) {
      return true;
    }
    return false;
  }, [initialData.reopened_process, initialData.status, readOnly]);

  return (
    <>
      <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
        <Main>
          <TextArea
            label="Onde os dados pessoais são armazenados?"
            explanation="Indicar onde os dados pessoais são guardados (meio físico) ou gravados (meio digital)."
            name="data.store.fields.where"
            disabled={disabled}
          />
          <InputBoolean
            label="A manutenção do dado pessoal após o uso é regido por alguma legislação?"
            explanation="Indicar caso o armazenamento tenha alguma fundamentação legal."
            inputLabel="Qual legislação?"
            name="data.store.fields.has_legislation"
            onChangeBoolean={booleanValue =>
              setStore_has_legislation_boolean(booleanValue)
            }
            disabled={disabled}
          />

          <TextArea
            label="Quais os cargos, setores ou funções possuem acesso aos dados pessoais armazenados deste processo?"
            explanation="Indicar o colaborador (cargo/função) e/ou departamento que tenha acesso aos dados pessoais armazenados."
            name="data.store.fields.who_access"
            disabled={disabled}
          />
        </Main>
        <Modal ref={modalConfirm} removeCloseButton size="sm">
          <ModalConfirm
            newData={confirmSaving}
            onCancel={() => modalConfirm.current?.close()}
            onSubmit={closedModal}
          />
        </Modal>
        {!disabled && (
          <Footer>
            <Button type="submit" background="#4ECE3D">
              Salvar
            </Button>
          </Footer>
        )}
      </Container>
    </>
  );
};

export default FormProcessStore;
