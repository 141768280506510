/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, {
  useContext,
  useCallback,
  useRef,
  useState,
  useMemo,
} from 'react';

import { toRoman } from 'roman-numerals';
import { NumberToAlphabet } from 'number-to-alphabet';
import { BsListCheck } from 'react-icons/bs';
import { mutate as mutateGlobal, cache } from 'swr';
import { format } from 'date-fns';
import {
  Container,
  Modules,
  ExpansionContentModule,
  Categories,
  ExpansionContentCategory,
  Questions,
  ShowQuestion,
} from './styles';
import {
  ProjectContext,
  IProjectQuestion,
  IProject,
  IProjectQuestionRecommentationTypes,
} from '..';
import ExpansionPainel from '../../../components/ExpansionPainel';
import Button from '../../../components/Button';
import Modal, { IModalHandles } from '../../../components/Modal';
import FormRecommendation from './forms/FormRecommendation';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import DisplayField from '../../../components/DisplayField';
import { useAuth } from '../../../hooks/auth';
import { useFetch } from '../../../hooks/fetch';

const defaultAlphabet = new NumberToAlphabet();

const Recommendations: React.FC = () => {
  const { project } = useContext(ProjectContext);
  const modalRecommendation = useRef<IModalHandles>();
  const modalRecommendationShow = useRef<IModalHandles>();
  const [questionSelected, setQuestionSelected] = useState<IProjectQuestion>();
  const { addToast } = useToast();
  const { isPartnerConsultant } = useAuth();

  const { data: recommendationTypes } = useFetch<
    IProjectQuestionRecommentationTypes[]
  >('/projectQuestionRecommendationTypes', {
    revalidateOnFocus: true,
  });

  const questionModule = useMemo(() => {
    return project.scopedModules.find(
      m => m.id === questionSelected?.project_module_id,
    );
  }, [project.scopedModules, questionSelected]);

  const questionCategory = useMemo(() => {
    return project.scopedModules
      .find(m => m.id === questionSelected?.project_module_id)
      ?.categories.find(c => c.id === questionSelected?.project_category_id);
  }, [project.scopedModules, questionSelected]);

  const handleOpenRecommendation = useCallback((question: IProjectQuestion) => {
    setQuestionSelected(question);
    modalRecommendation.current?.open();
  }, []);

  const handleOpenRecommendationShow = useCallback(
    (question: IProjectQuestion) => {
      setQuestionSelected(question);
      modalRecommendationShow.current?.open();
    },
    [],
  );

  const modulesFiltered = useMemo(() => {
    return project.scopedModules.filter(
      module => module.status === 'Finalizado',
    );
  }, [project.scopedModules]);

  const handleSubmitQuestion = useCallback(
    newQuestion => {
      const modifyNewQuestion: IProjectQuestion = {
        ...newQuestion,
        recommendation_type: recommendationTypes?.find(
          rt => rt.id === newQuestion.recommendation_type_id,
        ),
      };

      const newProject: IProject = {
        ...project,
        scopedModules: project.scopedModules.map(m => {
          if (m.id === modifyNewQuestion.project_module_id) {
            return {
              ...m,
              categories: m.categories.map(c => {
                if (c.id === modifyNewQuestion.project_category_id) {
                  return {
                    ...c,
                    questions: c.questions.map(q => {
                      if (q.id === modifyNewQuestion.id) {
                        return modifyNewQuestion;
                      }
                      return q;
                    }),
                  };
                }
                return c;
              }),
            };
          }
          return m;
        }),
      };
      const oldProject = cache.get(`/projects/${project.id}`);
      api
        .patch(`/projectQuestions/${newQuestion.id}`, newQuestion)
        .catch(() => {
          const description = 'Ocorreu um erro ao salvar a questão';
          mutateGlobal(`/projects/${project.id}`, oldProject, false);
          addToast({
            type: 'error',
            title: 'Erro ao salvar',
            description,
          });
        });
      mutateGlobal(`/projects/${project.id}`, newProject, false);
      modalRecommendation.current?.close();
    },
    [addToast, project, recommendationTypes],
  );

  return (
    <>
      <Container>
        <Modules>
          {modulesFiltered.length ? (
            modulesFiltered.map((module, i) => (
              <ExpansionPainel
                key={module.id}
                preTitle={`MODULO ${toRoman(i + 1)}`}
                preTitleWidth="110px"
                title={module.name}
                titleColor="#0079c4"
                clean
                barComponent={() => (
                  <h1 style={{ color: '#0079C4', fontSize: '16px' }}>
                    {(
                      (module.categories.reduce(
                        (acc, cat) =>
                          (acc += cat.questions.filter(
                            q =>
                              (q.score || q.score === 0) &&
                              (q.score_required || q.score_required === 0) &&
                              q.score < q.score_required &&
                              q.recommendation,
                          ).length),
                        0,
                      ) *
                        100) /
                        module.categories.reduce(
                          (acc, cat) =>
                            (acc += cat.questions.filter(
                              q =>
                                (q.score || q.score === 0) &&
                                (q.score_required || q.score_required === 0) &&
                                q.score < q.score_required,
                            ).length),
                          0,
                        ) || 0
                    ).toFixed(0)}
                    %
                  </h1>
                )}
              >
                <ExpansionContentModule>
                  <Categories>
                    {!module?.categories.length && (
                      <h3>Nenhum categoria criada</h3>
                    )}
                    {module?.categories.map((category, y) => (
                      <ExpansionPainel
                        key={category.id}
                        preTitle={(y + 1).toString()}
                        preTitleWidth="10px"
                        preTitleColor="#3F536E"
                        title={category.name}
                        titleFontSize="12px"
                        barComponent={() => (
                          <h1 style={{ color: '#0079C4', fontSize: '16px' }}>
                            {(
                              (category.questions.filter(
                                q =>
                                  q.recommendation &&
                                  (q.score || q.score === 0) &&
                                  (q.score_required ||
                                    q.score_required === 0) &&
                                  q.score < q.score_required,
                              ).length *
                                100) /
                                category.questions.filter(
                                  q =>
                                    (q.score || q.score === 0) &&
                                    (q.score_required ||
                                      q.score_required === 0) &&
                                    q.score < q.score_required,
                                ).length || 0
                            ).toFixed(0)}{' '}
                            %
                          </h1>
                        )}
                      >
                        <ExpansionContentCategory>
                          <Questions>
                            {!category?.questions.length && (
                              <h3>Nenhum pergunta criada</h3>
                            )}
                            {category.questions
                              .filter(
                                q =>
                                  (q.score || q.score === 0) &&
                                  (q.score_required ||
                                    q.score_required === 0) &&
                                  q.score < q.score_required,
                              )
                              .map(question => (
                                <li key={question.id}>
                                  <div className="Title">
                                    <h1>
                                      {defaultAlphabet.numberToString(
                                        question.position + 1,
                                      )}
                                      .
                                    </h1>
                                    <h2>{question.question}</h2>
                                  </div>
                                  <div className="Buttons">
                                    {isPartnerConsultant &&
                                    !question.recommendation &&
                                    project.status !== 'Finalizado' ? (
                                      <Button
                                        icon={BsListCheck}
                                        iconSize={20}
                                        className="ExecuteButton"
                                        background="#0079c4"
                                        type="button"
                                        onClick={() =>
                                          handleOpenRecommendation(question)
                                        }
                                      >
                                        Recomendar
                                      </Button>
                                    ) : (
                                      <Button
                                        icon={BsListCheck}
                                        iconSize={20}
                                        outline
                                        className="ShowButton"
                                        color="#0079c4"
                                        background="#0079c4"
                                        type="button"
                                        onClick={() =>
                                          handleOpenRecommendationShow(question)
                                        }
                                      >
                                        Ver plano
                                      </Button>
                                    )}
                                  </div>
                                </li>
                              ))}
                          </Questions>
                        </ExpansionContentCategory>
                      </ExpansionPainel>
                    ))}
                  </Categories>
                </ExpansionContentModule>
              </ExpansionPainel>
            ))
          ) : (
            <h1>Nenhuma avaliação finalizada</h1>
          )}
        </Modules>
      </Container>
      <Modal ref={modalRecommendation} size="md">
        {questionSelected &&
          questionModule &&
          questionCategory &&
          recommendationTypes && (
            <FormRecommendation
              initialData={questionSelected}
              questionModule={questionModule}
              questionCategory={questionCategory}
              recommendationTypes={recommendationTypes}
              onSubmit={handleSubmitQuestion}
              onCancel={() => {
                modalRecommendation.current?.close();
              }}
            />
          )}
      </Modal>

      <Modal ref={modalRecommendationShow} size="md">
        {questionSelected && questionModule && questionCategory && (
          <ShowQuestion>
            <header>
              <h1>Plano de recomendação</h1>
            </header>
            <main>
              <div className="Line1">
                <DisplayField label="Item">
                  {`${toRoman(questionModule.position + 1)}.${
                    questionCategory.position + 1
                  }.${defaultAlphabet.numberToString(
                    questionSelected.position + 1,
                  )}`}
                </DisplayField>
                <DisplayField label="Data">
                  {questionModule.interview?.done_date &&
                    format(
                      new Date(questionModule.interview?.done_date),
                      'dd/MMM/yyyy HH:mm',
                    )}
                </DisplayField>
                <DisplayField label="Entrevistado">
                  {questionModule.interview?.customerUser?.user.name}
                </DisplayField>
              </div>
              <div className="Line2">
                <DisplayField label="Nota">
                  {questionSelected.score || questionSelected.score === 0
                    ? questionSelected.score
                    : 'N/A'}
                </DisplayField>
                <DisplayField label="Requerida">
                  {questionSelected.score_required ||
                  questionSelected.score_required === 0
                    ? questionSelected.score_required
                    : 'N/A'}
                </DisplayField>
                <DisplayField label="Tipo de recomendação">
                  {questionSelected.recommendation_type?.name}
                </DisplayField>
              </div>
              <div className="Line3">
                <DisplayField label="Pergunta">
                  {questionSelected.question}
                </DisplayField>
              </div>
              <div className="Line4">
                <DisplayField label="Argumento coletado">
                  {questionSelected.answer}
                </DisplayField>
              </div>
              <div className="Line5">
                <DisplayField label="Recomendação">
                  {questionSelected.recommendation}
                </DisplayField>
              </div>
              <div className="Line6">
                <DisplayField label="Ação/crítica/motivação">
                  {questionSelected.motivation}
                </DisplayField>
              </div>
              <div className="Line7">
                <DisplayField label="Horas de consultoria">
                  {questionSelected.consulting_hours}
                </DisplayField>
                <DisplayField label="Prazo recomendado (Meses)">
                  {questionSelected.recommendation_deadline}
                </DisplayField>
              </div>
              <div className="Line8">
                <DisplayField label="Impacto">
                  {questionSelected.impact}
                </DisplayField>
              </div>
              <div className="Line9">
                <DisplayField label="Prioridade">
                  {questionSelected.priority}
                </DisplayField>
              </div>
            </main>
            <footer>
              <Button
                type="button"
                background="transparent"
                color="grey"
                onClick={() => modalRecommendationShow.current?.close()}
              >
                Fechar
              </Button>
            </footer>
          </ShowQuestion>
        )}
      </Modal>
    </>
  );
};

export default Recommendations;
