export function getEndpoint(): string | undefined {
  if (process.env.REACT_APP_API_NODE_ENV === 'local') {
    return process.env.REACT_APP_API_ENDPOINT_LOCAL;
  }
  if (process.env.REACT_APP_API_NODE_ENV === 'development') {
    return process.env.REACT_APP_API_ENDPOINT_DEV;
  }
  if (process.env.REACT_APP_API_NODE_ENV === 'production') {
    return process.env.REACT_APP_API_ENDPOINT_PROD;
  }
  return 'http://localhost:3333';
}
