import React, { useCallback } from 'react';

import {
  useParams,
  useHistory,
  useRouteMatch,
  useLocation,
  Switch,
  Route,
} from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import { FaRegClone } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { toRoman } from 'roman-numerals';
import { NumberToAlphabet } from 'number-to-alphabet';
import {
  Container,
  Modules,
  Categories,
  Questions,
  ExpansionContentModule,
  ExpansionContentCategory,
} from './styles';
import Button from '../../../components/Button';
import { useFetch } from '../../../hooks/fetch';
import TemplateEdit from '../TemplateEdit';
import Spinner from '../../../components/Spinner';
import { ITemplate } from '..';
import ExpansionPainel from '../../../components/ExpansionPainel';
import Badge from '../../../components/Badge';
import TemplateNewVersion from '../TemplateNewVersion';

const defaultAlphabet = new NumberToAlphabet();

const TemplateDetails: React.FC = () => {
  const { id: idTemplate } = useParams<{ id: string }>();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const { data: template } = useFetch<ITemplate>(`/templates/${idTemplate}`, {
    revalidateOnFocus: true,
  });

  const handleEditTemplate = useCallback(
    (id: string) => {
      history.push(`/home/templates/${id}/edit`);
    },
    [history],
  );

  const handleNewVersion = useCallback(
    (id: string) => {
      history.push(`/home/templates/${id}/newVersion`);
    },
    [history],
  );

  if (location.pathname !== `/home/templates/${idTemplate}`) {
    return (
      <Switch>
        <Route path={`${match.path}/edit`} component={TemplateEdit} />
        <Route
          path={`${match.path}/newVersion`}
          component={TemplateNewVersion}
        />
      </Switch>
    );
  }

  return (
    <Container>
      {true && (
        <header>
          <IoIosArrowBack size={24} onClick={() => history.goBack()} />
          <h1>Template de projeto</h1>
          <div className="Buttons">
            {template?.consolidated ? (
              <Button
                icon={FaRegClone}
                background="#0079C4"
                onClick={() => handleNewVersion(idTemplate)}
              >
                Gerar Nova Versão
              </Button>
            ) : (
              <Button
                icon={MdEdit}
                background="#FD7B1F"
                onClick={() => handleEditTemplate(idTemplate)}
              >
                Editar Template
              </Button>
            )}
          </div>
        </header>
      )}
      {template ? (
        <div className="TemplateData">
          <div className="Header">
            <div className="HeaderLeft">
              <h1>
                {template.name}
                <span>{template.version}</span>
              </h1>
              <p>{template.type}</p>
            </div>
            <div className="HeaderRight">
              {template.consolidated ? (
                <Badge
                  value="Consolidado"
                  color="#0079C4"
                  background="#FFF"
                  bordered
                />
              ) : (
                <Badge
                  value="Em edição"
                  color="#FACA00"
                  background="#FFF"
                  bordered
                />
              )}
            </div>
          </div>

          <Modules>
            {template?.modules.map((module, i) => (
              <ExpansionPainel
                key={module.id}
                preTitle={`MODULO ${toRoman(i + 1)}`}
                preTitleWidth="110px"
                title={module.name}
                titleColor="#0079c4"
                clean
              >
                <ExpansionContentModule>
                  <Categories>
                    {!module?.categories.length && (
                      <h3>Nenhum categoria criada</h3>
                    )}
                    {module?.categories.map((category, y) => (
                      <ExpansionPainel
                        key={category.id}
                        preTitle={(y + 1).toString()}
                        preTitleWidth="10px"
                        preTitleColor="#3F536E"
                        title={category.name}
                        titleFontSize="12px"
                      >
                        <ExpansionContentCategory>
                          <Questions>
                            {!category?.questions.length && (
                              <h3>Nenhum pergunta criada</h3>
                            )}
                            {category.questions.map((question, z) => (
                              <li key={question.id}>
                                <div className="Title">
                                  <h1>
                                    {defaultAlphabet.numberToString(z + 1)}.
                                  </h1>
                                  <h2>{question.description}</h2>
                                </div>
                              </li>
                            ))}
                          </Questions>
                        </ExpansionContentCategory>
                      </ExpansionPainel>
                    ))}
                  </Categories>
                </ExpansionContentModule>
              </ExpansionPainel>
            ))}
          </Modules>
        </div>
      ) : (
        <div>
          <Spinner />
        </div>
      )}
    </Container>
  );
};

export default TemplateDetails;
