import React, { useCallback, useMemo } from 'react';

import { MdEdit, MdKeyboardArrowRight } from 'react-icons/md';
import {
  useParams,
  useHistory,
  useLocation,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { Container, Projects, CustomerContent, ProjectContent } from './styles';
import Button from '../../../components/Button';
import { ICustomer } from '..';
import CustomerEdit from '../CustomerEdit';

import Badge from '../../../components/Badge';
import { useFetch } from '../../../hooks/fetch';
import Card from '../../../components/Card';
import {
  IProjectPercent,
  getPercent,
} from '../../Projects/Util/ProjectPercent';
import { useAuth } from '../../../hooks/auth';
import LoadingMask from '../../../components/LoadingMask';

const Customer: React.FC = () => {
  const { id: idCustomer } = useParams<{ id: string }>();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { isAdmin } = useAuth();

  const { data: customer } = useFetch<ICustomer>(`/customers/${idCustomer}`);

  const handleEditCustomer = useCallback(() => {
    history.push(`/home/customers/${idCustomer}/edit`);
  }, [history, idCustomer]);

  const percents = useMemo<IProjectPercent[]>(() => {
    if (!customer) {
      return [];
    }
    return customer.scopedProjects.map(project => {
      return getPercent(project);
    });
  }, [customer]);

  if (location.pathname !== `/home/customers/${idCustomer}`) {
    return (
      <Switch>
        <Route path={`${match.path}/edit`} component={CustomerEdit} />
      </Switch>
    );
  }

  return (
    <>
      {!customer ? (
        <LoadingMask text="Carregando cliente" />
      ) : (
        <Container>
          <header>
            <IoIosArrowBack size={24} onClick={() => history.goBack()} />
            <h1>Cliente</h1>
            {isAdmin && (
              <Button
                icon={MdEdit}
                background="#FD7B1F"
                onClick={handleEditCustomer}
              >
                Editar Cliente
              </Button>
            )}
          </header>

          <Card>
            <CustomerContent>
              <h1>{customer.name}</h1>
              <span>{customer.register_number}</span>
              <div className="Location">
                <h3>
                  {customer.address}{' '}
                  {customer.address &&
                    customer.number &&
                    `, ${customer.number}`}{' '}
                  {customer.district && `- ${customer.district}`}
                </h3>
                <h3>
                  {customer.city}{' '}
                  {customer.city && customer.state && `- ${customer.state}`}{' '}
                  {customer.city &&
                    customer.state &&
                    customer.zipcode &&
                    `- ${customer.zipcode}`}
                </h3>
              </div>
            </CustomerContent>
          </Card>

          <Projects>
            <h4>Projetos</h4>
            <ul>
              {customer?.scopedProjects.length ? (
                customer?.scopedProjects.map((project, i) => (
                  <li key={project.id}>
                    <Card>
                      <ProjectContent>
                        <h1>
                          {project.name} - {project.version}
                        </h1>
                        <div className="Right">
                          <h1>{percents[i].percentAssessment} %</h1>

                          <Badge
                            value={project.status}
                            color="#4ECE3D"
                            bordered
                          />

                          <MdKeyboardArrowRight
                            size={28}
                            onClick={() =>
                              history.push(`/home/projects/${project.id}`)
                            }
                          />
                        </div>
                      </ProjectContent>
                    </Card>
                  </li>
                ))
              ) : (
                <h1>Nenhum projeto iniciado</h1>
              )}
            </ul>
          </Projects>
        </Container>
      )}
    </>
  );
};

export default Customer;
