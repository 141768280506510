import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 110px 49px 42px;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 30px 0px;
  padding-left: 10px;

  > svg {
    color: #8dabc4;
    cursor: pointer;

    &:hover {
      color: ${shade('0.4', '#8dabc4')};
    }
  }

  > div {
    display: flex;
    margin-left: 36px;
    > h1 {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8dabc4;
    }

    > h2 {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8dabc4;
      padding-left: 3px;
    }

    > h3 {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8dabc4;
      padding-left: 3px;
    }

    > h4 {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #2c405a;
      padding-left: 3px;
    }
  }
`;

export const ProcessOpinionsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 43px 56px;

  > h1 {
    text-align: left;
    font-size: 20px;
    letter-spacing: 0px;
    color: #3f536e;
  }

  > button.Button {
    width: 200px;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }
`;

export const ProcessOpinionsMain = styled.div`
  padding: 0px 56px 56px 56px;
`;

export const TabsProject = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &.TabsProjectContent {
    border-top: 1px solid #b7d2e5cc;
  }
`;

export const HeaderTabs = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0px 32px 0px 0px;
`;

export const TabHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 65px;
  color: var(--unnamed-color-8dabc4);
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
  position: relative;
  padding-bottom: 8px;

  &.active {
    > h1 {
      text-align: center;
      font: normal normal normal 15px/22px Heebo;
      letter-spacing: 0px;
      color: #2c405a;
    }

    &::before {
      content: '';
      width: 100%;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #0093ee;
    }
  }

  > h1 {
    text-align: center;
    font: normal normal normal 15px/22px Heebo;
    letter-spacing: 0px;
    color: #8dabc4;
  }

  > svg {
    margin-left: 10px;
  }
`;

export const Tab = styled.div`
  display: flex;
  width: 100%;
`;

export const ContentProcessOpinions = styled.div`
  display: flex;
  width: 100%;
  padding: 34px 105px 46px 0px;
`;
