import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px 40px;
`;

export const MainCustomerAssessment = styled.div`
  header {
    background-color: #fafbfc;
    padding-top: 40px;
    padding-left: 60px;
    padding-bottom: 12px;
    border-radius: 8px 8px 0px 0px;
    h1 {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #2c405a;
    }
  }

  main {
    padding: 5px 10px;
    border-bottom: 1px solid #c5dbea;
    overflow-y: auto;
    > div.Line1 {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr 2fr;
      grid-gap: 32px;
    }
    > div.Line2 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line3 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line4 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line5 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line6 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
    > div.Line7 {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 32px;
    }
    > div.Line8 {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px;
      margin-top: 30px;
    }
  }
`;

export const FooterCustomerAssessment = styled.div`
  margin: 30px 0px 30px 0px;
  > form {
    > div.FooterLine {
      width: 80%;

      display: grid;
      align-items: center;
      grid-template-columns: 30% 30% 30% 30%;
      grid-gap: 20px;

      > button {
        margin-top: 22px;
      }
    }

    > div.FormCheckbox {
      margin-left: 20px;
      margin-top: 38px;
    }

    > button {
      width: 200px;
      margin-left: 20px;
      height: 42px;
      margin-top: 32px;
      border-radius: 4px;
      background-color: rgb(78, 206, 61);
      padding-right: 20px;
      padding-left: 20px;
      color: white;
      font-size: 14px;
    }
  }
`;
