import React, { useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { BiCheckShield } from 'react-icons/bi';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import logoImg from '../../assets/images/logo-gray.png';

import Input from '../../components/Input';
import Button from '../../components/Button';

import {
  Container,
  Content,
  AnimationContainer,
  Background,
  Sent,
} from './styles';
import api from '../../services/api';

interface IFirstAccessForData {
  email: string;
}

const FirstAccess: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState<string | null>(null);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: IFirstAccessForData) => {
      setLoading(true);
      setEmail(data.email);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('E-mail é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        api.post<void>(`/password/first`, { email: data.email }).catch(() => {
          addToast({
            type: 'error',
            title: 'Erro na recuperação',
            description:
              'Ocorreu um erro ao tentar recuperar sua senha, tente novamente.',
          });
        });
        setSent(true);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <>
      <Container>
        <Content>
          <AnimationContainer>
            <img src={logoImg} alt="DNA Sec" />
            {sent ? (
              <Sent>
                <BiCheckShield color="4ECE3D" size={24} />
                <h1>
                  Caso seu cadastro já tenha sido efetuado, enviaremos uma
                  mensagem para <span>{email}</span> para definição de sua
                  senha, por favor consulte sua caixa postal.
                </h1>
              </Sent>
            ) : (
              <Form ref={formRef} onSubmit={handleSubmit}>
                <h1>Primeiro acesso</h1>
                <Input name="email" type="email" placeholder="E-mail" />
                <Button
                  type="submit"
                  loading={loading}
                  loadingMsg="Processando ..."
                >
                  Solicitar
                </Button>
              </Form>
            )}
            <p>Acessar o DNASec</p>
            <button
              className="FirstAccess"
              type="button"
              onClick={() => {
                history.push('/');
              }}
            >
              Clique aqui
            </button>
          </AnimationContainer>
        </Content>
        <Background />
      </Container>
    </>
  );
};

export default FirstAccess;
