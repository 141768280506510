import axios from 'axios';
import { getEndpoint } from '../utils/getEndpoint';

const api = axios.create({
  baseURL: getEndpoint(),
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      const requestConfig = error.config;

      if (error.response.data.message === 'Invalid JWT token') {
        localStorage.removeItem('@DNASec:token');
        localStorage.removeItem('@DNASec:user');
        window.location.href = '/';
      }

      return axios(requestConfig);
    }
    return Promise.reject(error);
  },
);

export default api;
