import React, { useRef, useState, useCallback, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Container, Header, Main, Footer } from './styles';
import { FcOk } from 'react-icons/fc';
import { IUser } from '../../SignIn';
import getValidationErrors from '../../../utils/getValidationErrors';
import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { validatePassword } from '../../../utils/validatePassword';
import PasswordRequirements from '../../../components/PasswordRequirements';

interface IFormProps {
  initialData: IUser;
  onSubmit(user: IUser): void;
  onCancel?(): void;
}

interface INewPasswordFormData {
  new_password: string;
}

const FormChangePassword: React.FC<IFormProps> = ({
  initialData,
  onSubmit,
  onCancel,
}) => {
  const form = useRef<FormHandles>(null);
  const [password, setPassword] = useState('');

  const defaultRequirements = {
    minLength: false,
    number: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    noSequential: false,
    noRepetition: false,
  };

  const [requirements, setRequirements] = useState(defaultRequirements);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    if (value === '') {
      setRequirements(defaultRequirements);
    } else {
      const { requirements } = validatePassword(value);
      setRequirements(requirements);
    }
  };

  const handleSubmit = useCallback(
    async ({ new_password }: INewPasswordFormData) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          new_password: Yup.string()
            .required('Campo obrigatório.')
            .test(
              'is-valid-password',
              'A senha não cumpre os requisitos',
              value => {
                if (!value) return false;
                const { isValid } = validatePassword(value);
                return isValid;
              },
            ),
        });

        await schema.validate(
          { new_password },
          {
            abortEarly: false,
          },
        );

        onSubmit({ ...initialData, password: new_password });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [initialData, onSubmit],
  );

  return (
    <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
      <Header>
        <h1>Alterar Senha</h1>
      </Header>
      <Main>
        <h1>{initialData.name}</h1>
        <Input
          label="Nova Senha"
          name="new_password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />
        <PasswordRequirements requirements={requirements} />
      </Main>
      <Footer>
        <Button
          type="submit"
          background="transparent"
          color="grey"
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button type="submit">Salvar</Button>
      </Footer>
    </Container>
  );
};

export default FormChangePassword;
