import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { FiSearch, FiMoreVertical } from 'react-icons/fi';
import { MdAdd, MdEdit, MdDelete } from 'react-icons/md';
import { format } from 'date-fns';
import {
  useHistory,
  Link,
  useRouteMatch,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import arraySort from 'array-sort';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { cache } from 'swr';
import {
  Container,
  Header,
  Pagination,
  ItemPage,
  OptionsContainer,
} from './styles';
import InputOnly from '../../components/InputOnly';
import Button from '../../components/Button';
import Table from '../../components/Table';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import SuspensePainel from '../../components/SuspensePainel';
import IconOrderBy from '../../components/IconOrderBy';
import { useFetch } from '../../hooks/fetch';
import CustomerNew from './CustomerNew';
import CustomerDetails from './CustomerDetails';
import { IProject } from '../Projects';
import { useAuth } from '../../hooks/auth';
import { ICustomerGroup, ICustomerUser } from '../CustomerUsers';
import { IPartner } from '../Partners';

export interface ICustomer {
  id: string | undefined;
  name: string;
  register_number: string;
  address?: string;
  number?: number | undefined;
  complement?: string;
  district?: string;
  zipcode?: string;
  city?: string;
  state?: string;
  scopedProjects: IProject[];
  enabledPartners: IPartner[];
  customerUsers: ICustomerUser[];
  customerGroups: ICustomerGroup[];
  created_at: Date;
}

interface IOrderBy {
  fieldName: string;
  ascendant: boolean;
}

const Customers: React.FC = () => {
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [customersToDisplay, setCustomersToDisplay] = useState<{
    filtered: number;
    customers: ICustomer[];
  }>({ filtered: 0, customers: [] });
  const { addToast } = useToast();
  const history = useHistory();
  const [inputFilter, setInputFilter] = useState('');
  const [orderBy, setOrderBy] = useState<IOrderBy>({
    fieldName: 'name',
    ascendant: false,
  });
  const match = useRouteMatch();
  const location = useLocation();
  const { isAdmin } = useAuth();

  const { data: customers, mutate } = useFetch<ICustomer[]>('/customers');

  const handleRemoveCustomer = useCallback(
    async (id: string | undefined) => {
      const oldCustomers = cache.get('/customers');
      api.delete<ICustomer[]>(`/customers/${id}`).catch(() => {
        mutate(oldCustomers);
        addToast({
          type: 'error',
          title: 'Erro de remoção',
          description: 'Falha para remover cliente.',
        });
      });
      const updatedCustomers = customers?.filter(t => t.id !== id);
      mutate(updatedCustomers, false);
    },
    [addToast, mutate, customers],
  );

  const handleEditCustomer = useCallback(
    async (id: string | undefined) => {
      history.push(`/home/customers/${id}/edit`);
    },
    [history],
  );

  const handleOrderBy = useCallback(
    ({ fieldName, ascendant }: IOrderBy) => {
      setOrderBy({
        fieldName,
        ascendant:
          fieldName === orderBy.fieldName ? !orderBy.ascendant : ascendant,
      });
    },
    [orderBy.ascendant, orderBy.fieldName],
  );

  useEffect(() => {
    if (customers) {
      if (!inputFilter.length) {
        setCustomersToDisplay({ filtered: customers?.length, customers });
      }
      const filtered = customers?.filter(
        customer =>
          customer.name?.toLowerCase().includes(inputFilter.toLowerCase()) ||
          customer.register_number
            ?.toLowerCase()
            .includes(inputFilter.toLowerCase()) ||
          customer.city?.toLowerCase().includes(inputFilter.toLowerCase()) ||
          customer.state?.toLowerCase().includes(inputFilter.toLowerCase()) ||
          customer.scopedProjects?.some(p =>
            p.name.toLowerCase().includes(inputFilter.toLowerCase()),
          ),
      );

      if (filtered) {
        const ordered = arraySort(filtered, orderBy.fieldName, {
          reverse: orderBy.ascendant,
        });

        const paged = ordered.slice(
          (page - 1) * perPage,
          (page - 1) * perPage + perPage,
        );

        if (customers)
          setCustomersToDisplay({
            filtered: filtered.length,
            customers: paged,
          });
      }
    }
  }, [
    inputFilter,
    orderBy.ascendant,
    orderBy.fieldName,
    page,
    customers,
    perPage,
  ]);

  useEffect(() => {
    setPage(1);
  }, [inputFilter]);

  const pages = useMemo(() => {
    if (!customersToDisplay.filtered) return 1;
    return Math.ceil(customersToDisplay.filtered / perPage);
  }, [customersToDisplay.filtered, perPage]);

  const pageNumbers = useMemo(() => {
    const maxDisplayPages = 15;
    const diff = Math.ceil(maxDisplayPages / 2);
    let start = 0;
    if (pages > maxDisplayPages && page > pages - diff) {
      start = pages - maxDisplayPages;
    } else if (pages > maxDisplayPages && page > diff) {
      start = page - diff;
    } else {
      start = 0;
    }
    const limiters = { start, end: start + maxDisplayPages };

    const pns: number[] = [];
    for (let i = 1; i <= pages; i += 1) {
      pns.push(i);
    }
    return pns.slice(limiters.start, limiters.end);
  }, [page, pages]);

  const handleSetPageUp = useCallback(() => {
    if (page < pages) setPage(page + 1);
  }, [page, pages]);

  const handleSetPageDown = useCallback(() => {
    if (page > 1) setPage(page - 1);
  }, [page]);

  if (location.pathname !== '/home/customers') {
    return (
      <Switch>
        <Route path={`${match.path}/new`} component={CustomerNew} />
        <Route path={`${match.path}/:id`} component={CustomerDetails} />
      </Switch>
    );
  }

  return (
    <Container>
      <h1>Clientes</h1>
      <Header>
        <InputOnly
          name="searchProcessStr"
          icon={FiSearch}
          onChange={e => setInputFilter(e.target.value)}
        />
        {isAdmin && (
          <Link to="/home/customers/new">
            <Button icon={MdAdd}>Novo cliente</Button>
          </Link>
        )}
      </Header>
      <Table>
        <thead>
          <tr className="Header">
            <th style={{ width: '35%' }}>
              <div>
                <span>EMPRESA</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'name'}
                  onClick={() =>
                    handleOrderBy({ fieldName: 'name', ascendant: false })
                  }
                />
              </div>
            </th>
            <th style={{ width: '20%' }}>
              {' '}
              <div>
                <span>CNPJ</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'register_number'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'register_number',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>
            <th style={{ width: '12%' }}>
              {' '}
              <div>
                <span>LOCAL</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'city'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'city',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>
            <th style={{ width: '18%' }}>
              {' '}
              <div>
                <span>CADASTRO</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'created_at'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'created_at',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>
            <th style={{ width: '10%' }}>
              {' '}
              <div>
                <span>PROJETOS</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'projects'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'projects',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>
            <th style={{ width: '5%' }} />
          </tr>
        </thead>
        {!customers && (
          <tbody>
            <tr>
              <td colSpan={6}>Carregando ...</td>
            </tr>
          </tbody>
        )}
        {customers && !customers.length && (
          <tbody>
            <tr>
              <td colSpan={6}>Nenhum cliente encontrado</td>
            </tr>
          </tbody>
        )}
        <tbody>
          {customersToDisplay.customers.map(customer => (
            <tr
              key={customer.id}
              onClick={() => history.push(`/home/customers/${customer.id}`)}
            >
              <td>{customer.name}</td>
              <td>{customer.register_number}</td>
              <td>
                {customer.city}{' '}
                {customer.city && customer.state && ` - ${customer.state}`}
              </td>
              <td>{format(new Date(customer.created_at), 'dd/MMM/yyyy')}</td>
              <td style={{ textAlign: 'right', paddingRight: '10px' }}>
                {customer.scopedProjects.length}
              </td>
              <td
                className="Icons"
                style={{
                  paddingRight: '10px',
                }}
              >
                {isAdmin && (
                  <SuspensePainel icon={FiMoreVertical}>
                    <OptionsContainer>
                      <button
                        type="button"
                        onClick={event => {
                          event.stopPropagation();
                          handleEditCustomer(customer.id);
                        }}
                      >
                        <MdEdit size={20} />
                        <h1>Editar Cliente</h1>
                      </button>
                      <button
                        type="button"
                        onClick={event => {
                          event.stopPropagation();
                          handleRemoveCustomer(customer.id);
                        }}
                      >
                        <MdDelete size={20} />
                        <h1>Remover Cliente</h1>
                      </button>
                    </OptionsContainer>
                  </SuspensePainel>
                )}
              </td>
            </tr>
          ))}
          <tr className="Pagination">
            <td colSpan={6}>
              <Pagination>
                <li>
                  <IoMdArrowDropleft
                    size={18}
                    onClick={() => handleSetPageDown()}
                  />
                </li>
                {pageNumbers.map(pg => (
                  <ItemPage key={pg} active={pg === page}>
                    <button type="button" onClick={() => setPage(pg)}>
                      {pg}
                    </button>
                  </ItemPage>
                ))}
                <li>
                  <IoMdArrowDropright
                    size={18}
                    onClick={() => handleSetPageUp()}
                  />
                </li>
              </Pagination>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default Customers;
