import styled, { css } from 'styled-components';
import { shade } from 'polished';
import Tooltip from '../Tooltip';

interface IContentProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  label?: string;
  disabled: boolean;
}

interface IContentOptionsProps {
  showDisplay: boolean;
}

export const Container = styled.div``;

export const Content = styled.div<IContentProps>`
  display: flex;
  background: #fff;
  border-radius: 4px;
  width: 100%;
  position: relative;
  border: 1px solid #a8c6df;
  font-weight: 300;
  color: #3f536e !important;
  align-items: center;
  padding: 10px 15px;
  height: 42px;
  position: relative;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
      padding-right: 35px;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: ${shade(0.2, '#8DABC4')};
      background-color: #ecf5fd;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: ${shade(0.2, '#8DABC4')};
    `}

    ${props =>
    props.disabled &&
    css`
      background: ${shade(0.03, '#FFF')};
    `}

  label {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
      font-size: 15px;
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    select {
      appearance: none;
      border: none;
      background: transparent;
      outline: none;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      border: 0;
      color: #f4ede8;
      background: transparent;
      height: 22px;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */

      ${props =>
        props.label &&
        css`
          margin-left: 16px;
        `}

      &::placeholder {
        color: #666360;
      }
    }

    svg {
      color: #8dabc4;
      margin: 0px;
      font-size: 18px;
    }
  }

  svg {
    margin-right: 10px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  right: 10px;
  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 2px);
  left: calc(0px - 1px);
  width: calc(100% + 2px);
  z-index: 51;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  /* border: 1px solid #a8c6df; */
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
`;

export const ContentOptions = styled.div<IContentOptionsProps>`
  ${props =>
    props.showDisplay
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `};
`;

export const Label = styled.h1`
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #8dabc4;
  margin-bottom: 10px;
`;
