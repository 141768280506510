import React, { useCallback, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { cache, mutate as mutateGlobal } from 'swr';
import { Container } from './styles';
import { useToast } from '../../../hooks/toast';
import { useFetch } from '../../../hooks/fetch';
import api from '../../../services/api';
import { ITemplate } from '..';
import Spinner from '../../../components/Spinner';
import FormTemplate from '../forms/FormTemplate';
import LoadingMask from '../../../components/LoadingMask';

const TemplateEdit: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id: idTemplate } = useParams<{ id: string }>();
  const {
    data: template,
    error,
    mutate,
  } = useFetch(`/templates/${idTemplate}`, {
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    (templateReturned: ITemplate) => {
      const save = async (): Promise<void> => {
        const oldTemplates = cache.get('/templates');
        const oldTemplate = cache.get(`/templates/${idTemplate}`);
        setLoading(true);
        await api
          .patch(`/templates/${idTemplate}`, templateReturned)
          .catch(() => {
            const description =
              'Ocorreu um erro ao salvar os dados do template.';
            mutate(oldTemplate, false);
            mutateGlobal('/templates', oldTemplates, false);
            addToast({
              type: 'error',
              title: 'Erro ao salvar',
              description,
            });
          })
          .finally(() => {
            setLoading(false);
          });
        mutate(
          {
            ...templateReturned,
          },
          false,
        );
        mutateGlobal(
          '/templates',
          async (templates: ITemplate[]) => {
            return templates.map(p =>
              p.id === idTemplate ? templateReturned : p,
            );
          },
          false,
        );
        setLoading(false);
        history.goBack();
      };
      save();
    },
    [addToast, history, idTemplate, mutate],
  );

  if (!template) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (error) {
    addToast({
      type: 'error',
      title: 'Erro de carregamento',
      description: 'Ocorreu um erro ao carregar dados do template.',
    });
  }

  return (
    <Container>
      <h1>Editar Template</h1>
      <FormTemplate initialData={template} onSubmit={handleSubmit} />
      {loading ? <LoadingMask text="Salvando Template" /> : null}
    </Container>
  );
};

export default TemplateEdit;
