import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 95vh;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const Main = styled.main`
  padding: 30px;
  border-top: 1px solid #c5dbea;
  border-bottom: 1px solid #c5dbea;
  > div.Line1 {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-gap: 32px;
  }
  > div.Line2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 30px;

    div.TextArea {
      div {
        height: 90px;
      }
    }
  }
  > div.Line3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 30px;
  }
  > div.Line4 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 30px;

    > div {
      > h1.Title {
        text-align: left;
        font: normal normal normal 15px/22px Heebo;
        letter-spacing: 0px;
        color: #8dabc4;
        opacity: 1;
        margin-top: 25px;
      }
      > div.Checks {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 3fr;
        grid-gap: 32px;
        margin-top: 10px;
      }
    }
  }
  > div.Line5 {
  }
`;

export const Header = styled.header`
  background-color: #fafbfc;
  padding-top: 40px;
  padding-left: 30px;
  padding-bottom: 12px;
  border-radius: 8px 8px 0px 0px;
  h1 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #2c405a;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 30px;
  button.Button {
    width: 160px;

    & + button {
      margin-left: 16px;
    }
  }
`;
