import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 20px 24px 30px 24px;

  div.Title {
    display: flex;
    h1 {
      font-size: 14px;
      font-weight: 500;
      width: 40px;
    }

    h2 {
      font-size: 14px;
      font-weight: 400;
      flex: 1;
    }
  }

  > div.Line1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 20px;
  }

  > div.Line2 {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 32px;
    margin-top: 20px;
  }

  > div.Line3 {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-gap: 58px;
    margin-top: 20px;

    > div.ExecutorField {
      display: flex;

      > h1 {
        text-align: left;
        font-size: 15px;
        letter-spacing: 0px;
        color: #8dabc4;
        font-weight: 400;
        padding-top: 10px;
      }
      > div.Select {
        margin-left: 21px;
        width: 100%;
      }
    }
  }

  & + form {
    border-top: 1px solid #d8e5ee;
  }
`;
