import React from 'react';

import { MdClose } from 'react-icons/md';
import { Container } from './styles';

interface IBadgeProps {
  value: string | number;
  color?: string;
  withClose?: boolean;
  onClose?(): void;
}

const Chip: React.FC<IBadgeProps> = ({ value, withClose, onClose }) => {
  return (
    <Container className="Chip" value={value}>
      {value}
      {withClose && <MdClose onClick={onClose} />}
    </Container>
  );
};
export default Chip;
