import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 8px;
`;

export const HeaderDirectorate = styled.div`
  > h3 {
    font: normal normal normal 14px/19px Heebo;
    letter-spacing: 0px;
    color: #2c405a;
    opacity: 1;
    margin-top: 8px;
  }
`;

export const DepartmentList = styled.div`
  display: flex;
  margin-top: 24px;
  overflow-x: auto;
  padding-bottom: 40px;
`;

export const NewDepartment = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 177px;
  min-height: 280px;
  max-width: 177px;
  max-height: 280px;
  background: #fafbfc 0% 0% no-repeat padding-box;
  border: 1px dashed #0079c4;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${shade(0.03, '#FFF')};
  }

  > h1 {
    text-align: center;
    font-size: 16px;
    letter-spacing: 0px;
    color: #8dabc4;
    opacity: 1;
  }

  > svg {
    color: #8dabc4;
  }
`;

export const Department = styled.div``;

export const WrapperName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > h1 {
    text-align: left;
    font: normal normal bold 20px/29px Heebo;
    letter-spacing: 0px;
    color: #2c405a;
    opacity: 1;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    border-radius: 50%;
    background-color: #fafbfc;

    cursor: pointer;

    &:hover {
      background-color: ${shade('0.1', '#fafbfc')};
    }

    > svg {
      font-size: 18px;
      color: #8dabc4;
      margin: 6px;
    }
  }
`;
