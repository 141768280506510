import React, { useState, useRef, useCallback, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { FaFilter } from 'react-icons/fa';
import { VscGraph } from 'react-icons/vsc';
import Button from '../../components/Button';
import Card from '../../components/Card';
import ExpansionPainel from '../../components/ExpansionPainel';
import InputOnly from '../../components/InputOnly';
import { ExpansionContent } from '../Customers/CustomerNew/styles';
import ChartBubble from '../../components/ChartBubble';

import MaturityCharts from './components/CustomerAssessmentMaturityCharts';

import {
  Container,
  Main,
  Header,
  CardGraphics,
  CustomerAssessmentContent,
  WrapperFilter,
  WrapperHeader,
  WrapperCustomerAssessment,
} from './styles';

import { useFetch } from '../../hooks/fetch';
import LoadingMask from '../../components/LoadingMask';

import { IOpinion, IRiskType } from '../Opinions';

import CustomerAssessmentTitleComponent from './components/CustomerAssessmentTitleComponent';
import CustomerAssessmentBarComponent from './components/CustomerAssessmentBarComponent';
import CustomerAssessment from './components/CustomerAssessment';
import Modal, { IModalHandles } from '../../components/Modal';
import SideModal, { ISideModalHandles } from '../../components/SideModal';

import FormAssessmentClientFilter from './forms/FormAssessmentClientFilter';
import { IProjectQuestion, IProjectModule } from '../Projects';

import { ICustomerUser } from '../CustomerUsers';
import { ICustomer } from '../Customers';
import { IPartnerUser, IPartner } from '../Partners';

export interface IProject {
  id: string;
  customer_id: string;
  template_id: string;
  name: string;
  type: string;
  version: string;
  status: 'Em Andamento' | 'Finalizado';
  scopedModules: IProjectModule[];
  interviews: IProjectInterview[];
  created_at: Date;
  customer: ICustomer;
  percentAssessment?: number;
  percentRecommendation?: number;
  cost?: number;
}

export interface IProjectInterview {
  id: string;
  project_id: string;
  project_module_id: string;
  partner_id: string;
  partner?: IPartner;
  partner_user_id?: string | undefined;
  partnerUser?: IPartnerUser;
  customer_user_id?: string | undefined;
  customerUser?: ICustomerUser;
  module?: IProjectModule;
  date: Date | undefined;
  done?: boolean;
  done_date?: Date;
}

export interface IProjectRecommendationTypes {
  id: string;
  name: string;
}

interface ITolerancyGrade {
  MA_MB: number;
  MA_B: number;
  MA_M: number;
  MA_A: number;
  MA_MA: number;
  A_MB: number;
  A_B: number;
  A_M: number;
  A_A: number;
  A_MA: number;
  M_MB: number;
  M_B: number;
  M_M: number;
  M_A: number;
  M_MA: number;
  B_MB: number;
  B_B: number;
  B_M: number;
  B_A: number;
  B_MA: number;
  MB_MB: number;
  MB_B: number;
  MB_M: number;
  MB_A: number;
  MB_MA: number;
}

export interface IResponseCustomerAssessment {
  questions: IProjectQuestion[];
  tolerancy_grade: ITolerancyGrade;
}

export interface IResponseCustomerOpinion {
  data: IOpinion[];
  tolerancy_grade: ITolerancyGrade;
}

export interface IAssessmentFilter {
  risks: IRiskType[];
  projectId: string[];
  moduleId: string[];
  category: string[];
  status: string[];
}

const AssessmentClient: React.FC = () => {
  const [inputFilter, setInputFilter] = useState('');
  const modalAssessment = useRef<IModalHandles>();
  const modalFilter = useRef<ISideModalHandles>();
  const [filter, setFilter] = useState<IAssessmentFilter>();

  const [assessmentQuestionsToDisplay, setAssessmentQuestionsToDisplay] =
    useState<IProjectQuestion[]>();

  const { data: projectQuestions } = useFetch<IResponseCustomerAssessment>(
    `/customerAssessmentQuestions`,
    { revalidateOnFocus: false },
  );

  const { data: customerUsers } = useFetch<ICustomerUser[]>('/customerUsers');

  const handleOpenMaturityCharts = useCallback(() => {
    modalAssessment.current?.open();
  }, []);

  const handleSubmitFormAssessmentFilter = useCallback(newAssessmentFilter => {
    setFilter(newAssessmentFilter);
    modalFilter.current?.close();
  }, []);

  const handleClearFilter = useCallback(() => {
    if (projectQuestions) {
      setFilter({
        risks: [5, 4, 3, 2, 1],
        projectId: [],
        moduleId: [],
        category: [],
        status: ['Pendente', 'Em Andamento', 'Finalizado'],
      });
      modalFilter.current?.close();
    }
  }, [projectQuestions]);

  useEffect(() => {
    if (projectQuestions) {
      setFilter({
        risks: [5, 4, 3, 2, 1],
        projectId: [],
        moduleId: [],
        category: [],
        status: ['Pendente', 'Em Andamento', 'Finalizado'],
      });
    }
  }, [projectQuestions]);

  useEffect(() => {
    if (projectQuestions) {
      if (!filter) {
        return;
      }
      setAssessmentQuestionsToDisplay(
        projectQuestions.questions
          .filter(p => {
            if (p.risk) {
              if (p.risk <= 3 && filter?.risks.some(r => r === 1)) {
                return true;
              }
              if (
                p.risk >= 4 &&
                p.risk <= 7 &&
                filter?.risks.some(r => r === 2)
              ) {
                return true;
              }
              if (
                p.risk >= 8 &&
                p.risk <= 14 &&
                filter?.risks.some(r => r === 3)
              ) {
                return true;
              }
              if (
                p.risk >= 15 &&
                p.risk <= 20 &&
                filter?.risks.some(r => r === 4)
              ) {
                return true;
              }
              if (
                p.risk >= 21 &&
                p.risk <= 25 &&
                filter?.risks.some(r => r === 5)
              ) {
                return true;
              }
            }
            return false;
          })
          .filter(m => {
            if (filter.projectId.length > 0) {
              const projectId = JSON.stringify(m.project?.id);
              const projectFilter = JSON.stringify(filter.projectId);

              if (projectFilter.indexOf(projectId) !== -1) {
                return true;
              }
              return false;
            }
            return true;
          })
          .filter(m => {
            if (filter.moduleId.length > 0) {
              const moduleId = JSON.stringify(
                `${m.project_module?.id}-${m.project_id}`,
              );
              const moduleFilter = JSON.stringify(filter.moduleId);
              if (moduleFilter.indexOf(moduleId) !== -1) {
                return true;
              }
              return false;
            }
            return true;
          })
          .filter(c => {
            if (filter.category.length > 0) {
              const category = JSON.stringify(
                `${c.project_category?.id}-${c.project_id}`,
              );
              const categoryFilter = JSON.stringify(filter.category);

              if (categoryFilter.indexOf(category) !== -1) {
                return true;
              }
              return false;
            }
            return true;
          })
          .filter(c => {
            if (filter.status) {
              if (filter.status.some(r => r === c.status)) {
                return true;
              }
            }
            return false;
          })
          .filter(p => {
            if (inputFilter) {
              const normalizedInput = inputFilter
                .toLowerCase()
                .normalize('NFKD')
                .replace(/^\s+|\p{M}/gu, '');
              return (
                p.project_module?.name
                  .toLowerCase()
                  .normalize('NFKD')
                  .replace(/^\s+|\p{M}/gu, '')
                  .includes(normalizedInput) ||
                p.project_category?.name
                  .toLowerCase()
                  .normalize('NFKD')
                  .replace(/^\s+|\p{M}/gu, '')
                  .includes(normalizedInput) ||
                p.question
                  .toLowerCase()
                  .normalize('NFKD')
                  .replace(/^\s+|\p{M}/gu, '')
                  .includes(normalizedInput)
              );
            }
            return true;
          }),
      );
    }
  }, [projectQuestions, filter, inputFilter]);

  if (!projectQuestions) {
    return <LoadingMask text="Carregando..." />;
  }

  return (
    <>
      <Container>
        <Header>
          <h1>DNASec - Assessment</h1>

          <div className="button">
            <Button
              icon={VscGraph}
              background="#0079C4"
              color="#FFF"
              onClick={handleOpenMaturityCharts}
            >
              Gráficos de maturidade
            </Button>
          </div>
        </Header>

        <Main>
          <CardGraphics>
            <div className="cardGraphics">
              <h4>GRADE DE TOLERÂNCIA A RISCOS - IMPACTO x PRIORIDADE</h4>

              <ChartBubble
                labels={['']}
                scores={projectQuestions.tolerancy_grade}
              />
            </div>
          </CardGraphics>
          <Card>
            <CustomerAssessmentContent>
              <h1>PLANOS DE RECOMENDAÇÃO</h1>
              <WrapperHeader>
                <WrapperFilter>
                  <InputOnly
                    name="search"
                    icon={FiSearch}
                    onChange={e => setInputFilter(e.target.value)}
                  />
                  <Button
                    background="#a8c6df"
                    icon={FaFilter}
                    color="#FFF"
                    onClick={() => modalFilter.current?.open()}
                  />
                </WrapperFilter>
              </WrapperHeader>
              <WrapperCustomerAssessment>
                {assessmentQuestionsToDisplay?.length ? (
                  assessmentQuestionsToDisplay?.map(projectQuestion => (
                    <ExpansionPainel
                      key={projectQuestion.id}
                      titleComponent={() => (
                        <CustomerAssessmentTitleComponent
                          risk={projectQuestion.risk}
                          project_module={projectQuestion.project_module?.name}
                          project_category={
                            projectQuestion.project_category?.name
                          }
                          question={projectQuestion.question}
                        />
                      )}
                      barComponent={() => (
                        <CustomerAssessmentBarComponent
                          assessmentStatus={projectQuestion.status}
                          assessmentDate={projectQuestion.deadline}
                        />
                      )}
                    >
                      <ExpansionContent>
                        <CustomerAssessment
                          projectQuestion={projectQuestion}
                          customerUsers={customerUsers}
                        />
                      </ExpansionContent>
                    </ExpansionPainel>
                  ))
                ) : (
                  <h4>Nenhum plano de recomendação encontrado.</h4>
                )}
              </WrapperCustomerAssessment>
            </CustomerAssessmentContent>
          </Card>
        </Main>
      </Container>
      <Modal ref={modalAssessment} size="fs">
        <MaturityCharts onCancel={() => modalAssessment.current?.close()} />
      </Modal>
      <SideModal ref={modalFilter} title="FILTROS">
        <FormAssessmentClientFilter
          projectQuestions={projectQuestions.questions}
          initialData={filter}
          onSubmit={handleSubmitFormAssessmentFilter}
          onClear={handleClearFilter}
        />
      </SideModal>
    </>
  );
};

export default AssessmentClient;
