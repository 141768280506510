import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)``;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
  padding: 20px 30px 20px 30px;
  min-height: 60px;
  max-height: 60px;

  > h1 {
    text-align: left;
    font-size: 14px;
    letter-spacing: 2.16px;
    color: #3f536e;
    opacity: 1;
  }
`;

export const Main = styled.main`
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  overflow-y: auto;
  min-height: calc(100vh - 160px);
  max-height: calc(100vh - 160px);

  div.Select {
    width: 360px;
    span {
      width: 300px;
    }
  }

  > div.Line1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 40px;
  }

  > div.Line2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 20px;
  }

  > div.Line3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 200px;
    margin-top: 20px;
  }

  > div.Line4 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 20px;
  }

  > div.Line5 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 20px;
  }

  > div.Line6 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 20px;
  }
`;

export const Footer = styled.footer`
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 100px;
  max-height: 100px;

  button.Button {
    width: 200px;
    & + button {
      margin-left: 10px;
    }
  }
`;
