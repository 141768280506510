import React from 'react';
import { MdInfo } from 'react-icons/md';
import { ICustomerProcess } from '../../../Process';
import ProcessDisplayCard, { BadgesName } from '../ProcessDisplayCard';
import { Container, Wrapper } from './styles';

interface ICardStageProps {
  customer_process: ICustomerProcess | undefined;
  name: string;
  percent_filled: number;
  badges?: BadgesName[];
  alert?: boolean;
}

const CardStage: React.FC<ICardStageProps> = ({
  customer_process,
  name,
  percent_filled,
  badges,
  alert,
}) => {
  return (
    <Container>
      <Wrapper>
        <h1>{name}</h1>
        {alert && <MdInfo size={14} color="red" />}
      </Wrapper>
      <ProcessDisplayCard
        customer_process={customer_process}
        percent_filled={percent_filled}
        badges={badges}
      />
    </Container>
  );
};

export default CardStage;
