import React, { useRef, useCallback, useMemo } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { toRoman } from 'roman-numerals';
import { NumberToAlphabet } from 'number-to-alphabet';
import { Container, Header, Main, Footer } from './styles';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import {
  IProjectQuestion,
  IProjectModule,
  IProjectCategory,
  IProjectQuestionRecommentationTypes,
} from '../../..';
import TextArea from '../../../../../components/TextArea';
import Input from '../../../../../components/Input';
import Button from '../../../../../components/Button';
import DisplayField from '../../../../../components/DisplayField';
import InputPriority from '../../../../../components/InputPriority';
import Select from '../../../../../components/Select';
import Option from '../../../../../components/Select/Option';

const defaultAlphabet = new NumberToAlphabet();

interface IFormProps {
  initialData: IProjectQuestion;
  questionModule: IProjectModule;
  questionCategory: IProjectCategory;
  recommendationTypes: IProjectQuestionRecommentationTypes[];
  onSubmit(newQuestion: IProjectQuestion): void;

  onCancel?(): void;
}

interface IFormData {
  recommendation_type: IProjectQuestionRecommentationTypes;
  recommendation_type_id: string;
  recommendation: string;
  motivation: string;
  consulting_hours: number;
  recommendation_deadline: number;
  impact: number;
  priority: number;
}

const FormRecommendation: React.FC<IFormProps> = ({
  initialData,
  questionModule,
  questionCategory,
  recommendationTypes,
  onSubmit,
  onCancel,
}) => {
  const form = useRef<FormHandles>(null);

  const recommendationTypeFiltered = useMemo(() => {
    return recommendationTypes?.filter(t => t.id);
  }, [recommendationTypes]);

  const handleSubmit = useCallback(async () => {
    const {
      recommendation_type_id,
      recommendation,
      motivation,
      consulting_hours,
      recommendation_deadline,
      impact,
      priority,
    } = form.current?.getData() as IFormData;
    try {
      form.current?.setErrors({});
      const schema = Yup.object().shape({
        recommendation: Yup.string().required('Recomendação é obrigatória'),
        motivation: Yup.string().required('Motivação é obrigatória'),
        consulting_hours: Yup.string().required(
          'Horas de consultoria é obrigatório',
        ),
        recommendation_deadline: Yup.string().required(
          'Prazo recomendado é obrigatório',
        ),
        impact: Yup.string().required('Impacto é obrigatório'),
        priority: Yup.string().required('Prioridade é obrigatório'),
        recommendation_type_id: Yup.string().required(
          'Tipo de recomendação é obrigatório',
        ),
      });

      await schema.validate(
        {
          recommendation_type_id,
          recommendation,
          motivation,
          consulting_hours,
          recommendation_deadline,
          impact,
          priority,
        },
        {
          abortEarly: false,
        },
      );

      const newQuestion: IProjectQuestion = {
        ...initialData,
        recommendation_type_id,
        recommendation,
        motivation,
        consulting_hours,
        recommendation_deadline,
        impact,
        priority,
      };
      onSubmit(newQuestion);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        form.current?.setErrors(errors);
      }
    }
  }, [initialData, onSubmit]);

  return (
    <Container ref={form} initialData={initialData} onSubmit={handleSubmit}>
      <Header>
        <h1>Plano de recomendação</h1>
      </Header>
      <Main>
        <div className="Line1">
          <DisplayField label="Item">
            {`${toRoman(questionModule.position + 1)}.${
              questionCategory.position + 1
            }.${defaultAlphabet.numberToString(initialData.position + 1)}`}
          </DisplayField>
          <DisplayField label="Data">
            {questionModule.interview?.done_date &&
              format(
                new Date(questionModule.interview?.done_date),
                'dd/MMM/yyyy HH:mm',
              )}
          </DisplayField>
          <DisplayField label="Entrevistado">
            {questionModule.interview?.customerUser?.user.name}
          </DisplayField>
        </div>
        <div className="Line2">
          <DisplayField label="Nota">
            {initialData.score || initialData.score === 0
              ? initialData.score
              : 'N/A'}
          </DisplayField>
          <DisplayField label="Requerida">
            {initialData.score_required || initialData.score_required === 0
              ? initialData.score_required
              : 'N/A'}
          </DisplayField>
          <Select name="recommendation_type_id" label="Tipo de recomendação">
            {recommendationTypeFiltered &&
              recommendationTypeFiltered.map(t => (
                <Option key={t.id} label={`${t.name}`} value={t.id}>
                  {t.name}
                </Option>
              ))}
          </Select>
        </div>

        <div className="Line3">
          <DisplayField label="Pergunta">{initialData.question}</DisplayField>
        </div>
        <div className="Line4">
          <DisplayField label="Argumento coletado">
            {initialData.answer}
          </DisplayField>
        </div>
        <div className="Line5">
          <TextArea label="Recomendação" name="recommendation" />
        </div>
        <div className="Line6">
          <TextArea label="Ação/crítica/motivação" name="motivation" />
        </div>
        <div className="Line7">
          <Input label="Horas de consultoria" name="consulting_hours" />
          <Input
            label="Prazo recomendado (Meses)"
            name="recommendation_deadline"
            type="number"
          />
        </div>
        <div className="Line8">
          <InputPriority label="Impacto" name="impact" />
        </div>
        <div className="Line9">
          <InputPriority label="Prioridade" name="priority" />
        </div>
      </Main>
      <Footer>
        <Button
          type="button"
          background="transparent"
          color="grey"
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button type="submit">Salvar</Button>
      </Footer>
    </Container>
  );
};

export default FormRecommendation;
