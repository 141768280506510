import React, { useMemo } from 'react';

import { Container } from './styles';

interface ICustomerOpinionTitleComponentProps {
  label?: string;
  directorate?: string;
  department?: string;
  process: string;
}

const CustomerOpinionTitleComponent: React.FC<ICustomerOpinionTitleComponentProps> =
  ({ label, directorate, department, process }) => {
    const [background, color] = useMemo((): [string, string] => {
      if (label === 'Muito Baixo') {
        return ['#82ff70', '#72b36a'];
      }
      if (label === 'Baixo') {
        return ['#cefcc8', '#40b630'];
      }
      if (label === 'Médio') {
        return ['#fef5d1', '#ffbc00'];
      }
      if (label === 'Alto') {
        return ['#fcd8bf', '#fd7b1f'];
      }
      if (label === 'Muito Alto') {
        return ['#ffafb2', '#c40911'];
      }
      return ['#FFFFFF', '#000000'];
    }, [label]);

    return (
      <Container color={color} backgroundColor={background}>
        <div>
          <h1>{label}</h1>
        </div>
        <section>
          <h1>{directorate} /</h1>
          <h2>{department} /</h2>
          <h3>{process}</h3>
        </section>
      </Container>
    );
  };

export default CustomerOpinionTitleComponent;
