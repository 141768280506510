import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > h1 {
    color: var(--unnamed-color-3f536e);
    text-align: left;
    font-size: 13px;
    letter-spacing: 0px;
    font-weight: 400;
  }
`;

export const Modules = styled.div`
  > div + div {
    margin-top: 20px;
  }

  > h1 {
    color: var(--unnamed-color-3f536e);
    text-align: left;
    font-size: 13px;
    letter-spacing: 0px;
    font-weight: 400;
  }

  > div.HeaderCost {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    > h1 {
      color: var(--unnamed-color-3f536e);
      text-align: left;
      font-size: 15px;
      letter-spacing: 0px;
      font-weight: 300;
    }
    > h2 {
      min-width: 120px;
      color: var(--unnamed-color-3f536e);
      text-align: right;
      letter-spacing: 0px;
      margin-left: 25px;
      font-weight: 500;
      font-size: 15px;
    }
  }
`;

export const Categories = styled.div`
  > div + div {
    margin-top: 10px;
  }
  > h3 {
    font-size: 14px;
    padding: 16px 24px;
    font-weight: 400;
  }
`;

export const Questions = styled.ul`
  list-style-type: none;

  > h3 {
    font-size: 14px;
    padding: 16px 24px;
    font-weight: 400;
  }
`;

export const ExpansionContentModule = styled.div`
  padding: 16px;
`;

export const ExpansionContentCategory = styled.div``;

export const Header = styled.header`
  background-color: #fafbfc;
  padding-top: 40px;
  padding-left: 60px;
  padding-bottom: 12px;
  border-radius: 8px 8px 0px 0px;
  h1 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #2c405a;
  }
`;
