import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  SelectHTMLAttributes,
  createContext,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { IoMdArrowDropdown } from 'react-icons/io';
import {
  Container,
  Content,
  Error,
  Options,
  Backdrop,
  ContentOptions,
  Label,
} from './styles';
import Spinner from '../Spinner';

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  icon?: React.ComponentType<IconBaseProps>;
  multiSelection?: boolean;
  onChange?(value: any): void;
  value?: any;
  disabled?: boolean;
  loading?: boolean;
}

interface IOption {
  value: any;
  label: string;
  withCheckbox?: boolean;
}

interface ISelectContextData {
  selection: any | any[];
  registerOption(data: IOption): void;
  unregisterOption(data: IOption): void;
  selectOption(value: any): void;
}

export const SelectContext = createContext<ISelectContextData>(
  {} as ISelectContextData,
);

const Select: React.FC<ISelectProps> = ({
  children,
  name,
  label: TopLabel,
  icon: Icon,
  multiSelection: MultiSelection,
  value: Value,
  disabled,
  onChange,
  loading,
  ...rest
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setisFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [selection, setSelection] = useState<any | any[]>();
  const [multiSelection, setMultiSelection] = useState(MultiSelection);

  const handleClick = useCallback(() => {
    if (!disabled) {
      setIsFocused(true);
      setShowOptions(true);
    }
  }, [disabled]);

  const registerOption = useCallback(
    ({ value, label, withCheckbox }: IOption) => {
      if (withCheckbox) setMultiSelection(true);
      setOptions(oldOptions => [...oldOptions, { value, label }]);
    },
    [],
  );

  const unregisterOption = useCallback(
    ({ value, label, withCheckbox }: IOption) => {
      if (withCheckbox) setMultiSelection(true);
      setOptions(oldOptions =>
        oldOptions.filter(o => o.label === label && o.value === value),
      );
    },
    [],
  );

  useEffect(() => {
    if (!defaultValue && multiSelection) {
      setSelection([]);
    } else {
      setSelection(defaultValue);
    }
  }, [defaultValue, multiSelection, options]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue() {
        return selection || undefined;
      },
    });
  }, [fieldName, registerField, selection, options]);

  useEffect(() => {
    if (Value !== undefined) setSelection(Value);
  }, [Value, options, name]);

  const selectOption = useCallback(
    (value: any) => {
      onChange && onChange(value);
      if (multiSelection) {
        if (!selection.includes(value)) {
          setSelection([...selection, value]);
        } else {
          setSelection(selection.filter((s: any | any[]) => s !== value));
        }
      } else {
        setSelection(value);
        setIsFocused(false);
        setisFilled(!!selectRef.current?.value);
        setShowOptions(false);
      }
    },
    [multiSelection, onChange, selection],
  );

  const getLabel = useCallback(() => {
    if (multiSelection && selection) {
      return options
        .filter(o => (selection as string[]).includes(o.label))
        .map(o => o.label)
        .join(', ');
    }
    return options.find(o => o.value === selection)?.label;
  }, [multiSelection, options, selection]);

  return (
    <SelectContext.Provider
      value={{ selectOption, registerOption, unregisterOption, selection }}
    >
      <Container className="Select">
        {TopLabel && <Label>{TopLabel}</Label>}
        <Content
          isErrored={!!error}
          isFilled={isFilled}
          isFocused={isFocused}
          label={Label}
          onClick={handleClick}
          className="Select"
          disabled={disabled || false}
        >
          {Icon && <Icon size={20} />}
          <label htmlFor={name}>
            <span>{getLabel()}</span>
            <select
              id={name}
              defaultValue={defaultValue}
              ref={selectRef}
              value={selection || undefined}
              {...rest}
            />
            {loading ? <Spinner size={14} /> : <IoMdArrowDropdown />}
          </label>
          <ContentOptions showDisplay={showOptions}>
            <Backdrop
              onClick={event => {
                event.stopPropagation();
                setIsFocused(false);
                setisFilled(!!selectRef.current?.value);
                setShowOptions(false);
              }}
            />
            <Options>{children}</Options>
          </ContentOptions>
          {error && (
            <Error title={error}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )}
        </Content>
      </Container>
    </SelectContext.Provider>
  );
};

export default Select;
