import React, { useRef, useCallback, useState } from 'react';
import { MdSettings, MdNotifications } from 'react-icons/md';
import { FiLogOut } from 'react-icons/fi';
import { FaKey } from 'react-icons/fa';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import logoImg from '../../assets/images/logo-header.svg';

import {
  Container,
  Logo,
  UserMenu,
  OptionsContainer,
  ModalHeader,
  ModalMain,
  ModalFooter,
} from './styles';
import { useAuth } from '../../hooks/auth';
import SuspensePainel from '../SuspensePainel';
import Modal, { IModalHandles } from '../Modal';
import Input from '../Input';
import Button from '../Button';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

import { validatePassword } from '../../utils/validatePassword';
import PasswordRequirements from '../PasswordRequirements';

interface IPasswordFormData {
  old_password: string;
  password: string;
  password_confirmation: string;
}

const Header: React.FC = () => {
  const {
    isAdmin,
    isPartnerConsultant,
    isCustomerDPO,
    isCustomerDPL,
    isCustomerResponsableDirectorate,
    isCustomerResponsableDepartment,
    isCustomerResponsableProcess,
  } = useAuth();
  const { signOut } = useAuth();
  const form = useRef<FormHandles>(null);
  const modalPassword = useRef<IModalHandles>();
  const user: { name: string } | undefined = JSON.parse(
    localStorage.getItem('@DNASec:user') as string,
  );
  const [password, setPassword] = useState('');
  const { addToast } = useToast();

  const defaultRequirements = {
    minLength: false,
    number: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    noSequential: false,
    noRepetition: false,
  };

  const [requirements, setRequirements] = useState(defaultRequirements);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    if (value === '') {
      setRequirements(defaultRequirements);
    } else {
      const { requirements } = validatePassword(value);
      setRequirements(requirements);
    }
  };

  const handleSubmitChangePassword = useCallback(
    async ({
      old_password,
      password,
      password_confirmation,
    }: IPasswordFormData) => {
      try {
        form.current?.setErrors({});

        const schema = Yup.object().shape({
          old_password: Yup.string().required('Senha atual é obrigatória'),
          password: Yup.string()
            .required('Nova senha é obrigatória')
            .test(
              'is-valid-password',
              'A senha não cumpre os requisitos',
              value => {
                if (!value) return false;
                const { isValid } = validatePassword(value);
                return isValid;
              },
            ),
          password_confirmation: Yup.string()
            .required('Confirmação de senha obrigatória')
            .oneOf(
              [Yup.ref('password'), null],
              'As senhas precisam ser iguais',
            ),
        });

        await schema.validate(
          { old_password, password, password_confirmation },
          {
            abortEarly: false,
          },
        );

        await api
          .put('/profile', {
            old_password,
            password,
            password_confirmation,
          })
          .then(() => {
            addToast({
              type: 'success',
              title: 'Ok!',
              description: 'Senha alterada com sucesso',
            });
          })
          .catch(err => {
            const { message } = err.response.data;
            let description = 'Ocorreu um erro ao alterar senha';
            if (message === 'Old password does not match') {
              description = 'Senha atual invalida';
            }
            addToast({
              type: 'error',
              title: 'Erro ao mudar senha',
              description,
            });
          })
          .finally(() => {
            modalPassword.current?.close();
          });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [addToast],
  );

  return (
    <>
      <Container>
        <Logo>
          <div>
            <img src={logoImg} alt="DNA Sec" />
          </div>
          {isAdmin && <h1>{user?.name} - Administrador</h1>}
          {isPartnerConsultant && <h1>{user?.name} - Parceiro DNA Sec</h1>}
          {(isCustomerDPO ||
            isCustomerDPL ||
            isCustomerResponsableDirectorate ||
            isCustomerResponsableDepartment ||
            isCustomerResponsableProcess) && (
            <h1>{user?.name} - Cliente DNASec</h1>
          )}
        </Logo>
        <UserMenu>
          <SuspensePainel icon={MdNotifications}>
            <OptionsContainer>
              <ul>
                {/* <li>
                <h1>Novo parecer recebido</h1>
                <p>10/Jan/2021 18:30</p>
              </li> */}
              </ul>
            </OptionsContainer>
          </SuspensePainel>
          <SuspensePainel icon={MdSettings}>
            <OptionsContainer>
              <button
                type="button"
                onClick={() => modalPassword.current?.open()}
              >
                <FaKey size={16} />
                <h1>Alterar Senha</h1>
              </button>
            </OptionsContainer>
            <OptionsContainer>
              <button type="button" onClick={signOut}>
                <FiLogOut size={20} />
                <h1>Sair</h1>
              </button>
            </OptionsContainer>
          </SuspensePainel>
        </UserMenu>
      </Container>
      <Modal ref={modalPassword} size="sm">
        <Form ref={form} onSubmit={handleSubmitChangePassword}>
          <ModalHeader>
            <h1>Alterar Senha</h1>
          </ModalHeader>
          <ModalMain>
            <Input label="Senha Atual" name="old_password" type="password" />
            <Input
              label="Nova Senha"
              name="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
            <PasswordRequirements requirements={requirements} />
            <Input
              label="Confirmar Nova Senha"
              name="password_confirmation"
              type="password"
            />
          </ModalMain>
          <ModalFooter>
            <Button
              background="transparent"
              color="grey"
              onClick={() => modalPassword.current?.close()}
            >
              Cancelar
            </Button>
            <Button type="submit">Alterar</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default Header;
