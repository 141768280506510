import styled, { css } from 'styled-components';

interface IProgressBarProps {
  percent: number | undefined;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  > div {
    margin-left: 14px;
    width: 150px;
    height: 12px;
    background-color: #ecf5fd;
    border-radius: 18px;
  }

  > h1 {
    text-align: center;
    font-size: 13px;
    letter-spacing: 0px;
    color: #0079c4;
  }
`;

export const ProgressBar = styled.div<IProgressBarProps>`
  ${props =>
    css`
      width: ${props.percent || 0}%;
    `}
  height: 12px;
  background: #0079c4 0% 0% no-repeat padding-box;
  border-radius: 18px;
`;
