import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { v4 } from 'uuid';
import { Container, Header, Main, Footer } from './styles';
import { IPartnerUser } from '../..';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import TextArea from '../../../../components/TextArea';
import InputChips from '../../../../components/InputChips';
import FormCheckbox from '../../../../components/FormCheckbox';
import { validatePassword } from '../../../../utils/validatePassword';
import PasswordRequirements from '../../../../components/PasswordRequirements';

interface IFormProps {
  initialData: IPartnerUser;
  onSubmit(partnerUser: IPartnerUser): void;
  onCancel?(): void;
}

const FormPartnerUser: React.FC<IFormProps> = ({
  initialData,
  onSubmit,
  onCancel,
}) => {
  const form = useRef<FormHandles>(null);

  const [password, setPassword] = useState('');
  const defaultRequirements = {
    minLength: false,
    number: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    noSequential: false,
    noRepetition: false,
  };

  const [requirements, setRequirements] = useState(defaultRequirements);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    if (value === '') {
      setRequirements(defaultRequirements);
    } else {
      const { requirements } = validatePassword(value);
      setRequirements(requirements);
    }
  };

  const handleSubmit = useCallback(
    async ({
      resume,
      specialty,
      telephone,
      user,
      can_receive_opinion_busines,
      can_receive_opinion_juridical,
      can_receive_opinion_compliance,
      can_receive_opinion_security,
    }: IPartnerUser) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          user: Yup.object().shape({
            name: Yup.string().required('Nome é obrigatório'),
            email: Yup.string()
              .email('Formato de e-mail inválido')
              .required('E-mail é obrigatório'),
            password: Yup.string()
              .required('Senha é obrigatório')
              .test(
                'is-valid-password',
                'A senha não cumpre os requisitos',
                value => {
                  if (!value) return false;
                  const { isValid } = validatePassword(value);
                  return isValid;
                },
              ),
          }),
        });
        if (!initialData.user.name) {
          await schema.validate(
            { user },
            {
              abortEarly: false,
            },
          );
        }

        const newPartnerUser: IPartnerUser = {
          id: initialData.id,
          resume,
          specialty,
          telephone,
          user: {
            ...initialData.user,
            id: v4(),
            name: user.name,
            email: user.email,
            password: user.password,
          },
          can_receive_opinion_busines,
          can_receive_opinion_juridical,
          can_receive_opinion_compliance,
          can_receive_opinion_security,
        };
        onSubmit(newPartnerUser);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [initialData.id, initialData.user, onSubmit],
  );

  return (
    <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
      <Header>
        <h1>{!initialData.user.name ? 'Novo Usuário' : 'Editar Usuário'}</h1>
      </Header>
      <Main>
        <div className="Line1">
          <Input
            label="Nome *"
            name="user.name"
            type="text"
            disabled={!!initialData.user.name}
          />
          <Input label="Telefone" name="telephone" type="text" />
        </div>
        <div className="Line2">
          <TextArea label="Mini currículo" name="resume" rows={3} />
        </div>
        <div className="Line3">
          <InputChips label="Especialidade" name="specialty" type="text" />
        </div>
        {!initialData.user.name && (
          <div className="Line4">
            <Input label="E-mail *" name="user.email" type="text" />
            <div>
              <Input
                label="Senha *"
                name="user.password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
              <PasswordRequirements requirements={requirements} />
              <h1 className="Title">Pareceres</h1>
              <div className="Checks">
                <FormCheckbox
                  name="can_receive_opinion_busines"
                  label="Negócioss"
                />
                <FormCheckbox
                  name="can_receive_opinion_juridical"
                  label="Jurídico"
                />
                <FormCheckbox
                  name="can_receive_opinion_compliance"
                  label="Compliance"
                />
                <FormCheckbox
                  name="can_receive_opinion_security"
                  label="Cybersecurity"
                />
              </div>
            </div>
          </div>
        )}
        <div className="Line5"></div>
      </Main>
      <Footer>
        <Button
          type="button"
          background="transparent"
          color="grey"
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button type="submit">Salvar</Button>
      </Footer>
    </Container>
  );
};

export default FormPartnerUser;
