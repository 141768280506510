import { css } from '@emotion/core';
import React from 'react';
import { BeatLoader } from 'react-spinners';

import { Container, Card } from './styles';

interface ILoadingMaskProps {
  text?: string;
}

const override = css`
  display: flex;
`;

const LoadingMask: React.FC<ILoadingMaskProps> = ({ text }) => {
  return (
    <Container>
      <Card>
        {text ? <h1>{text}</h1> : <h1>Carregando ... </h1>}{' '}
        <BeatLoader css={override} color="#19b2ff" loading size={12} />{' '}
      </Card>
    </Container>
  );
};

export default LoadingMask;
