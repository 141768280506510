import styled, { css } from 'styled-components';

interface IItemPageProps {
  active?: boolean;
}

export const Container = styled.div`
  padding: 29px 42px 29px 42px;
  overflow-y: auto;

  > h1 {
    text-align: left;
    font-size: 22px;
    letter-spacing: 0px;
    color: #2c405a;
    opacity: 1;
    font-weight: 400;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
  margin-bottom: 19px;

  > div {
    width: 283px;
  }

  > a {
    text-decoration: none;
    button.Button {
      width: 180px;
      margin: 0px;
    }
  }
`;

export const Pagination = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 31px;
    border-top: 1px solid #c5d9e8;
    border-left: 1px solid #c5d9e8;
    border-bottom: 1px solid #c5d9e8;
    margin: 0px;
    padding: 0px;
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */

    cursor: pointer;

    button {
      border: 0;
      background-color: transparent;
      font-size: 13px;
      letter-spacing: 0px;
      color: #a8c6df;
      flex: 1;
    }

    svg {
      color: #a8c6df;
    }

    &:hover {
      background-color: #e1ebf5;
    }
  }

  li:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  li:last-child {
    border-right: 1px solid #c5d9e8;
    border-radius: 0px 4px 4px 0px;
  }
`;

export const ItemPage = styled.li<IItemPageProps>`
  ${props =>
    props.active &&
    css`
      background-color: #e1ebf5;
    `}
`;

export const OptionsContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 16px;
    background-color: #ffffff;
    border: 0px;
    cursor: pointer;

    h1 {
      text-align: left;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #3f536e;
      width: 120px;
    }

    svg {
      color: #8dabc4;
      margin-right: 16px;
    }

    &:hover {
      background-color: #fafbfc;
    }
  }
`;
