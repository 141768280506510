import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { FiSearch } from 'react-icons/fi';
import arraySort from 'array-sort';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Container, Header, Pagination, ItemPage } from './styles';
import InputOnly from '../../components/InputOnly';
import Table from '../../components/Table';
import IconOrderBy from '../../components/IconOrderBy';
import { useFetch } from '../../hooks/fetch';
import { ICustomer } from '../Customers';
import { ICustomerDirectorate } from '../DataMapping/components/Directorate';
import { ICustomerDepartment } from '../DataMapping/components/Department';
import {
  ICustomerProcess,
  IProcessOpinionType,
} from '../DataMapping/components/Process';
import { ICustomerUser } from '../CustomerUsers';
import { IPartner, IPartnerUser } from '../Partners';
import Badge from '../../components/Badge';

export type IRiskAnwserType = 'Mitigar' | 'Aceitar' | 'Transferir' | 'Eliminar';

export type IRiskType = 1 | 2 | 3 | 4 | 5;

export const riskTypeLookup = [
  'Nenhum',
  'Muito Baixo',
  'Baixo',
  'Médio',
  'Alto',
  'Muito Alto',
];

export type IOpinionItemStatus =
  | 'Preenchimento'
  | 'Não Recomendado'
  | 'Recomendado'
  | 'Solicitado'
  | 'Revalidação'
  | 'Aceito';

export type IOpinionStatus = 'Pendente' | 'Respondido' | 'Criado';

export interface IOpinion {
  id: string;
  customer?: ICustomer;
  customer_directorate_id?: string;
  customer_directorate?: ICustomerDirectorate;
  customer_department_id?: string;
  customer_department?: ICustomerDepartment;
  customer_process_id?: string;
  customer_process?: ICustomerProcess;
  owner_customer_user_id?: string;
  owner_customer_user?: ICustomerUser;
  opinion_partner_id?: string;
  opinion_partner?: IPartner;
  opinion_partner_user_id?: string;
  opinion_partner_user?: IPartnerUser;
  type?: IProcessOpinionType;
  status?: IOpinionStatus;
  impact?: IRiskType;
  probability?: IRiskType;
  risk?: IRiskType;
  risk_answer?: IRiskAnwserType;
  description?: string;
  response_date?: Date;
  plan_action?: string;
  next_step?: string;
  deadline?: Date;
  done?: boolean;
  risk_identified?: string;
  created_at?: Date;
  updated_at?: Date;
}

export interface IOpinionItem {
  status: IOpinionItemStatus;
  opinions: IOpinion[];
}

export interface IOpinions {
  busines: IOpinionItem;
  compliance: IOpinionItem;
  juridical: IOpinionItem;
  security: IOpinionItem;
}

interface IOrderBy {
  fieldName: string;
  ascendant: boolean;
}

export const LookupOpinionsType = {
  busines: 'Parecer de Negócios',
  compliance: 'Parecer de Compliance',
  juridical: 'Parecer Jurídico',
  security: 'Parecer de Cybersecurity',
};

const Opinions: React.FC = () => {
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [opinionsToDisplay, setOpinionsToDisplay] = useState<{
    filtered: number;
    opinions: IOpinion[];
  }>({ filtered: 0, opinions: [] });
  const history = useHistory();

  const [inputFilter, setInputFilter] = useState('');

  const [orderBy, setOrderBy] = useState<IOrderBy>({
    fieldName: 'created_at',
    ascendant: true,
  });

  const { data: opinions } = useFetch<IOpinion[]>('/opinions');

  const handleOrderBy = useCallback(
    ({ fieldName, ascendant }: IOrderBy) => {
      setOrderBy({
        fieldName,
        ascendant:
          fieldName === orderBy.fieldName ? !orderBy.ascendant : ascendant,
      });
    },
    [orderBy.ascendant, orderBy.fieldName],
  );

  useEffect(() => {
    if (opinions) {
      if (!inputFilter.length) {
        setOpinionsToDisplay({
          filtered: opinions?.length,
          opinions,
        });
      }
      const filtered = opinions?.filter(
        opinion =>
          opinion.customer?.name
            .toLowerCase()
            .includes(inputFilter.toLowerCase()) ||
          opinion.customer_process?.name
            .toLowerCase()
            .includes(inputFilter.toLowerCase()),
      );

      if (filtered) {
        const ordered = arraySort(filtered, orderBy.fieldName, {
          reverse: orderBy.ascendant,
        });

        const paged = ordered.slice(
          (page - 1) * perPage,
          (page - 1) * perPage + perPage,
        );

        if (opinions)
          setOpinionsToDisplay({
            filtered: filtered.length,
            opinions: paged,
          });
      }
    }
  }, [
    inputFilter,
    orderBy.ascendant,
    orderBy.fieldName,
    page,
    perPage,
    opinions,
  ]);

  useEffect(() => {
    setPage(1);
  }, [inputFilter]);

  const pages = useMemo(() => {
    if (!opinionsToDisplay.filtered) return 1;
    return Math.ceil(opinionsToDisplay.filtered / perPage);
  }, [opinionsToDisplay.filtered, perPage]);

  const pageNumbers = useMemo(() => {
    const maxDisplayPages = 15;
    const diff = Math.ceil(maxDisplayPages / 2);
    let start = 0;
    if (pages > maxDisplayPages && page > pages - diff) {
      start = pages - maxDisplayPages;
    } else if (pages > maxDisplayPages && page > diff) {
      start = page - diff;
    } else {
      start = 0;
    }
    const limiters = { start, end: start + maxDisplayPages };

    const pns: number[] = [];
    for (let i = 1; i <= pages; i += 1) {
      pns.push(i);
    }
    return pns.slice(limiters.start, limiters.end);
  }, [page, pages]);

  const handleSetPageUp = useCallback(() => {
    if (page < pages) setPage(page + 1);
  }, [page, pages]);

  const handleSetPageDown = useCallback(() => {
    if (page > 1) setPage(page - 1);
  }, [page]);

  return (
    <>
      <Container>
        <h1>Pareceres</h1>
        <Header>
          <InputOnly
            name="search"
            icon={FiSearch}
            onChange={e => setInputFilter(e.target.value)}
          />
        </Header>
        <Table>
          <thead>
            <tr className="Header">
              <th style={{ width: '20%' }}>
                <div>
                  <span>CLIENTE</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'customerName'}
                    onClick={() =>
                      handleOrderBy({
                        fieldName: 'customerName',
                        ascendant: false,
                      })
                    }
                  />
                </div>
              </th>
              <th style={{ width: '25%' }}>
                {' '}
                <div>
                  <span>PROCESSO</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'processName'}
                    onClick={() =>
                      handleOrderBy({
                        fieldName: 'processName',
                        ascendant: false,
                      })
                    }
                  />
                </div>
              </th>
              <th style={{ width: '15%' }}>
                {' '}
                <div>
                  <span>TIPO DE PARECER</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'type'}
                    onClick={() =>
                      handleOrderBy({
                        fieldName: 'type',
                        ascendant: false,
                      })
                    }
                  />
                </div>
              </th>
              <th style={{ width: '15%' }}>
                {' '}
                <div>
                  <span>DATA SOL.</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'created_at'}
                    onClick={() =>
                      handleOrderBy({
                        fieldName: 'created_at',
                        ascendant: false,
                      })
                    }
                  />
                </div>
              </th>
              <th style={{ width: '15%' }}>
                {' '}
                <div>
                  <span>RESPOSTA</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'response_date'}
                    onClick={() =>
                      handleOrderBy({
                        fieldName: 'response_date',
                        ascendant: false,
                      })
                    }
                  />
                </div>
              </th>
              <th style={{ width: '10%' }}>
                {' '}
                <div>
                  <span>STATUS</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'status'}
                    onClick={() =>
                      handleOrderBy({
                        fieldName: 'status',
                        ascendant: false,
                      })
                    }
                  />
                </div>
              </th>
            </tr>
          </thead>
          {!opinions && (
            <tbody>
              <tr>
                <td colSpan={6}>Carregando ...</td>
              </tr>
            </tbody>
          )}
          {opinions && !opinions.length && (
            <tbody>
              <tr>
                <td colSpan={6}>Nenhum parecer encontrado</td>
              </tr>
            </tbody>
          )}
          <tbody>
            {opinionsToDisplay.opinions.map(opinion => (
              <tr
                key={opinion.id}
                onClick={() => history.push(`/home/opinions/${opinion.id}`)}
              >
                <td>{opinion.customer?.name}</td>
                <td>{opinion.customer_process?.name}</td>
                <td>{opinion.type && LookupOpinionsType[opinion.type]}</td>
                <td>
                  {opinion.created_at &&
                    format(new Date(opinion.created_at), 'dd/MMM/yyyy HH:mm')}
                </td>
                <td>
                  {opinion.response_date &&
                    format(
                      new Date(opinion.response_date),
                      'dd/MMM/yyyy HH:mm',
                    )}
                </td>
                <td
                  style={{
                    paddingRight: 10,
                    paddingLeft: 10,
                  }}
                >
                  {opinion.status === 'Respondido' ? (
                    <Badge
                      value={opinion.status}
                      color="#4ECE3D"
                      background="#FFF"
                      bordered
                    />
                  ) : (
                    <Badge
                      value={opinion.status}
                      color="#8DABC4"
                      background="#FFF"
                      bordered
                    />
                  )}
                </td>
              </tr>
            ))}
            <tr className="Pagination">
              <td colSpan={6}>
                <Pagination>
                  <li>
                    <IoMdArrowDropleft
                      size={18}
                      onClick={() => handleSetPageDown()}
                    />
                  </li>
                  {pageNumbers.map(pg => (
                    <ItemPage key={pg} active={pg === page}>
                      <button type="button" onClick={() => setPage(pg)}>
                        {pg}
                      </button>
                    </ItemPage>
                  ))}
                  <li>
                    <IoMdArrowDropright
                      size={18}
                      onClick={() => handleSetPageUp()}
                    />
                  </li>
                </Pagination>
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default Opinions;
