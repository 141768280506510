import styled from 'styled-components';

interface IContainerProps {
  value: string | number;
}

export const Container = styled.span<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #deeef7;
  border-radius: 50px;
  font-size: 14px;
  color: #037bc5;
  padding: 2px 8px;

  svg {
    margin: 0px 0px 0px 4px !important;
    cursor: pointer;
  }
`;
