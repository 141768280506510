import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import { Container } from './styles';

interface IFormCheckboxProps {
  name: string;
  label: string;
  disabled?: boolean;
}

const FormCheckbox: React.FC<IFormCheckboxProps> = ({
  name,
  label,
  disabled,
}) => {
  const { fieldName, defaultValue, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: HTMLInputElement) => {
        return ref.checked;
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container className="FormCheckbox">
      <label htmlFor={name}>
        <input
          ref={inputRef}
          id={name}
          type="checkbox"
          defaultChecked={defaultValue}
          disabled={disabled}
        />
        <span>{label}</span>
      </label>
    </Container>
  );
};

export default FormCheckbox;
