import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  overflow-y: auto;
  height: calc(100vh);

  > header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 17px;
    > svg {
      cursor: pointer;
      color: #8dabc4;
      &:hover {
        color: #0093ee;
      }
    }
    > h1 {
      color: var(--unnamed-color-2c405a);
      text-align: left;
      font-weight: 400;
      font-size: 22px;
      letter-spacing: 0px;
      margin-left: 16px;
    }
  }
`;

export const QuizHeader = styled.div`
  padding: 31px 106px;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
`;

export const QuizCategories = styled.div`
  padding: 31px 106px;
  display: grid;
  grid-gap: 32px;
`;

export const QuizFooter = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 56px;
  background-color: #ffff;
  border-radius: 0px 0px 4px 4px;

  > button {
    width: 160px;

    & + button {
      margin-left: 16px;
    }
  }
`;

export const Question = styled.div`
  padding: 30px 55px 0px 55px;
  > h1 {
    color: var(--unnamed-color-3f536e);
    text-align: left;
    font: normal normal normal 15px/19px Heebo;
    letter-spacing: 0px;
    color: #3f536e;
    opacity: 1;
    > span {
      color: var(--unnamed-color-3f536e);
      text-align: left;
      font: normal normal bold 15px/19px Heebo;
      letter-spacing: 0px;
      color: #3f536e;
      opacity: 1;
      margin-right: 15px;
    }
  }
  > div.Wrapper {
    padding: 15px 0px 30px 27px;
  }
`;

export const ModuleConclusion = styled.div`
  padding: 31px 106px;
`;
