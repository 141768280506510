/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable radix */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable default-case */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import wrapHandlers from '../wrapHandlers.js';

const propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  keyProp: PropTypes.string.isRequired,
  labelProp: PropTypes.string.isRequired,
  shape: PropTypes.string.isRequired,
  nodeProps: PropTypes.object.isRequired,
  gProps: PropTypes.object.isRequired,
  textProps: PropTypes.object.isRequired,
};

export default class Node extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  makeBG(elem) {
    const svgns = 'http://www.w3.org/2000/svg';
    const bounds = elem.getBBox();
    const bg = document.createElementNS(svgns, 'rect');
    const style = getComputedStyle(elem);
    const padding_top = parseInt(style['padding-top']);
    const padding_left = parseInt(style['padding-left']);
    const padding_right = parseInt(style['padding-right']);
    const padding_bottom = parseInt(style['padding-bottom']);
    bg.setAttribute('x', bounds.x - parseInt(style['padding-left']));
    bg.setAttribute('y', bounds.y - parseInt(style['padding-top']));
    bg.setAttribute('width', bounds.width + padding_left + padding_right);
    bg.setAttribute('height', bounds.height + padding_top + padding_bottom);
    bg.setAttribute('fill', style['background-color']);
    bg.setAttribute('rx', style['border-radius']);
    bg.setAttribute('stroke-width', style['border-top-width']);
    bg.setAttribute('stroke', style['border-top-color']);
    if (elem.hasAttribute('transform')) {
      bg.setAttribute('transform', elem.getAttribute('transform'));
    }
    elem.parentNode.insertBefore(bg, elem);
  }

  getTransform() {
    return `translate(${this.props.y}, ${this.props.x})`;
  }

  componentDidMount() {
    this.makeBG(this.ref.current);
  }

  render() {
    let offset = 0;
    let nodePropsWithDefaults = this.props.nodeProps;
    switch (this.props.shape) {
      case 'circle':
        nodePropsWithDefaults = { r: 5, ...nodePropsWithDefaults };
        offset = nodePropsWithDefaults.r;
        break;
      case 'image':
      case 'rect':
        nodePropsWithDefaults = {
          height: 10,
          width: 10,
          ...nodePropsWithDefaults,
        };
        nodePropsWithDefaults = {
          x: -nodePropsWithDefaults.width / 2,
          y: -nodePropsWithDefaults.height / 2,
          ...nodePropsWithDefaults,
        };
        offset = nodePropsWithDefaults.width / 2;
        break;
    }

    const wrappedNodeProps = wrapHandlers(
      nodePropsWithDefaults,
      this.props[this.props.keyProp],
    );

    const wrappedGProps = wrapHandlers(
      this.props.gProps,
      this.props[this.props.keyProp],
    );

    const wrappedTextProps = wrapHandlers(
      this.props.textProps,
      this.props[this.props.keyProp],
    );

    const cutText = str => {
      if (str.length > 20) {
        return `${str.slice(0, 30)}...`;
      }
      return str;
    };

    return (
      <g {...wrappedGProps} transform={this.getTransform()}>
        <this.props.shape {...wrappedNodeProps} />

        <text ref={this.ref} dx={offset + 0.5} dy={5} {...wrappedTextProps}>
          {cutText(this.props[this.props.labelProp])}
          <title>{this.props[this.props.labelProp]}</title>
        </text>
      </g>
    );
  }
}

Node.propTypes = propTypes;
