import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import Card from '../../../../components/Card';
import LoadingMask from '../../../../components/LoadingMask';

import { useFetch } from '../../../../hooks/fetch';
import Diagram from '../Diagram';
import { getAllPercentFilled, ICustomerProcess } from '../Process';

import { Container, Header } from './styles';

const ProcessDiagram: React.FC = () => {
  const { id: idProcess } = useParams<{ id: string }>();
  const history = useHistory();

  const { data: customer_process, mutate } = useFetch<ICustomerProcess>(
    `/customerProcesses/${idProcess}`,
  );

  if (customer_process) {
    mutate(getAllPercentFilled(customer_process), false);
  }

  if (!customer_process) {
    return <LoadingMask text="Carregando..." />;
  }

  return (
    <Container>
      <Header>
        <IoIosArrowBack size={24} onClick={() => history.goBack()} />
        <div>
          <h1>{customer_process.customer_directorate?.name} /</h1>
          <h2>{customer_process.customer_department?.name} /</h2>
          <h3>{customer_process?.name} /</h3>
          <h4>Diagrama</h4>
        </div>
      </Header>
      <Card>
        <Diagram customer_process={customer_process} />
      </Card>
    </Container>
  );
};

export default ProcessDiagram;
