import React, { useCallback } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { cache, mutate as mutateGlobal } from 'swr';
import { v4 } from 'uuid';
import { Container } from './styles';
import { useToast } from '../../../hooks/toast';
import { useFetch } from '../../../hooks/fetch';
import api from '../../../services/api';
import { ITemplate } from '..';
import Spinner from '../../../components/Spinner';
import FormTemplate from '../forms/FormTemplate';

const TemplateNewVersion: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id: idTemplate } = useParams<{ id: string }>();
  const { data: template, error } = useFetch<ITemplate>(
    `/templates/${idTemplate}`,
    { revalidateOnFocus: false },
  );

  const handleSubmit = useCallback(
    (templateReturned: ITemplate) => {
      const template_id = v4();
      const newTemplate = {
        ...templateReturned,
        id: template_id,
        modules: templateReturned.modules.map(m => {
          const template_module_id = v4();
          return {
            ...m,
            id: template_module_id,
            template_id,
            categories: m.categories.map(c => {
              const template_category_id = v4();
              return {
                ...c,
                id: template_category_id,
                template_id,
                template_module_id,
                questions: c.questions.map(q => {
                  const question_id = v4();
                  return {
                    ...q,
                    id: question_id,
                    template_id,
                    template_module_id,
                    template_category_id,
                  };
                }),
              };
            }),
          };
        }),
      };

      const save = async (): Promise<void> => {
        const oldTemplates = cache.get('/templates');
        api.post<ITemplate>(`/templates/`, newTemplate).catch(err => {
          const { message } = err.response.data;
          let description = 'Ocorreu um erro ao salvar os dados do template.';
          if (message === 'Template name and version already used.') {
            description = 'Versão já existente para esse template';
          }
          mutateGlobal('/templates', oldTemplates, false);
          addToast({
            type: 'error',
            title: 'Erro ao salvar',
            description,
          });
        });
        mutateGlobal(
          '/templates',
          async (templates: ITemplate[]) => {
            return [...templates, newTemplate];
          },
          false,
        );
        history.goBack();
      };
      save();
    },
    [addToast, history],
  );

  if (template) {
    const template_id = v4();

    Object.assign(template, {
      ...template,
      id: template_id,
      modules: template.modules.map(m => {
        const template_module_id = v4();
        return {
          ...m,
          id: template_module_id,
          template_id,
          categories: m.categories.map(c => {
            const template_category_id = v4();
            return {
              ...c,
              id: template_category_id,
              template_id,
              template_module_id,
              questions: c.questions.map(q => {
                const question_id = v4();
                return {
                  ...q,
                  id: question_id,
                  template_id,
                  template_module_id,
                  template_category_id,
                };
              }),
            };
          }),
        };
      }),
    });
  } else {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (error) {
    addToast({
      type: 'error',
      title: 'Erro de carregamento',
      description: 'Ocorreu um erro ao carregar dados do template.',
    });
  }

  return (
    <Container>
      <h1>Editar Template</h1>
      <FormTemplate initialData={template} onSubmit={handleSubmit} newVersion />
    </Container>
  );
};

export default TemplateNewVersion;
