import React from 'react';
import { MdInfo } from 'react-icons/md';
import Button from '../../../../../../components/Button';
import { IModalHandles } from '../../../../../../components/Modal';

import { Container } from './styles';

interface ICardOpinionNotRecommendedProps {
  label: string;
  modal_request_opinion: React.MutableRefObject<IModalHandles | undefined>;
}

const CardOpinionNotRecommended: React.FC<ICardOpinionNotRecommendedProps> = ({
  label,
  modal_request_opinion,
}) => {
  return (
    <Container>
      <MdInfo size={28} color="#A8C6DF" />
      <h1>
        Parecer {label} <b>não recomendado</b>
      </h1>
      <Button
        background="#0079C4"
        onClick={() => modal_request_opinion.current?.open()}
      >
        Solicitar agora
      </Button>
    </Container>
  );
};

export default CardOpinionNotRecommended;
