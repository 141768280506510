import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { FiSearch, FiMoreVertical } from 'react-icons/fi';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import {
  Link,
  useHistory,
  useRouteMatch,
  useLocation,
  Switch,
  Route,
} from 'react-router-dom';
import arraySort from 'array-sort';
import { format } from 'date-fns';
import { cache } from 'swr';
import { FaRegClone } from 'react-icons/fa';
import {
  Container,
  Header,
  Pagination,
  OptionsContainer,
  ItemPage,
} from './styles';
import InputOnly from '../../components/InputOnly';
import Button from '../../components/Button';
import Table from '../../components/Table';
import IconOrderBy from '../../components/IconOrderBy';
import SuspensePainel from '../../components/SuspensePainel';
import { useToast } from '../../hooks/toast';
import { useFetch } from '../../hooks/fetch';
import TemplateDetails from './TemplateDetails';
import TemplateNew from './TemplateNew';
import api from '../../services/api';
import Badge from '../../components/Badge';

export interface ITemplateQuestion {
  id: string;
  description: string;
  position?: number;
  template_id?: string;
  template_module_id?: string;
  template_category_id?: string;
}

export interface ITemplateCategory {
  id: string;
  name: string;
  position?: number;
  template_id?: string;
  template_module_id?: string;
  questions: ITemplateQuestion[];
}

export interface ITemplateModule {
  id: string;
  name: string;
  position?: number;
  template_id?: string;
  categories: ITemplateCategory[];
}

export interface ITemplate {
  id: string | undefined;
  name: string;
  type: string;
  version: string;
  consolidated: boolean;
  created_at: Date;
  modules: ITemplateModule[];
}

interface IOrderBy {
  fieldName: string;
  ascendant: boolean;
}

const Templates: React.FC = () => {
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [templatesToDisplay, setTemplatesToDisplay] = useState<{
    filtered: number;
    templates: ITemplate[];
  }>({ filtered: 0, templates: [] });
  const { addToast } = useToast();
  const history = useHistory();
  const [inputFilter, setInputFilter] = useState('');
  const [orderBy, setOrderBy] = useState<IOrderBy>({
    fieldName: 'name',
    ascendant: false,
  });
  const match = useRouteMatch();
  const location = useLocation();

  const { data: templates, mutate } = useFetch<ITemplate[]>('/templates', {
    revalidateOnFocus: true,
  });

  const handleOrderBy = useCallback(
    ({ fieldName, ascendant }: IOrderBy) => {
      setOrderBy({
        fieldName,
        ascendant:
          fieldName === orderBy.fieldName ? !orderBy.ascendant : ascendant,
      });
    },
    [orderBy.ascendant, orderBy.fieldName],
  );

  useEffect(() => {
    if (templates) {
      if (!inputFilter.length) {
        setTemplatesToDisplay({ filtered: templates?.length, templates });
      }
      const filtered = templates?.filter(template => {
        const normalizedInputFilter = inputFilter
          .toLowerCase()
          .normalize('NFKD')
          .replace(/^\s+|\p{M}/gu, '');

        const normalizedTemplateName = template.name
          .toLowerCase()
          .normalize('NFKD')
          .replace(/^\s+|\p{M}/gu, '');

        return (
          normalizedTemplateName.includes(normalizedInputFilter) ||
          template.version.toLowerCase().includes(normalizedInputFilter)
        );
      });

      if (filtered) {
        const ordered = arraySort(filtered, orderBy.fieldName, {
          reverse: orderBy.ascendant,
        });

        const paged = ordered.slice(
          (page - 1) * perPage,
          (page - 1) * perPage + perPage,
        );

        if (templates)
          setTemplatesToDisplay({
            filtered: filtered.length,
            templates: paged,
          });
      }
    }
  }, [
    inputFilter,
    orderBy.ascendant,
    orderBy.fieldName,
    page,
    templates,
    perPage,
  ]);

  useEffect(() => {
    setPage(1);
  }, [inputFilter]);

  const pages = useMemo(() => {
    if (!templatesToDisplay.filtered) return 1;
    return Math.ceil(templatesToDisplay.filtered / perPage);
  }, [templatesToDisplay.filtered, perPage]);

  const pageNumbers = useMemo(() => {
    const maxDisplayPages = 15;
    const diff = Math.ceil(maxDisplayPages / 2);
    let start = 0;
    if (pages > maxDisplayPages && page > pages - diff) {
      start = pages - maxDisplayPages;
    } else if (pages > maxDisplayPages && page > diff) {
      start = page - diff;
    } else {
      start = 0;
    }
    const limiters = { start, end: start + maxDisplayPages };

    const pns: number[] = [];
    for (let i = 1; i <= pages; i += 1) {
      pns.push(i);
    }
    return pns.slice(limiters.start, limiters.end);
  }, [page, pages]);

  const handleSetPageUp = useCallback(() => {
    if (page < pages) setPage(page + 1);
  }, [page, pages]);

  const handleSetPageDown = useCallback(() => {
    if (page > 1) setPage(page - 1);
  }, [page]);

  const handleRemoveTemplate = useCallback(
    async (id: string | undefined) => {
      const oldTemplates = cache.get('/templates');
      api.delete<ITemplate[]>(`/templates/${id}`).catch(() => {
        mutate(oldTemplates);
        addToast({
          type: 'error',
          title: 'Erro de remoção',
          description: 'Falha para remover template.',
        });
      });
      const updatedTemplates = templates?.filter(t => t.id !== id);
      mutate(updatedTemplates, false);
    },
    [addToast, mutate, templates],
  );

  const handleEditTemplate = useCallback(
    async (id: string | undefined) => {
      history.push(`/home/templates/${id}/edit`);
    },
    [history],
  );

  const handleNewTemplateVersion = useCallback(
    async (id: string | undefined) => {
      history.push(`/home/templates/${id}/newVersion`);
    },
    [history],
  );

  if (location.pathname !== '/home/templates') {
    return (
      <Switch>
        <Route path={`${match.path}/new`} component={TemplateNew} />
        <Route path={`${match.path}/:id`} component={TemplateDetails} />
      </Switch>
    );
  }

  return (
    <Container>
      <h1>Templates de projeto</h1>
      <Header>
        <InputOnly
          name="search"
          icon={FiSearch}
          onChange={e => setInputFilter(e.target.value)}
        />
        <Link to="/home/templates/new">
          <Button icon={MdAdd}>Novo template</Button>
        </Link>
      </Header>
      <Table>
        <thead>
          <tr className="Header">
            <th style={{ width: '52%' }}>
              <div>
                <span>NOME</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'name'}
                  onClick={() =>
                    handleOrderBy({ fieldName: 'name', ascendant: false })
                  }
                />
              </div>
            </th>
            <th style={{ width: '15%' }}>
              <div>
                <span>DATA</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'created_at'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'created_at',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>
            <th style={{ width: '15%' }}>
              <div>
                <span>VERSÃO</span>
                <IconOrderBy
                  ascendant={orderBy.ascendant}
                  size={18}
                  active={orderBy.fieldName === 'version'}
                  onClick={() =>
                    handleOrderBy({
                      fieldName: 'version',
                      ascendant: false,
                    })
                  }
                />
              </div>
            </th>

            <th style={{ width: '13%' }} />
            <th style={{ width: '5%' }} />
          </tr>
        </thead>
        {!templates && (
          <tbody>
            <tr>
              <td colSpan={6}>Carregando ...</td>
            </tr>
          </tbody>
        )}
        {templates && !templates.length && (
          <tbody>
            <tr>
              <td colSpan={6}>Nenhum template encontrado</td>
            </tr>
          </tbody>
        )}
        <tbody>
          {templatesToDisplay.templates.map(template => (
            <tr
              key={template.id}
              onClick={() => history.push(`/home/templates/${template.id}`)}
            >
              <td>{template.name}</td>
              <td>{format(new Date(template.created_at), 'dd/MMM/yyyy')}</td>
              <td>{template.version}</td>
              <td>
                {template.consolidated ? (
                  <Badge
                    value="Consolidado"
                    color="#0079C4"
                    background="#FFF"
                    bordered
                  />
                ) : (
                  <Badge
                    value="Em edição"
                    color="#FACA00"
                    background="#FFF"
                    bordered
                  />
                )}
              </td>
              <td
                className="Icons"
                style={{
                  paddingRight: '10px',
                }}
              >
                <SuspensePainel icon={FiMoreVertical}>
                  <OptionsContainer>
                    {template.consolidated ? (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            handleNewTemplateVersion(template.id);
                          }}
                        >
                          <FaRegClone size={20} />
                          <h1>Gerar nova versão</h1>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            handleRemoveTemplate(template.id);
                          }}
                        >
                          <MdDelete size={20} />
                          <h1>Remover Template</h1>
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            handleEditTemplate(template.id);
                          }}
                        >
                          <MdEdit size={20} />
                          <h1>Editar Template</h1>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            handleRemoveTemplate(template.id);
                          }}
                        >
                          <MdDelete size={20} />
                          <h1>Remover Template</h1>
                        </button>
                      </>
                    )}
                  </OptionsContainer>
                </SuspensePainel>
              </td>
            </tr>
          ))}
          <tr className="Pagination">
            <td colSpan={6}>
              <Pagination>
                <li>
                  <IoMdArrowDropleft
                    size={18}
                    onClick={() => handleSetPageDown()}
                  />
                </li>
                {pageNumbers.map(pg => (
                  <ItemPage key={pg} active={pg === page}>
                    <button type="button" onClick={() => setPage(pg)}>
                      {pg}
                    </button>
                  </ItemPage>
                ))}
                <li>
                  <IoMdArrowDropright
                    size={18}
                    onClick={() => handleSetPageUp()}
                  />
                </li>
              </Pagination>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default Templates;
