import styled from 'styled-components';

export const Container = styled.div`
  padding: 29px 42px 29px 42px;
  overflow-y: auto;

  > h1 {
    text-align: left;
    font: normal normal normal 22px/32px Heebo;
    letter-spacing: 0px;
    color: #2c405a;
    margin-bottom: 27px;
  }
`;

export const Main = styled.div`
  > div.Card + div.Card {
    margin-top: 13px;
  }
`;

export const TolerancyContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 27px 60px;

  > h1 {
    text-align: left;
    font-size: 14px;
    letter-spacing: 2.16px;
    color: #3f536e;
  }

  > table {
    margin-top: 17px;
    border: 1px solid #7c858c;
    width: 819px;
    border-collapse: collapse;
    border-spacing: 0;

    > thead > tr > th {
      border: 1px solid #7c858c;
      text-align: center;
      height: 36px;
      background-color: #f6f8fa;
    }

    > tbody > tr > td {
      border: 1px solid #7c858c;
      text-align: center;
      height: 36px;
    }

    > tbody > tr > td.Yellow {
      background: #fef5d1 0% 0% no-repeat padding-box;
      text-align: center;
      font-size: 15px;
      letter-spacing: 0px;
      color: #ffbc00;
    }

    > tbody > tr > td.Orange {
      background: #fcd8bf 0% 0% no-repeat padding-box;
      text-align: center;
      font-size: 15px;
      letter-spacing: 0px;
      color: #fd7b1f;
    }

    > tbody > tr > td.Red {
      background: #ffafb2 0% 0% no-repeat padding-box;
      text-align: center;
      font-size: 15px;
      letter-spacing: 0px;
      color: #c40911;
    }

    > tbody > tr > td.Green {
      background: #cefcc8 0% 0% no-repeat padding-box;
      text-align: center;
      font-size: 15px;
      letter-spacing: 0px;
      color: #40b630;
    }

    > tbody > tr > td.GreenMore {
      background: #82ff70 0% 0% no-repeat padding-box;
      text-align: center;
      font-size: 15px;
      letter-spacing: 0px;
      color: #72b36a;
    }
  }
`;

export const CustomerOpinionsContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 27px 60px;

  > h1 {
    text-align: left;
    font-size: 14px;
    letter-spacing: 2.16px;
    color: #3f536e;
  }
`;

export const WrapperFilter = styled.div`
  display: flex;

  > div.InputOnly {
    width: 283px;
  }

  > button.Button {
    width: 42px;
    height: 42px;
    border-radius: 4px;
    padding: 0px;
    margin-left: 21px;
  }
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 17px;

  > button.Button {
    width: 200px;
  }
`;

export const WrapperCustomerOpinions = styled.div`
  margin-top: 37px;

  > div.ExpansionPainel {
    margin-bottom: 16px;
  }
`;

export const CustomerOpinionContent = styled.div`
  display: flex;
`;

export const HeaderCustomerOpinion = styled.div``;

export const ModalConfirmationContent = styled.div`
  padding: 20px;

  > h1 {
    font-size: 16px;
    margin-bottom: 32px;
    font-weight: 400;
  }

  > div.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`;
