import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  > h1 {
    color: var(--unnamed-color-8dabc4);
    text-align: left;
    font: normal normal normal 15px/19px Heebo;
    letter-spacing: 0px;
    color: #8dabc4;
    opacity: 1;
    width: 120px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 35px;
  grid-template-columns: repeat(7, 29px);

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      margin-right: 9px;
    }
  }
`;
