import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';

import { IoIosArrowBack } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';
import { toRoman } from 'roman-numerals';
import { NumberToAlphabet } from 'number-to-alphabet';
import { mutate as mutateGlobal, cache } from 'swr';
import {
  Container,
  QuizHeader,
  QuizCategories,
  QuizFooter,
  Question,
  ModuleConclusion,
} from './styles';
import Card from '../../../components/Card';
import { ProjectContext, IProjectModule, IProject } from '..';
import DisplayField from '../../../components/DisplayField';
import Button from '../../../components/Button';
import ExpansionPainel from '../../../components/ExpansionPainel';
import FormQuestion from './forms/FormQuestion';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import FormModuleConclusion from './forms/FormModuleConclusion';

const defaultAlphabet = new NumberToAlphabet();

const Quiz: React.FC = () => {
  const history = useHistory();
  const { project } = useContext(ProjectContext);
  const { id: idModule } = useParams<{ id: string }>();
  const [module, setModule] = useState<IProjectModule>();
  const { addToast } = useToast();

  useEffect(() => {
    setModule(project.scopedModules.find(m => m.id === idModule));
  }, [idModule, project.scopedModules]);

  const handleSubmitQuestion = useCallback(
    newQuestion => {
      const newProject: IProject = {
        ...project,
        scopedModules: project.scopedModules.map(m => {
          if (m.id === newQuestion.project_module_id) {
            return {
              ...m,
              categories: m.categories.map(c => {
                if (c.id === newQuestion.project_category_id) {
                  return {
                    ...c,
                    questions: c.questions.map(q => {
                      if (q.id === newQuestion.id) {
                        return newQuestion;
                      }
                      return q;
                    }),
                  };
                }
                return c;
              }),
            };
          }
          return m;
        }),
      };
      const oldProject = cache.get(`/projects/${project.id}`);
      api
        .patch(`/projectQuestions/${newQuestion.id}`, newQuestion)
        .catch(() => {
          const description = 'Ocorreu um erro ao salvar a questão';
          mutateGlobal(`/projects/${project.id}`, oldProject, false);
          addToast({
            type: 'error',
            title: 'Erro ao salvar',
            description,
          });
        });
      mutateGlobal(`/projects/${project.id}`, newProject, false);
    },
    [addToast, project],
  );

  const handleSubmitModuleConclusion = useCallback(
    (conclusion: string, project_interview_id: string) => {
      const newProject: IProject = {
        ...project,
        scopedModules: project.scopedModules.map(m => {
          if (m.id === idModule) {
            return {
              ...m,
              conclusion,
              project_interview_id,
            };
          }
          return m;
        }),
      };
      const oldProject = cache.get(`/projects/${project.id}`);
      mutateGlobal(`/projects/${project.id}`, newProject, false);
      api
        .patch(`/projectModules/${idModule}`, {
          conclusion,
          project_interview_id,
        })
        .catch(() => {
          const description = 'Ocorreu um erro ao salvar o módulo';
          mutateGlobal(`/projects/${project.id}`, oldProject, false);
          addToast({
            type: 'error',
            title: 'Erro ao salvar',
            description,
          });
        });
    },
    [addToast, idModule, project],
  );

  const canFinished = useMemo<boolean | undefined>(() => {
    return (
      module?.categories.every(c => c.questions.every(q => q.answer)) &&
      !!module.conclusion
    );
  }, [module]);

  const handleModuleFinished = useCallback(() => {
    if (canFinished) {
      const oldProject = cache.get(`/projects/${project.id}`);
      const newProject: IProject = {
        ...project,
        scopedModules: project.scopedModules.map(m => {
          if (m.id === idModule) {
            return {
              ...m,
              status: 'Finalizado',
            };
          }
          return m;
        }),
        interviews: project.interviews.map(interview => {
          if (module?.project_interview_id === interview.id) {
            return { ...interview, done: true, done_date: new Date() };
          }
          return interview;
        }),
      };
      api
        .patch(`/projectModules/${idModule}`, {
          status: 'Finalizado',
        })
        .catch(() => {
          const description = 'Ocorreu um erro ao salvar o módulo';
          mutateGlobal(`/projects/${project.id}`, oldProject, false);
          addToast({
            type: 'error',
            title: 'Erro ao salvar',
            description,
          });
        });
      mutateGlobal(`/projects/${project.id}`, newProject, false);
      addToast({
        type: 'success',
        title: 'Módulo Finalizado',
        description: 'Avaliação do módulo executada com sucesso',
      });
      history.goBack();
    } else {
      addToast({
        type: 'error',
        title: 'Módulo Incompleto',
        description:
          'Para finalizar o módulo todos os campos precisam estar devidamente preenchidos',
      });
    }
  }, [addToast, canFinished, history, idModule, module, project]);

  return (
    <Container>
      {module && (
        <>
          <header>
            <IoIosArrowBack size={24} onClick={() => history.goBack()} />
            <h1>
              {`Módulo ${toRoman(module.position + 1)} - ${module?.name}`}
            </h1>
          </header>
          <Card>
            <QuizHeader>
              <DisplayField label="Empresa">
                {project.customer.name}
              </DisplayField>
            </QuizHeader>
            <QuizCategories>
              {module?.categories.map((category, categoryIndex) => (
                <ExpansionPainel
                  key={category.id}
                  preTitle={(categoryIndex + 1).toString()}
                  preTitleWidth="10px"
                  preTitleColor="#3F536E"
                  title={category.name}
                  titleFontSize="12px"
                  clean
                  barComponent={() => (
                    <h1 style={{ color: '#0079C4', fontSize: '16px' }}>
                      {(
                        (category.questions.filter(q => q.answer).length *
                          100) /
                        category.questions.length
                      ).toFixed(0)}{' '}
                      %
                    </h1>
                  )}
                >
                  {category.questions.map((question, questionIndex) => (
                    <Question key={question.id}>
                      <h1>
                        <span>
                          {defaultAlphabet.numberToString(questionIndex + 1)}.
                        </span>
                        {question.question}
                      </h1>
                      <div className="Wrapper">
                        <FormQuestion
                          initialData={question}
                          onSubmit={handleSubmitQuestion}
                        />
                      </div>
                    </Question>
                  ))}
                </ExpansionPainel>
              ))}
            </QuizCategories>
            <ModuleConclusion>
              <FormModuleConclusion
                initialData={{
                  conclusion: module.conclusion,
                  project_interview_id: module.project_interview_id,
                }}
                onSubmit={handleSubmitModuleConclusion}
                interviews={project.interviews}
              />
            </ModuleConclusion>
            <QuizFooter>
              <Button
                type="button"
                background="transparent"
                color="grey"
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button>
              <Button type="submit" onClick={handleModuleFinished}>
                Finalizar Módulo
              </Button>
            </QuizFooter>
          </Card>
        </>
      )}
    </Container>
  );
};

export default Quiz;
