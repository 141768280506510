import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  SelectHTMLAttributes,
  cloneElement,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { IoMdArrowDropdown } from 'react-icons/io';

import {
  Container,
  Content,
  Error,
  Options,
  Backdrop,
  ContentOptions,
  Label,
} from './styles';
import Spinner from '../Spinner';
import { IOption } from './Option';

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  icon?: React.ComponentType<IconBaseProps>;
  multi?: boolean;
  onSelection?(selection: IOption[]): void;
  value?: any;
  multiSelection?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const Select3: React.FC<ISelectProps> = ({
  children,
  name,
  label: TopLabel,
  icon: Icon,
  value: Value,
  disabled,
  multiSelection: MultiSelection,
  onSelection,
  loading,
  ...rest
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setisFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [showOptions, setShowOptions] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [selection, setSelection] = useState<IOption | IOption[]>(
    MultiSelection ? [] : { value: '', label: '' },
  );

  const handleClick = useCallback(() => {
    if (!disabled) {
      setIsFocused(true);
      setShowOptions(true);
    }
  }, [disabled]);

  const registerOption = useCallback((option: IOption) => {
    setOptions(oldOptions => [...oldOptions, option]);
  }, []);

  const unregisterOption = useCallback((option: IOption) => {
    setOptions(oldOptions => oldOptions.filter(o => o.value !== option.value));
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue() {
        return MultiSelection
          ? (selection as IOption[]).map(sO => sO.value)
          : (selection as IOption)
          ? (selection as IOption).value
          : '';
      },
    });
  }, [MultiSelection, fieldName, registerField, selection]);

  useEffect(() => {
    if (MultiSelection) {
      if (defaultValue) {
        const newSelection: IOption[] = (defaultValue as any[]).map(df => {
          const option = options.find(o => o.value === df) as IOption;
          return { value: option?.value, label: option?.label };
        });
        setSelection(newSelection);
      } else {
        setSelection([]);
      }
    } else {
      setSelection(defaultValue);
    }
  }, [defaultValue, MultiSelection, name, options]);

  useEffect(() => {
    if (Value !== undefined) setSelection(Value);
  }, [Value]);

  const selectOption = useCallback(
    (option: IOption) => {
      if (MultiSelection) {
        if ((selection as IOption[]).find(sO => sO.label === option.label)) {
          const newSelection = (selection as IOption[]).filter(
            sO => sO.label !== option.label,
          );
          setSelection(newSelection);

          if (onSelection) {
            onSelection(newSelection);
          }
        } else {
          const newSelection = [...(selection as IOption[]), option];
          setSelection(newSelection);
          if (onSelection) {
            onSelection(newSelection);
          }
        }
      } else {
        setSelection(option);
        setShowOptions(false);
      }
    },
    [MultiSelection, onSelection, selection],
  );

  const label = useMemo(() => {
    if (MultiSelection) {
      const out = (selection as IOption[])
        .map(sO => options.find(o => o.value === sO.value)?.label)
        .join(', ');
      return out;
    }
    return (selection as IOption) && (selection as IOption).label;
  }, [MultiSelection, options, selection]);

  return (
    <Container className="Select">
      {TopLabel && <Label>{TopLabel}</Label>}
      <Content
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        label={Label}
        onClick={handleClick}
        className="Select"
        disabled={disabled || false}
      >
        {Icon && <Icon size={20} />}
        <label htmlFor={name}>
          <span>{label}</span>
          <select
            id={name}
            defaultValue={defaultValue}
            multiple={MultiSelection}
            ref={selectRef}
            value={Value}
            {...rest}
          />
          {loading ? <Spinner size={14} /> : <IoMdArrowDropdown />}
        </label>
        <ContentOptions showDisplay={showOptions}>
          <Backdrop
            onClick={event => {
              event.stopPropagation();
              setIsFocused(false);
              setisFilled(!!selectRef.current?.value);
              setShowOptions(false);
            }}
          />
          <Options>
            {(children as React.ReactElement<any>[])?.map(child =>
              cloneElement(child, {
                selectOption,
                selection,
                MultiSelection,
                registerOption,
                unregisterOption,
              }),
            )}
          </Options>
        </ContentOptions>
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Content>
    </Container>
  );
};

export default Select3;
