import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: #FFFFFF;
    color: #2C405A;
    font-family: 'Heebo', sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }

  html, body, #root {
    height: 100%;
  }

  body, input, select, button, textarea {
    font-family: 'Heebo',  sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
    border: 0px;
    background: transparent;
  }

  ul {
    list-style: none;
  }

  :root {
    --unnamed-color-0079c4: #0079C4;
    --unnamed-color-8dabc4: #8DABC4;
    --unnamed-color-4ece3d: #4ECE3D;
    --unnamed-color-a8c6df: #A8C6DF;
    --unnamed-color-2c405a: #2C405A;
    --unnamed-color-0093ee: #0093EE;
    --unnamed-color-3f536e: #3F536E;
    --unnamed-color-40b630: #40B630;
    --unnamed-color-c5d9e8: #C5D9E8;
    --unnamed-color-ecf5fd: #ECF5FD;
    --unnamed-color-fafbfc: #FAFBFC;
    --unnamed-color-faca00: #FACA00;
    --unnamed-color-ff151f: #FF151F;
    --unnamed-color-fd7b1f: #FD7B1F;
    --unnamed-color-ffffff66: #FFFFFF;
    --unnamed-color-ab7df6: #AB7DF6;
    --unnamed-color-26c1c9: #26C1C9;
  }


`;
