import React, { useState, memo } from 'react';
import { Bubble } from 'react-chartjs-2';
import { ChartData, ChartOptions, ChartPoint } from 'chart.js';

import { Container } from './styles';
import Spinner from '../Spinner';
import { riskTypeLookup } from '../../pages/Opinions';

interface IChartBubbleProps {
  width?: number;
  height?: number;
  labels: string[];
  scores: {
    MA_MB: number;
    MA_B: number;
    MA_M: number;
    MA_A: number;
    MA_MA: number;
    A_MB: number;
    A_B: number;
    A_M: number;
    A_A: number;
    A_MA: number;
    M_MB: number;
    M_B: number;
    M_M: number;
    M_A: number;
    M_MA: number;
    B_MB: number;
    B_B: number;
    B_M: number;
    B_A: number;
    B_MA: number;
    MB_MB: number;
    MB_B: number;
    MB_M: number;
    MB_A: number;
    MB_MA: number;
  };
}

const ChartBubble: React.FC<IChartBubbleProps> = ({
  width,
  height,
  labels,
  scores,
}) => {
  const colorExtreme = 'rgba(255, 0, 0, 0.2)';
  const colorVeryHigh = 'rgba(204, 0, 0, 0.2)';
  const colorHigh = 'rgba(253, 123, 31, 0.2)';
  const colorMedium = 'rgba(254, 245, 209, 0.2)';
  const colorLow = 'rgba(206, 252, 200, 0.2)';
  const colorVeryLow = 'rgba(130, 255, 112, 0.2)';

  const options: ChartOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            callback(value, index, values) {
              if (value === 0) {
                return '';
              }
              return riskTypeLookup[Number(value)];
            },
            beginAtZero: true,
            min: 0,
            max: 6,
          },
          gridLines: {
            borderDash: [10],
            offsetGridLines: true,
            display: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            callback(value, index, values) {
              if (value === 0) {
                return '';
              }
              return riskTypeLookup[Number(value)];
            },
            beginAtZero: true,
            min: 0,
            max: 6,
          },
          gridLines: {
            borderDash: [10],
            offsetGridLines: true,
            display: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label(tooltipItens, data) {
          const { datasetIndex } = tooltipItens;

          if (datasetIndex && data.datasets) {
            const dataset = data.datasets[datasetIndex];

            if (dataset.data && tooltipItens.xLabel) {
              const { r } = dataset.data[0] as ChartPoint;

              const label = r?.toString();

              return label as string;
            }
          }
          return '';
        },
      },
    },
    // scale: {
    // ticks: {
    //  beginAtZero: true,
    // },
    // },
    // legend: {
    //   position: 'right',
    //   display: true,
    //   labels: {
    //     boxWidth: 50,
    //     usePointStyle: true,
    //   },
    // },
  };

  const [chartData] = useState<ChartData>({
    labels,
    datasets: [
      {
        label: 'Muito Baixo',
        data: [{ x: 1, y: 1, r: scores.MB_MB }],
        borderColor: '#B1B1B1',
        backgroundColor: colorVeryLow,
      },
      {
        label: 'Baixo 1',
        data: [{ x: 1, y: 2, r: scores.B_MB }],
        borderColor: '#B1B1B1',
        backgroundColor: colorLow,
      },
      {
        label: 'Baixo 2',
        data: [{ x: 1, y: 3, r: scores.M_MB }],
        borderColor: '#B1B1B1',
        backgroundColor: colorLow,
      },
      {
        label: 'Médio 1',
        data: [{ x: 1, y: 4, r: scores.A_MB }],
        borderColor: '#B1B1B1',
        backgroundColor: colorMedium,
      },
      {
        label: 'Alto 1',
        data: [{ x: 1, y: 5, r: scores.MA_MB }],
        borderColor: '#B1B1B1',
        backgroundColor: colorHigh,
      },
      {
        label: 'Baixo 3',
        data: [{ x: 2, y: 1, r: scores.MB_B }],
        borderColor: '#B1B1B1',
        backgroundColor: colorLow,
      },
      {
        label: 'Baixo 4',
        data: [{ x: 2, y: 2, r: scores.B_B }],
        borderColor: '#B1B1B1',
        backgroundColor: colorLow,
      },
      {
        label: 'Médio 2',
        data: [{ x: 2, y: 3, r: scores.M_B }],
        borderColor: '#B1B1B1',
        backgroundColor: colorMedium,
      },
      {
        label: 'Médio 3',
        data: [{ x: 2, y: 4, r: scores.A_B }],
        borderColor: '#B1B1B1',
        backgroundColor: colorMedium,
      },
      {
        label: 'Alto 2',
        data: [{ x: 2, y: 5, r: scores.MA_B }],
        borderColor: '#B1B1B1',
        backgroundColor: colorHigh,
      },
      {
        label: 'Baixo 5',
        data: [{ x: 3, y: 1, r: scores.MB_M }],
        borderColor: '#B1B1B1',
        backgroundColor: colorLow,
      },
      {
        label: 'Médio 4',
        data: [{ x: 3, y: 2, r: scores.B_M }],
        borderColor: '#B1B1B1',
        backgroundColor: colorMedium,
      },
      {
        label: 'Médio 5',
        data: [{ x: 3, y: 3, r: scores.M_M }],
        borderColor: '#B1B1B1',
        backgroundColor: colorMedium,
      },
      {
        label: 'Alto 3',
        data: [{ x: 3, y: 4, r: scores.A_M }],
        borderColor: '#B1B1B1',
        backgroundColor: colorHigh,
      },
      {
        label: 'Muito Alto 1',
        data: [{ x: 3, y: 5, r: scores.MA_M }],
        borderColor: '#B1B1B1',
        backgroundColor: colorVeryHigh,
      },
      {
        label: 'Médio 6',
        data: [{ x: 4, y: 1, r: scores.MB_A }],
        borderColor: '#B1B1B1',
        backgroundColor: colorMedium,
      },
      {
        label: 'Médio 7',
        data: [{ x: 4, y: 2, r: scores.B_A }],
        borderColor: '#B1B1B1',
        backgroundColor: colorMedium,
      },
      {
        label: 'Alto 4',
        data: [{ x: 4, y: 3, r: scores.M_A }],
        borderColor: '#B1B1B1',
        backgroundColor: colorHigh,
      },
      {
        label: 'Alto 5',
        data: [{ x: 4, y: 4, r: scores.A_A }],
        borderColor: '#B1B1B1',
        backgroundColor: colorHigh,
      },
      {
        label: 'Muito Alto 2',
        data: [{ x: 4, y: 5, r: scores.MA_A }],
        borderColor: '#B1B1B1',
        backgroundColor: colorVeryHigh,
      },
      {
        label: 'Alto 6',
        data: [{ x: 5, y: 1, r: scores.MB_MA }],
        borderColor: '#B1B1B1',
        backgroundColor: colorHigh,
      },
      {
        label: 'Alto 7',
        data: [{ x: 5, y: 2, r: scores.B_MA }],
        borderColor: '#B1B1B1',
        backgroundColor: colorHigh,
      },
      {
        label: 'Muito Alto 3',
        data: [{ x: 5, y: 3, r: scores.M_MA }],
        borderColor: '#B1B1B1',
        backgroundColor: colorVeryHigh,
      },
      {
        label: 'Muito Alto 4',
        data: [{ x: 5, y: 4, r: scores.A_MA }],
        borderColor: '#B1B1B1',
        backgroundColor: colorVeryHigh,
      },
      {
        label: 'Muito Alto',
        data: [{ x: 5, y: 5, r: scores.MA_MA }],
        borderColor: '#B1B1B1',
        backgroundColor: colorExtreme,
      },
    ],
  });
  const [error] = useState(false);
  const [loading] = useState(false);

  return (
    <Container>
      {error ? (
        <h1>Error ao processar gráfico ...</h1>
      ) : loading ? (
        <Spinner />
      ) : (
        <Bubble
          data={chartData}
          width={width || 300}
          height={height || 100}
          options={options}
        />
      )}
    </Container>
  );
};

export default memo(ChartBubble);
