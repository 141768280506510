import React from 'react';

import { useLocation } from 'react-router-dom';
import { Container } from './styles';

interface IItemMenuProps {
  route: string;
  nameTitle: string;
}

const ItemSideMenu: React.FC<IItemMenuProps> = ({
  route,
  nameTitle,
  ...rest
}) => {
  const location = useLocation();
  return (
    <Container
      title={nameTitle}
      to={route}
      selected={!!location.pathname.includes(route)}
      {...rest}
    />
  );
};

export default ItemSideMenu;
