import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  overflow-y: auto;
  height: calc(100vh);

  > header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 17px;
    > svg {
      cursor: pointer;
      color: #8dabc4;
      &:hover {
        color: #0093ee;
      }
    }
    > h1 {
      color: var(--unnamed-color-2c405a);
      text-align: left;
      font-weight: 400;
      font-size: 22px;
      letter-spacing: 0px;
      margin-left: 16px;
    }
  }
`;

export const HeaderProject = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 34px 105px 46px 105px;
`;

export const TabsProject = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &.TabsProjectContent {
    border-top: 1px solid #b7d2e5cc;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;

  h1 {
    color: var(--unnamed-color-3f536e);
    text-align: left;
    font-size: 20px;
    letter-spacing: 0px;

    span {
      color: var(--unnamed-color-8dabc4);
      text-align: left;
      font-size: 15px;
      letter-spacing: 0px;
      margin-left: 11px;
    }
  }

  p {
    color: var(--unnamed-color-8dabc4);
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0px;
    opacity: 1;
  }
`;

export const BarRight = styled.div`
  display: flex;
  font-size: 8px;

  div.Percents {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--unnamed-color-ecf5fd) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;

    div.PercentBar {
      padding: 4px 23px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #4ece3d;
    color: white;
    text-align: left;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    border: 1px solid #0000000d;
    border-radius: 4px;
    margin-left: 18px;
    width: 171px;
    border: 0px;
  }
`;

export const PercentBar = styled.div`
  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--unnamed-color-0079c4);
    text-align: left;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0px;
    opacity: 1;

    span {
      color: var(--unnamed-color-3f536e);
      text-align: left;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0px;
      opacity: 1;
      margin-left: 7px;
    }
  }
`;

export const HeaderTabs = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 0px 32px 0px 105px;
`;

export const ContentProject = styled.div`
  display: flex;
  width: 100%;
  padding: 34px 105px 46px 105px;
`;

export const TabHeader = styled.div`
  display: flex;
  margin-right: 65px;
  color: var(--unnamed-color-8dabc4);
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
  position: relative;
  padding-bottom: 8px;

  &.active {
    color: var(--unnamed-color-2c405a);
    text-align: left;

    &::before {
      content: '';
      width: 100%;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: #0093ee;
    }
  }
`;

export const Tab = styled.div`
  display: flex;
  width: 100%;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 110px;
  min-height: 70px;
  max-height: 70px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -2px 2px #0000001a;
  opacity: 1;
  z-index: 2;

  button {
    padding: 10px 24px;
    color: var(--unnamed-color-0079c4);
    text-align: left;
    font-weight: 500;
    font: normal normal medium 15px/22px Heebo;
    letter-spacing: 0px;
    color: #0079c4;
    opacity: 1;
    border: 1px solid var(--unnamed-color-0079c4);
    border: 1px solid #0079c4;
    border-radius: 4px;
  }
`;
