import styled, { css } from 'styled-components';

interface IBadgeProps {
  color?: string;
  background?: string;
  bordered?: boolean;
}

export const Container = styled.span<IBadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 14px;
  height: 25px;
  padding: 2px 16px;
  color: ${props => props.color || '#037bc5'};
  background: ${props => props.background || '#deeef7'};
  ${props =>
    props.bordered
      ? css`
          border: 1px solid ${props.color};
        `
      : css`
          border: 0;
        `};

  svg {
    margin: 0px 0px 0px 4px !important;
    cursor: pointer;
  }
`;
