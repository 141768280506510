import React from 'react';
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';

import { Container } from './styles';

interface ISpinnerProps {
  size?: number;
}

const override = css`
  display: flex;
`;

const Spinner: React.FC<ISpinnerProps> = ({ size }) => {
  return (
    <Container className="sweet-loading">
      <ClipLoader css={override} color="#19b2ff" loading size={size} />
    </Container>
  );
};

export default Spinner;
