import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)``;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0px 38px 38px 38px;

  > div.TextArea {
    margin-top: 33px;
  }

  > div.InputBoolean {
    margin-top: 33px;
  }
  > div.Input {
    margin-top: 33px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #f6f8fa;
  padding: 20px 38px;

  > button.Button {
    width: 195px;
  }
`;
