import styled, { css } from 'styled-components';
import { shade } from 'polished';
import Tooltip from '../Tooltip';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  innerLabel?: string;
  disabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.h1`
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #8dabc4;
  margin-bottom: 10px;
  display: flex;

  > .Icon {
    margin-left: 5px;
  }
`;

export const InnerLabel = styled.h1`
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #8dabc4;
`;

export const Content = styled.div<IContainerProps>`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: flex-start;
  background: #ffff;
  border-radius: 4px;
  padding: 6px;
  border: 1px solid #a8c6df;
  color: #8dabc4;
  font-weight: 400;
  position: relative;

  & + div {
    margin-top: 10px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: ${shade(0.2, '#8DABC4')};
      background-color: #ecf5fd;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: ${shade(0.2, '#8DABC4')};
    `}

  ${props =>
    props.disabled &&
    css`
      background: ${shade(0.03, '#FFF')};
    `}

  > label {
    display: flex;
    height: 100%;
    width: 100%;
    textarea {
      background: transparent;
      height: 100%;
      width: 100%;
      color: #3f536e !important;
      font-size: 15px;
      border: 0px;
      resize: none;
      padding-right: 30px;

      ${props =>
        props.innerLabel &&
        css`
          padding-left: 8px;
        `}

      &::placeholder {
        text-align: left;
        font-size: 15px;
        letter-spacing: 0px;
        color: #c5d9e8;
      }
    }
  }

  svg {
    margin-right: 10px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  svg {
    margin: 0;
    padding: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
