import React from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { IOpinion } from '../../../Opinions';

import { Container, Buttons } from './styles';

interface IOpinionBarComponentProps {
  customerOpinion: IOpinion;
  onEdit: () => void;
  onDelete: () => void;
}

const OpinionBarComponent: React.FC<IOpinionBarComponentProps> = ({
  customerOpinion,
  onEdit,
  onDelete,
}) => {
  return (
    <Container>
      <div>
        {customerOpinion.status === 'Criado' && (
          <Buttons>
            <MdEdit onClick={onEdit} size={20} />
            <MdDelete onClick={onDelete} size={20} />
          </Buttons>
        )}
        <h1>Status</h1>
        <h2>{customerOpinion.status}</h2>
      </div>
    </Container>
  );
};

export default OpinionBarComponent;
