import React from 'react';
import OpinionBadge from '../../../OpinionBadge';
import { ICustomerProcess } from '../../../Process';
import CardStageProgressBar from '../CardStageProgressBar';

import { Container } from './styles';

export type BadgesName = 'busines' | 'compliance' | 'juridical' | 'security';

interface IProcessDisplayCardProps {
  customer_process: ICustomerProcess | undefined;
  percent_filled: number;
  badges?: BadgesName[];
}

const ProcessDisplayCard: React.FC<IProcessDisplayCardProps> = ({
  customer_process,
  percent_filled,
  badges,
}) => {
  return (
    <Container>
      {customer_process?.status === 'Preenchimento' ? (
        <CardStageProgressBar percent={percent_filled} />
      ) : (
        <>
          {customer_process && badges?.includes('busines') && (
            <OpinionBadge
              name="busines"
              label="de Negócios"
              opinion_status={
                customer_process?.formated_opinions.busines.status
              }
            />
          )}
          {customer_process && badges?.includes('compliance') && (
            <OpinionBadge
              name="compliance"
              label="de Compliance"
              opinion_status={
                customer_process?.formated_opinions.compliance.status
              }
            />
          )}
          {customer_process && badges?.includes('juridical') && (
            <OpinionBadge
              name="juridical"
              label="Jurídici"
              opinion_status={
                customer_process?.formated_opinions.juridical.status
              }
            />
          )}
          {customer_process && badges?.includes('security') && (
            <OpinionBadge
              name="security"
              label="de Cybersecurity"
              opinion_status={
                customer_process?.formated_opinions.security.status
              }
            />
          )}
        </>
      )}
    </Container>
  );
};

export default ProcessDisplayCard;
