import React from 'react';

import { Container, ProgressBar } from './styles';

interface IProcessProgressBarProps {
  percent: number;
  onCancel?(): void;
}

const ProcessProgressBar: React.FC<IProcessProgressBarProps> = ({
  percent,
}) => {
  return (
    <Container>
      <div>
        <ProgressBar percent={Number(percent ? percent.toFixed(0) : 0)} />
      </div>
      <h1>{Number(percent ? percent.toFixed(0) : 0)}%</h1>
    </Container>
  );
};

export default ProcessProgressBar;
