import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  text-decoration: none;
  position: relative;

  min-width: 226px;
  min-height: 280px;
  max-width: 226px;
  max-height: 280px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b7d2e5cc;
  border-radius: 8px;
  opacity: 1;
  margin-left: 13px;
  cursor: pointer;

  &:hover {
    background-color: ${shade(0.03, '#FFF')};
  }

  > h1 {
    padding: 0px 20px;
    margin-top: 15px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 0px;
    color: #2c405a;
    opacity: 1;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > h3 {
    text-align: center;
    font-size: 14px;
    letter-spacing: 0px;
    color: #8dabc4;
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ButtonEdit = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  border-radius: 50%;
  background-color: #fafbfc;
  z-index: 10;

  cursor: pointer;

  &:hover {
    background-color: ${shade('0.1', '#fafbfc')};
  }

  > svg {
    font-size: 18px;
    color: #8dabc4;
    margin: 6px;
  }
`;
