import styled from 'styled-components';

export const Container = styled.div`
  > h1 {
    text-align: right;
    font: normal normal normal 12px/18px Heebo;
    letter-spacing: 0px;
    color: #8dabc4;
  }
`;
