import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { MdHome } from 'react-icons/md';
import { RiArchiveDrawerFill, RiMindMap } from 'react-icons/ri';
import { FaUsersBetweenLines } from 'react-icons/fa6';
import { CgTemplate } from 'react-icons/cg';
import { ImUser } from 'react-icons/im';
import { LuListChecks } from 'react-icons/lu';
import { FaHandshake } from 'react-icons/fa';
import { LiaClipboardListSolid } from 'react-icons/lia';
import { AiOutlineAudit } from 'react-icons/ai';
import { HiMiniHandThumbUp } from 'react-icons/hi2';
import { Container, Content, NavContent } from './styles';
import Header from '../../components/Header';
import SideMenu from '../../components/SideMenu';
import ItemSideMenu from '../../components/SideMenu/ItemSideMenu';
import Dashboard from '../Dashboard';
import Templates from '../Templates';
import Customers from '../Customers';
import Partners from '../Partners';
import Projects from '../Projects';
import { useAuth } from '../../hooks/auth';
import DataMapping from '../DataMapping';
import DPIA from '../DPIA';
import Audit from '../Audit';
import CustomerUsers from '../CustomerUsers';
import Opinions from '../Opinions';
import OpinionView from '../Opinions/OpinionView';
import CustomerData from '../CustomerData';
import AssessmentClient from '../AssessmentClient';

const Home: React.FC = () => {
  const match = useRouteMatch();
  const {
    isAdmin,
    isPartnerConsultant,
    isCustomerDPO,
    isCustomerDPL,
    isCustomerResponsableDirectorate,
    isCustomerResponsableDepartment,
    isCustomerResponsableProcess,
  } = useAuth();

  return (
    <Container>
      <Header />
      <Content>
        <SideMenu>
          <div className="SideMenuIconsTop">
            {/* {isAdmin && (
              <ItemSideMenu
                nameTitle="Início"
                route={`${match.path}/dashboard`}
              >
                <MdHome size={29} />
              </ItemSideMenu>
            )} */}
            {isAdmin && (
              <ItemSideMenu
                nameTitle="Templates de Projeto"
                route={`${match.path}/templates`}
              >
                <CgTemplate size={25} />
              </ItemSideMenu>
            )}
            {(isAdmin || isPartnerConsultant) && (
              <ItemSideMenu
                nameTitle="Clientes"
                route={`${match.path}/customers`}
              >
                <FaUsersBetweenLines size={24} />
              </ItemSideMenu>
            )}
            {(isAdmin || isPartnerConsultant) && (
              <ItemSideMenu
                nameTitle="Pareceres"
                route={`${match.path}/opinions`}
              >
                <LiaClipboardListSolid size={26} />
              </ItemSideMenu>
            )}
            {isAdmin && (
              <ItemSideMenu
                nameTitle="Parceiros"
                route={`${match.path}/partners`}
              >
                <FaHandshake size={24} />
              </ItemSideMenu>
            )}
            {(isCustomerDPO ||
              isCustomerDPL ||
              isCustomerResponsableDirectorate ||
              isCustomerResponsableDepartment ||
              isCustomerResponsableProcess) && (
              <ItemSideMenu
                nameTitle="Mapeamento de dados"
                route={`${match.path}/data-mapping`}
              >
                <RiArchiveDrawerFill size={24} />
              </ItemSideMenu>
            )}
            {(isCustomerDPO || isCustomerDPL) && (
              <ItemSideMenu
                nameTitle="Mapa de dados"
                route={`${match.path}/customerData`}
              >
                <RiMindMap size={26} />
              </ItemSideMenu>
            )}
            {(isCustomerDPO || isCustomerDPL) && (
              <ItemSideMenu
                nameTitle="Planos de ação"
                route={`${match.path}/dpia`}
              >
                <LuListChecks size={28} />
              </ItemSideMenu>
            )}
            {(isCustomerDPO || isCustomerDPL) && (
              <ItemSideMenu
                nameTitle="Planos de recomendação"
                route={`${match.path}/AssessmentClient`}
              >
                <HiMiniHandThumbUp size={24} />
              </ItemSideMenu>
            )}
          </div>
          <div className="SideMenuIconsBottom">
            {(isCustomerDPO ||
              isCustomerDPL ||
              isCustomerResponsableDirectorate ||
              isCustomerResponsableDepartment) && (
              <ItemSideMenu
                nameTitle="Usuários"
                route={`${match.path}/customerUsers`}
              >
                <ImUser size={22} />
              </ItemSideMenu>
            )}
            {(isAdmin ||
              isCustomerDPO ||
              isCustomerDPL ||
              isCustomerResponsableDirectorate ||
              isCustomerResponsableDepartment ||
              isCustomerResponsableProcess) && (
              <ItemSideMenu nameTitle="Auditoria" route={`${match.path}/audit`}>
                <AiOutlineAudit size={24} />
              </ItemSideMenu>
            )}
          </div>
        </SideMenu>
        <NavContent>
          <Switch>
            <Route path={`${match.path}/dashboard`} component={Dashboard} />
            <Route path={`${match.path}/templates`} component={Templates} />
            <Route path={`${match.path}/customers`} component={Customers} />
            <Route path={`${match.path}/partners`} component={Partners} />
            <Route path={`${match.path}/projects/:id`} component={Projects} />
            <Route
              path={`${match.path}/data-mapping`}
              component={DataMapping}
            />
            <Route path={`${match.path}/dpia`} component={DPIA} />
            <Route
              path={`${match.path}/customerData`}
              component={CustomerData}
            />
            <Route
              path={`${match.path}/assessmentClient`}
              component={AssessmentClient}
            />

            <Route
              path={`${match.path}/customerUsers`}
              component={CustomerUsers}
            />
            <Route path={`${match.path}/audit`} component={Audit} />
            <Route path={`${match.path}/opinions`} component={Opinions} exact />
            <Route
              path={`${match.path}/opinions/:id`}
              component={OpinionView}
            />
          </Switch>
        </NavContent>
      </Content>
    </Container>
  );
};

export default Home;
