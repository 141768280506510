import React, { useContext } from 'react';

import { MdDragHandle, MdMoreVert, MdEdit, MdDelete } from 'react-icons/md';
import { toRoman } from 'roman-numerals';
import {
  Container,
  OptionsContainerTemplateModules,
  Categories,
} from './styles';
import { ITemplateModule } from '../../..';
import SuspensePainel from '../../../../../components/SuspensePainel';
import TemplateCategoryCard from '../TemplateCategoryCard';
import { TemplateContext } from '..';

interface ITemplateModuleCardProps {
  templateModule: ITemplateModule;
  templateModuleIndex: number;
}

const TemplateModuleCardProps: React.FC<ITemplateModuleCardProps> = ({
  templateModule,
  templateModuleIndex,
}) => {
  const { handleEditModule, handleRemoveModule } = useContext(TemplateContext);

  return (
    <Container
      key={templateModule.id}
      preTitle={`MODULO ${toRoman(templateModuleIndex + 1)}`}
      preTitleWidth="110px"
      title={templateModule.name}
      titleColor="#0079c4"
      clean
      barComponent={() => (
        <>
          <MdDragHandle size={24} style={{ cursor: 'grab' }} />
          <SuspensePainel icon={MdMoreVert}>
            <OptionsContainerTemplateModules>
              <button
                type="button"
                onClick={() => {
                  handleEditModule(templateModule);
                }}
              >
                <MdEdit size={24} />
                <h1>Editar Módulo</h1>
              </button>
              <button
                type="button"
                onClick={() => {
                  handleRemoveModule(templateModule.id);
                }}
              >
                <MdDelete size={24} />
                <h1>Remover Módulo</h1>
              </button>
            </OptionsContainerTemplateModules>
          </SuspensePainel>
        </>
      )}
    >
      <Categories>
        {!templateModule.categories.length && <h3>Nenhum categoria criada</h3>}
        {templateModule?.categories.map(
          (templateCategory, templateCategoryIndex) => (
            <TemplateCategoryCard
              key={templateCategory.id}
              templateModule={templateModule}
              templateCategory={templateCategory}
              templateCategoryIndex={templateCategoryIndex}
            />
          ),
        )}
      </Categories>
    </Container>
  );
};

export default TemplateModuleCardProps;
