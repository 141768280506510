import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div.OpinionItem {
    margin-bottom: 51px;
  }
`;
