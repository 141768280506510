import React, { useState, useEffect, memo } from 'react';
import { Radar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';

import { Container } from './styles';
import Spinner from '../Spinner';

interface IChartRadarProps {
  width?: number;
  height?: number;
  labels: string[];
  scores: {
    perfect: number[];
    score: number[];
    required: number[];
  };
}

const ChartRadar: React.FC<IChartRadarProps> = ({
  width,
  height,
  labels,
  scores,
}) => {
  const options: ChartOptions = {
    scale: {
      ticks: {
        beginAtZero: true,
      },
    },
    legend: {
      position: 'right',
      display: true,
      labels: {
        boxWidth: 50,
        usePointStyle: true,
      },
    },
  };

  const [chartData] = useState<ChartData>({
    labels,
    datasets: [
      {
        label: 'Avaliado',
        backgroundColor: 'transparent',
        borderColor: 'rgba(78,122,199,1)',
        pointBackgroundColor: 'rgba(78,122,199,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(78,122,199,1)',
        data: scores.score,
      },
      {
        label: 'Ideal',
        backgroundColor: 'transparent',
        borderColor: 'rgba(86,126,44,1)',
        pointBackgroundColor: 'rgba(86,126,44,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(86,126,44,1)',
        data: scores.perfect,
        borderDash: [10, 5],
      },
      {
        label: 'Requerido',
        backgroundColor: 'transparent',
        borderColor: 'rgba(255,154,6,1)',
        pointBackgroundColor: 'rgba(255,154,6,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255,154,6,1)',
        data: scores.required,
      },
    ],
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadChartConfig(): Promise<void> {
      setLoading(true);
      try {
        // const response = await api.get<ChartData>(`/charts`, {
        //   params: {
        //     template_id: chartPreference.template_id,
        //     chartPreference_id: chartPreference._id,
        //     maxGroupColumns,
        //   },
        // });
        // setChartData(oldChartData => {
        //   return {
        //     ...oldChartData,
        //     labels: response.data.labels,
        //     datasets: response.data.datasets,
        //   };
        // });
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    loadChartConfig();
  }, []);

  return (
    <Container className="ChartRadar">
      {error ? (
        <h1>Error ao processar gráfico ...</h1>
      ) : loading ? (
        <Spinner />
      ) : (
        <Radar
          data={chartData}
          width={width || 200}
          height={height || 100}
          options={options}
        />
      )}
    </Container>
  );
};

export default memo(ChartRadar);
