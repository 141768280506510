import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  background-color: #0079c4;
  min-height: 60px;
  max-height: 60px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* box-shadow: 0 1px 1px rgba(255, 255, 255, 0.25) !important;
    position: relative;
    z-index: 1;*/
`;

export const Logo = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    width: 60px;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  > h1 {
    flex: 1;
    margin-left: 30px;
    font-size: 22px;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: 1.4px;
    color: #ffffff;
    opacity: 1;
    font-size: 14px;
    text-transform: uppercase;
  }
`;

export const UserMenu = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 300;
  margin-right: 40px;

  svg {
    color: #8dabc4;
    cursor: pointer;
    margin-right: 10px;
  }
`;

export const OptionsContainer = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 16px;
    background-color: #ffffff;
    border: 0px;
    cursor: pointer;

    h1 {
      text-align: left;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #3f536e;
      width: 120px;
    }

    svg {
      color: #8dabc4;
      margin-right: 16px;
    }

    &:hover {
      background-color: #fafbfc;
    }
  }

  ul {
    flex: 1;
    max-height: 600px;
    overflow-y: scroll;
    li {
      width: 300px;
      height: 60px;
      padding: 10px;
      cursor: pointer;
      h1 {
        font-size: 14px;
        font-weight: 400;
        flex: 1;
      }
      p {
        font-size: 12px;
        flex: 1;
        text-align: right;
      }
    }
    li + li {
      border-top: 1px solid #eee;
    }
    li:hover {
      background-color: ${shade(0.1, '#FFF')};
    }
  }
`;

export const ModalMain = styled.main`
  padding: 30px;
  border-top: 1px solid #c5dbea;
  border-bottom: 1px solid #c5dbea;
  > div.Input {
    text-align: left;
    font-size: 18px;
    letter-spacing: 0px;
    color: #3f536e;
    margin-bottom: 17px;
  }
`;

export const ModalHeader = styled.header`
  background-color: #fafbfc;
  padding-top: 40px;
  padding-left: 30px;
  padding-bottom: 12px;
  border-radius: 8px 8px 0px 0px;
  h1 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #2c405a;
  }
`;

export const ModalFooter = styled.footer`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 16px 30px;
  button.Button {
    width: 160px;

    & + button {
      margin-left: 16px;
    }
  }
`;
