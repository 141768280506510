import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  SelectHTMLAttributes,
  cloneElement,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { IoMdArrowDropdown } from 'react-icons/io';

import {
  Container,
  Content,
  Error,
  Options,
  Backdrop,
  ContentOptions,
  Label,
} from './styles';
import Spinner from '../Spinner';
import { IOption } from './Option';

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  icon?: React.ComponentType<IconBaseProps>;
  multi?: boolean;
  onChange?(value: any): void;
  value?: any;
  multiSelection?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const Select2: React.FC<ISelectProps> = ({
  children,
  name,
  label: TopLabel,
  icon: Icon,
  value: Value,
  disabled,
  multiSelection: MultiSelection,
  onChange,
  loading,
  ...rest
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setisFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [showOptions, setShowOptions] = useState(false);
  const [selection, setSelection] = useState<IOption | IOption[]>(
    MultiSelection ? [] : { value: '', label: '' },
  );

  const handleClick = useCallback(() => {
    if (!disabled) {
      setIsFocused(true);
      setShowOptions(true);
    }
  }, [disabled]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue() {
        if (MultiSelection) {
          const filteredOptions = (selection as IOption[]).filter(
            sO => sO?.label !== undefined,
          );
          return filteredOptions.map(sO => sO.value);
        }
        return (selection as IOption).value;
      },
    });
  }, [MultiSelection, fieldName, registerField, selection]);

  useEffect(() => {
    if (MultiSelection) {
      if (defaultValue) {
        setSelection(defaultValue);
      } else {
        setSelection([]);
      }
    } else {
      setSelection(defaultValue);
    }
  }, [defaultValue, MultiSelection, name]);

  useEffect(() => {
    if (Value !== undefined) setSelection(Value);
  }, [Value]);

  const selectOption = useCallback(
    (option: IOption) => {
      if (MultiSelection) {
        const filteredOptions = (selection as IOption[]).filter(
          sO => sO?.label !== undefined,
        );
        if (filteredOptions.find(sO => sO.label === option.label)) {
          setSelection(filteredOptions.filter(sO => sO.label !== option.label));
        } else {
          setSelection([...filteredOptions, option]);
        }
      } else {
        setSelection(option);
        setShowOptions(false);
      }
    },
    [MultiSelection, selection],
  );

  const label = useMemo(() => {
    if (MultiSelection) {
      const filteredOptions = (selection as IOption[]).filter(
        sO => sO?.label !== undefined,
      );
      return filteredOptions.map(sO => sO.label).join(', ');
    }
    return (selection as IOption).label;
  }, [MultiSelection, selection]);

  return (
    <Container className="Select">
      {TopLabel && <Label>{TopLabel}</Label>}
      <Content
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        label={Label}
        onClick={handleClick}
        className="Select"
        disabled={disabled || false}
      >
        {Icon && <Icon size={20} />}
        <label htmlFor={name}>
          <span>{label}</span>
          <select
            id={name}
            defaultValue={defaultValue}
            multiple={MultiSelection}
            onChange={onChange}
            ref={selectRef}
            value={Value}
            {...rest}
          />
          {loading ? <Spinner size={14} /> : <IoMdArrowDropdown />}
        </label>
        <ContentOptions showDisplay={showOptions}>
          <Backdrop
            onClick={event => {
              event.stopPropagation();
              setIsFocused(false);
              setisFilled(!!selectRef.current?.value);
              setShowOptions(false);
            }}
          />
          <Options>
            {(children as React.ReactElement<any>[])?.map(child =>
              cloneElement(child, { selectOption, selection, MultiSelection }),
            )}
          </Options>
        </ContentOptions>
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Content>
    </Container>
  );
};

export default Select2;
