import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { IOpinion, IOpinionItem } from '../../../../../Opinions';

import { Container } from './styles';

interface IOpinionStatusProps {
  opinion_item: IOpinionItem;
}

const OpinionStatus: React.FC<IOpinionStatusProps> = ({ opinion_item }) => {
  const lastOpinion: IOpinion = useMemo(() => {
    return opinion_item.opinions[opinion_item.opinions.length - 1];
  }, [opinion_item.opinions]);

  return (
    <Container>
      {opinion_item.status === 'Preenchimento' &&
        !opinion_item.opinions.length && <h1>Aguardando preenchimento</h1>}
      {opinion_item.status === 'Solicitado' &&
        !opinion_item.opinions.length && <h1>Aguardando retorno</h1>}
      {opinion_item.status === 'Revalidação' && <h1>Revalidação sugerida</h1>}
      {opinion_item.status === 'Aceito' && <h1>Parecer Aceito</h1>}
      {opinion_item.status === 'Solicitado' &&
        !!opinion_item.opinions.length && (
          <h1>
            Último parecer solicitado em{' '}
            {lastOpinion.created_at &&
              format(new Date(lastOpinion.created_at), 'dd/MM/yyyy')}
          </h1>
        )}
    </Container>
  );
};

export default OpinionStatus;
