/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useCallback, useContext } from 'react';

import { NumberToAlphabet } from 'number-to-alphabet';
import currency from 'currency-formatter';
import { mutate as mutateGlobal } from 'swr';
import { Container } from './styles';
import { IProjectQuestion, ProjectContext, IProject } from '../../..';
import { IPartner } from '../../../../Partners';
import DisplayField from '../../../../../components/DisplayField';
import DisplayFieldInline from '../../../../../components/DisplayFieldInline';
import Select from '../../../../../components/Select';
import Option from '../../../../../components/Select/Option';

interface IComercialQuestionProps {
  question: IProjectQuestion;
  partners: IPartner[];
}

const defaultAlphabet = new NumberToAlphabet();

const ComercialQuestion: React.FC<IComercialQuestionProps> = ({
  question,
  partners,
}) => {
  const { project, setHasChanges } = useContext(ProjectContext);

  const handleChangePartnerId = useCallback(
    newPartnerId => {
      const partner = partners.find(p => p.id === newPartnerId);
      let newCost = 0;
      if (
        question.consulting_hours &&
        partner &&
        partner.technical_hourly_value
      ) {
        newCost = partner.technical_hourly_value * question.consulting_hours;
      }

      const newProject: IProject = {
        ...project,
        scopedModules: project.scopedModules
          .map(m => {
            if (m.id === question.project_module_id) {
              return {
                ...m,
                categories: m.categories.map(c => {
                  if (c.id === question.project_category_id) {
                    return {
                      ...c,
                      questions: c.questions.map(q => {
                        if (q.id === question.id) {
                          return {
                            ...q,
                            cost: newCost,
                            partner_executor_id: newPartnerId,
                          };
                        }
                        return q;
                      }),
                    };
                  }
                  return c;
                }),
              };
            }
            return m;
          })
          .map(m => ({
            ...m,
            cost: m.categories.reduce(
              (accC, c) =>
                (accC += c.questions.reduce(
                  (accQ, q) => (accQ += Number(q.cost) || 0),
                  0,
                )),
              0,
            ),
          })),
      };

      newProject.cost = newProject.scopedModules.reduce(
        (accM, m) => (accM += Number(m.cost) || 0),
        0,
      );

      mutateGlobal(`/projects/${question.project_id}`, newProject, false);
      setHasChanges(true);
    },
    [
      partners,
      project,
      question.consulting_hours,
      question.id,
      question.project_category_id,
      question.project_id,
      question.project_module_id,
      setHasChanges,
    ],
  );

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Container
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
      initialData={{ executor: question.partner_executor_id }}
    >
      <div className="Title">
        <h1>{defaultAlphabet.numberToString(question.position + 1)}.</h1>
        <h2>{question.question}</h2>
      </div>
      <div className="Line1">
        <DisplayField label="Recomendação">
          {question.recommendation}
        </DisplayField>
      </div>
      <div className="Line2">
        <DisplayFieldInline label="Impacto">
          {question.impact}
        </DisplayFieldInline>
        <DisplayFieldInline label="Prioridade">
          {question.priority}
        </DisplayFieldInline>
        <DisplayFieldInline label="Horas">
          {question.consulting_hours}
        </DisplayFieldInline>
      </div>
      <div className="Line3">
        <div className="ExecutorField">
          <h1>Executor</h1>
          <Select
            id="1"
            name="executor"
            onChange={handleChangePartnerId}
            disabled={project.status === 'Finalizado'}
          >
            {partners?.map(partner => (
              <Option key={partner.id} label={partner.name} value={partner.id}>
                {partner.name}
              </Option>
            ))}
          </Select>
        </div>

        <DisplayFieldInline label="Valor total" bold>
          {currency.format(question.cost || 0, { code: 'BRL' })}
        </DisplayFieldInline>
      </div>
    </Container>
  );
};

export default ComercialQuestion;
