/* eslint-disable react/require-default-props */
import React, { useRef, useEffect, useMemo } from 'react';
import { OptionTypeBase, GroupTypeBase, Styles } from 'react-select';
import Select, { Props as CreatableProps } from 'react-select/creatable';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Container, Label, Error } from './styles';

interface IProps
  extends CreatableProps<OptionTypeBase, true, GroupTypeBase<OptionTypeBase>> {
  name: string;
  label: string;
  explanation?: string;
  // secInputLabel: string;
  // treatmentHypothesisTypes?: ICustomerTreatmentHypothesisTypes[];
  // filterByType?: string;
  // secInputName: string;
  // secExplanation?: string;
  // disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function FormCreatableSelect({
  name,
  label,
  explanation,
  //secInputLabel,
  //treatmentHypothesisTypes,
  //filterByType,
  //secInputName,
  //secExplanation,
  //disabled,
  ...rest
}: IProps) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  // eslint-disable-next-line consistent-return
  const defaultValueModified = useMemo(() => {
    if (rest.isMulti) {
      return (defaultValue as string[]).map(df =>
        rest?.options ? rest?.options.find(o => o.value === df) : [],
      );
    }
    if (rest?.options) {
      return rest?.options.find(o => o.value === defaultValue);
    }
  }, [defaultValue, rest]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const customStyles: Styles<
    OptionTypeBase,
    true,
    GroupTypeBase<OptionTypeBase>
  > = {
    control: (provided, state) => ({
      ...provided,
      border: error ? '1px solid #c53030' : '1px solid #a8c6df',
      boxShadow: `0 0 0 0px '#a8c6df'`,
      '&:hover': {
        boxShadow: `0 0 0 0px '#a8c6df'`,
      },
      backgroundColor: state.isFocused
        ? '#ecf5fd'
        : state.isDisabled
        ? '#f7f7f7'
        : '#fff',
    }),
  };

  return (
    <Container className="FormCreatableSelect" isErrored={!!error}>
      <div className="ElementOne">
        {label && (
          <Label>
            {label}{' '}
            {explanation && (
              <AiOutlineQuestionCircle
                size={20}
                color="rgb(0,121,196)"
                title={explanation}
                className="Icon"
              />
            )}
          </Label>
        )}

        <div className="box_input">
          <div className="input_select">
            <Select
              styles={customStyles}
              defaultValue={defaultValueModified}
              ref={selectRef}
              classNamePrefix="react-select"
              noOptionsMessage={() => 'Nenhuma opção'}
              formatCreateLabel={inputLabel => `Criar ${inputLabel}`}
              {...rest}
            />
          </div>
          <div className="button_error">
            {error && (
              <Error title={error}>
                <FiAlertCircle color="#c53030" size={20} />
              </Error>
            )}
          </div>
        </div>
      </div>

      {/* <div className="ElementTwo">
        <FormSelect
          styles={customStyles}
          label={secInputLabel}
          explanation={secExplanation}
          placeholder=""
          name={secInputName}
          // onFocus={handleInputFocus}
          disabledSelec={disabled}
          isMulti
          options={treatmentHypothesisTypes
            ?.filter((p: { type: string }) => p.type === filterByType)
            ?.map((strData: { id: string; name: string }) => ({
              value: strData.id,
              label: strData.name,
            }))}
        />
      </div> */}
    </Container>
  );
}
