import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const Main = styled.main`
  padding: 30px 60px;
  border-top: 1px solid #c5dbea;
  border-bottom: 1px solid #c5dbea;
  height: 60vh;
  overflow-y: auto;
  > div.Line1 {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    grid-gap: 32px;
  }
  > div.Line2 {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    grid-gap: 32px;
    margin-top: 30px;
  }
  > div.Line3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
  > div.Line4 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 30px;
  }
  > div.Line5 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 30px;
    /* div.TextArea {
      div.Content {
        height: 90px;
      }
    } */
  }
  > div.Line6 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 30px;
    /* div.TextArea {
      div.Content {
        height: 90px;
      }
    } */
  }
  > div.Line7 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
    margin-top: 30px;
  }
  > div.Line8 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 30px;
  }
  > div.Line9 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 30px;
  }
`;

export const Header = styled.header`
  background-color: #fafbfc;
  padding-top: 40px;
  padding-left: 60px;
  padding-bottom: 12px;
  border-radius: 8px 8px 0px 0px;
  h1 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #2c405a;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 56px;
  background-color: #ffff;
  border-right: 1px solid #b7d2e5cc;
  border-left: 1px solid #b7d2e5cc;
  border-bottom: 1px solid #b7d2e5cc;
  border-radius: 0px 0px 4px 4px;

  > button {
    width: 160px;

    & + button {
      margin-left: 16px;
    }
  }
`;
