/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, { useContext, useMemo } from 'react';

import { toRoman } from 'roman-numerals';
import { NumberToAlphabet } from 'number-to-alphabet';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import {
  Container,
  Modules,
  HeaderRelatory,
  BodyRelatory,
  TableRelatory,
  TableTextRecommendation,
  RelatoryStyled,
} from './styles';
import { ProjectContext } from '..';

const defaultAlphabet = new NumberToAlphabet();

const sanitizer = DOMPurify.sanitize;

const Relatory: React.FC = () => {
  const { project } = useContext(ProjectContext);

  const modulesFiltered = useMemo(() => {
    return project.scopedModules.filter(
      module => module.status === 'Finalizado',
    );
  }, [project.scopedModules]);

  return (
    <Container>
      <Modules>
        {modulesFiltered.length ? (
          modulesFiltered.map((module, i) => (
            <div key={module.id}>
              <HeaderRelatory>
                {`MODULO ${`${toRoman(i + 1)} - ${module.name}`} `}
              </HeaderRelatory>

              {module?.categories.map((categorytxt, y) => (
                <TableTextRecommendation key={categorytxt.id}>
                  <h3>{`${(y + 1).toString()} - ${categorytxt.name}`}</h3>

                  {categorytxt.questions
                    .filter(
                      q =>
                        (q.score || q.score === 0) &&
                        (q.score_required || q.score_required === 0),
                    )
                    .map(questionTxt => (
                      <span key={questionTxt.id}>
                        {`${questionTxt.answer} `}
                      </span>
                    ))}
                </TableTextRecommendation>
              ))}

              <h4>Planos de Recomendação</h4>

              {module?.categories.map((category, y) => (
                <BodyRelatory key={category.id}>
                  {category.questions
                    .filter(
                      q =>
                        (q.score || q.score === 0) &&
                        (q.score_required || q.score_required === 0) &&
                        q.score < q.score_required,
                    )
                    .map(question => (
                      <RelatoryStyled key={question.id}>
                        <h3>
                          Contexto:
                          {`
                          ${toRoman(module.position + 1)}.${y + 1}.
                            ${defaultAlphabet.numberToString(
                              question.position + 1,
                            )}
                          `}
                        </h3>
                        <TableRelatory>
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <b>Data da Coleta</b>
                                </td>
                                <td>
                                  <p>
                                    {module.interview?.done_date &&
                                      format(
                                        new Date(module.interview?.done_date),
                                        'dd/MM/yyyy',
                                      )}
                                  </p>
                                </td>
                                <td>
                                  <b>Entrevistado</b>
                                </td>
                                <td>
                                  <p>
                                    {module.interview?.customerUser?.user.name}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Módulo / Categoria / Item</b>
                                </td>
                                <td>
                                  <p>
                                    {`${toRoman(module.position + 1)}.${y + 1}.
                                    ${defaultAlphabet.numberToString(
                                      question.position + 1,
                                    )}`}
                                  </p>
                                </td>
                                <td>
                                  <b>Tipo de recomendação</b>
                                </td>
                                <td>
                                  <p className="line_text">
                                    {question.recommendation_type?.name}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  <b>Contexto</b>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  <p className="line_text">
                                    {question.question}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  <b>Argumento Coletado</b>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  {question.answer && (
                                    <p
                                      className="line_text"
                                      // eslint-disable-next-line react/no-danger
                                      dangerouslySetInnerHTML={{
                                        __html: sanitizer(
                                          question.answer
                                            .replace('\n', '<br />')
                                            .replace('\t', '&emsp;'),
                                        ),
                                      }}
                                    />
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  <b>Recomendação</b>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  <p className="line_text">
                                    {question.recommendation}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  <b>Ação / Crítica / Motivação</b>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={4}>
                                  <p className="line_text">
                                    {question.motivation}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Horas de Consultoria para Tratativa</b>
                                </td>
                                <td>
                                  <p>{question.consulting_hours}</p>
                                </td>
                                <td>
                                  <b>Prazo recomendado (Meses)</b>
                                </td>
                                <td>
                                  <p>{question.recommendation_deadline}</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Prioridade (1 - 5: 1 = mais crítico)</b>
                                </td>
                                <td>
                                  <p>{question.priority}</p>
                                </td>
                                <td>
                                  <b>Impacto</b>
                                </td>
                                <td>
                                  <p>{question.impact}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </TableRelatory>
                      </RelatoryStyled>
                    ))}
                </BodyRelatory>
              ))}
            </div>
          ))
        ) : (
          <h1>Nenhum plano de recomendação</h1>
        )}
      </Modules>
    </Container>
  );
};

export default Relatory;
