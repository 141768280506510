import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Container, Main, Footer } from './styles';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';
import TextArea from '../../../../components/TextArea';
import Button from '../../../../components/Button';
import { IOpinion } from '../..';
import Select from '../../../../components/Select';
import Option from '../../../../components/Select/Option';
import Input from '../../../../components/Input';

interface IFormProps {
  initialData: IOpinion;
}

const FormOpinion: React.FC<IFormProps> = ({ initialData }) => {
  const form = useRef<FormHandles>(null);
  const history = useHistory();

  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async ({
      risk_identified,
      impact,
      probability,
      risk,
      risk_answer,
      description,
      plan_action,
      deadline,
    }: IOpinion) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          risk_identified: Yup.string().required(
            'Risco identificado é obrigatório',
          ),
          impact: Yup.string().required('Impacto é obrigatório'),
          probability: Yup.string().required('Probabilidade é obrigatório'),
          risk: Yup.string().required('Risco é obrigatório'),
          risk_answer: Yup.string().required('Resposta ao risco é obrigatório'),
          description: Yup.string().required('Parecer é obrigatório'),
          plan_action: Yup.string().required('Plano de ação é obrigatório'),
          deadline: Yup.string().required('Prazo é obrigatório'),
        });
        await schema.validate(
          {
            risk_identified,
            impact,
            probability,
            risk,
            risk_answer,
            description,
            plan_action,
            deadline,
          },
          {
            abortEarly: false,
          },
        );

        const newOpinion: any = {
          ...initialData,
          risk_identified,
          impact,
          probability,
          risk,
          risk_answer,
          description,
          plan_action,
          deadline,
        };

        setLoading(true);
        try {
          await api.patch(`/opinions/${initialData.id}`, newOpinion);
          addToast({
            title: 'Ok!',
            type: 'success',
            description: 'Parecer respondido com sucesso',
          });
          history.goBack();
        } catch (error) {
          addToast({
            title: 'Erro',
            type: 'error',
            description: 'Falha ao tentar salvar',
          });
        } finally {
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [initialData, addToast, history],
  );

  return (
    <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
      <Main>
        <div className="Line0">
          <TextArea
            label="Risco identificado"
            name="risk_identified"
            disabled={initialData.status === 'Respondido'}
          />
        </div>

        <div className="Line1">
          <Select
            label="Impacto"
            name="impact"
            disabled={initialData.status === 'Respondido'}
          >
            <Option key={1} value={1} label="Muito Baixo">
              Muito Baixo
            </Option>
            <Option key={2} value={2} label="Baixo">
              Baixo
            </Option>
            <Option key={3} value={3} label="Médio">
              Médio
            </Option>
            <Option key={4} value={4} label="Alto">
              Alto
            </Option>
            <Option key={5} value={5} label="Muito Alto">
              Muito Alto
            </Option>
          </Select>

          <Select
            label="Probabilidade"
            name="probability"
            disabled={initialData.status === 'Respondido'}
          >
            <Option key={1} value={1} label="Muito Baixo">
              Muito Baixo
            </Option>
            <Option key={2} value={2} label="Baixo">
              Baixo
            </Option>
            <Option key={3} value={3} label="Médio">
              Médio
            </Option>
            <Option key={4} value={4} label="Alto">
              Alto
            </Option>
            <Option key={5} value={5} label="Muito Alto">
              Muito Alto
            </Option>
          </Select>

          <Select
            label="Risco"
            name="risk"
            disabled={initialData.status === 'Respondido'}
          >
            <Option key={1} value={1} label="Muito Baixo">
              Muito Baixo
            </Option>
            <Option key={2} value={2} label="Baixo">
              Baixo
            </Option>
            <Option key={3} value={3} label="Médio">
              Médio
            </Option>
            <Option key={4} value={4} label="Alto">
              Alto
            </Option>
            <Option key={5} value={5} label="Muito Alto">
              Muito Alto
            </Option>
          </Select>

          <Select
            label="Resposta ao risco"
            name="risk_answer"
            disabled={initialData.status === 'Respondido'}
          >
            <Option key="Mitigar" value="Mitigar" label="Mitigar">
              Mitigar
            </Option>
            <Option key="Aceitar" value="Aceitar" label="Aceitar">
              Aceitar
            </Option>
            <Option key="Transferir" value="Transferir" label="Transferir">
              Transferir
            </Option>
            <Option key="Eliminar" value="Eliminar" label="Eliminar">
              Eliminar
            </Option>
          </Select>
        </div>

        <div className="Line2">
          <TextArea
            label="Parecer"
            name="description"
            disabled={initialData.status === 'Respondido'}
          />
        </div>

        <div className="Line3">
          <TextArea
            label="Plano de ação"
            name="plan_action"
            disabled={initialData.status === 'Respondido'}
          />
        </div>

        <div className="Line4">
          <Input
            label="Prazo"
            name="deadline"
            type="date"
            disabled={initialData.status === 'Respondido'}
          />
        </div>
      </Main>
      {initialData.status === 'Pendente' ? (
        <Footer>
          <Button
            background="#FFF"
            color="#A8C6DF"
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            background="#4ECE3D"
            loading={loading}
            loadingMsg="Salvando ..."
          >
            Salvar
          </Button>
        </Footer>
      ) : null}
    </Container>
  );
};

export default FormOpinion;
