import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 280px;
  height: 18px;

  > svg {
    width: 22px;
  }

  > h1 {
    margin-left: 16px;
    text-align: left;
    font: normal normal normal 12px/18px Heebo;
    letter-spacing: 0px;
    color: #3f536e;
  }
`;
