import React from 'react';
import { FaToolbox } from 'react-icons/fa';
import { HiClipboardList, HiLibrary } from 'react-icons/hi';
import { RiShieldCheckFill } from 'react-icons/ri';
import { IOpinionItemStatus } from '../../../Opinions';
import { IProcessOpinionType } from '../Process';

interface IOpinionBadgeProps {
  name: IProcessOpinionType;
  label: string;
  opinion_status: IOpinionItemStatus | undefined;
  noColor?: boolean;
  noTitle?: boolean;
}

const OpinionBadge: React.FC<IOpinionBadgeProps> = ({
  name,
  label,
  opinion_status,
  noColor,
  noTitle,
}) => {
  let color = '#a8c6df';
  let title = 'Não Recomendado';

  if (!noColor) {
    if (opinion_status === 'Solicitado') {
      color = '#FFBC00';
      title = `Parecer ${label} solicitado`;
    }

    if (opinion_status === 'Aceito') {
      color = '#40B630';
      title = `Parecer ${label} certificado`;
    }

    if (opinion_status === 'Recomendado') {
      color = '#FF151F';
      title = `Parecer ${label} recomendado`;
    }

    if (opinion_status === 'Revalidação') {
      color = '#FD7B1F';
      title = `Parecer ${label} - Necessário revalidação`;
    }
  }

  if (noTitle) {
    title = '';
  }

  switch (name) {
    case 'busines': {
      return (
        <HiClipboardList
          size={22}
          color={color}
          title={title}
          style={{ cursor: 'pointer' }}
        />
      );
    }
    case 'compliance': {
      return (
        <FaToolbox
          size={18}
          color={color}
          title={title}
          style={{ cursor: 'pointer' }}
        />
      );
    }
    case 'juridical': {
      return (
        <HiLibrary
          size={20}
          color={color}
          title={title}
          style={{ cursor: 'pointer' }}
        />
      );
    }
    case 'security': {
      return (
        <RiShieldCheckFill
          size={18}
          color={color}
          title={title}
          style={{ cursor: 'pointer' }}
        />
      );
    }
    default: {
      return <h1>Opinion Not Found</h1>;
    }
  }
};

export default OpinionBadge;
