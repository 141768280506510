import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { mutate as mutateGlobal, cache } from 'swr';
import { Container } from './styles';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import { ICustomer } from '..';
import FormCustomer from '../forms/FormCustomer';

const CustomerForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    (customerReturned: ICustomer) => {
      const save = async (): Promise<void> => {
        const oldCustomers = cache.get('/customers');
        api.post<ICustomer>(`/customers/`, customerReturned).catch(error => {
          const { message } = error.response.data;
          let description = 'Ocorreu um erro ao salvar os dados do cliente.';
          if (message === 'Customer register number already used.') {
            description = 'CNPJ já utilizado.';
          }
          if (message === 'E-mail address already taken') {
            description = 'E-mail já utilizado.';
          }
          mutateGlobal('/customers', oldCustomers, false);
          addToast({
            type: 'error',
            title: 'Erro ao salvar',
            description,
          });
        });
        mutateGlobal(
          '/customers',
          async (customers: ICustomer[]) => {
            return [customerReturned, ...customers];
          },
          false,
        );
        history.goBack();
      };
      save();
    },
    [addToast, history],
  );

  return (
    <Container>
      <h1>Novo Cliente</h1>
      <FormCustomer onSubmit={handleSubmit} />
    </Container>
  );
};

export default CustomerForm;
