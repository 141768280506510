import styled from 'styled-components';

export const Container = styled.div`
  padding: 29px 42px;
  overflow: auto;

  > h1 {
    text-align: left;
    font-size: 22px;
    letter-spacing: 0px;
    color: #2c405a;
    opacity: 1;
    font-weight: 400;
    margin-bottom: 21px;
  }
`;
