import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)``;

export const Header = styled.header`
  background-color: #fafbfc;
  padding-top: 40px;
  padding-left: 30px;
  padding-bottom: 12px;
  border-radius: 8px 8px 0px 0px;
  h1 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #2c405a;
  }
  > div.Line1 {
    width: 200px;
    height: 42px;
  }
`;

export const Title = styled.main`
  margin-left: 30px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #2c405a;
`;

export const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: 70vh;
  overflow: auto;
  padding: 20px;

  > div.Line {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid #c5dbea;
    margin: 0px 10px 10px 7px;
    padding: 30px;

    > h2 {
      font-size: 16px;
      padding: 20px;
      margin-bottom: 20px;
    }

    > div.ChartRadar {
    }
  }

  > h1 {
    font-size: 25px;
    color: #2c405a;
  }
`;
