import styled from 'styled-components';
import Tooltip from '../Tooltip';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > h1 {
    color: var(--unnamed-color-8dabc4);
    text-align: left;
    font: normal normal normal 15px/19px Heebo;
    letter-spacing: 0px;
    color: #8dabc4;
    opacity: 1;
    width: 120px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 35px;
  grid-template-columns: repeat(8, 40px);
  margin-top: 10px;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      margin-right: 9px;
    }
  }

  h1 {
    color: #40b630;
    font-size: 12px;
    padding-top: 3px;
  }

  h2 {
    color: #ff151f;
    font-size: 12px;
    padding-top: 3px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  svg {
    margin: 0;
    padding: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
