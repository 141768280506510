/* eslint-disable no-lonely-if */
import React, { useRef, useState, useCallback, useContext } from 'react';

import { MdAdd, MdEdit, MdDelete, MdCheck } from 'react-icons/md';
import { format } from 'date-fns';
import { mutate as mutateGlobal } from 'swr';
import { toRoman } from 'roman-numerals';
import { v4 } from 'uuid';
import { Container, ModalConfirmationContent } from './styles';
import { IProject, IProjectInterview, ProjectContext } from '..';
import Button from '../../../components/Button';
import Modal, { IModalHandles } from '../../../components/Modal';

import FormInterview from './forms/FormInterview';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import LoadingMask from '../../../components/LoadingMask';

const Interviews: React.FC = () => {
  const modalInterview = useRef<IModalHandles>();
  const modalConfirmation = useRef<IModalHandles>();
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('Salvando');
  const [projectInterviewSelected, setProjectInterviewSelected] = useState<
    IProjectInterview
  >();
  const [modalNewInterviewState, setModalNewInterviewState] = useState(true);
  const { project } = useContext(ProjectContext);
  const { isAdmin } = useAuth();

  const handleSubmitInterview = useCallback(
    async ({
      project_module_id,
      customer_user_id,
      partner_id,
      partner_user_id,
      date,
      partnerUser,
    }: IProjectInterview) => {
      const module = project.scopedModules.find(
        m => m.id === project_module_id,
      );

      const customerUser = project.customer.customerUsers.find(
        i => i.id === customer_user_id,
      );

      const newInterview: IProjectInterview = {
        id: v4(),
        module,
        partner_id,
        partnerUser,
        partner_user_id,
        customer_user_id,
        customerUser,
        project_id: project.id,
        project_module_id,
        date,
      };

      modalInterview.current?.close();
      setLoadingMsg('Salvando');
      setLoading(true);
      if (modalNewInterviewState) {
        await api.post('/projectInterviews', newInterview);
        mutateGlobal(
          `/projects/${project.id}`,
          async (oldProject: IProject) => {
            return {
              ...oldProject,
              interviews: [...oldProject.interviews, newInterview],
            };
          },
          false,
        );
      } else {
        if (projectInterviewSelected) {
          await api.patch(
            `/projectInterviews/${projectInterviewSelected.id}`,
            newInterview,
          );
          mutateGlobal(
            `/projects/${project.id}`,
            async (oldProject: IProject) => {
              return {
                ...oldProject,
                interviews: oldProject.interviews.map(interview => {
                  if (interview.id === projectInterviewSelected.id) {
                    return newInterview;
                  }
                  return interview;
                }),
              };
            },
            false,
          );
        }
      }
      setLoading(false);
      // setHasChanges(true);
    },
    [
      modalNewInterviewState,
      project.customer.customerUsers,
      project.id,
      project.scopedModules,
      projectInterviewSelected,
    ],
  );

  const handleNewInterview = useCallback(() => {
    setModalNewInterviewState(true);
    setProjectInterviewSelected({
      id: v4(),
      project_id: project.id,
      project_module_id: '',
      customer_user_id: '',
      partner_id: '',
      partner_user_id: undefined,
      date: undefined,
      done: false,
    });
    modalInterview.current?.open();
  }, [project.id]);

  const handleEditInterview = useCallback(
    (interviewSelected: IProjectInterview) => {
      setModalNewInterviewState(false);
      setProjectInterviewSelected(interviewSelected);
      modalInterview.current?.open();
    },
    [],
  );

  const handleConfirmationRemoveInterview = useCallback(
    (interviewSelected: IProjectInterview) => {
      setProjectInterviewSelected(interviewSelected);
      modalConfirmation.current?.open();
    },
    [],
  );

  const handleRemoveInterview = useCallback(async () => {
    modalConfirmation.current?.close();
    if (projectInterviewSelected) {
      setLoadingMsg('Removendo');
      setLoading(true);
      await api.delete(`/projectInterviews/${projectInterviewSelected.id}`);
      mutateGlobal(
        `/projects/${project.id}`,
        async (oldProject: IProject) => {
          return {
            ...oldProject,
            interviews: oldProject.interviews.filter(
              i => i.id !== projectInterviewSelected.id,
            ),
          };
        },
        false,
      );
      setLoading(false);
    }
  }, [project.id, projectInterviewSelected]);

  return (
    <>
      {loading ? <LoadingMask text={loadingMsg} /> : null}
      <Container>
        {isAdmin && project.status !== 'Finalizado' ? (
          <header>
            <Button
              icon={MdAdd}
              background="#0079c4"
              onClick={handleNewInterview}
            >
              Nova
            </Button>
          </header>
        ) : null}
        <table>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Módulo</th>
              <th style={{ width: '24%' }}>Entrevistado</th>
              <th style={{ width: '24%' }}>Consultor</th>
              <th style={{ width: '15%' }}>Data</th>
              <th style={{ width: '7%' }} />
            </tr>
          </thead>
          <tbody>
            {!project.interviews.length ? (
              <tr>
                <td colSpan={5}>Nenhuma entrevista agendada</td>
              </tr>
            ) : null}

            {project.interviews.map(interview => (
              <tr key={interview.id}>
                <td>
                  {interview.module &&
                    `Módulo ${toRoman(interview.module.position + 1)} - ${
                      interview.module.name
                    }`}
                </td>
                <td>{interview.customerUser?.user.name}</td>
                <td>{interview.partnerUser?.user.name}</td>
                <td>
                  {interview.done
                    ? interview.done_date &&
                      format(new Date(interview.done_date), 'dd/MMM/yyyy HH:mm')
                    : interview.date &&
                      format(new Date(interview.date), 'dd/MMM/yyyy HH:mm')}
                </td>
                <td className="Icons">
                  {isAdmin &&
                  !interview.done &&
                  project.status !== 'Finalizado' ? (
                    <>
                      <MdEdit
                        size={20}
                        onClick={() => handleEditInterview(interview)}
                      />
                      <MdDelete
                        size={20}
                        onClick={() =>
                          handleConfirmationRemoveInterview(interview)
                        }
                      />
                    </>
                  ) : null}
                  {interview.done ? <MdCheck size={20} /> : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Container>
      {isAdmin ? (
        <Modal ref={modalInterview} size="md">
          <FormInterview
            initialData={projectInterviewSelected as IProjectInterview}
            onSubmit={handleSubmitInterview}
            onCancel={() => modalInterview.current?.close()}
          />
        </Modal>
      ) : null}

      <Modal ref={modalConfirmation} size="sm" removeCloseButton>
        <ModalConfirmationContent>
          <h1>Tem certeza que deseja remover a entrevista selecionada?</h1>
          <div className="wrapper">
            <Button onClick={handleRemoveInterview} background="#0079C4">
              Sim
            </Button>
            <Button
              onClick={() => modalConfirmation.current?.close()}
              background="#FF151F"
            >
              Não
            </Button>
          </div>
        </ModalConfirmationContent>
      </Modal>
    </>
  );
};

export default Interviews;
