import React from 'react';

import { Container, ProgressBar } from './styles';

interface IQuisProgressBarProps {
  percent: number | undefined;
}

const QuisProgressBar: React.FC<IQuisProgressBarProps> = ({ percent }) => {
  return (
    <Container>
      <h1>{percent}% completo</h1>
      <div>
        <ProgressBar percent={percent} />
      </div>
    </Container>
  );
};

export default QuisProgressBar;
