import styled from 'styled-components';

interface IContainerProps {
  color: string;
  backgroundColor: string;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 103px;
    height: 24px;
    background: ${props => props.backgroundColor} 0% 0% no-repeat padding-box;
    border-radius: 4px;
    margin-left: 16px;

    > h1 {
      color: ${props => props.color};
      text-align: left;
      font-size: 13px;
      letter-spacing: 0px;
    }
  }

  > section {
    display: flex;
    margin-left: 36px;
    > h1 {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8dabc4;
    }

    > h2 {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8dabc4;
      padding-left: 3px;
    }

    > h3 {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #2c405a;
      padding-left: 3px;
    }
  }
`;
