import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { mutate as mutateGlobal, cache } from 'swr';
import { Container } from './styles';
import { useToast } from '../../../hooks/toast';
import { ITemplate } from '..';
import api from '../../../services/api';
import FormTemplate from '../forms/FormTemplate';
import LoadingMask from '../../../components/LoadingMask';

const TemplateNew: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    (templateReturned: ITemplate) => {
      const save = async (): Promise<void> => {
        setLoading(true);
        await api
          .post<ITemplate>(`/templates/`, templateReturned)
          .catch(() => {
            const description =
              'Ocorreu um erro ao salvar os dados do template.';

            addToast({
              type: 'error',
              title: 'Erro ao salvar',
              description,
            });
          })
          .finally(() => {
            setLoading(false);
          });
        cache.set(`/templates/${templateReturned.id}`, templateReturned);
        mutateGlobal(
          '/templates',
          async (templates: ITemplate[]) => {
            return [templateReturned, ...templates];
          },
          false,
        );
        history.goBack();
      };
      save();
    },
    [addToast, history],
  );

  return (
    <Container>
      <h1>Novo template de projeto</h1>
      <FormTemplate onSubmit={handleSubmit} />
      {loading ? <LoadingMask text="Salvando Template" /> : null}
    </Container>
  );
};

export default TemplateNew;
