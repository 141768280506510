import React from 'react';

import { Container, Badges } from './styles';
import OpinionBadge from '../../../OpinionBadge';
import ProcessProgressBar from '../ProcessProgressBar';
import { ICustomerProcess } from '../..';

interface IProcessDisplayProps {
  customer_process: ICustomerProcess;
}

const ProcessDisplay: React.FC<IProcessDisplayProps> = ({
  customer_process,
}) => {
  return (
    <Container>
      {customer_process.status === 'Preenchimento' ? (
        <ProcessProgressBar percent={customer_process.percent_filled} />
      ) : (
        <Badges>
          <OpinionBadge
            name="busines"
            label="de Negócios"
            opinion_status={customer_process.formated_opinions.busines.status}
          />
          <OpinionBadge
            name="compliance"
            label="de Compliance"
            opinion_status={
              customer_process.formated_opinions.compliance.status
            }
          />
          <OpinionBadge
            name="juridical"
            label="Jurídico"
            opinion_status={customer_process.formated_opinions.juridical.status}
          />
          <OpinionBadge
            name="security"
            label="de Cybersecurity"
            opinion_status={customer_process.formated_opinions.security.status}
          />
        </Badges>
      )}
    </Container>
  );
};

export default ProcessDisplay;
