export interface IZipcode {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  localidade: string;
  logradouro: string;
  uf: string;
}

export default async function findZipCode(
  zipCode: string,
): Promise<IZipcode | undefined> {
  try {
    const result = await fetch(
      `https://viacep.com.br/ws/${zipCode}/json/`,
    ).then(res => res.json());
    return result as IZipcode;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}
