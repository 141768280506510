import React, { useRef, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Container, Header, Main, Footer } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Button from '../../../../components/Button';
import { ITemplateModule } from '../..';
import Select from '../../../../components/Select';
import Option from '../../../../components/Select/Option';
import TextArea from '../../../../components/TextArea';

interface IQuestionFormData {
  description: string;
  newModuleId: string;
  newCategoryId: string;
}

interface IFormProps {
  initialData?: IQuestionFormData;
  onSubmit(data: IQuestionFormData): void;
  onCancel?(): void;
  modules: ITemplateModule[];
  edit?: boolean;
}

const FormTemplateQuestion: React.FC<IFormProps> = ({
  onSubmit,
  onCancel,
  initialData,
  modules,
  edit,
}) => {
  const form = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async ({ description, newModuleId, newCategoryId }: IQuestionFormData) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          description: Yup.string().required('Pergunta é obrigatório'),
        });

        await schema.validate(
          { description },
          {
            abortEarly: false,
          },
        );

        onSubmit({ description, newModuleId, newCategoryId });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [onSubmit],
  );

  return (
    <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
      <Header>
        {edit ? <h1>Editar pergunta</h1> : <h1>Nova pergunta</h1>}
      </Header>
      <Main>
        <Select name="newModuleId" label="Nome do módulo" disabled={edit}>
          {modules.map(m => (
            <Option key={m.id} label={m.name} value={m.id}>
              {`${m.name} - ${m.id}`}
            </Option>
          ))}
        </Select>
        <Select name="newCategoryId" label="Nome da categoria" disabled={edit}>
          {modules
            .find(m => m.id === initialData?.newModuleId)
            ?.categories.map(c => (
              <Option key={c.id} label={c.name} value={c.id}>
                {`${c.name} - ${c.id}`}
              </Option>
            ))}
        </Select>
        <TextArea name="description" label="Pergunta" rows={3} />
      </Main>
      <Footer>
        <Button
          type="submit"
          background="transparent"
          color="grey"
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button type="submit">Salvar</Button>
      </Footer>
    </Container>
  );
};

export default FormTemplateQuestion;
