/* eslint-disable react/jsx-indent */
import React from 'react';

import { toRoman } from 'roman-numerals';
import { format } from 'date-fns';
import { Container } from './styles';
import { IProject } from '../../Projects';

interface IInterviewsTableProps {
  project: IProject;
}

const InterviewsTable: React.FC<IInterviewsTableProps> = ({ project }) => {
  return (
    <Container>
      <thead>
        <tr>
          <th style={{ width: '30%' }}>Módulo</th>
          <th style={{ width: '24%' }}>Entrevistado</th>
          <th style={{ width: '24%' }}>Consultor</th>
          <th style={{ width: '22%' }}>Data</th>
        </tr>
      </thead>
      <tbody>
        {!project.scopedModules.length ? (
          <tr>
            <td colSpan={5}>Nenhuma entrevista agendada</td>
          </tr>
        ) : null}

        {project.scopedModules.map(module => (
          <tr key={module.id}>
            <td>{`Módulo ${toRoman(module.position + 1)} - ${module.name}`}</td>
            <td>{module.interview?.customerUser?.user.name}</td>
            <td>{module.interview?.partnerUser?.user.name}</td>
            <td>
              {module.interview?.done
                ? module.interview.done_date &&
                  format(
                    new Date(module.interview.done_date),
                    'dd/MMM/yyyy HH:mm',
                  )
                : module.interview?.date &&
                  format(new Date(module.interview.date), 'dd/MMM/yyyy HH:mm')}
            </td>
          </tr>
        ))}
      </tbody>
    </Container>
  );
};

export default InterviewsTable;
