import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)`
  display: grid;
  grid-gap: 32px;

  > button {
    margin-top: 0px;
    background: var(--unnamed-color-0079c4) 0% 0% no-repeat padding-box;
    background: #0079c4 0% 0% no-repeat padding-box;
    border: 1px solid #0000000d;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: normal normal medium 15px/22px Heebo;
    letter-spacing: 0px;
    color: #ffffff;
    width: 146px;
    padding: 7px 30px;
  }

  > div.Buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    > button.SaveButton {
      background: var(--unnamed-color-0079c4) 0% 0% no-repeat padding-box;
      background: #0079c4 0% 0% no-repeat padding-box;
      border: 1px solid #0000000d;
      border-radius: 4px;
      opacity: 1;
      text-align: center;
      font: normal normal medium 15px/22px Heebo;
      letter-spacing: 0px;
      color: #ffffff;
      width: 146px;
      padding: 7px 30px;
      margin-left: 49px;
    }

    button.EditButton {
      border: 1px solid var(--unnamed-color-0079c4);
      border: 1px solid #0079c4;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
      color: var(--unnamed-color-0079c4);
      text-align: center;
      font: normal normal medium 15px/22px Heebo;
      letter-spacing: 0px;
      color: #0079c4;
      width: 146px;
      padding: 7px 30px;
    }
  }
`;
