import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)``;

export const Main = styled.main`
  background-color: #ffff;
  border: 1px solid #b7d2e5cc;
  border-radius: 4px 4px 0px 0px;
  padding: 46px 86px;

  > div.Line1 {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-gap: 32px;
  }

  > div.Line2 {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 2fr;
    grid-gap: 32px;
    margin-top: 38px;
  }

  > div.Line3 {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-gap: 32px;
    margin-top: 38px;
  }

  > div.Line4 {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 32px;
    margin-top: 38px;
  }

  div.Content {
    background-color: #ffff;
    border: 1px solid #b7d2e5cc;
    border-radius: 4px 4px 0px 0px;
    padding: 46px 86px;
  }
`;

export const HeaderPartnerUsers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 52px;

  > h1 {
    text-align: left;
    font-size: 12px;
    letter-spacing: 1.85px;
    color: #3f536e;
  }

  button {
    margin: 0px;
    width: 146px;
  }
`;

export const PartnerUsers = styled.div`
  margin-top: 32px;

  > div {
    margin-bottom: 10px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 56px;
  background-color: #ffff;
  border-right: 1px solid #b7d2e5cc;
  border-left: 1px solid #b7d2e5cc;
  border-bottom: 1px solid #b7d2e5cc;
  border-radius: 0px 0px 4px 4px;

  > button {
    width: 160px;

    & + button {
      margin-left: 16px;
    }
  }
`;

export const ExpansionContent = styled.div`
  padding: 24px 38px 27px 54px;
  > div.Line11 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px;
    button {
      margin-left: 16px;
      text-align: left;
      font-size: 13px;
      letter-spacing: 0px;
      color: #0093ee;
      border: 0px;
      background-color: transparent;
    }
  }
  > div.Line22 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 21px;
  }
  > div.Line33 {
    display: flex;
    margin-top: 36px;
    flex-wrap: wrap;

    span.Badge {
      padding: 2px 8px;
      margin-right: 8px;
      margin-top: 8px;
    }
  }
`;
