import React, {
  useRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Container, Main, Footer } from './styles';
import Button from '../../../../../../components/Button';
import Modal, { IModalHandles } from '../../../../../../components/Modal';

import TextArea from '../../../../../../components/TextArea';
import InputBoolean from '../../../../../../components/InputBoolean';
import InputHypothesisTypes from '../../../InputHypothesisTypes';
import Input from '../../../../../../components/Input';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import FormSelect from '../../../../../../components/FormSelect';
import {
  ICustomerProcess,
  ICustomerProcessData,
  ICustomerProcessShareByWhom,
  ICustomerProcessWho,
  ICustomerOriginCollectTypes,
  ICustomerTreatmentHypothesisTypes,
  ICustomerCollectPersonalDataTypes,
} from '../..';

import FormCreatableSelect from '../../../../../../components/FormCreatableSelect';
import FormCreatableDoubleSelect from '../../../../../../components/FormCreatableDoubleSelect';
import onlyUnique from '../../../../../../utils/unique';

import ModalConfirm from '../../components/ProcessConfirm';

interface IFormProps {
  initialData: ICustomerProcess;
  customer_data?: ICustomerProcessData[];
  customer_who?: ICustomerProcessWho[];
  customer_process_share_by_whom?: ICustomerProcessShareByWhom[];
  customer_origin_collect_types?: ICustomerOriginCollectTypes[];
  readOnly?: boolean;
  customer_treatment_hypothesis_types?: ICustomerTreatmentHypothesisTypes[];
  customer_collect_personal_data_types?: ICustomerCollectPersonalDataTypes[];
}

const FormProcessCollect: React.FC<IFormProps> = ({
  initialData,
  readOnly,
  customer_data,
  customer_who,
  customer_process_share_by_whom,
  customer_origin_collect_types,
  customer_treatment_hypothesis_types,
  customer_collect_personal_data_types,
}) => {
  const modalConfirm = useRef<IModalHandles>();
  const form = useRef<FormHandles>(null);
  const [collect_legislation_boolean, setCollect_legislation_boolean] =
    useState<boolean | undefined>(
      !!initialData?.data.collect?.fields.legislation,
    );

  const [sensitive_Data_boolean, setSensitive_Data_boolean] = useState<
    boolean | undefined
  >(!!initialData?.data.collect?.fields.data_sensitive);

  const [children_Data_boolean, setChildren_Data_boolean] = useState<
    boolean | undefined
  >(true);

  const [visibleOptionsWay, setVisibleOptionsWay] = useState(false);

  const [confirmSaving, setConfirmSaving] =
    useState<ICustomerProcess>(initialData);
  const closedModal = useCallback(() => {
    modalConfirm.current?.close();
  }, []);

  useEffect(() => {
    initialData.data.collect.fields.data_options_way?.map(value => {
      customer_collect_personal_data_types?.map(valueTypes => {
        if (
          value === valueTypes.id &&
          valueTypes.name.toUpperCase() === 'OUTROS'
        ) {
          setVisibleOptionsWay(true);
        }
      });
    });
  }, [customer_collect_personal_data_types, initialData]);

  const optionsByWhom = useMemo(() => {
    let options;

    if (customer_process_share_by_whom) {
      options = customer_who?.concat(customer_process_share_by_whom);
    }
    if (initialData.data.share.fields.by_whom) {
      options = options?.concat(
        initialData.data.share.fields.by_whom.map(f => ({
          id: f,
          name: f,
          customer_process_id: f,
        })),
      );
    }

    return options
      ?.map(cd => cd.name)
      .filter(onlyUnique)
      .map(strData => ({
        value: strData,
        label: strData,
      }));
  }, [
    customer_process_share_by_whom,
    customer_who,
    initialData.data.share.fields.by_whom,
  ]);

  const handleSubmit = useCallback(
    async ({ data }: ICustomerProcess) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          data: Yup.object().shape({
            collect: Yup.object().shape({
              fields: Yup.object().shape({
                objective: Yup.string().required('Campo obrigatório'),
                data_what: Yup.string().required('Campo obrigatório'),
                data_what_hypothesis_types:
                  Yup.string().required('Campo obrigatório'),
                origin_collect: Yup.string().required('Campo obrigatório'),
                data_options_way: Yup.string().required('Campo obrigatório'),
                data_by_who: Yup.string().required('Campo obrigatório'),
                legislation: collect_legislation_boolean
                  ? Yup.string().required('Campo obrigatório')
                  : Yup.string().nullable(),
                data_sensitive: sensitive_Data_boolean
                  ? Yup.string().required('Campo obrigatório')
                  : Yup.string().nullable(),

                data_children: children_Data_boolean
                  ? Yup.string().required('Campo obrigatório')
                  : Yup.string().nullable(),
              }),
            }),
          }),
        });

        await schema.validate(
          { data },
          {
            abortEarly: false,
          },
        );

        const newCustomerProcess: ICustomerProcess = {
          ...initialData,
          data: {
            ...initialData.data,
            collect: {
              ...initialData.data.collect,
              fields: {
                ...data.collect.fields,
                data_sensitive: data.collect.fields.data_sensitive?.filter(
                  w => {
                    if (sensitive_Data_boolean) {
                      return data.collect.fields.data_sensitive?.includes(w);
                    }
                    return false;
                  },
                ),

                data_children: data.collect.fields.data_children?.filter(w => {
                  if (children_Data_boolean) {
                    return data.collect.fields.data_children?.includes(w);
                  }
                  return false;
                }),

                data_what_hypothesis_types:
                  data.collect.fields.data_what_hypothesis_types?.filter(w => {
                    return data.collect.fields.data_what_hypothesis_types?.includes(
                      w,
                    );
                  }),
                legislation: data.collect.fields.legislation,
                firstrecording_collect: true,
              },
            },
            share: {
              ...initialData.data.share,
              fields: {
                ...initialData.data.share.fields,
                what: initialData.data.share.fields.what?.filter(w => {
                  if (w) {
                    return data.collect.fields.data_what?.includes(w);
                  }
                  return false;
                }),
              },
            },
          },
        };

        setConfirmSaving(newCustomerProcess);
        modalConfirm.current?.open();
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [
      collect_legislation_boolean,
      sensitive_Data_boolean,
      children_Data_boolean,
      initialData,
    ],
  );

  const disabled = useMemo(() => {
    if (readOnly) {
      return true;
    }
    if (
      initialData.status !== 'Preenchimento' &&
      initialData.status !== 'Revalidação' &&
      initialData.reopened_process !== true
    ) {
      return true;
    }
    return false;
  }, [initialData.reopened_process, initialData.status, readOnly]);

  return (
    <>
      <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
        <Main>
          <TextArea
            label="Qual a motivação?"
            explanation="Indicar qual o propósito do processo e explicar porque ele é realizado na organização."
            name="data.collect.fields.objective"
            disabled={disabled}
          />

          <div className="line2">
            <FormCreatableDoubleSelect
              label="Quais os dados pessoais coletados?"
              name="data.collect.fields.data_what"
              explanation={
                'Dado pessoal: informação relacionada a pessoa natural identificada ou identificável.\nExemplo: nome, sobrenome, CPF, RG etc'
              }
              isMulti
              options={customer_data
                ?.map(cd => cd.name)
                .filter(onlyUnique)
                .map(strData => ({
                  value: strData,
                  label: strData,
                }))}
              placeholder=""
              isDisabled={disabled}
            />

            <FormSelect
              label="Qual a Hipótese de Tratamento?"
              explanation="Indicar a hipótese, que rege o tratamento dos dados pessoais neste processo."
              placeholder=""
              name="data.collect.fields.data_what_hypothesis_types"
              disabledSelec={disabled}
              isMulti
              options={customer_treatment_hypothesis_types
                ?.filter((p: { type: string }) => p.type === 'DADOS')
                ?.map((strData: { id: string; name: string }) => ({
                  value: strData.id,
                  label: strData.name,
                }))}
            />
          </div>

          <div className="line2">
            <InputHypothesisTypes
              label="Há coleta de dados pessoais sensíveis?"
              explanation={
                'Dado pessoal sensível: Dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política,\nfiliação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde\nou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;'
              }
              inputLabel="Qual a Hipótese de Tratamento?"
              explanationsec="Indicar a hipótese, que rege o tratamento de dados sensíveis neste processo."
              value="data.collect.fields.data_sensitive"
              name="data.collect.fields.data_sensitive"
              onChangeBoolean={booleanValue => {
                setSensitive_Data_boolean(booleanValue);
              }}
              disabled={disabled}
              initialData={initialData?.data.collect?.fields.data_sensitive}
              filterByType="DADOS_SENSIVEIS"
              treatmentHypothesisTypes={customer_treatment_hypothesis_types}
            />
          </div>
          <div className="line2">
            <InputHypothesisTypes
              label="Há coleta de dados pessoais de crianças ou adolescentes?"
              explanation={
                'O tratamento de dados pessoais de crianças e de adolescentes deverá ser realizado em\nseu melhor interesse, nos termos da LGPD (L13709). Os controladores deverão manter\npública a informação sobre os tipos de dados coletados, a forma de sua utilização\ne os procedimentos para o exercício dos direitos do Titular.'
              }
              explanationsec="Indicar a hipótese, que rege o tratamento de dados de crianças ou adolescentes neste processo."
              inputLabel="Qual a Hipótese de Tratamento?"
              value="data.collect.fields.data_children"
              name="data.collect.fields.data_children"
              onChangeBoolean={booleanValue =>
                setChildren_Data_boolean(booleanValue)
              }
              disabled={disabled}
              initialData={initialData?.data.collect?.fields.data_children}
              filterByType="DADOS_CRIANCAS_ADOLESCENTES"
              treatmentHypothesisTypes={customer_treatment_hypothesis_types}
            />
          </div>
          <FormSelect
            label="Quem fornece os dados pessoais utilizados no processo?"
            explanation={
              'Indicar quem fornece os dados utilizados neste processo. \n \t - Agente externo: qualquer pessoa física ou jurídica que não faz parte do grupo empresarial.  \n \t - Departamento interno: qualquer área, departamento ou setor que integra a estrutura do grupo empresarial.\n \t- Titular dos Dados Pessoais: quando o dado é fornecido diretamente pela pessoa física a quem ele se refere.\n Obs.: estas definições devem ser discutidas juntamente ao Comitê de Privacidade da organização.'
            }
            name="data.collect.fields.origin_collect"
            placeholder=""
            isMulti
            options={customer_origin_collect_types?.map(strData => ({
              value: strData.id,
              label: strData.name,
            }))}
            disabledSelec={disabled}
          />
          <FormSelect
            label="Qual a forma de coleta dos dados pessoais?"
            explanation="Descrever os meios utilizados para a coleta dos dados pessoais (e-mail, WhatsApp, sistemas, telefone, presencialmente, etc)."
            name="data.collect.fields.data_options_way"
            placeholder=""
            isMulti
            options={customer_collect_personal_data_types?.map(strData => ({
              value: strData.id,
              label: strData.name,
            }))}
            disabledSelec={disabled}
            onChange={event => {
              setVisibleOptionsWay(false);
              let have_others = false;

              if (event) {
                // eslint-disable-next-line array-callback-return
                event.map(value => {
                  if (value.label.toString().toUpperCase() === 'OUTROS') {
                    setVisibleOptionsWay(true);
                    have_others = true;
                  }
                });
              }
              if (have_others === false) {
                // eslint-disable-next-line no-param-reassign
                initialData.data.collect.fields.way = '';
              }
            }}
          />
          {visibleOptionsWay ? (
            <Input
              className="fieldway"
              label=""
              explanation=""
              placeholder="informe as outras formas de coleta dos dados pessoais."
              name="data.collect.fields.way"
              disabled={disabled}
            />
          ) : null}

          <FormCreatableSelect
            label="Qual o cargo, setor ou função do responsável por realizar a coleta?"
            explanation="Indicar o cargo, setor ou função do responsável por realizar a coleta."
            name="data.collect.fields.data_by_who"
            isMulti
            options={optionsByWhom}
            placeholder=""
            disabled={disabled}
          />

          <InputBoolean
            label="O processo é regido por alguma legislação?"
            explanation="Indicar se existem leis ou regulamentos que exijam a coleta de dados pessoais neste processo. Exemplo: CDC, CLT, BACEN etc."
            explanationsec="Indicar qual a legislação."
            inputLabel="Qual?"
            name="data.collect.fields.legislation"
            onChangeBoolean={booleanValue =>
              setCollect_legislation_boolean(booleanValue)
            }
            disabled={disabled}
          />
        </Main>

        <Modal ref={modalConfirm} removeCloseButton size="sm">
          <ModalConfirm
            newData={confirmSaving}
            onCancel={() => modalConfirm.current?.close()}
            onSubmit={closedModal}
          />
        </Modal>

        {!disabled && (
          <Footer>
            <Button type="submit" background="#4ECE3D">
              Salvar
            </Button>
          </Footer>
        )}
      </Container>
    </>
  );
};

export default FormProcessCollect;
