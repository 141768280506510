import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  display: flex;
  border: 0px;
  color: #3f536e;
  background: #ecf5fd;
  padding: 8px 15px;
  z-index: 51;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  min-height: 36px;

  &:hover {
    background: ${shade(0.05, '#ecf5fd')};
  }
`;
