import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { mutate as mutateGlobal, cache } from 'swr';
import { Container } from './styles';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import Spinner from '../../../components/Spinner';
import { useFetch } from '../../../hooks/fetch';
import FormCustomer from '../forms/FormCustomer';
import { ICustomer } from '..';

interface INewPasswordFormData {
  new_password: string;
}

const CustomerForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id: idCustomer } = useParams<{ id: string }>();
  const {
    data: customer,
    error,
    mutate,
  } = useFetch(`/customers/${idCustomer}`, { revalidateOnFocus: false });

  const handleSubmit = useCallback(
    (customerReturned: ICustomer) => {
      const save = async (): Promise<void> => {
        const oldCustomers = cache.get('/customers');
        const oldCustomer = cache.get(`/customers/${idCustomer}`);
        api.patch(`/customers/${idCustomer}`, customerReturned).catch(err => {
          const { message } = err.response.data;
          let description = 'Ocorreu um erro ao salvar os dados do cliente.';
          if (message === 'Customer register number already used.') {
            description = 'CNPJ já utilizado.';
          }
          if (message === 'E-mail address already taken') {
            description = 'E-mail já utilizado.';
          }
          mutate(oldCustomer, false);
          mutateGlobal('/customers', oldCustomers, false);
          addToast({
            type: 'error',
            title: 'Erro ao salvar',
            description,
          });
        });
        mutate(customerReturned, false);
        mutateGlobal(
          '/customers',
          async (customers: ICustomer[]) => {
            return customers.map(p =>
              p.id === idCustomer ? customerReturned : p,
            );
          },
          false,
        );
        history.goBack();
      };
      save();
    },
    [addToast, history, idCustomer, mutate],
  );

  if (!customer) {
    return (
      <Container>
        <Spinner />
      </Container>
    );
  }

  if (error) {
    addToast({
      type: 'error',
      title: 'Erro de carregamento',
      description: 'Ocorreu um erro ao carregar dados do cliente.',
    });
  }

  return (
    <Container>
      <h1>Editar Cliente</h1>
      <FormCustomer initialData={customer} onSubmit={handleSubmit} />
    </Container>
  );
};

export default CustomerForm;
