import arraySort from 'array-sort';
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { FiMoreVertical, FiSearch } from 'react-icons/fi';
import {
  IoIosArrowBack,
  IoMdArrowDropleft,
  IoMdArrowDropright,
} from 'react-icons/io';
import { MdAdd, MdDelete, MdEdit, MdDescription } from 'react-icons/md';
import {
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
  Route,
} from 'react-router-dom';
import { mutate as mutateGlobal } from 'swr';
import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import IconOrderBy from '../../../../components/IconOrderBy';
import InputOnly from '../../../../components/InputOnly';
import LoadingMask from '../../../../components/LoadingMask';
import Modal, { IModalHandles } from '../../../../components/Modal';
import SuspensePainel from '../../../../components/SuspensePainel';
import Table from '../../../../components/Table';
import { useAuth } from '../../../../hooks/auth';
import { useFetch } from '../../../../hooks/fetch';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import { ICustomerUser } from '../../../CustomerUsers';
import FormDepartment from '../../forms/FormDepartment';
import FormProcess from '../../forms/FormProcess';
import { ICustomerDirectorate } from '../Directorate';
import Process, { ICustomerProcess, mutateProcess } from '../Process';
import ProcessDisplay from '../Process/components/ProcessDisplay';
import ProcessStatus from '../Process/components/ProcessStatus';

import {
  Container,
  Header,
  DepartmentHeader,
  OptionsContainer,
  DepartmentTable,
  WrapperName,
  ItemPage,
  Pagination,
} from './styles';

export interface ICustomerDepartment {
  id: string;
  name: string;
  responsable_customer_user_id: string;
  responsable_customer_user: ICustomerUser | undefined;
  customer_directorate_id: string;
  customer_directorate: ICustomerDirectorate | undefined;
  scoped_customer_processes: ICustomerProcess[];
  percent_filled: number;
}

interface IOrderBy {
  fieldName: string;
  ascendant: boolean;
}

const CustomerDepartment: React.FC = () => {
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [inputFilter, setInputFilter] = useState('');
  const { id: idDepartment } = useParams<{ id: string }>();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const [processesToDisplay, setProcessesToDisplay] = useState<{
    filtered: number;
    processes: ICustomerProcess[];
  }>({ filtered: 0, processes: [] });
  const modalDepartment = useRef<IModalHandles>();
  const modalProcess = useRef<IModalHandles>();
  const [loading, setLoading] = useState(false);

  const [orderBy, setOrderBy] = useState<IOrderBy>({
    fieldName: 'cod',
    ascendant: false,
  });

  const [customerProcessSelected, setCustomerProcessSelected] =
    useState<ICustomerProcess>();

  const { addToast } = useToast();
  const {
    isCustomerDPO,
    isCustomerDPL,
    isCustomerResponsableDirectorate,
    isCustomerResponsableDepartment,
    customerUser,
  } = useAuth();

  const { data: customerDepartment } = useFetch<ICustomerDepartment>(
    `/customerDepartments/${idDepartment}`,
  );

  const { data: customerDirectorates } = useFetch<ICustomerDirectorate[]>(
    '/customerDirectorates',
  );

  const { data: customerUsers } = useFetch<ICustomerUser[]>('/customerUsers');

  const pages = useMemo(() => {
    if (!processesToDisplay.filtered) return 1;
    return Math.ceil(processesToDisplay.filtered / perPage);
  }, [processesToDisplay.filtered, perPage]);

  const pageNumbers = useMemo(() => {
    const maxDisplayPages = 15;
    const diff = Math.ceil(maxDisplayPages / 2);
    let start = 0;
    if (pages > maxDisplayPages && page > pages - diff) {
      start = pages - maxDisplayPages;
    } else if (pages > maxDisplayPages && page > diff) {
      start = page - diff;
    } else {
      start = 0;
    }
    const limiters = { start, end: start + maxDisplayPages };

    const pns: number[] = [];
    for (let i = 1; i <= pages; i += 1) {
      pns.push(i);
    }
    return pns.slice(limiters.start, limiters.end);
  }, [page, pages]);

  const handleSetPageUp = useCallback(() => {
    if (page < pages) setPage(page + 1);
  }, [page, pages]);

  const handleSetPageDown = useCallback(() => {
    if (page > 1) setPage(page - 1);
  }, [page]);

  useEffect(() => {
    if (customerDepartment) {
      if (!inputFilter.length) {
        setProcessesToDisplay({
          filtered: customerDepartment.scoped_customer_processes?.length,
          processes: customerDepartment.scoped_customer_processes,
        });
      }

      const filtered = customerDepartment.scoped_customer_processes?.filter(
        customerProcess =>
          customerProcess.name
            .toLowerCase()
            .includes(inputFilter.toLowerCase()) ||
          customerProcess.cod.toLowerCase().includes(inputFilter.toLowerCase()),
      );
      if (filtered) {
        const ordered = arraySort(filtered, orderBy.fieldName, {
          reverse: orderBy.ascendant,
        });
        const paged = ordered.slice(
          (page - 1) * perPage,
          (page - 1) * perPage + perPage,
        );

        if (customerDepartment)
          setProcessesToDisplay({
            filtered: filtered.length,
            processes: paged,
          });
      }
    }
  }, [
    customerDepartment,
    inputFilter,
    orderBy.ascendant,
    orderBy.fieldName,
    page,
    perPage,
  ]);

  const handleOrderBy = useCallback(
    ({ fieldName, ascendant }: IOrderBy) => {
      setOrderBy({
        fieldName,
        ascendant:
          fieldName === orderBy.fieldName ? !orderBy.ascendant : ascendant,
      });
    },
    [orderBy.ascendant, orderBy.fieldName],
  );

  const handleEditDepartment = useCallback(() => {
    modalDepartment.current?.open();
  }, []);

  const handleSubmitFormEditDepartment = useCallback(
    async (newCustomerDepartment: ICustomerDepartment) => {
      try {
        modalDepartment.current?.close();
        await api.patch(
          `/customerDepartments/${newCustomerDepartment.id}`,
          newCustomerDepartment,
        );
        mutateGlobal(
          `/customerDepartments/${newCustomerDepartment.id}`,
          newCustomerDepartment,
          false,
        );
        mutateGlobal(
          '/customerDirectorates',
          async (oldCustomerDirectorates: ICustomerDirectorate[]) => {
            return oldCustomerDirectorates.map(cd => {
              if (cd.id === newCustomerDepartment.customer_directorate_id) {
                return {
                  ...cd,
                  customer_departments: cd.scoped_customer_departments.map(
                    cde => {
                      if (cde.id === newCustomerDepartment.id) {
                        return newCustomerDepartment;
                      }
                      return cde;
                    },
                  ),
                };
              }
              return cd;
            });
          },
          false,
        );
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar salvar',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleDeleteDepartment = useCallback(async () => {
    try {
      setLoading(true);
      modalDepartment.current?.close();
      await api.delete(`/customerDepartments/${customerDepartment?.id}`);
      mutateGlobal(
        '/customerDirectorates',
        async (oldCustomerDirectorates: ICustomerDirectorate[]) => {
          return oldCustomerDirectorates.map(cd => {
            if (cd.id === customerDepartment?.customer_directorate_id) {
              return {
                ...cd,
                scoped_customer_departments:
                  cd.scoped_customer_departments.filter(
                    cde => cde.id !== customerDepartment?.id,
                  ),
              };
            }
            return cd;
          });
        },
        false,
      );
      history.goBack();
    } catch (error) {
      addToast({
        title: 'Erro',
        type: 'error',
        description: 'Falha ao tentar remover',
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, customerDepartment, history]);

  const handleSubmitFormProcess = useCallback(
    async (newCustomerProcess: ICustomerProcess) => {
      setLoading(true);
      try {
        modalProcess.current?.close();

        if (customerProcessSelected) {
          await api.patch(
            `/customerProcesses/${newCustomerProcess.id}`,
            newCustomerProcess,
          );
          mutateProcess(newCustomerProcess, 'edit');
        } else {
          await api.post('/customerProcesses', newCustomerProcess);
          mutateProcess(newCustomerProcess, 'add');
        }
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar salvar',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, customerProcessSelected],
  );

  const handleNewProcess = useCallback(() => {
    setCustomerProcessSelected(undefined);
    modalProcess.current?.open();
  }, []);

  const handleEditProcess = useCallback(
    (customer_process: ICustomerProcess) => {
      setCustomerProcessSelected(customer_process);
      modalProcess.current?.open();
    },
    [],
  );

  const handleOpenProcess = useCallback(
    async (customer_process: ICustomerProcess) => {
      try {
        const newCustomerProcess: ICustomerProcess = {
          ...customer_process,
          reopened_process: true,
          status: 'Preenchimento',
        };

        setLoading(true);
        await api.patch(
          `/customerProcesses/${newCustomerProcess?.id}`,
          newCustomerProcess,
        );

        if (customerDepartment) {
          const newCustomerDepartment: ICustomerDepartment = {
            ...customerDepartment,
            scoped_customer_processes:
              customerDepartment.scoped_customer_processes?.map(data => {
                if (data.id === newCustomerProcess.id) {
                  return newCustomerProcess;
                }
                return data;
              }),
          };

          setProcessesToDisplay({
            filtered: newCustomerDepartment.scoped_customer_processes?.length,
            processes: newCustomerDepartment.scoped_customer_processes.sort(
              function OrdercustomerUsers(
                a: ICustomerProcess,
                b: ICustomerProcess,
              ) {
                return a.cod < b.cod ? -1 : a.cod > b.cod ? 1 : 0;
              },
            ),
          });

          mutateGlobal(
            `/customerDepartments/${newCustomerDepartment.id}`,
            newCustomerDepartment,
            false,
          );
        }
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar reabrir o processo',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, customerDepartment],
  );

  const handleDeleteProcess = useCallback(
    async (customer_process: ICustomerProcess) => {
      try {
        setLoading(true);
        await api.delete(`/customerProcesses/${customer_process?.id}`);
        await mutateProcess(customer_process, 'delete');
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar remover',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  if (location.pathname !== `/home/data-mapping/department/${idDepartment}`) {
    return (
      <Switch>
        <Route path={`${match.path}/process/:id`} component={Process} />
      </Switch>
    );
  }

  if (!customerDepartment || !customerDirectorates) {
    return <LoadingMask text="Carregando..." />;
  }

  return (
    <>
      {loading ? <LoadingMask text="Salvando" /> : null}
      <Container>
        <Header>
          <IoIosArrowBack size={24} onClick={() => history.goBack()} />
          <div>
            <h1>{customerDepartment?.customer_directorate?.name} /</h1>
            <h2>{customerDepartment?.name}</h2>
          </div>
        </Header>
        {customerDepartment ? (
          <Card>
            <DepartmentHeader>
              <div>
                <WrapperName>
                  <h1>{customerDepartment.name}</h1>
                  {(isCustomerDPO ||
                    isCustomerDPL ||
                    isCustomerResponsableDirectorate ||
                    isCustomerResponsableDepartment) && (
                    <button type="button" onClick={handleEditDepartment}>
                      <MdEdit />
                    </button>
                  )}
                </WrapperName>
                <h3>
                  {customerDepartment.responsable_customer_user?.user.name ||
                    'Responsável indefinido'}
                </h3>
              </div>
              <InputOnly
                name="search"
                icon={FiSearch}
                onChange={e => setInputFilter(e.target.value)}
              />
              <Button
                icon={MdAdd}
                background="#0079C4"
                onClick={handleNewProcess}
              >
                Novo Processo
              </Button>
            </DepartmentHeader>
            <DepartmentTable>
              <Table>
                <thead>
                  <tr className="Header" style={{ backgroundColor: '#F6F8FA' }}>
                    <th style={{ width: '10%' }}>
                      <div>
                        <span>ID</span>
                        <IconOrderBy
                          ascendant={orderBy.ascendant}
                          size={18}
                          active={orderBy.fieldName === 'cod'}
                          onClick={() =>
                            handleOrderBy({
                              fieldName: 'cod',
                              ascendant: false,
                            })
                          }
                        />
                      </div>
                    </th>
                    <th style={{ width: '30%' }}>
                      {' '}
                      <div>
                        <span>PROCESSO</span>
                        <IconOrderBy
                          ascendant={orderBy.ascendant}
                          size={18}
                          active={orderBy.fieldName === 'name'}
                          onClick={() =>
                            handleOrderBy({
                              fieldName: 'name',
                              ascendant: false,
                            })
                          }
                        />
                      </div>
                    </th>
                    <th style={{ width: '25%' }}>
                      {' '}
                      <div>
                        <span>RESPONSÁVEL</span>
                        <IconOrderBy
                          ascendant={orderBy.ascendant}
                          size={18}
                          active={
                            orderBy.fieldName === 'responsable_customer_user_id'
                          }
                          onClick={() =>
                            handleOrderBy({
                              fieldName: 'responsable_customer_user_id',
                              ascendant: false,
                            })
                          }
                        />
                      </div>
                    </th>
                    <th style={{ width: '15%' }} />
                    <th style={{ width: '15%' }}>
                      {' '}
                      <div>
                        <span>STATUS</span>
                        <IconOrderBy
                          ascendant={orderBy.ascendant}
                          size={18}
                          active={orderBy.fieldName === 'projects'}
                          onClick={() =>
                            handleOrderBy({
                              fieldName: 'projects',
                              ascendant: false,
                            })
                          }
                        />
                      </div>
                    </th>
                    <th style={{ width: '5%' }} />
                  </tr>
                </thead>
                {!customerDepartment && (
                  <tbody>
                    <tr>
                      <td colSpan={6}>Carregando ...</td>
                    </tr>
                  </tbody>
                )}
                {customerDepartment &&
                  !customerDepartment.scoped_customer_processes.length && (
                    <tbody>
                      <tr>
                        <td colSpan={6}>Nenhum processo encontrado</td>
                      </tr>
                    </tbody>
                  )}
                <tbody>
                  {processesToDisplay.processes.map(customer_process => (
                    <tr
                      key={customer_process.id}
                      onClick={() =>
                        history.push(
                          `/home/data-mapping/department/${customerDepartment.id}/process/${customer_process.id}`,
                        )
                      }
                    >
                      <td>{customer_process.cod}</td>
                      <td>{customer_process.name}</td>
                      <td>
                        {customer_process.responsable_customer_user?.user
                          .name || 'Responsável indefinido'}
                      </td>
                      <td>
                        <ProcessDisplay customer_process={customer_process} />
                      </td>
                      <td style={{ textAlign: 'right', paddingRight: '10px' }}>
                        <ProcessStatus value={customer_process.status} />
                      </td>
                      <td
                        className="Icons"
                        style={{
                          paddingRight: '10px',
                        }}
                      >
                        <SuspensePainel icon={FiMoreVertical}>
                          <OptionsContainer>
                            {customerUser?.reopen_process && (
                              <button
                                type="button"
                                onClick={event => {
                                  handleOpenProcess(customer_process);
                                }}
                              >
                                <MdDescription size={20} />
                                <h1>Reabrir Processo</h1>
                              </button>
                            )}

                            <button
                              type="button"
                              onClick={event => {
                                // event.stopPropagation();
                                handleEditProcess(customer_process);
                              }}
                            >
                              <MdEdit size={20} />
                              <h1>Renomear Processo</h1>
                            </button>
                            <button
                              type="button"
                              onClick={event => {
                                // event.stopPropagation();
                                handleDeleteProcess(customer_process);
                              }}
                            >
                              <MdDelete size={20} />
                              <h1>Remover Processo</h1>
                            </button>
                          </OptionsContainer>
                        </SuspensePainel>
                      </td>
                    </tr>
                  ))}
                  <tr className="Pagination">
                    <td colSpan={6}>
                      <Pagination>
                        <li>
                          <IoMdArrowDropleft
                            size={18}
                            onClick={() => handleSetPageDown()}
                          />
                        </li>
                        {pageNumbers.map(pg => (
                          <ItemPage key={pg} active={pg === page}>
                            <button type="button" onClick={() => setPage(pg)}>
                              {pg}
                            </button>
                          </ItemPage>
                        ))}
                        <li>
                          <IoMdArrowDropright
                            size={18}
                            onClick={() => handleSetPageUp()}
                          />
                        </li>
                      </Pagination>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </DepartmentTable>
          </Card>
        ) : (
          <LoadingMask text="Carregando..." />
        )}
      </Container>
      <Modal ref={modalDepartment} size="sm" removeCloseButton>
        <FormDepartment
          initialData={customerDepartment}
          customerUsers={customerUsers || []}
          customerDirectorates={customerDirectorates}
          onSubmit={handleSubmitFormEditDepartment}
          onCancel={() => modalDepartment.current?.close()}
          onDelete={handleDeleteDepartment}
        />
      </Modal>
      <Modal ref={modalProcess} size="md" removeCloseButton>
        <FormProcess
          initialData={customerProcessSelected}
          customerUsers={customerUsers || []}
          customerDepartment={customerDepartment}
          onCancel={() => modalProcess.current?.close()}
          onSubmit={handleSubmitFormProcess}
        />
      </Modal>
    </>
  );
};

export default CustomerDepartment;
