import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { v4 } from 'uuid';
import { mutate as mutateGlobal } from 'swr';
import { Container, Header, Main, Footer } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Button from '../../../../components/Button';
import { ICustomerDirectorate } from '../../components/Directorate';
import Input from '../../../../components/Input';
import { ICustomerUser } from '../../../CustomerUsers';
import Modal, { IModalHandles } from '../../../../components/Modal';
import FormCustomerUserFromCustomer from '../../../CustomerUsers/forms/FormCustomerUserFromCustomer';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import LoadingMask from '../../../../components/LoadingMask';
import { useAuth } from '../../../../hooks/auth';
import FormSelect from '../../../../components/FormSelect';

interface IFormProps {
  initialData?: ICustomerDirectorate | undefined;
  customerUsers: ICustomerUser[];
  onSubmit(newCustomerDirecotorate: ICustomerDirectorate): void;
  onCancel(): void;
  onDelete?(): void;
}

interface IDirectorateFormData {
  name: string;
  responsable_customer_user_id: string;
  dpl_customer_user_id: string;
}

const FormDirectorate: React.FC<IFormProps> = ({
  initialData,
  customerUsers,
  onSubmit,
  onCancel,
  onDelete,
}) => {
  const form = useRef<FormHandles>(null);
  const modalCustomerUser = useRef<IModalHandles>();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { isCustomerDPO, isCustomerDPL } = useAuth();

  const handleSubmit = useCallback(
    async ({
      name,
      responsable_customer_user_id,
      dpl_customer_user_id,
    }: IDirectorateFormData) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da diretoria é obrigatório'),
          responsable_customer_user_id: Yup.string().required(
            'Responsável é obrigatório',
          ),
        });

        await schema.validate(
          { name, responsable_customer_user_id },
          {
            abortEarly: false,
          },
        );

        const responsable_customer_user = customerUsers.find(
          cu => cu.id === responsable_customer_user_id,
        );

        const newDirectorate: ICustomerDirectorate = {
          ...(initialData || {
            id: v4(),
            scoped_customer_departments: [],
          }),
          name,
          responsable_customer_user_id,
          responsable_customer_user,
          dpl_customer_user_id,
        };

        onSubmit(newDirectorate);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [customerUsers, initialData, onSubmit],
  );

  const handleSubmitFormNewCustomerUser = useCallback(
    async (newCustomerUser: ICustomerUser) => {
      setLoading(true);
      try {
        modalCustomerUser.current?.close();

        await api.post('/customerUsers', newCustomerUser);
        mutateGlobal(
          '/customerUsers',
          async (oldCustomerUsers: ICustomerUser[]) => {
            return [...oldCustomerUsers, newCustomerUser];
          },
          false,
        );
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar salvar',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <>
      {loading ? <LoadingMask text="Salvando" /> : null}
      <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
        <Header>
          <h1>{initialData ? 'Editar Diretoria' : 'Nova Diretoria'}</h1>
        </Header>
        <Main>
          <Input name="name" label="Nome da diretoria *" />
          <FormSelect
            name="responsable_customer_user_id"
            label="Responsável pela diretoria *"
            placeholder=""
            options={customerUsers
              .filter(cu =>
                cu.customerGroups.find(
                  cg => cg.name === 'Responsable Directorate',
                ),
              )
              .map(customerUser => ({
                label: `${customerUser.user.name} (${customerUser.position})`,
                value: customerUser.id,
              }))}
          />
          <button
            type="button"
            className="AddNewCustomerUser"
            onClick={() => modalCustomerUser.current?.open()}
          >
            + Novo usuário
          </button>
          <FormSelect
            name="dpl_customer_user_id"
            label="DPL"
            placeholder=""
            options={customerUsers
              .filter(cu => cu.customerGroups.find(cg => cg.name === 'DPL'))
              .map(customerUser => ({
                label: `${customerUser.user.name} (${customerUser.position})`,
                value: customerUser.id,
              }))}
          />

          <button
            type="button"
            className="AddNewCustomerUser"
            onClick={() => modalCustomerUser.current?.open()}
          >
            + Novo usuário
          </button>
        </Main>
        <Footer>
          {initialData && (isCustomerDPO || isCustomerDPL) && (
            <Button background="#FF151F" onClick={onDelete}>
              Remover
            </Button>
          )}
          <Button background="transparent" color="#A8C6DF" onClick={onCancel}>
            Cancelar
          </Button>
          <Button type="submit" background="#4ECE3D">
            Salvar
          </Button>
        </Footer>
      </Container>
      <Modal ref={modalCustomerUser} size="md" removeCloseButton>
        <FormCustomerUserFromCustomer
          onCancel={() => modalCustomerUser.current?.close()}
          onSubmit={handleSubmitFormNewCustomerUser}
        />
      </Modal>
    </>
  );
};

export default FormDirectorate;
