import React from 'react';
import { FaToolbox } from 'react-icons/fa';
import { HiClipboardList, HiLibrary } from 'react-icons/hi';
import { RiShieldCheckFill } from 'react-icons/ri';
import { IProcessOpinionType } from '../../../Process';

import { Container } from './styles';

interface IBadgeLabelProps {
  name: IProcessOpinionType;
  label: string;
  color?: string;
}

const BadgeLabel: React.FC<IBadgeLabelProps> = ({ name, label, color }) => {
  const defaultColor = '#a8c6df';

  switch (name) {
    case 'busines': {
      return (
        <Container className="BadgeLabel">
          <HiClipboardList size={24} color={color || defaultColor} />
          <h1>{label}</h1>
        </Container>
      );
    }
    case 'compliance': {
      return (
        <Container className="BadgeLabel">
          <FaToolbox size={20} color={color || defaultColor} />
          <h1>{label}</h1>
        </Container>
      );
    }
    case 'juridical': {
      return (
        <Container className="BadgeLabel">
          <HiLibrary size={22} color={color || defaultColor} />
          <h1>{label}</h1>
        </Container>
      );
    }
    case 'security': {
      return (
        <Container className="BadgeLabel">
          <RiShieldCheckFill size={20} color={color || defaultColor} />
          <h1>{label}</h1>
        </Container>
      );
    }
    default: {
      return <h1>Opinion Not Found</h1>;
    }
  }
};

export default BadgeLabel;
