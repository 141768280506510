/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import React, {
  useState,
  useCallback,
  createContext,
  useEffect,
  useMemo,
} from 'react';
import {
  useParams,
  useHistory,
  useRouteMatch,
  useLocation,
  Switch,
  Route,
} from 'react-router-dom';

import { IoIosArrowBack } from 'react-icons/io';
import { cache, mutate as mutateGlobal } from 'swr';
import {
  Container,
  HeaderProject,
  TabsProject,
  Title,
  BarRight,
  PercentBar,
  HeaderTabs,
  ContentProject,
  TabHeader,
  Tab,
  Footer,
} from './styles';
import Card from '../../components/Card';
import { useFetch } from '../../hooks/fetch';
import Quiz from './Quiz';
import Assessment from './Assessment';
import Results from './Results';
import Recommendations from './Recommendations';
import CommercialTable from './CommercialTable';

import Interviews from './Interviews';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { ICustomer } from '../Customers';
import { IPartnerUser, IPartner } from '../Partners';
import QuizShow from './QuizShow';
import { getPercent } from './Util/ProjectPercent';
import { useAuth } from '../../hooks/auth';
import SummaryTable from './SummaryTable';
import Relatory from './Relatory';
import { ICustomerUser } from '../CustomerUsers';
import LoadingMask from '../../components/LoadingMask';

export interface IProjectQuestion {
  id: string;
  project_id: string;
  project_module_id: string;
  project_category_id: string;
  partner_executor_id: string;
  project?: IProject;
  project_module?: IProjectModule;
  project_category?: IProjectCategory;
  risk?: number;
  position: number;
  question: string;
  answer?: string;
  score?: number;
  score_required?: number;
  recommendation?: string;
  motivation?: string;
  consulting_hours?: number;
  recommendation_deadline?: number;
  impact?: number;
  priority?: number;
  cost?: number;
  recommendation_type?: IProjectQuestionRecommentationTypes;
  recommendation_type_id?: string;
  responsable_customer_user?: ICustomerUser;
  responsable_customer_user_id?: string;
  deadline?: Date;
  status?: string;
}

export interface IProjectCategory {
  id: string;
  project_id: string;
  project?: IProject;
  project_module_id: string;
  name: string;
  position: number;
  questions: IProjectQuestion[];
  avg_score: number;
  avg_score_required: number;
  cost?: number;
}

export interface IProjectQuestionRecommentationTypes {
  id: string;
  name: string;
}

export interface IProjectModule {
  id: string;
  position: number;
  project_id: string;
  project?: IProject;
  project_interview_id: string;
  interview?: IProjectInterview;
  name: string;
  status: 'Em Andamento' | 'Finalizado';
  conclusion: string;
  categories: IProjectCategory[];
  percentAssessment?: number;
  percentRecommendation?: number;
  cost?: number;
}

export interface IProjectInterview {
  id: string;
  project_id: string;
  project_module_id: string;
  partner_id: string;
  partner?: IPartner;
  partner_user_id?: string | undefined;
  partnerUser?: IPartnerUser;
  customer_user_id?: string | undefined;
  customerUser?: ICustomerUser;
  module?: IProjectModule;
  date: Date | undefined;
  done?: boolean;
  done_date?: Date;
}

export interface IProject {
  id: string;
  customer_id: string;
  template_id: string;
  name: string;
  type: string;
  version: string;
  status: 'Em Andamento' | 'Finalizado';
  scopedModules: IProjectModule[];
  interviews: IProjectInterview[];
  created_at: Date;
  customer: ICustomer;
  percentAssessment?: number;
  percentRecommendation?: number;
  cost?: number;
}

interface IProjectContextData {
  project: IProject;
  hasChanges: boolean;
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>;
  handleSaveProject(newProject: IProject, oldProject: IProject): void;
}

export const ProjectContext = createContext<IProjectContextData>(
  {} as IProjectContextData,
);

const Projects: React.FC = () => {
  const [activeTab, setActiveTab] = useState('Avaliação');
  const [hasChanges, setHasChanges] = useState(false);
  const { id: idProject } = useParams<{ id: string }>();
  const { addToast } = useToast();
  const { roles } = useAuth();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const isAdmin = useMemo(() => roles.includes('Administrator'), [roles]);

  const { data: project, mutate } = useFetch<IProject>(
    `/projects/${idProject}`,
    { revalidateOnFocus: false },
  );

  const handleSaveProject = useCallback(
    (newProject: IProject, oldProject: IProject) => {
      if (project) {
        api.patch(`/projects/${project.id}`, newProject).catch(() => {
          const description = 'Ocorreu um erro ao salvar o projeto.';
          addToast({
            type: 'error',
            title: 'Erro ao salvar',
            description,
          });
          mutateGlobal(`/projects/${project.id}`, oldProject, false);
        });
        setHasChanges(false);
        addToast({
          type: 'success',
          title: 'Projeto Atualizado',
          description: '',
        });
      }
    },
    [addToast, project],
  );

  const handleFinishProject = useCallback(() => {
    if (project) {
      const oldProject: IProject = cache.get(`/project/${project.id}`);
      const newProject: IProject = {
        ...project,
        status: 'Finalizado',
      };
      mutate(newProject, false);
      const oldCustomer: ICustomer = cache.get(
        `/customer/${project.customer_id}`,
      );
      if (oldCustomer) {
        mutateGlobal(
          `/customer/${project.customer_id}`,
          {
            ...oldCustomer,
            projects: oldCustomer.scopedProjects.map(p => {
              if (p.id === project.customer_id) {
                return {
                  ...p,
                  status: 'Finalizado',
                };
              }
              return p;
            }),
          },
          false,
        );
      }

      handleSaveProject(newProject, oldProject);
    }
  }, [handleSaveProject, mutate, project]);

  useEffect(() => {
    if (project) {
      const { percentAssessment, percentRecommendation } = getPercent(project);

      const newModules = project.scopedModules.map(m => {
        const numberOfQuestions = m.categories.reduce(
          (agg, c) => (agg += c.questions.length),
          0,
        );
        const numberOfQuestionsAnswered = m.categories.reduce(
          (agg, c) => (agg += c.questions.filter(q => q.answer).length),
          0,
        );
        const percent = (numberOfQuestionsAnswered * 100) / numberOfQuestions;
        return {
          ...m,
          percentAssessment: Number(percent.toFixed(0)),
        };
      });

      mutate(
        {
          ...project,
          scopedModules: newModules,
          percentAssessment,
          percentRecommendation,
        },
        false,
      );
    }
  }, [mutate, project]);

  if (!project) {
    return (
      <Container>
        <LoadingMask text="Carregando projeto" />
      </Container>
    );
  }

  if (location.pathname !== `/home/projects/${idProject}`) {
    return (
      <ProjectContext.Provider
        value={{ project, hasChanges, setHasChanges, handleSaveProject }}
      >
        <Switch>
          <Route path={`${match.path}/quiz/:id`} component={Quiz} />
        </Switch>
        <Switch>
          <Route path={`${match.path}/quizShow/:id`} component={QuizShow} />
        </Switch>
      </ProjectContext.Provider>
    );
  }

  return (
    <>
      <Container>
        <header>
          <IoIosArrowBack size={24} onClick={() => history.goBack()} />
          <h1>{project.customer.name}</h1>
        </header>

        <Card>
          <HeaderProject>
            <Title>
              <h1>
                {project.name}
                <span>{project.version}</span>
              </h1>
              <p>{project.type}</p>
            </Title>
            <BarRight>
              <div className="Percents">
                <PercentBar className="PercentBar">
                  <h1>
                    {project.percentAssessment !== undefined
                      ? `${project.percentAssessment}%`
                      : 'N/A'}
                    <span>Avaliação</span>
                  </h1>
                </PercentBar>

                <PercentBar className="PercentBar">
                  <h1>
                    {project.percentRecommendation !== undefined
                      ? `${project.percentRecommendation}%`
                      : 'N/A'}
                    <span>Plano de recomendação</span>
                  </h1>
                </PercentBar>
              </div>

              {isAdmin && project.status !== 'Finalizado' ? (
                <button type="button" onClick={handleFinishProject}>
                  Finalizar Projeto
                </button>
              ) : null}
            </BarRight>
          </HeaderProject>
          <TabsProject>
            <HeaderTabs>
              <TabHeader
                className={activeTab === 'Avaliação' ? 'active' : undefined}
                onClick={() => setActiveTab('Avaliação')}
              >
                Avaliação
              </TabHeader>

              <TabHeader
                className={activeTab === 'Entrevistas' ? 'active' : undefined}
                onClick={() => setActiveTab('Entrevistas')}
              >
                Entrevistas
              </TabHeader>

              <TabHeader
                className={activeTab === 'Resultados' ? 'active' : undefined}
                onClick={() => setActiveTab('Resultados')}
              >
                Resultados
              </TabHeader>
              <TabHeader
                className={
                  activeTab === 'Plano de recomendação' ? 'active' : undefined
                }
                onClick={() => setActiveTab('Plano de recomendação')}
              >
                Plano de recomendação
              </TabHeader>
              {isAdmin && (
                <TabHeader
                  className={
                    activeTab === 'Tabela Comercial' ? 'active' : undefined
                  }
                  onClick={() => setActiveTab('Tabela Comercial')}
                >
                  Tabela Comercial
                </TabHeader>
              )}
              {isAdmin && (
                <TabHeader
                  className={
                    activeTab === 'Tabela Resumo' ? 'active' : undefined
                  }
                  onClick={() => setActiveTab('Tabela Resumo')}
                >
                  Tabela Resumo
                </TabHeader>
              )}
              {isAdmin && (
                <TabHeader
                  className={activeTab === 'Relatório' ? 'active' : undefined}
                  onClick={() => setActiveTab('Relatório')}
                >
                  Relatório
                </TabHeader>
              )}
            </HeaderTabs>
          </TabsProject>
          <TabsProject className="TabsProjectContent">
            <ProjectContext.Provider
              value={{ project, hasChanges, setHasChanges, handleSaveProject }}
            >
              <ContentProject>
                {activeTab === 'Avaliação' && (
                  <Tab>
                    <Assessment />
                  </Tab>
                )}
                {activeTab === 'Entrevistas' && (
                  <Tab>
                    <Interviews />
                  </Tab>
                )}
                {activeTab === 'Resultados' && (
                  <Tab>
                    <Results />
                  </Tab>
                )}
                {activeTab === 'Plano de recomendação' && (
                  <Tab>
                    <Recommendations />
                  </Tab>
                )}
                {activeTab === 'Tabela Comercial' && (
                  <Tab>
                    <CommercialTable />
                  </Tab>
                )}
                {activeTab === 'Tabela Resumo' && (
                  <Tab>
                    <SummaryTable />
                  </Tab>
                )}

                {activeTab === 'Relatório' && (
                  <Tab>
                    <Relatory />
                  </Tab>
                )}
              </ContentProject>
            </ProjectContext.Provider>
          </TabsProject>
        </Card>
      </Container>
      {hasChanges ? (
        <Footer>
          <button
            type="button"
            onClick={() => handleSaveProject(project, project)}
          >
            Salvar alterações
          </button>
        </Footer>
      ) : null}
    </>
  );
};

export default Projects;
