import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import { Wrapper, CheckboxContainer, Label } from './styles';

interface IInputBoolean2Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  explanation?: string;
  inputLabel?: string;
  innerLabel?: string;
  inputInnerLabel?: string;
  name: string;
  focused?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  disabled?: boolean;
  onChangeBoolean?(booleanValue: boolean | undefined): void;
}

const InputBoolean2: React.FC<IInputBoolean2Props> = ({
  name,
  label,
  explanation,
  onChangeBoolean,
  disabled,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [booleanValue, setBooleanValue] = useState<boolean | undefined>(
    defaultValue === undefined || defaultValue === null
      ? undefined
      : JSON.parse(defaultValue),
  );

  useEffect(() => {
    if (onChangeBoolean) {
      onChangeBoolean(booleanValue);
    }
  }, [booleanValue, onChangeBoolean]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: HTMLInputElement) => {
        if (booleanValue === undefined) {
          return undefined;
        }
        return JSON.stringify(booleanValue);
      },
    });
  }, [booleanValue, fieldName, registerField]);

  return (
    <Wrapper className="InputBoolean2">
      <CheckboxContainer>
        {label && (
          <Label>
            {label}{' '}
            {explanation && (
              <AiOutlineQuestionCircle
                size={20}
                color="rgb(0,121,196)"
                title={explanation}
                className="Icon"
              />
            )}
          </Label>
        )}
        <div>
          <label htmlFor={`${fieldName}_sim`}>
            <input
              id={`${fieldName}_sim`}
              type="radio"
              checked={booleanValue || false}
              onChange={() => !disabled && setBooleanValue(true)}
            />
            <span style={{ paddingLeft: '12px' }}>Sim</span>
          </label>
          <label htmlFor={`${fieldName}_nao`} style={{ marginLeft: '40px' }}>
            <input
              id={`${fieldName}_nao`}
              type="radio"
              checked={booleanValue === undefined ? false : !booleanValue}
              onChange={() => !disabled && setBooleanValue(false)}
            />
            <span style={{ paddingLeft: '12px' }}>Não</span>
          </label>
        </div>
      </CheckboxContainer>
    </Wrapper>
  );
};

export default InputBoolean2;
