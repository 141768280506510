import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  padding: 2rem;
  overflow-y: auto;

  > header {
    align-items: center;
    display: grid;
    grid-template-columns: 0fr 5fr 1.1fr;
    margin-bottom: 17px;
    > svg {
      cursor: pointer;
      color: #8dabc4;
      &:hover {
        color: #0093ee;
      }
    }
    h1 {
      text-align: left;
      font-size: 22px;
      letter-spacing: 0px;
      color: #2c405a;
      opacity: 1;
      font-weight: 400;
    }
    div.Buttons {
      display: flex;
      justify-content: flex-end;

      button {
        width: 230px;
        text-align: left;
        font-size: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 16px;
      }
    }
  }

  > div.TemplateData {
    background-color: #ffffff;
    border: 1px solid #b7d2e5cc;
    border-radius: 4px;
    padding: 59px 103px 55px 103px;

    h1 {
      text-align: left;
      font-size: 20px;
      letter-spacing: 0px;
      color: #3f536e;
      font-weight: 500;

      span {
        text-align: left;
        font-size: 15px;
        letter-spacing: 0px;
        color: #8dabc4;
        margin-left: 11px;
        font-weight: 400;
      }
    }

    p {
      text-align: left;
      font-size: 15px;
      letter-spacing: 0px;
      color: #8dabc4;
      font-weight: 400;
      margin-top: 3px;
    }

    div.Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const Modules = styled.div`
  margin-top: 32px;

  > div + div {
    margin-top: 10px;
  }
`;

export const Categories = styled.div`
  > div + div {
    margin-top: 10px;
  }
  > h3 {
    font-size: 14px;
    padding: 16px 24px;
    font-weight: 400;
  }
`;

export const Questions = styled.ul`
  list-style-type: none;
  > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;

    div.Title {
      display: flex;
      h1 {
        font-size: 14px;
        font-weight: 500;
        width: 40px;
      }

      h2 {
        font-size: 14px;
        font-weight: 400;
        flex: 1;
      }
    }
    div.Icons {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: #c4dae9;
        cursor: pointer;

        margin-left: 8px;

        &:hover {
          color: ${shade(0.2, '#C4DAE9')};
        }
      }
    }

    & + li {
      border-top: 1px solid #d8e5ee;
    }
  }

  > h3 {
    font-size: 14px;
    padding: 16px 24px;
    font-weight: 400;
  }
`;

export const ExpansionContentModule = styled.div`
  padding: 16px;
`;

export const ExpansionContentCategory = styled.div``;
