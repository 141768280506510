import styled from 'styled-components';

interface IContainerProps {
  bold?: boolean;
}

export const Container = styled.div<IContainerProps>`
  > h3 {
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
    color: #8dabc4;
    font-weight: 400;
  }
  > p {
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
    color: #3f536e;
    margin-top: 7px;
    font-weight: ${props => (props.bold ? 500 : 400)};
  }
`;
