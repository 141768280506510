import styled from 'styled-components';

export const Container = styled.div``;

export const Main = styled.main`
  background-color: #ffff;
  border: 1px solid #b7d2e5cc;
  border-radius: 4px 4px 0px 0px;
  padding: 2rem 3rem;

  > div.Line1 {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-gap: 32px;
  }

  > div.Line2 {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 2fr;
    grid-gap: 32px;
    margin-top: 38px;
  }

  > div.Line3 {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-gap: 32px;
    margin-top: 38px;
  }

  > div.Line4 {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 32px;
    margin-top: 38px;
  }

  div.Content {
    background-color: #ffff;
    border: 1px solid #b7d2e5cc;
    border-radius: 4px 4px 0px 0px;
    padding: 46px 86px;
  }
`;

export const HeaderCustomerUsers = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 52px;

  > h1 {
    text-align: left;
    font-size: 12px;
    letter-spacing: 1.85px;
    color: #3f536e;
  }

  button {
    margin: 0px;
    width: 146px;
  }
`;

export const Projects = styled.div`
  padding: 41px 105px;
  background-color: #ffff;
  border-right: 1px solid #b7d2e5cc;
  border-left: 1px solid #b7d2e5cc;
  border-bottom: 1px solid #b7d2e5cc;

  > div {
    margin-bottom: 10px;
  }
`;

export const NewProject = styled.div`
  padding: 41px 105px;
  background-color: #ffff;
  border-right: 1px solid #b7d2e5cc;
  border-left: 1px solid #b7d2e5cc;
  border-bottom: 1px solid #b7d2e5cc;
`;

export const NewPartner = styled.div`
  padding: 41px 105px;
  background-color: #ffff;
  border-right: 1px solid #b7d2e5cc;
  border-left: 1px solid #b7d2e5cc;
  border-bottom: 1px solid #b7d2e5cc;

  > table {
    margin-top: 20px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 56px;
  background-color: #ffff;
  border-right: 1px solid #b7d2e5cc;
  border-left: 1px solid #b7d2e5cc;
  border-bottom: 1px solid #b7d2e5cc;
  border-radius: 0px 0px 4px 4px;

  > button {
    width: 160px;

    & + button {
      margin-left: 16px;
    }
  }
`;

export const ExpansionContent = styled.div`
  padding: 24px 38px 27px 54px;
  > div.Line11 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px;
    button {
      margin-left: 16px;
      text-align: left;
      font-size: 13px;
      letter-spacing: 0px;
      color: #0093ee;
      border: 0px;
      background-color: transparent;
    }
  }
  > div.Line22 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 21px;
  }
  > div.Line33 {
    display: flex;
    margin-top: 36px;
    flex-wrap: wrap;

    span.Badge {
      padding: 2px 8px;
      margin-right: 8px;
      margin-top: 8px;
    }
  }
`;

export const CustomerUsers = styled.div`
  margin-top: 32px;

  > div {
    margin-bottom: 10px;
  }
`;

export const CustomerUsersOld = styled.div`
  padding: 41px 105px;
  background-color: #ffff;
  border-right: 1px solid #b7d2e5cc;
  border-left: 1px solid #b7d2e5cc;
  border-bottom: 1px solid #b7d2e5cc;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      color: var(--unnamed-color-3f536e);
      text-align: left;
      font-size: 12px;
      letter-spacing: 1.85px;
      opacity: 1;
      text-transform: uppercase;
    }

    button {
      border: 1px solid #0000000d;
      border-radius: 4px;
      opacity: 1;
      width: 146px;
    }
  }

  > table {
    background: #ffffff;
    border: 1px solid #b7d2e5cc;
    border-radius: 4px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 24px;

    thead tr:first-child th:first-child {
      border-top-left-radius: 4px;
    }

    thead tr:first-child th:last-child {
      border-top-right-radius: 4px;
    }

    tbody tr:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }

    tbody tr:last-child td:last-child {
      border-bottom-right-radius: 4px;
    }

    thead > tr > th {
      color: var(--unnamed-color-8dabc4);
      text-align: left;
      font-size: 12px;
      letter-spacing: 1.85px;
      opacity: 1;
      text-transform: uppercase;
      font-weight: 400;
      background-color: #fafbfc;
      padding: 10px;
    }

    tbody > tr > td {
      color: var(--unnamed-color-3f536e);
      text-align: left;
      font-size: 13px;
      letter-spacing: 0px;
      opacity: 1;
      padding: 12px;
      border-top: 1px solid #e5eef5;

      &.Icons {
        padding-left: 0px;
        padding-right: 16px;
        text-align: right;
        svg {
          color: #a8c6df;
          cursor: pointer;
          margin-left: 4px;

          &:hover {
            color: #0093ee;
          }
        }
      }
    }
  }
`;
