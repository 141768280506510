import styled from 'styled-components';

interface IContainerProps {
  color: string;
  backgroundColor: string;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 24px;
    background: ${props => props.backgroundColor} 0% 0% no-repeat padding-box;
    border-radius: 4px;

    > h1 {
      color: ${props => props.color};
      text-align: left;
      font-size: 12px;
      letter-spacing: 0px;
      font-weight: 400;
      white-space: nowrap;
    }
  }

  > section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > h1 {
      text-align: left;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0px;
      padding-left: 1px;
      color: #8dabc4;
      white-space: nowrap;
    }

    > h2 {
      text-align: left;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #8dabc4;
      padding-left: 0px;
      white-space: nowrap;
    }

    > h3 {
      text-align: left;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #2c405a;
      padding-left: 5px;
    }
  }
`;
