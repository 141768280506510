import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import { FiSearch, FiMoreVertical } from 'react-icons/fi';
import { MdAdd, MdEdit, MdDelete } from 'react-icons/md';
import arraySort from 'array-sort';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { mutate as mutateGlobal } from 'swr';
import {
  Container,
  Header,
  Pagination,
  ItemPage,
  OptionsContainer,
} from './styles';
import InputOnly from '../../components/InputOnly';
import Button from '../../components/Button';
import Table from '../../components/Table';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import SuspensePainel from '../../components/SuspensePainel';
import IconOrderBy from '../../components/IconOrderBy';
import { useFetch } from '../../hooks/fetch';
import { useAuth } from '../../hooks/auth';
import { IUser } from '../SignIn';
import Modal, { IModalHandles } from '../../components/Modal';
import FormCustomerUserFromCustomer from './forms/FormCustomerUserFromCustomer';

export interface ICustomerGroup {
  id: string;
  name: string;
}

export interface ICustomerUser {
  id: string;
  customer_id: string;
  telephone: string;
  position: string;
  user: IUser;
  customerGroups: ICustomerGroup[];
  reopen_process: boolean;
}

interface IOrderBy {
  fieldName: string;
  ascendant: boolean;
}

const CustomerUsers: React.FC = () => {
  const [perPage] = useState(10);
  const [page, setPage] = useState(1);
  const [customerUsersToDisplay, setCustomerUsersToDisplay] = useState<{
    filtered: number;
    customerUsers: ICustomerUser[];
  }>({ filtered: 0, customerUsers: [] });
  const { addToast } = useToast();
  const [inputFilter, setInputFilter] = useState('');
  const [customerUserSelected, setCustomerUserSelected] = useState<
    ICustomerUser | undefined
  >();

  const [, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState<IOrderBy>({
    fieldName: 'user.name',
    ascendant: false,
  });
  const { isCustomerDPO } = useAuth();

  const { data: customerUsers } = useFetch<ICustomerUser[]>('/customerUsers');
  const modalCustomerUser = useRef<IModalHandles>();

  const handleOrderBy = useCallback(
    ({ fieldName, ascendant }: IOrderBy) => {
      setOrderBy({
        fieldName,
        ascendant:
          fieldName === orderBy.fieldName ? !orderBy.ascendant : ascendant,
      });
    },
    [orderBy.ascendant, orderBy.fieldName],
  );

  useEffect(() => {
    if (customerUsers) {
      if (!inputFilter.length) {
        setCustomerUsersToDisplay({
          filtered: customerUsers?.length,
          customerUsers,
        });
      }
      const filtered = customerUsers?.filter(customerUser => {
        const normalizedInputFilter = inputFilter
          .toLowerCase()
          .normalize('NFKD')
          .replace(/^\s+|\p{M}/gu, '');

        const normalizedCustomerName =
          customerUser.user.name
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[\s\p{M}-]+/gu, '') ||
          customerUser.position
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[\s\p{M}-]+/gu, '') ||
          customerUser.user.email
            ?.toLowerCase()
            .normalize('NFKD')
            .replace(/[\s\p{M}-]+/gu, '') ||
          customerUser.telephone
            ?.toLowerCase()
            .normalize('NFKD')
            .replace(/[\s\p{M}-]+/gu, '');
        return (
          normalizedCustomerName.includes(normalizedInputFilter) ||
          customerUser.user.name
            .toLowerCase()
            .includes(normalizedInputFilter) ||
          customerUser.position.toLowerCase().includes(normalizedInputFilter) ||
          customerUser.user.email
            ?.toLowerCase()
            .includes(normalizedInputFilter) ||
          customerUser.telephone?.toLowerCase().includes(normalizedInputFilter)
        );
      });

      if (filtered) {
        const ordered = arraySort(filtered, orderBy.fieldName, {
          reverse: orderBy.ascendant,
        });

        const paged = ordered.slice(
          (page - 1) * perPage,
          (page - 1) * perPage + perPage,
        );

        if (customerUsers)
          setCustomerUsersToDisplay({
            filtered: filtered.length,
            customerUsers: paged,
          });
      }
    }
  }, [
    inputFilter,
    orderBy.ascendant,
    orderBy.fieldName,
    page,
    customerUsers,
    perPage,
  ]);

  useEffect(() => {
    setPage(1);
  }, [inputFilter]);

  const pages = useMemo(() => {
    if (!customerUsersToDisplay.filtered) return 1;
    return Math.ceil(customerUsersToDisplay.filtered / perPage);
  }, [customerUsersToDisplay.filtered, perPage]);

  const pageNumbers = useMemo(() => {
    const maxDisplayPages = 15;
    const diff = Math.ceil(maxDisplayPages / 2);
    let start = 0;
    if (pages > maxDisplayPages && page > pages - diff) {
      start = pages - maxDisplayPages;
    } else if (pages > maxDisplayPages && page > diff) {
      start = page - diff;
    } else {
      start = 0;
    }
    const limiters = { start, end: start + maxDisplayPages };

    const pns: number[] = [];
    for (let i = 1; i <= pages; i += 1) {
      pns.push(i);
    }
    return pns.slice(limiters.start, limiters.end);
  }, [page, pages]);

  const handleSetPageUp = useCallback(() => {
    if (page < pages) setPage(page + 1);
  }, [page, pages]);

  const handleSetPageDown = useCallback(() => {
    if (page > 1) setPage(page - 1);
  }, [page]);

  const handleNewCustomerUser = useCallback(() => {
    setCustomerUserSelected(undefined);
    modalCustomerUser.current?.open();
  }, []);

  const handleEditCustomerUser = useCallback(
    (customer_user_id: string) => {
      const customerUserLoaded = customerUsers?.find(
        c => c.id === customer_user_id,
      );
      if (customerUserLoaded) {
        setCustomerUserSelected(customerUserLoaded);
      }
      modalCustomerUser.current?.open();
    },
    [customerUsers],
  );

  const handleDeleteCustomerUser = useCallback(
    async (customer_user_id: string) => {
      try {
        setLoading(true);
        await api.delete(`/customerUsers/${customer_user_id}`);
        mutateGlobal(
          '/customerUsers',
          async (oldCustomerUsers: ICustomerUser[]) => {
            return oldCustomerUsers.filter(cd => cd.id !== customer_user_id);
          },
          false,
        );
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar remover usuário',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleSubmitFormNewCustomerUser = useCallback(
    async (newCustomerUser: ICustomerUser) => {
      setLoading(true);
      try {
        modalCustomerUser.current?.close();
        if (customerUserSelected) {
          await api.patch(
            `/customerUsers/${newCustomerUser.id}`,
            newCustomerUser,
          );
          mutateGlobal(
            '/customerUsers',
            async (oldCustomerUsers: ICustomerUser[]) => {
              return oldCustomerUsers.map(cu =>
                cu.id === customerUserSelected.id ? newCustomerUser : cu,
              );
            },
            false,
          );
        } else {
          await api.post('/customerUsers', newCustomerUser);
          mutateGlobal(
            '/customerUsers',
            async (oldCustomerUsers: ICustomerUser[]) => {
              return [...oldCustomerUsers, newCustomerUser];
            },
            false,
          );
        }
      } catch (error) {
        addToast({
          title: 'Erro',
          type: 'error',
          description: 'Falha ao tentar salvar',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, customerUserSelected],
  );

  return (
    <>
      <Container>
        <h1>Usuários</h1>
        <Header>
          <InputOnly
            name="search"
            icon={FiSearch}
            onChange={e => setInputFilter(e.target.value)}
          />
          <Button icon={MdAdd} onClick={handleNewCustomerUser}>
            Novo usuário
          </Button>
        </Header>
        <Table>
          <thead>
            <tr className="Header">
              <th style={{ width: '35%' }}>
                <div>
                  <span>NOME</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'user.name'}
                    onClick={() =>
                      handleOrderBy({
                        fieldName: 'user.name',
                        ascendant: false,
                      })
                    }
                  />
                </div>
              </th>
              <th style={{ width: '20%' }}>
                {' '}
                <div>
                  <span>CARGO</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'position'}
                    onClick={() =>
                      handleOrderBy({
                        fieldName: 'position',
                        ascendant: false,
                      })
                    }
                  />
                </div>
              </th>
              <th style={{ width: '12%' }}>
                {' '}
                <div>
                  <span>E-MAIL</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'email'}
                    onClick={() =>
                      handleOrderBy({
                        fieldName: 'email',
                        ascendant: false,
                      })
                    }
                  />
                </div>
              </th>
              <th style={{ width: '18%' }}>
                {' '}
                <div>
                  <span>TELEFONE</span>
                  <IconOrderBy
                    ascendant={orderBy.ascendant}
                    size={18}
                    active={orderBy.fieldName === 'telephone'}
                    onClick={() =>
                      handleOrderBy({
                        fieldName: 'telephone',
                        ascendant: false,
                      })
                    }
                  />
                </div>
              </th>
              <th style={{ width: '5%' }} />
            </tr>
          </thead>
          {!customerUsers && (
            <tbody>
              <tr>
                <td colSpan={6}>Carregando ...</td>
              </tr>
            </tbody>
          )}
          {customerUsers && !customerUsers.length && (
            <tbody>
              <tr>
                <td colSpan={6}>Nenhum cliente encontrado</td>
              </tr>
            </tbody>
          )}
          <tbody>
            {customerUsersToDisplay.customerUsers.map(customerUser => (
              <tr
                key={customerUser.id}
                onClick={() => handleEditCustomerUser(customerUser.id)}
              >
                <td>{customerUser.user.name}</td>
                <td>{customerUser.position}</td>
                <td>{customerUser.user.email}</td>
                <td>{customerUser.telephone}</td>
                <td
                  className="Icons"
                  style={{
                    paddingRight: '10px',
                  }}
                >
                  {isCustomerDPO && (
                    <SuspensePainel icon={FiMoreVertical}>
                      <OptionsContainer>
                        <button
                          type="button"
                          onClick={() => {
                            handleEditCustomerUser(customerUser.id);
                          }}
                        >
                          <MdEdit size={20} />
                          <h1>Editar Usuário</h1>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            handleDeleteCustomerUser(customerUser.id);
                          }}
                        >
                          <MdDelete size={20} />
                          <h1>Remover Usuário</h1>
                        </button>
                      </OptionsContainer>
                    </SuspensePainel>
                  )}
                </td>
              </tr>
            ))}
            <tr className="Pagination">
              <td colSpan={6}>
                <Pagination>
                  <li>
                    <IoMdArrowDropleft
                      size={18}
                      onClick={() => handleSetPageDown()}
                    />
                  </li>
                  {pageNumbers.map(pg => (
                    <ItemPage key={pg} active={pg === page}>
                      <button type="button" onClick={() => setPage(pg)}>
                        {pg}
                      </button>
                    </ItemPage>
                  ))}
                  <li>
                    <IoMdArrowDropright
                      size={18}
                      onClick={() => handleSetPageUp()}
                    />
                  </li>
                </Pagination>
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
      <Modal ref={modalCustomerUser} size="md" removeCloseButton>
        <FormCustomerUserFromCustomer
          initialData={customerUserSelected}
          onCancel={() => modalCustomerUser.current?.close()}
          onSubmit={handleSubmitFormNewCustomerUser}
        />
      </Modal>
    </>
  );
};

export default CustomerUsers;
