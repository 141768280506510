import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const HeaderRelatory = styled.div`
  border-top-left-radius: 4px;
  color: var(--unnamed-color-8dabc4);
  text-align: left;
  font-size: 12px;
  letter-spacing: 1.85px;
  opacity: 1;
  text-transform: uppercase;
  font-weight: 400;
  background-color: #fafbfc;
  padding: 10px;
`;

export const BodyRelatory = styled.div`
  color: var(--unnamed-color-3f536e);
  text-align: left;
  font-size: 13px;
  letter-spacing: 0px;
  opacity: 1;
  padding: 12px;
`;

export const RelatoryStyled = styled.div`
  font-size: 12px;

  table,
  td,
  th {
    border: 1px solid black;
  }

  table {
    width: 800px;
    border-collapse: collapse;
  }

  b {
    margin: 0px 5px;
    font-weight: 600;
    font-size: 12px;
  }

  p {
    margin: 0px 10px;
  }

  p.line_text {
    text-align: justify;
  }
`;

export const TableTextRecommendation = styled.div`
  font-size: 12px;
  text-align: justify;

  > h3 {
    font-size: 14px;
    margin: 10px 0px;
    font-weight: 400;
  }
`;

export const TableRelatory = styled.div`
  margin: 15px;
`;

export const Modules = styled.div`
  > div + div {
    margin-top: 20px;
  }

  > h1 {
    color: var(--unnamed-color-3f536e);
    text-align: left;
    font-size: 13px;
    letter-spacing: 0px;
    font-weight: 400;
  }
`;

export const Categories = styled.div`
  > div + div {
    margin-top: 10px;
  }
  > h3 {
    font-size: 14px;
    padding: 16px 24px;
    font-weight: 400;
  }
`;

export const Questions = styled.ul`
  list-style-type: none;
  > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;

    div.Title {
      display: flex;
      h1 {
        font-size: 14px;
        font-weight: 500;
        width: 40px;
      }

      h2 {
        font-size: 14px;
        font-weight: 400;
        flex: 1;
      }
    }
  }

  > h3 {
    font-size: 14px;
    padding: 16px 24px;
    font-weight: 400;
  }
`;

export const ExpansionContentModule = styled.div`
  padding: 16px;
`;

export const ExpansionContentCategory = styled.div``;

export const Header = styled.header`
  background-color: #fafbfc;
  padding-top: 40px;
  padding-left: 60px;
  padding-bottom: 12px;
  border-radius: 8px 8px 0px 0px;
  h1 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #2c405a;
  }
`;
