import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useCallback, useState } from 'react';
import { NumberToAlphabet } from 'number-to-alphabet';
import { toRoman } from 'roman-numerals';
import { format } from 'date-fns';

import * as Yup from 'yup';
import { mutate as mutateGlobal } from 'swr';
import { getDateWithoutTimezone } from '../../../../utils/date';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import Option from '../../../../components/Select/Option';
import getValidationErrors from '../../../../utils/getValidationErrors';

import { IProjectQuestion } from '../../../Projects';

import { ICustomerUser } from '../../../CustomerUsers';
import { IResponseCustomerAssessment } from '../..';
import { useToast } from '../../../../hooks/toast';
import {
  Container,
  MainCustomerAssessment,
  FooterCustomerAssessment,
} from './styles';

import DisplayField from '../../../../components/DisplayField';

import Button from '../../../../components/Button';

import api from '../../../../services/api';

interface IFormStatus {
  deadline: string;
  responsable_customer_user_id: string;
  status: string;
}

interface IFormProps {
  projectQuestion: IProjectQuestion;
  customerUsers: ICustomerUser[] | undefined;
}

const defaultAlphabet = new NumberToAlphabet();

const AssessmentOpinion: React.FC<IFormProps> = ({
  projectQuestion,
  customerUsers,
}) => {
  const form = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  customerUsers?.sort(function OrdercustomerUsers(
    a: ICustomerUser,
    b: ICustomerUser,
  ) {
    return a.user.name < b.user.name ? -1 : a.user.name > b.user.name ? 1 : 0;
  });

  const handleSubmit = useCallback(
    async ({ deadline, responsable_customer_user_id, status }: IFormStatus) => {
      const { id } = projectQuestion;
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          deadline: Yup.date().required('Prazo é obrigatório'),
          responsable_customer_user_id: Yup.string().required(
            'O Responsável é obrigatório',
          ),
          status: Yup.string().required('Status é Obrigatório.'),
        });

        await schema.validate(
          { responsable_customer_user_id, deadline, status },
          {
            abortEarly: false,
          },
        );

        setLoading(true);
        await api
          .patch(`/customerAssessmentQuestions`, {
            id,
            responsable_customer_user_id,
            deadline,
            status,
          })
          .then(() => {
            mutateGlobal(
              '/customerAssessmentQuestions',
              async (oldProjectQuestions: IResponseCustomerAssessment) => {
                return {
                  ...oldProjectQuestions,
                  questions: oldProjectQuestions.questions.map(question => {
                    if (question.id === id) {
                      return {
                        ...question,
                        responsable_customer_user_id,
                        status,
                        deadline: getDateWithoutTimezone(deadline),
                      };
                    }
                    return question;
                  }),
                };
              },
              false,
            );
            addToast({
              title: 'Atualizado',
              type: 'success',
              description: 'Plano de ação atualizado com sucesso',
            });
          })
          .catch(() => {
            addToast({
              title: 'Erro',
              type: 'error',
              description: 'Falha ao tentar atualizar plano de acão',
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [projectQuestion, addToast],
  );

  return (
    <Container>
      <MainCustomerAssessment>
        <main>
          <div className="Line1">
            <DisplayField label="Item">
              {`${toRoman(projectQuestion.position + 1)}.${
                projectQuestion.position + 1
              }.${defaultAlphabet.numberToString(
                projectQuestion.position + 1,
              )}`}
            </DisplayField>
            <DisplayField label="Data">
              {projectQuestion.project_module?.interview?.done_date &&
                format(
                  new Date(
                    projectQuestion.project_module?.interview?.done_date,
                  ),
                  'dd/MM/yyyy',
                )}
            </DisplayField>
            <DisplayField label="Entrevistado">
              {
                projectQuestion.project_module?.interview?.customerUser?.user
                  .name
              }
            </DisplayField>

            <DisplayField label="Tipo de recomendação">
              {projectQuestion.recommendation_type?.name}
            </DisplayField>
          </div>
          <div className="Line2">
            <DisplayField label="Pergunta">
              {projectQuestion.question}
            </DisplayField>
          </div>
          <div className="Line3">
            <DisplayField label="Argumento coletado">
              {projectQuestion.answer}
            </DisplayField>
          </div>
          <div className="Line4">
            <DisplayField label="Recomendação">
              {projectQuestion.recommendation}
            </DisplayField>
          </div>
          <div className="Line5">
            <DisplayField label="Ação/crítica/motivação">
              {projectQuestion.motivation}
            </DisplayField>
          </div>
          <div className="Line6">
            <DisplayField label="Horas de consultoria">
              {projectQuestion.consulting_hours}
            </DisplayField>
            <DisplayField label="Prazo recomendado (Meses)">
              {projectQuestion.recommendation_deadline}
            </DisplayField>
          </div>
          <div className="Line7">
            <DisplayField label="Impacto">
              {projectQuestion.impact}
            </DisplayField>
            <DisplayField label="Prioridade">
              {projectQuestion.priority}
            </DisplayField>
          </div>
        </main>
      </MainCustomerAssessment>

      <FooterCustomerAssessment>
        <Form
          ref={form}
          onSubmit={handleSubmit}
          initialData={{
            responsable_customer_user_id:
              projectQuestion.responsable_customer_user_id,
            deadline: projectQuestion.deadline,
            status: projectQuestion.status,
          }}
        >
          <div className="FooterLine">
            <Select name="responsable_customer_user_id" label="Responsável">
              {customerUsers &&
                customerUsers.map(customerUser => (
                  <Option
                    key={customerUser.id}
                    label={customerUser.user.name}
                    value={customerUser.id}
                  >
                    {customerUser.user.name}
                  </Option>
                ))}
            </Select>

            <Input type="date" name="deadline" label="Prazo" />

            <Select label="Status" name="status">
              <Option key="Pendente" value="Pendente" label="Pendente">
                Pendente
              </Option>
              <Option key="Andamento" value="Em Andamento" label="Em Andamento">
                Em Andamento
              </Option>
              <Option key="Finalizado" value="Finalizado" label="Finalizado">
                Finalizado
              </Option>
            </Select>

            <Button type="submit" loading={loading} loadingMsg="Salvando ...">
              Salvar
            </Button>
          </div>
        </Form>
      </FooterCustomerAssessment>
    </Container>
  );
};

export default AssessmentOpinion;
