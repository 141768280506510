import { Form } from '@unform/web';
import styled from 'styled-components';

export const Container = styled(Form)``;

export const Header = styled.header`
  border-radius: 8px 8px 0px 0px;
  padding: 20px 30px 20px 30px;
  background: #f6f8fa 0% 0% no-repeat padding-box;

  > h1 {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #2c405a;
  }
`;

export const Main = styled.main`
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #c5dbea;
  border-bottom: 1px solid #c5dbea;
  max-height: 70vh;
  overflow-y: auto;

  > div.Line1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
  }

  > div.Line2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    margin-top: 20px;
  }

  > div.Line3 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 20px;
  }

  > div.Line4 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 20px;
  }

  > div.Line5 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    grid-gap: 32px;
    margin-top: 20px;
  }

  > div.Line6 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 32px;
    margin-top: 20px;
  }

  > div.Line7 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 20px;
  }

  > div.Line8 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    margin-top: 20px;
  }

  > div.Line9 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-gap: 32px;
    margin-top: 20px;
  }
`;

export const Footer = styled.footer`
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button.Button {
    width: 200px;
    & + button {
      margin-left: 10px;
    }
  }
`;
