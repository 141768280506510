/* eslint-disable import/extensions */
import React, { useMemo } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useHistory, useParams } from 'react-router-dom';

import Card from '../../../components/Card';
import LoadingMask from '../../../components/LoadingMask';
import { useFetch } from '../../../hooks/fetch';

import { Container, Header, CardContent } from './styles';
import Tree from '../../../components/Tree';

interface ICustomerDataProcess {
  name: string;
}

interface ICustomerDataDepartment {
  name: string;
  customer_processes: ICustomerDataProcess[];
}

interface ICustomerDataDirectorate {
  name: string;
  customer_departments: ICustomerDataDepartment[];
}

interface ICustomerDataDetail {
  name: string;
  customer_directorates: ICustomerDataDirectorate[];
}

const CustomerDataDetails: React.FC = () => {
  const { name } = useParams<{ name: string }>();
  const { data: customerDataDetail } = useFetch<ICustomerDataDetail>(
    `/customerDataGroup/${name}`,
  );
  const history = useHistory();

  const data = useMemo(() => {
    return {
      name: customerDataDetail?.name,
      textProps: { x: 8, y: 0 },
      children: customerDataDetail?.customer_directorates.map(cdir => ({
        name: cdir.name,
        textProps: { x: 8, y: 0 },
        children: cdir.customer_departments.map(cdep => ({
          name: cdep.name,
          textProps: { x: 8, y: 0 },
          children: cdep.customer_processes.map(cp => ({
            textProps: { x: 8, y: 0 },
            name: cp.name,
          })),
        })),
      })),
    };
  }, [customerDataDetail]);

  const height = useMemo<number>(() => {
    const numberOfProcess = customerDataDetail?.customer_directorates.reduce(
      (pv, cur) =>
        pv +
        cur.customer_departments.reduce(
          (pv2, cur2) => pv2 + cur2.customer_processes.length,
          0,
        ),
      0,
    );
    if (numberOfProcess && numberOfProcess > 15) {
      const add = (numberOfProcess - 15) * 40;
      return 600 + add;
    }
    return 600;
  }, [customerDataDetail]);

  return (
    <>
      {!customerDataDetail ? (
        <LoadingMask text="Carregando detalhes" />
      ) : (
        <Container>
          <Header>
            <IoIosArrowBack size={24} onClick={() => history.goBack()} />
            <h1>{name}</h1>
          </Header>

          <Card>
            <CardContent>
              <header>
                <h1>Dado / Diretoria / Departamento / Processo </h1>
              </header>
              <main>
                <Tree data={data} height={height} width={1200} />
              </main>
            </CardContent>
          </Card>
        </Container>
      )}
    </>
  );
};

export default CustomerDataDetails;
