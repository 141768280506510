import styled from 'styled-components';

export const Container = styled.div`
  padding: 29px 0px 29px 42px;
  overflow-y: auto;
  overflow-x: hidden;

  > h1 {
    text-align: left;
    font: normal normal normal 22px/32px Heebo;
    letter-spacing: 0px;
    color: #2c405a;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
  margin-bottom: 19px;
  padding-right: 110px;

  > div {
    width: 283px;
  }

  > button.Button {
    cursor: pointer;
    width: 180px;
    margin: 0px;
  }
`;

export const Main = styled.div`
  > h1 {
    margin-top: 48px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: #3f536e;
  }
`;
