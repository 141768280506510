import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import arraySort from 'array-sort';
import { v4 } from 'uuid';
import { Container, Header, Main, Footer } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Button from '../../../../components/Button';
import { IOpinion, IRiskAnwserType, IRiskType } from '../../../Opinions';
import Select from '../../../../components/Select';
import Option from '../../../../components/Select/Option';
import { ICustomerDirectorate } from '../../../DataMapping/components/Directorate';
import { useFetch } from '../../../../hooks/fetch';
import { ICustomerDepartment } from '../../../DataMapping/components/Department';
import {
  ICustomerProcess,
  IProcessOpinionType,
} from '../../../DataMapping/components/Process';
import Input from '../../../../components/Input';
import TextArea from '../../../../components/TextArea';

interface IFormProps {
  initialData: IOpinion | null;
  onSubmit(newOpinion: IOpinion, edit: boolean): void;
  onCancel(): void;
}

interface IDepartmentFormData {
  customer_directorate_id: string;
  customer_department_id: string;
  customer_process_id: string;
  type: IProcessOpinionType;
  risk_identified: string;
  impact: IRiskType;
  probability: IRiskType;
  risk: IRiskType;
  risk_answer: IRiskAnwserType;
  description: string;
  plan_action: string;
  deadline: Date;
}

const FormOpinion: React.FC<IFormProps> = ({
  initialData,
  onSubmit,
  onCancel,
}) => {
  const form = useRef<FormHandles>(null);
  const [customerDepartments, setCustomerDepartments] =
    useState<ICustomerDepartment[]>();
  const [customerProcesses, setCustomerProcesses] =
    useState<ICustomerProcess[]>();

  const [selectedCustomerProcess, setSelectedCustomerProcess] =
    useState<ICustomerProcess>();

  const { data: customerDirectorates } = useFetch<ICustomerDirectorate[]>(
    '/customerDirectorates',
  );

  const handleSubmit = useCallback(
    async ({
      customer_directorate_id,
      customer_department_id,
      customer_process_id,
      type,
      risk_identified,
      impact,
      probability,
      risk,
      risk_answer,
      description,
      plan_action,
      deadline,
    }: IDepartmentFormData) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          type: Yup.string().required('Tipo do parecer é obrigatório'),
          customer_directorate_id: Yup.string().required(
            'Diretoria é obrigatório',
          ),
          customer_department_id: Yup.string().required(
            'Departamento é obrigatório',
          ),
          customer_process_id: Yup.string().required('Processo é obrigatório'),
          risk_identified: Yup.string().required(
            'Risco identificado é obrigatório',
          ),
          impact: Yup.number().required('Impacto é obrigatório'),
          probability: Yup.number().required('Probabilidade é obrigatório'),
          risk: Yup.number().required('Risco é obrigatório'),
          risk_answer: Yup.string().required('Resposta ao risco é obrigatório'),
          description: Yup.string().required('Parecer é obrigatório'),
          plan_action: Yup.string().required('Plano de ação é obrigatório'),
          deadline: Yup.date().required('Prazo é obrigatório'),
        });

        await schema.validate(
          {
            customer_directorate_id,
            customer_department_id,
            customer_process_id,
            type,
            risk_identified,
            impact,
            probability,
            risk,
            risk_answer,
            description,
            plan_action,
            deadline,
          },
          {
            abortEarly: false,
          },
        );

        let newOpinion: IOpinion;

        if (initialData) {
          newOpinion = {
            ...initialData,
            type,
            risk_identified,
            impact,
            probability,
            risk,
            risk_answer,
            description,
            plan_action,
            deadline,
          };
        } else {
          newOpinion = {
            id: v4(),
            type,
            customer_directorate_id,
            customer_department_id,
            customer_process_id,
            risk_identified,
            impact,
            probability,
            risk,
            risk_answer,
            description,
            plan_action,
            deadline,
          };
        }

        onSubmit(newOpinion, !!initialData);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [initialData, onSubmit],
  );

  const handleSetDepartments = useCallback(
    idDirectorate => {
      setCustomerDepartments(
        customerDirectorates?.find(cd => cd.id === idDirectorate)
          ?.scoped_customer_departments,
      );
      setCustomerProcesses([]);
      setSelectedCustomerProcess(undefined);
    },
    [customerDirectorates],
  );

  const handleSetProcesses = useCallback(
    idDepartment => {
      setCustomerProcesses(
        customerDepartments?.find(cp => cp.id === idDepartment)
          ?.scoped_customer_processes,
      );
      setSelectedCustomerProcess(undefined);
    },
    [customerDepartments],
  );

  const handleSetSelectedProcess = useCallback(
    idProcess => {
      setSelectedCustomerProcess(
        customerProcesses?.find(cp => cp.id === idProcess),
      );
    },
    [customerProcesses],
  );

  useEffect(() => {
    if (initialData) {
      handleSetDepartments(initialData.customer_directorate_id);
      handleSetProcesses(initialData.customer_department_id);
      handleSetSelectedProcess(initialData.customer_process_id);
    }
  }, [
    handleSetDepartments,
    handleSetProcesses,
    handleSetSelectedProcess,
    initialData,
  ]);

  return (
    <>
      <Container
        ref={form}
        onSubmit={handleSubmit}
        initialData={initialData || { id: v4() }}
      >
        <Header>
          <h1>{initialData ? 'Editar plano de ação' : 'Novo plano de ação'}</h1>
        </Header>
        <Main>
          <div className="Line1">
            <Select
              name="customer_directorate_id"
              label="Diretoria *"
              loading={!customerDirectorates}
              onChange={handleSetDepartments}
              disabled={!!initialData}
            >
              {customerDirectorates &&
                arraySort(customerDirectorates, 'name').map(
                  customerDirectorate => (
                    <Option
                      key={customerDirectorate.id}
                      label={customerDirectorate.name}
                      value={customerDirectorate.id}
                    >
                      {customerDirectorate.name}
                    </Option>
                  ),
                )}
            </Select>
            <Select
              name="customer_department_id"
              label="Departamento *"
              disabled={!customerDepartments?.length || !!initialData}
              onChange={handleSetProcesses}
            >
              {customerDepartments &&
                arraySort(customerDepartments, 'name').map(
                  customerDepartment => (
                    <Option
                      key={customerDepartment.id}
                      label={customerDepartment.name}
                      value={customerDepartment.id}
                    >
                      {customerDepartment.name}
                    </Option>
                  ),
                )}
            </Select>
            <Select
              name="customer_process_id"
              label="Processo *"
              disabled={!customerProcesses?.length || !!initialData}
              onChange={handleSetSelectedProcess}
            >
              {customerProcesses &&
                arraySort(customerProcesses, 'name').map(customerProcess => (
                  <Option
                    key={customerProcess.id}
                    label={customerProcess.name}
                    value={customerProcess.id}
                  >
                    {customerProcess.name}
                  </Option>
                ))}
            </Select>
          </div>

          {selectedCustomerProcess && (
            <div className="Line3">
              <TextArea
                label="Descrição do processo"
                name="process_description"
                value={selectedCustomerProcess.description}
                disabled
              />
            </div>
          )}

          <div className="Line4">
            <TextArea
              label="Risco Identificado *"
              name="risk_identified"
              disabled={!selectedCustomerProcess}
            />
          </div>

          <div className="Line5">
            <Select
              label="Impacto *"
              name="impact"
              disabled={!selectedCustomerProcess}
            >
              <Option key={1} value={1} label="Muito Baixo">
                Muito Baixo
              </Option>
              <Option key={2} value={2} label="Baixo">
                Baixo
              </Option>
              <Option key={3} value={3} label="Médio">
                Médio
              </Option>
              <Option key={4} value={4} label="Alto">
                Alto
              </Option>
              <Option key={5} value={5} label="Muito Alto">
                Muito Alto
              </Option>
            </Select>

            <Select
              label="Probabilidade *"
              name="probability"
              disabled={!selectedCustomerProcess}
            >
              <Option key={1} value={1} label="Muito Baixo">
                Muito Baixo
              </Option>
              <Option key={2} value={2} label="Baixo">
                Baixo
              </Option>
              <Option key={3} value={3} label="Médio">
                Médio
              </Option>
              <Option key={4} value={4} label="Alto">
                Alto
              </Option>
              <Option key={5} value={5} label="Muito Alto">
                Muito Alto
              </Option>
            </Select>

            <Select
              label="Risco *"
              name="risk"
              disabled={!selectedCustomerProcess}
            >
              <Option key={1} value={1} label="Muito Baixo">
                Muito Baixo
              </Option>
              <Option key={2} value={2} label="Baixo">
                Baixo
              </Option>
              <Option key={3} value={3} label="Médio">
                Médio
              </Option>
              <Option key={4} value={4} label="Alto">
                Alto
              </Option>
              <Option key={5} value={5} label="Muito Alto">
                Muito Alto
              </Option>
            </Select>

            <Select
              label="Resposta ao risco *"
              name="risk_answer"
              disabled={!selectedCustomerProcess}
            >
              <Option key="Mitigar" value="Mitigar" label="Mitigar">
                Mitigar
              </Option>
              <Option key="Aceitar" value="Aceitar" label="Aceitar">
                Aceitar
              </Option>
              <Option key="Transferir" value="Transferir" label="Transferir">
                Transferir
              </Option>
              <Option key="Eliminar" value="Eliminar" label="Eliminar">
                Eliminar
              </Option>
            </Select>
          </div>

          <div className="Line6">
            <Select
              label="Tipo de parecer *"
              name="type"
              disabled={!selectedCustomerProcess}
            >
              <Option key="busines" value="busines" label="Parecer de Negócios">
                Parecer de Negócios
              </Option>
              <Option
                key="compliance"
                value="compliance"
                label="Parecer de Compliance"
              >
                Parecer de Compliance
              </Option>
              <Option
                key="juridical"
                value="juridical"
                label="Parecer Jurídico"
              >
                Parecer Jurídico
              </Option>
              <Option
                key="security"
                value="security"
                label="Parecer de Cybersecurity"
              >
                Parecer de Cybersecurity
              </Option>
            </Select>
          </div>

          <div className="Line7">
            <TextArea
              label="Parecer *"
              name="description"
              disabled={!selectedCustomerProcess}
            />
          </div>

          <div className="Line8">
            <TextArea
              label="Plano de ação *"
              name="plan_action"
              disabled={!selectedCustomerProcess}
            />
          </div>

          <div className="Line9">
            <Input
              label="Prazo *"
              name="deadline"
              type="date"
              disabled={!selectedCustomerProcess}
            />
          </div>
        </Main>
        <Footer>
          <Button background="transparent" color="#A8C6DF" onClick={onCancel}>
            Cancelar
          </Button>
          <Button type="submit" background="#4ECE3D">
            Salvar
          </Button>
        </Footer>
      </Container>
    </>
  );
};

export default FormOpinion;
