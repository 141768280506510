import styled, { css } from 'styled-components';
import { shade } from 'polished';
import Tooltip from '../Tooltip';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  innerLabel?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.h1`
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #8dabc4;
  margin-bottom: 10px;
`;

export const Content = styled.div<IContainerProps>`
  display: flex;
  width: 100%;
  min-height: 42px;
  align-items: center;
  background: #ffff;
  border-radius: 4px;
  padding: 2px 15px 8px 15px;
  border: 1px solid #a8c6df;
  color: #8dabc4;
  font-weight: 400;

  & + div {
    margin-top: 10px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: ${shade(0.2, '#8DABC4')};
    `}

  > label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    flex-wrap: wrap;
    input {
      background: transparent;
      flex: 1;
      border: 0;
      color: #3f536e !important;
      font-size: 15px;
      margin-top: 6px;

      &::placeholder {
        color: #8dabc4;
      }
    }

    span.Chip {
      margin-right: 6px;
      margin-top: 6px;
    }
  }

  svg {
    margin-right: 10px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
