import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  border: 1px solid var(--unnamed-color-c5d9e8);
  background: #f6f8fa 0% 0% no-repeat padding-box;
  border: 1px solid #c5d9e8;
  border-radius: 5px;

  flex: 1;
  height: 129px;

  > div.Status {
    position: absolute;
    bottom: 20px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > h1 {
    text-align: center;
    font: normal normal bold 14px/21px Heebo;
    letter-spacing: 2.16px;
    color: #0079c4;
  }

  > svg {
    margin-left: 4px;
    margin-bottom: 2px;
  }
`;
