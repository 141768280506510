import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { v4 } from 'uuid';
import { Container, Header, Main, Footer } from './styles';
import { phoneMask } from '../../../components/Masks';
import getValidationErrors from '../../../../utils/getValidationErrors';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { ICustomerUser } from '../..';
import Select2 from '../../../../components/Select2';
import Option from '../../../../components/Select2/Option';
import InputBoolean2 from '../../../../components/InputBoolean2';
import { validatePassword } from '../../../../utils/validatePassword';
import PasswordRequirements from '../../../../components/PasswordRequirements';

interface IFormProps {
  initialData?: ICustomerUser | undefined;
  onSubmit(newCustomerDirecotorate: ICustomerUser): void;
  onCancel(): void;
}

interface ICustomerUserFormData {
  telephone: string;
  position: string;
  user: {
    name: string;
    email: string;
    password: string;
  };
  customerGroups: string[];
  reopen_process: boolean;
}

export const optionsCustomerGroups = [
  { value: 'Interviewee', label: 'Entrevistado' },
  { value: 'DPO', label: 'DPO' },
  { value: 'DPL', label: 'DPL' },
  { value: 'Responsable Directorate', label: 'Responsável Diretoria' },
  { value: 'Responsable Department', label: 'Responsável Departamento' },
  { value: 'Responsable Process', label: 'Responsável Processo' },
];

const FormCustomerUserFromCustomer: React.FC<IFormProps> = ({
  initialData,
  onSubmit,
  onCancel,
}) => {
  const form = useRef<FormHandles>(null);

  const newInitialData = {
    ...initialData,
    customerGroups: initialData?.customerGroups
      ? initialData.customerGroups.map(cg =>
          optionsCustomerGroups.find(ocg => ocg.value === cg.name),
        )
      : [],
  };

  const [password, setPassword] = useState('');
  const defaultRequirements = {
    minLength: false,
    number: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    noSequential: false,
    noRepetition: false,
  };

  const [requirements, setRequirements] = useState(defaultRequirements);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    if (value === '') {
      setRequirements(defaultRequirements);
    } else {
      const { requirements } = validatePassword(value);
      setRequirements(requirements);
    }
  };

  const handleSubmit = useCallback(
    async ({
      telephone,
      position,
      user,
      reopen_process,
      customerGroups: CustomerGroups,
    }: ICustomerUserFormData) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          user: Yup.object().shape({
            name: Yup.string().required('Nome é obrigatório'),
            email: Yup.string()
              .email('Formato de e-mail inválido')
              .required('E-mail é obrigatório'),
            password: Yup.string()
              .required('A senha é obrigatória')
              .test(
                'is-valid-password',
                'A senha não cumpre os requisitos',
                value => {
                  if (!value) return false;
                  const { isValid } = validatePassword(value);
                  return isValid;
                },
              ),
          }),
          position: Yup.string().required('Cargo é obrigatório'),
        });

        if (!initialData?.user.name) {
          await schema.validate(
            { user, position },
            {
              abortEarly: false,
            },
          );
        }

        const newCustomerUser: ICustomerUser = {
          ...(initialData || {
            id: v4(),
            customer_id: v4(),
            customerGroups: [],
          }),
          telephone,
          position,
          reopen_process,
          user: {
            ...(initialData?.user || { id: v4(), groups: [] }),
            name: initialData?.user.name ? initialData?.user.name : user.name,
            email: initialData?.user.email
              ? initialData?.user.email
              : user.email,
            password: user.password,
          },
          customerGroups: CustomerGroups.map(cg => ({
            id: v4(),
            name: cg,
          })),
        };

        onSubmit(newCustomerUser);
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [initialData, onSubmit],
  );

  return (
    <Container ref={form} onSubmit={handleSubmit} initialData={newInitialData}>
      <Header>
        <h1>{initialData ? 'Editar Usuário' : 'Novo Usuário'}</h1>
      </Header>
      <Main>
        <div className="Line1">
          <Input
            label="Nome *"
            name="user.name"
            type="text"
            disabled={!!initialData?.user.name}
          />
          <Input
            label="Telefone"
            name="telephone"
            type="text"
            onChange={e => phoneMask(e)}
          />
        </div>
        {!initialData?.user.name && (
          <div className="Line2">
            <Input label="Cargo *" name="position" type="text" />
            <Select2 label="Grupos" name="customerGroups" multiSelection>
              <Option
                key="Interviewee"
                value="Interviewee"
                label="Entrevistado"
              />
              <Option key="DPO" value="DPO" label="DPO" />
              <Option key="DPL" value="DPL" label="DPL" />
              <Option
                key="Responsable Directorate"
                value="Responsable Directorate"
                label="Responsável Diretoria"
              />
              <Option
                key="Responsable Department"
                value="Responsable Department"
                label="Responsável Departamento"
              />
              <Option
                key="Responsable Process"
                value="Responsable Process"
                label="Responsável Processo"
              />
            </Select2>
          </div>
        )}
        <div className="Line3">
          <Input
            label="E-mail *"
            name="user.email"
            type="text"
            disabled={!!initialData?.user.name}
          />
          <div>
            <Input
              label="Senha *"
              name="user.password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              disabled={!!initialData?.user.password}
            />
            <PasswordRequirements requirements={requirements} />
            <InputBoolean2
              label="Permitir usuário reabrir o processo?"
              name="reopen_process"
            />
          </div>
        </div>
      </Main>
      <Footer>
        <Button background="transparent" color="#A8C6DF" onClick={onCancel}>
          Cancelar
        </Button>
        <Button type="submit" background="#4ECE3D">
          Salvar
        </Button>
      </Footer>
    </Container>
  );
};

export default FormCustomerUserFromCustomer;
