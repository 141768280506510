import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, Wrapper, Error } from './styles';

interface IInputPriorityProps {
  name: string;
  label: string;
  disabled?: boolean;
}

const InputPriority: React.FC<IInputPriorityProps> = ({
  name,
  label,
  disabled,
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [valueSelected, setValueSelected] = useState<number>();
  const { fieldName, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        const rst = (refs.find(ref => ref && ref.checked) as HTMLInputElement)
          ? (refs.find(ref => ref && ref.checked) as HTMLInputElement).value
          : undefined;
        return rst;
      },
    });
  }, [fieldName, registerField]);

  const handleSelect = useCallback((number: number) => {
    setValueSelected(number);
  }, []);

  return (
    <Container>
      <h1>{label}</h1>
      <Wrapper>
        <h1>(Maior)</h1>
        <label htmlFor="n1">
          <input
            ref={ref => {
              inputRefs.current[1] = ref as HTMLInputElement;
            }}
            type="radio"
            disabled={disabled}
            value={1}
            checked={valueSelected === 1}
            onChange={() => {
              handleSelect(1);
            }}
          />
          1
        </label>
        <label htmlFor="n2">
          <input
            ref={ref => {
              inputRefs.current[2] = ref as HTMLInputElement;
            }}
            type="radio"
            disabled={disabled}
            value={2}
            checked={valueSelected === 2}
            onChange={() => {
              handleSelect(2);
            }}
          />
          2
        </label>
        <label htmlFor="n3">
          <input
            ref={ref => {
              inputRefs.current[3] = ref as HTMLInputElement;
            }}
            type="radio"
            disabled={disabled}
            value={3}
            checked={valueSelected === 3}
            onChange={() => {
              handleSelect(3);
            }}
          />
          3
        </label>
        <label htmlFor="n4">
          <input
            ref={ref => {
              inputRefs.current[4] = ref as HTMLInputElement;
            }}
            type="radio"
            disabled={disabled}
            value={4}
            checked={valueSelected === 4}
            onChange={() => {
              handleSelect(4);
            }}
          />
          4
        </label>
        <label htmlFor="n5">
          <input
            ref={ref => {
              inputRefs.current[5] = ref as HTMLInputElement;
            }}
            type="radio"
            disabled={disabled}
            value={5}
            checked={valueSelected === 5}
            onChange={() => {
              handleSelect(5);
            }}
          />
          5
        </label>
        <h2>(Menor)</h2>
        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Wrapper>
    </Container>
  );
};

export default InputPriority;
