/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useCallback, useState, useMemo } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { Container, Main, Footer } from './styles';
import Button from '../../../../../../components/Button';
import Modal, { IModalHandles } from '../../../../../../components/Modal';
import TextArea from '../../../../../../components/TextArea';
import InputBoolean2 from '../../../../../../components/InputBoolean2';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import { ICustomerProcess } from '../..';
import Input from '../../../../../../components/Input';
import ModalConfirm from '../../components/ProcessConfirm';

interface IFormProps {
  initialData: ICustomerProcess;
  callbackUsesConsent: any;
  readOnly?: boolean;
}

const FormProcessConsent: React.FC<IFormProps> = ({
  initialData,
  callbackUsesConsent,
  readOnly,
}) => {
  const form = useRef<FormHandles>(null);
  const modalConfirm = useRef<IModalHandles>();
  const [confirmSaving, setConfirmSaving] =
    useState<ICustomerProcess>(initialData);
  const closedModal = useCallback(() => {
    modalConfirm.current?.close();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [consent_storage_boolean, setConsent_storage_boolean] = useState<
    boolean | undefined
  >(!!initialData?.data.consent?.fields.storage);

  const [enableInputs, setEnableInputs] = useState(true);

  const handleSubmit = useCallback(
    async ({ data }: ICustomerProcess) => {
      try {
        form.current?.setErrors({});
        const schema = Yup.object().shape({
          data: Yup.object().shape({
            consent: Yup.object().shape({
              fields: Yup.object().shape({
                consent: enableInputs
                  ? Yup.string().required('Campo obrigatório')
                  : Yup.string().nullable(),
                how: enableInputs
                  ? Yup.string().required('Campo obrigatório')
                  : Yup.string().nullable(),
                who: enableInputs
                  ? Yup.string().required('Campo obrigatório')
                  : Yup.string().nullable(),
                storage: enableInputs
                  ? Yup.string().required('Campo obrigatório')
                  : Yup.string().nullable(),
              }),
            }),
          }),
        });
        await schema.validate(
          { data },
          {
            abortEarly: false,
          },
        );

        const newCustomerProcess: ICustomerProcess = {
          ...initialData,
          data: {
            ...initialData.data,
            consent: {
              ...data.consent,
              fields: {
                ...data.consent.fields,
                firstrecording_consent: true,
              },
            },
          },
        };

        setConfirmSaving(newCustomerProcess);
        modalConfirm.current?.open();

        // try {
        //   await api.patch(
        //     `/customerProcesses/${initialData.id}`,
        //     newCustomerProcess,
        //   );

        //   mutateProcess(newCustomerProcess, 'edit');
        // } catch (error) {
        //   addToast({
        //     title: 'Erro',
        //     type: 'error',
        //     description: 'Falha ao tentar salvar',
        //   });
        // } finally {
        //   setLoading(false);
        // }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          form.current?.setErrors(errors);
        }
      }
    },
    [initialData, enableInputs],
  );

  const disabled = useMemo(() => {
    if (readOnly) {
      return true;
    }
    if (
      initialData.status !== 'Preenchimento' &&
      initialData.status !== 'Revalidação' &&
      initialData.reopened_process !== true
    ) {
      return true;
    }
    return false;
  }, [initialData.reopened_process, initialData.status, readOnly]);

  return (
    <>
      <Container ref={form} onSubmit={handleSubmit} initialData={initialData}>
        <Main>
          <InputBoolean2
            label="A organização obtém o consentimento do titular para o tratamento dos dados pessoais?"
            explanation="O consentimento trata-se da autorização ou manifestação favorável à utilização de seus dados pessoais."
            name="data.consent.fields.consent"
            disabled={disabled}
            onChangeBoolean={booleanValue => {
              setEnableInputs(false);
              callbackUsesConsent(booleanValue);
              booleanValue && setEnableInputs(booleanValue);
            }}
          />

          {enableInputs && (
            <>
              <TextArea
                label="Como é realizado o consentimento?"
                explanation={
                  'Explique de que forma a organização obtêm a autorização formal do titular para a utilização dos seus dados pessoais. \nExemplos: Documentos fisicos, autorização virtual ou gravação de voz.'
                }
                name="data.consent.fields.how"
                disabled={disabled}
              />
              <Input
                label="Qual o cargo, setor ou função é/ou responsável por realizar a coleta do consentimento?"
                explanation="Indicar o cargo, setor ou função do responsável por realizar a coleta do consentimento."
                name="data.consent.fields.who"
                disabled={disabled}
              />

              {/* <InputBoolean
                label="O consentimento é armazenado?"
                inputLabel="Como o consentimento é armazenado?"
                explanation="Indicar se o consentimento é armazenado."
                explanationsec={
                  'Indicar como o consentimento é armazenado. \nExemplos: armários físicos, nuvem, pendrive, HD externo, dispositivos móveis.'
                }
                name="data.consent.fields.storage"
                onChangeBoolean={booleanValue =>
                  setConsent_storage_boolean(booleanValue)
                }
                disabled={disabled}
              /> */}

              <Input
                label="Como o consentimento é armazenado?"
                explanation="O controlador deve ter um sistema de gestão de consentimento em que seja possível o armazenamento do consentimento dado pelo Titular. Este controle pode ser digital ou físico."
                name="data.consent.fields.storage"
                disabled={disabled}
              />

              <InputBoolean2
                label="Há rastreabilidade?"
                explanation={
                  'Indicar como o consentimento é armazenado. \nExemplos: armários físicos, nuvem, pendrive, HD externo, dispositivos móveis.'
                }
                name="data.consent.fields.has_traceability"
                disabled={disabled}
              />
            </>
          )}
        </Main>
        <Modal ref={modalConfirm} removeCloseButton size="sm">
          <ModalConfirm
            newData={confirmSaving}
            onCancel={() => modalConfirm.current?.close()}
            onSubmit={closedModal}
          />
        </Modal>

        {!disabled && (
          <Footer>
            <Button type="submit" background="#4ECE3D">
              Salvar
            </Button>
          </Footer>
        )}
      </Container>
    </>
  );
};

export default FormProcessConsent;
