import styled from 'styled-components';
import Tooltip from '../Tooltip';

interface IContainerProps {
  isErrored: boolean;
}


export const Container = styled.div<IContainerProps>`
  display: flex;
  width: 100%;

  > div.ElementOne {
    width: 100%;
    margin: 0px 20px 0px 0px;

    > div.box_input {
      display: flex;

      > div.input_select {
        width: 97%;
        display: block;
      }
    }
  }

`;

export const Label = styled.h1`
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #8dabc4;
  margin-bottom: 10px;
  display: flex;

  > .Icon {
    margin-left: 5px;
  }
`;

export const Error = styled(Tooltip)`
  svg {
    margin: 10px 10px 0 5px;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
