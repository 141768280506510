import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

import {
  Wrapper,
  InputContainer,
  CheckboxContainer,
  Content,
  Label,
  InnerLabel,
  Options,
  Error,
} from './styles';

interface IInputBooleanProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  explanation?: string;
  explanationsec?: string;
  inputLabel: string;
  innerLabel?: string;
  inputInnerLabel?: string;
  name: string;
  focused?: boolean;
  icon?: React.ComponentType<IconBaseProps>;
  disabled?: boolean;
  onChangeBoolean?(booleanValue: boolean | undefined): void;
}

const InputBoolean: React.FC<IInputBooleanProps> = ({
  name,
  label,
  explanation,
  explanationsec,
  inputLabel,
  inputInnerLabel,
  icon: Icon,
  focused,
  onChangeBoolean,
  disabled,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(focused || false);
  const [isFilled, setisFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [booleanValue, setBooleanValue] = useState<boolean | undefined>(
    defaultValue === undefined || defaultValue === null
      ? undefined
      : !!defaultValue,
  );

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setisFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    if (onChangeBoolean) {
      onChangeBoolean(booleanValue);
    }
  }, [booleanValue, onChangeBoolean]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: HTMLInputElement) => {
        if (booleanValue === undefined) {
          return undefined;
        }
        if (booleanValue === true) {
          return ref.value;
        }
        return '';
      },
    });
  }, [booleanValue, fieldName, inputLabel, registerField]);

  return (
    <Wrapper className="InputBoolean">
      <CheckboxContainer>
        {label && (
          <Label>
            {label}{' '}
            {explanation && (
              <AiOutlineQuestionCircle
                size={20}
                color="rgb(0,121,196)"
                title={explanation}
                className="Icon"
              />
            )}
          </Label>
        )}
        <Options>
          <label htmlFor={`${fieldName}_sim`}>
            <input
              id={`${fieldName}_sim`}
              type="radio"
              checked={booleanValue || false}
              onChange={() => !disabled && setBooleanValue(true)}
            />
            <span style={{ paddingLeft: '12px' }}>Sim</span>
          </label>
          <label htmlFor={`${fieldName}_nao`} style={{ marginLeft: '40px' }}>
            <input
              id={`${fieldName}_nao`}
              type="radio"
              checked={booleanValue === undefined ? true : !booleanValue}
              onChange={() => !disabled && setBooleanValue(false)}
            />
            <span style={{ paddingLeft: '12px' }}>Não</span>
          </label>
        </Options>
      </CheckboxContainer>
      {inputLabel && booleanValue && (
        <InputContainer>
          {inputLabel && (
            <Label>
              {inputLabel}{' '}
              {explanationsec && (
                <AiOutlineQuestionCircle
                  size={20}
                  color="rgb(0,121,196)"
                  title={explanationsec}
                  className="Icon"
                />
              )}
            </Label>
          )}
          <Content
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocused}
            innerLabel={inputInnerLabel}
            disabled={!booleanValue || disabled}
          >
            {Icon && <Icon size={20} />}
            <label htmlFor={name}>
              {inputInnerLabel && <InnerLabel>{inputInnerLabel}</InnerLabel>}
              <input
                id={name}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={defaultValue}
                ref={inputRef}
                disabled={!booleanValue || disabled}
                {...rest}
              />
            </label>

            {error && (
              <Error title={error}>
                <FiAlertCircle color="#c53030" size={20} />
              </Error>
            )}
          </Content>
        </InputContainer>
      )}
    </Wrapper>
  );
};

export default InputBoolean;
